import { BaseSyntheticEvent, useContext, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { ConstructionsContext } from '../../../../contexts/ConstructionsContext'
import { ClientsContext } from '../../../../contexts/ClientsContext'
import { mdiCheck, mdiFileDocument, mdiHome, mdiImagePlus } from '@mdi/js'
import { api } from '../../../../services/api'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { ptBR } from 'date-fns/locale'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import styles from './style.module.css'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

type SubmitType = {
  client_id: number
  start_date: string
  start_date_formated: string
  delivery_forecast: string
  neighborhood: string
  name: string
  address: string
  address_number: number
  observation: string
  image?: File
  contract?: File
}

type InputType = {
  value: string | File
  name: string
}

type ClientType = {
  id: number
  name: string
}

export default function ModalCreateConstruction({ setModalCreateConstructionOpen, handleToast }) {
  const [formValues, setFormValues] = useState({
    start_date: `${new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`,
    delivery_forecast: `${new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`
  } as SubmitType)
  const [contract, setContract] = useState<File>(null)

  const { getConstructions } = useContext(ConstructionsContext)
  const { clients } = useContext(ClientsContext)

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function handleCreate(e: BaseSyntheticEvent) {
    e.preventDefault()

    const formData = new FormData()
    Object.keys(formValues).map((key) => formData.append(key, formValues[key]))

    api
      .post(`/constructions`, formData)
      .then((response) => {
        if (contract) addContract(Number(response.data.data.constructionId), contract)
        getConstructions()
        handleToast(<Toast category="success" message={response.data.message} />)
        setModalCreateConstructionOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  async function addContract(constructionId: number, file: File) {
    const formData = new FormData()
    formData.append('contract', file)

    api
      .put(`/constructions/${constructionId}/contract`, formData)
      .then((_) => {})
      .catch((err) => {
        console.log(err)
        handleToast(<Toast category="error" message="Erro ao salvar o contrato" />)
      })
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiHome} size={1.5} />
            <h4 className={styles.title}>CADASTRAR OBRA</h4>
          </div>

          <form className={styles.form__create} onSubmit={(e) => handleCreate(e)}>
            <Autocomplete
              value={undefined}
              onChange={(event, newValue: ClientType) => {
                handleInputChange({
                  name: 'client_id',
                  value: String(newValue.id)
                })
              }}
              options={clients}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Cliente" required />}
            />

            <div className={styles.input__block}>
              <TextField
                label="Nome da Obra"
                type="text"
                required
                autoComplete="off"
                onChange={(e) =>
                  handleInputChange({
                    name: 'name',
                    value: e.target.value
                  })
                }
              />

              <TextField
                label="Valor"
                type="text"
                autoComplete="off"
                onChange={(e) =>
                  handleInputChange({
                    name: 'value',
                    value: e.target.value
                  })
                }
              />
            </div>

            <TextField
              label="Endereço"
              type="text"
              required
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'address',
                  value: e.target.value
                })
              }
            />

            <div className={styles.input__block}>
              <TextField
                label="Bairro"
                type="text"
                required
                autoComplete="off"
                onChange={(e) =>
                  handleInputChange({
                    name: 'neighborhood',
                    value: e.target.value
                  })
                }
              />
              <TextField
                label="Número"
                type="text"
                required
                autoComplete="off"
                onChange={(e) =>
                  handleInputChange({
                    name: 'address_number',
                    value: e.target.value
                  })
                }
              />
            </div>

            <div className={styles.input__block}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  label="Data de Início"
                  mask="__/__/____"
                  value={formValues.start_date ? formValues.start_date : `${new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}
                  onChange={(newValue) => {
                    handleInputChange({
                      name: 'start_date',
                      value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                    })
                  }}
                  renderInput={(params) => <TextField {...params} required />}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  label="Previsão de Finalização"
                  mask="__/__/____"
                  value={
                    formValues.delivery_forecast
                      ? formValues.delivery_forecast
                      : `${new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`
                  }
                  onChange={(newValue) => {
                    handleInputChange({
                      name: 'delivery_forecast',
                      value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                    })
                  }}
                  renderInput={(params) => <TextField {...params} required />}
                />
              </LocalizationProvider>
            </div>

            <div className={styles.add__image}>
              <div className={styles.field__box} title="Adicionar Imagem">
                <input
                  accept="image/png,image/jpg,image/jpeg"
                  style={{ display: 'none' }}
                  onChange={(e) =>
                    handleInputChange({
                      name: 'image',
                      value: e.target.files[0]
                    })
                  }
                  id="construction-image"
                  type="file"
                />
                <label htmlFor="construction-image">
                  <Icon path={mdiImagePlus} size={1.2} color="#FFF" />
                </label>
                {formValues.image ? (
                  <div className={styles.field__filled}>
                    <Icon path={mdiCheck} size={1.2} color="#FFF" />
                  </div>
                ) : null}
              </div>

              <div className={styles.field__box} title="Adicionar Contrato">
                <input
                  accept="application/pdf"
                  style={{ display: 'none' }}
                  onChange={(e) => setContract(e.target.files[0])}
                  id="construction-contract"
                  type="file"
                />
                <label htmlFor="construction-contract">
                  <Icon path={mdiFileDocument} size={1.2} color="#FFF" />
                </label>
                {contract ? (
                  <div className={styles.field__filled}>
                    <Icon path={mdiCheck} size={1.2} color="#FFF" />
                  </div>
                ) : null}
              </div>
            </div>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalCreateConstructionOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
