import { useState } from 'react'

import EyeIcon from '../../../assets/icons/eye.svg'
import CheckIcon from '../../../assets/icons/success.svg'
import RunningIcon from '../../../assets/icons/clock_running.svg'

import styles from './styles.module.css'
import DefaultModal from '../../Modals/DefaultModal'
import ModalClientViewProject from './Modals/Details'

export interface Project {
  area: string
  client: string
  client_id: number
  colaborator_delivery_date: string
  criterion: string
  duration: number
  finish_date: string
  finish_date_prediction: string
  finish_date_prediction_unformated: string
  id: number
  local: string
  project_type: string
  project_type_category: number
  project_type_id: number
  responsable: string
  responsable_id: number
  start_date: string
  start_date_unformated: string
  status: number
  document: string
  value: string
}

interface Props {
  project: Project
}

export default function Project({ project }: Props) {
  const [modalClientViewProjectOpen, setModalClientViewProjectOpen] = useState(false)

  return (
    <div className={styles.construction__box}>
      <div className={styles.details}>
        <div className={styles.info}>
          {project.status === 4 ? <img src={CheckIcon} alt="check" /> : <img src={RunningIcon} alt="running" />}

          <h1>{project.project_type}</h1>
        </div>

        <div className={styles.actions}>
          <img src={EyeIcon} alt="eye" onClick={() => setModalClientViewProjectOpen(true)} />
        </div>
      </div>

      <DefaultModal isOpen={modalClientViewProjectOpen} handleOpen={setModalClientViewProjectOpen}>
        <ModalClientViewProject setModalClientViewProjectOpen={setModalClientViewProjectOpen} project={project} />
      </DefaultModal>
    </div>
  )
}
