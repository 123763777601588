import { BaseSyntheticEvent, useContext, useState, useEffect, useRef } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { api } from '../../../../../services/api'
import { mdiPackageVariantClosed } from '@mdi/js'
import { ProductsContext, ProductType } from '../../../../../contexts/ProductsContext'
import { MeasureContext, MeasuresType } from '../../../../../contexts/MeasureContext'

import Icon from '@mdi/react'
import Toast from '../../../../Toast'

import styles from './style.module.css'
import ModalCreateProduct from '../../../Product/Create'
import DefaultModal from '../../../DefaultModal'

type SubmitType = {
  product_id: number
  measure_id: number
  quantity: number
}

type InputType = {
  value: string | File
  name: string
}

export default function ModalAddProduct({ listId, setModalAddProductOpen, getProductsList, handleToast }) {
  const [modalCreateProductOpen, setModalCreateProductOpen] = useState(false)

  const [formValues, setFormValues] = useState({} as SubmitType)

  const { products, getProducts } = useContext(ProductsContext)
  const { measures } = useContext(MeasureContext)

  const [writedProduct, setWritedProduct] = useState('')

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function create(e: BaseSyntheticEvent) {
    e.preventDefault()

    api
      .post(`/lists/product/add/${listId}`, formValues)
      .then((response) => {
        handleToast(<Toast category="success" message={response.data.message} />)
        getProductsList()
        setModalAddProductOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  function autoSelectCreatedProduct(product: ProductType) {
    setWritedProduct('')
  }

  function clearField() {
    setWritedProduct('')
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiPackageVariantClosed} size={1.5} />
            <h4 className={styles.title}>NOVO PRODUTO</h4>
          </div>

          <form className={styles.form__create} onSubmit={(e) => create(e)}>
            <Autocomplete
              onBlur={() => clearField()}
              onChange={(e, newValue: ProductType) => {
                handleInputChange({
                  name: 'product_id',
                  value: newValue && String(newValue.id)
                })
              }}
              options={products}
              getOptionLabel={(option) => option.description}
              noOptionsText={<div onClick={(e) => setModalCreateProductOpen(true)}>+ adicionar produto...</div>}
              renderInput={(params) => <TextField {...params} label="Produto" onChange={(e) => setWritedProduct(e.target.value)} required />}
            />

            <Autocomplete
              value={undefined}
              onChange={(e, newValue: MeasuresType) => {
                handleInputChange({
                  name: 'measure_id',
                  value: String(newValue.id)
                })
              }}
              options={measures}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Un. de Medida" required />}
            />

            <TextField
              label="Quantidade"
              type="text"
              required
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'quantity',
                  value: e.target.value
                })
              }
            />

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalAddProductOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>

      <DefaultModal handleOpen={setModalCreateProductOpen} isOpen={modalCreateProductOpen}>
        <ModalCreateProduct
          setModalCreateProductOpen={setModalCreateProductOpen}
          handleToast={handleToast}
          description={writedProduct}
          callback={autoSelectCreatedProduct}
          clearField={clearField}
        />
      </DefaultModal>
    </div>
  )
}
