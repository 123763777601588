const links = {
  // ROLES
  // 1 - Possui todas as permissões do sistema: Administrador.
  // 2 - Poderá lançar despesas e receitas além de ter acesso as configurações do sistema: Financeiro.
  // 3 - Lançar produtos/ferramentas/diários de obra: Engenheiro Júnior (Obra).
  // 4 - Lançar diários de obra, listas/compra de produtos: Engenheiro Júnior (Administrativo).
  // 5 - Irá gerenciar e lançar projetos para os projetistas: Projetista Chefe.
  // 6 - Terá acesso apenas aos projetos que lhe forem cadastrados pelo projetista chefe: Projetista.
  title: 'Cadastros',
  links: [
    { label: 'Produtos', href: '/records/products', roles: [1, 2, 3, 4] },
    { label: 'Ferramentas', href: '/records/tools', roles: [1, 2, 3, 4] },
    { label: 'Fornecedores', href: '/records/providers', roles: [1, 2, 3, 4] },
    { label: 'Clientes', href: '/records/clients', roles: [1, 2, 3, 4] },
    { label: 'Obras', href: '/records/constructions', roles: [1, 2, 3, 4] },
    { label: 'Projetos', href: '/records/projects', roles: [1, 2, 5, 4, 6] },
    { label: 'Colaboradores', href: '/records/employees', roles: [1, 2, 3, 4] },
    // {
    //   label: 'Diárias de Colaboradores',
    //   href: '/records/diaries/colaborators',
    //   roles: [1, 2, 3, 4],
    // },
    // {
    //   label: 'Faltas de Colaboradores',
    //   href: '/records/absents/colaborators',
    //   roles: [1, 2, 3, 4],
    // },
    {
      label: 'Listas de Compras',
      href: '/records/lists',
      roles: [1, 2, 3, 4]
    },
    {
      label: 'Pedidos de Compras',
      href: '/records/purchases',
      roles: [1, 2, 3, 4]
    }
  ]
}

export default links
