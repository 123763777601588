import { BaseSyntheticEvent, useContext, useState } from 'react'
import { Autocomplete, TextField, Tooltip } from '@mui/material'
import { api } from '../../../../services/api'
import { mdiImagePlus, mdiPackageVariantClosed } from '@mdi/js'
import { ConstructionsContext } from '../../../../contexts/ConstructionsContext'
import { ClientsContext } from '../../../../contexts/ClientsContext'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { ptBR } from 'date-fns/locale'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

import styles from './style.module.css'
import DefaultModal from '../../DefaultModal'
import ModalAddFile from '../AddFile'
import { ClientType } from '../../../../contexts/ClientsContext'
import { ProjectsContext, TypesType } from '../../../../contexts/ProjectsContext'

type SubmitType = {
  client_id: number
  project_type_id: number
  area: string
  start_date: string
}

type InputType = {
  value: string | File
  name: string
}

type AddedFile = {
  description: string
  file: File
}

export default function ModalCreateProject({ setModalCreateProjectOpen, handleToast }) {
  const [modalAddFileOpen, setModalAddFileOpen] = useState(false)

  const [formValues, setFormValues] = useState({} as SubmitType)

  const { clients } = useContext(ClientsContext)
  const { projectsTypes } = useContext(ProjectsContext)

  const [files, setFiles] = useState<AddedFile[]>([])

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function create(e: BaseSyntheticEvent) {
    e.preventDefault()

    api
      .post(`/projects`, formValues)
      .then((response) => {
        handleToast(<Toast category="success" message={response.data.message} />)
        saveFiles(response.data.project.id)
        setModalCreateProjectOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  async function saveFiles(projectId: number) {
    for (const file in files) {
      const formData = new FormData()
      formData.append('file', files[file].file)
      formData.append('description', files[file].description)
      formData.append('project_id', projectId.toString())

      try {
        await api.post(`/projects/files`, formData)
      } catch (err) {
        console.error({ message: 'fail on save project files', err })
      }
    }
  }

  function addFile(description: string, file: File) {
    setFiles((prev) => [...prev, { description, file }])
    setModalAddFileOpen(false)
  }

  function removeFile(description: string) {
    setFiles((prev) => [...prev.filter((f) => f.description !== description)])
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiPackageVariantClosed} size={1.5} />
            <h4 className={styles.title}>NOVO PROJETO</h4>
          </div>

          <form className={styles.form__create} onSubmit={(e) => create(e)}>
            <Autocomplete
              value={undefined}
              onChange={(event, newValue: ClientType) => {
                handleInputChange({
                  name: 'client_id',
                  value: newValue && newValue.id.toString()
                })
              }}
              options={clients}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Cliente" required />}
            />

            <Autocomplete
              value={undefined}
              onChange={(event, newValue: TypesType) => {
                handleInputChange({
                  name: 'project_type_id',
                  value: newValue && newValue.id.toString()
                })
              }}
              options={projectsTypes}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Tipo do Projeto" required />}
            />

            <TextField
              label="Área/Tempo"
              type="text"
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'area',
                  value: e.target.value
                })
              }
            />

            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Data de Ínicio"
                mask="__/__/____"
                value={formValues.start_date ? formValues.start_date : null}
                onChange={(newValue) => {
                  handleInputChange({
                    name: 'start_date',
                    value: `${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`
                  })
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            <TextField
              label="Observação"
              type="text"
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'observation',
                  value: e.target.value
                })
              }
            />

            {files.length ? (
              <div className={styles.files}>
                {files.map((file) => (
                  <Tooltip title="Clique para remover" placement="top">
                    <div className={styles.new__file} onClick={() => removeFile(file.description)}>
                      <Icon path={mdiImagePlus} size={1.2} color="#000" /> <h3>{file.description}</h3>
                    </div>
                  </Tooltip>
                ))}
              </div>
            ) : null}

            <div className={styles.add__file__button} onClick={() => setModalAddFileOpen(true)}>
              <Icon path={mdiImagePlus} size={1.2} color="#FFF" />
            </div>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalCreateProjectOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>

      <DefaultModal handleOpen={setModalAddFileOpen} isOpen={modalAddFileOpen}>
        <ModalAddFile setModalAddFileOpen={setModalAddFileOpen} addFile={addFile} />
      </DefaultModal>
    </div>
  )
}
