import React, { useState } from 'react';
import { mdiKey, mdiEye, mdiTrashCan, mdiPencil } from '@mdi/js';

import Icon from '@mdi/react';
import DefaultModal from '../Modals/DefaultModal';
import ModalEditClient from '../Modals/Client/Edit';
import ModalDeleteClient from '../Modals/Client/Delete';
import ModalClientDetails from '../Modals/Client/Details';
import ModalCreateClientAccount from '../Modals/Client/CreateAccount';

import styles from './style.module.css';

type ClientParam = {
  client: ClientType;
  refs: Header[];
  handleToast: any;
};

type Header = {
  label: string;
  ref: string;
};

type ClientType = {
  id: number;
  name: string;
};

function Client({ client, refs, handleToast }: ClientParam) {
  const [modalEditClientOpen, setModalEditClientOpen] = useState(false);
  const [modalDeleteClientOpen, setModalDeleteClientOpen] = useState(false);
  const [modalClientDetailsOpen, setModalClientDetailsOpen] = useState(false);
  const [modalCreateClientAccountOpen, setModalCreateClientAccountOpen] =
    useState(false);

  return (
    <tr className={styles.box}>
      {refs.map((ref) => (
        <td key={ref.ref}>{client[ref.ref]}</td>
      ))}

      <td className={styles.buttons}>
        <span onClick={() => setModalCreateClientAccountOpen(true)}>
          <Icon path={mdiKey} size={1.2} className={styles.icon__key} />
        </span>
        <span onClick={() => setModalClientDetailsOpen(true)}>
          <Icon path={mdiEye} size={1.2} className={styles.icon__eye} />
        </span>
        <span onClick={() => setModalEditClientOpen(true)}>
          <Icon path={mdiPencil} size={1.2} className={styles.icon__pencil} />
        </span>
        <span onClick={() => setModalDeleteClientOpen(true)}>
          <Icon path={mdiTrashCan} size={1.2} className={styles.icon__trash} />
        </span>
      </td>

      <DefaultModal
        handleOpen={setModalEditClientOpen}
        isOpen={modalEditClientOpen}
      >
        <ModalEditClient
          client={client}
          setModalEditClientOpen={setModalEditClientOpen}
          handleToast={handleToast}
        />
      </DefaultModal>

      <DefaultModal
        handleOpen={setModalDeleteClientOpen}
        isOpen={modalDeleteClientOpen}
      >
        <ModalDeleteClient
          client={client}
          setModalDeleteClientOpen={setModalDeleteClientOpen}
          handleToast={handleToast}
        />
      </DefaultModal>

      <DefaultModal
        handleOpen={setModalClientDetailsOpen}
        isOpen={modalClientDetailsOpen}
      >
        <ModalClientDetails
          client={client}
          setModalClientDetailsOpen={setModalClientDetailsOpen}
          handleToast={handleToast}
        />
      </DefaultModal>

      <DefaultModal
        handleOpen={setModalCreateClientAccountOpen}
        isOpen={modalCreateClientAccountOpen}
      >
        <ModalCreateClientAccount
          client={client}
          setModalCreateClientAccountOpen={setModalCreateClientAccountOpen}
          handleToast={handleToast}
        />
      </DefaultModal>
    </tr>
  );
}

export default React.memo(Client);
