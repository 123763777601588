import React, { useContext, useState } from 'react';

import styles from './style.module.css';

import iPencil from '../../assets/icons/pencil.svg';
import iTrash from '../../assets/icons/trash.svg';

import { Modal } from '@material-ui/core';

import { useForm } from 'react-hook-form';
import { api } from '../../services/api';

import Toast from '../Toast';

import { ConstructionsContext } from '../../contexts/ConstructionsContext';
import { ColaboratorsContext } from '../../contexts/ColaboratorsContext';

type ColaboratorAbsentParam = {
  absent: AbsentType;
  handleToast: any;
};

type AbsentType = {
  id: number;
  employee: string;
  employee_id: number;
  construction: string;
  construction_id: number;
  date: string;
  integral: number;
  _date: string;
};

type SubmitType = {
  employee_id: number;
  construction_id: number;
  date: string;
  integral: number;
};

function ColaboratorDiary({ absent, handleToast }: ColaboratorAbsentParam) {
  const [empDiary, setEmpDiary] = useState(absent);

  const { constructions } = useContext(ConstructionsContext);
  const { colaborators } = useContext(ColaboratorsContext);

  const { register, handleSubmit } = useForm();

  const [open, setOpen] = useState(false);
  const [modalBody, setModalBody] = useState(<div></div>);

  function deleteAbsent() {
    api
      .delete(`/employees/absents/${absent.id}`)
      .then((response) => {
        setOpen(false);
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />);
      });
  }

  async function handleEdit(data: SubmitType) {
    data['employee_id'] = absent.employee_id;

    api
      .put(`/employees/absents/${absent.id}`, data)
      .then((response) => {
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
        setOpen(false);
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />);
      });
  }

  function handleModalOpen(body: string) {
    if (body === 'edit') {
      setModalBody(bodyEditColaboratorDiary);
    } else if (body === 'delete') {
      setModalBody(bodyDeleteColaboratorDiary);
    }

    setOpen(true);
  }

  const bodyDeleteColaboratorDiary = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>
              Deseja realmente deletar essa diária?
            </h4>
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm} onClick={() => deleteAbsent()}>
              Confirmar
            </button>
            <button
              className={styles.cancel}
              type="button"
              onClick={() => setOpen(false)}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const bodyEditColaboratorDiary = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '450px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Editar Diária</h4>
          </div>

          <form className={styles.formEdit} onSubmit={handleSubmit(handleEdit)}>
            <label htmlFor="date">Data: *</label>
            <input
              name="date"
              type="date"
              {...register('date')}
              defaultValue={absent._date}
            />

            <label htmlFor="employee_id">Colaborador: *</label>
            <select
              name="employee_id"
              required
              {...register('employee_id')}
              defaultValue={absent.employee_id}
            >
              <option value="0">Selecione um colaborador</option>
              {colaborators.map((employee) => (
                <option key={employee.id} value={employee.id}>
                  {employee.name}
                </option>
              ))}
            </select>

            <label htmlFor="construction_id">Obra: *</label>
            <select
              defaultValue={absent.construction_id}
              name="construction_id"
              required
              {...register('construction_id')}
            >
              <option value="0">Selecione uma obra</option>
              {constructions.map((employee) => (
                <option key={employee.id} value={employee.id}>
                  {employee.name}
                </option>
              ))}
            </select>

            <div className={styles.inputRadios}>
              <input
                type="radio"
                name="integral"
                {...register('integral')}
                value="0"
                checked={absent.integral === 0}
              />
              Meio Período
              <input
                type="radio"
                name="integral"
                {...register('integral')}
                value="1"
                checked={absent.integral === 1}
              />
              Integral
            </div>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button
                className={styles.cancel}
                type="button"
                onClick={() => setOpen(false)}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.box}>
      <div className={styles.boxInfoConstruction}>
        <span>{empDiary.employee}</span>
      </div>

      <div className={styles.boxInfoConstruction} style={{ width: '50%' }}>
        <span>{empDiary.date}</span>
      </div>

      <div className={styles.buttons}>
        <img src={iPencil} alt="" onClick={() => handleModalOpen('edit')} />
        <img src={iTrash} alt="" onClick={() => handleModalOpen('delete')} />
      </div>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>
    </div>
  );
}

export default React.memo(ColaboratorDiary);
