import { BaseSyntheticEvent, useContext } from 'react';
import { ColaboratorsContext } from '../../../../contexts/ColaboratorsContext';
import { api } from '../../../../services/api';
import { mdiAccountTie } from '@mdi/js';

import Icon from '@mdi/react';
import Toast from '../../../Toast';
import styles from './style.module.css';

export default function ModalDeleteColaborator({
  colaborator,
  setModalDeleteColaboratorOpen,
  handleToast,
}) {
  const { getColaborators } = useContext(ColaboratorsContext);

  async function handleDelete(e: BaseSyntheticEvent) {
    e.preventDefault();

    api
      .delete(`/employees/${colaborator.id}`)
      .then((response) => {
        getColaborators();
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
        setModalDeleteColaboratorOpen(false);
      })
      .catch((err) => {
        handleToast(
          <Toast category="error" message={err.response.data.message} />
        );
      });
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiAccountTie} size={1.5} />
            <h4 className={styles.title}>DELETAR COLABORADOR</h4>
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm} onClick={(e) => handleDelete(e)}>
              Confirmar
            </button>
            <button
              className={styles.cancel}
              type="button"
              onClick={() => setModalDeleteColaboratorOpen(false)}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
