import { mdiHammer } from '@mdi/js'

import Icon from '@mdi/react'

import styles from './style.module.css'

export default function ModalDeleteStage({ setModalDeleteStageOpen, remove }) {
  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiHammer} size={1.5} />
            <h4 className={styles.title}>DELETAR ETAPA DO DIÁRIO</h4>
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm} onClick={() => remove()}>
              Confirmar
            </button>
            <button className={styles.cancel} type="button" onClick={() => setModalDeleteStageOpen(false)}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
