import { useEffect, useState } from 'react'
import { mdiFileDocument, mdiListStatus, mdiPencil, mdiPlusBox, mdiTrashCan } from '@mdi/js'
import { api } from '../../../../services/api'
import { IIncoming } from '../../../../interfaces/Incoming'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import styles from './style.module.css'
import Parcel from './Parcel'
import DefaultModal from '../../DefaultModal'
import ModalEditExpense from '../Edit'
import ModalCreateParcel from '../../Parcel/Create'
import ModalDeleteIncoming from '../Delete'
import { variables } from '../../../../variables'

export default function ModalIncomingParcels({ incoming, setModalIncomingParcelsOpen, handleToast }) {
  const [parcels, setParcels] = useState([])
  const [incomingDetails, setIncomingDetails] = useState<IIncoming>({} as IIncoming)
  const [totalParcels, setTotalParcels] = useState(0)
  const [totalPaidParcels, setTotalPaidParcels] = useState(0)

  const [modalDeleteIncomingOpen, setModalDeleteIncomingOpen] = useState(false)
  const [modalEditIncomingOpen, setModalEditIncomingOpen] = useState(false)
  const [modalCreateParcelOpen, setModalCreateParcelOpen] = useState(false)

  const isParcel = !!incoming.parcel

  useEffect(() => {
    getIncomingParcels()
    getIncoming()
  }, [])

  async function getIncomingParcels() {
    const { data, status } = await api.get(`/incomings_parcels/incoming/${isParcel ? incoming.incoming_id : incoming.id}`)

    if (status !== 200) {
      handleToast(<Toast category="error" message={data.message} />)
      return
    }

    let total = 0
    let totalPaid = 0

    for (const parcel of data) {
      if (parcel.payday) {
        totalPaid += Number(parcel.value)
      }

      total += Number(parcel.value)
    }

    setTotalPaidParcels(totalPaid)
    setTotalParcels(total)
    setParcels(data)
  }

  async function getIncoming() {
    const { data, status } = await api.get(`/incomings/${isParcel ? incoming.incoming_id : incoming.id}`)

    if (status !== 200) {
      handleToast(<Toast category="error" message={data.message} />)
      return
    }

    setIncomingDetails(data[0])
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiListStatus} size={1.5} />
            <h4 className={styles.title}>PARCELAS DA RECEITA</h4>
          </div>

          <div className={styles.incoming__details}>
            <h1>
              DETALHES DA RECEITA
              <div>
                {incomingDetails.invoice && (
                  <span onClick={() => window.open(`${variables.api}/public/${incomingDetails.invoice}`)} title="Nota Fiscal">
                    <Icon path={mdiFileDocument} size={1.2} className={styles.icon__pencil} />
                  </span>
                )}
                <span onClick={() => setModalEditIncomingOpen(true)} title="Editar">
                  <Icon path={mdiPencil} size={1.2} color="green" />
                </span>
                <span onClick={() => setModalDeleteIncomingOpen(true)} title="Excluir">
                  <Icon path={mdiTrashCan} size={1.2} color="red" />
                </span>
              </div>
            </h1>
            <h5>tipo: {incomingDetails.incoming_type}</h5>
            {incomingDetails.client && <h5>cliente: {incomingDetails.client}</h5>}
            {incomingDetails.money_origin && <h5>destino: {incomingDetails.money_origin}</h5>}
            <h5>
              valor: {Number(incomingDetails.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} parcelado em {incomingDetails.parcels}x
            </h5>

            <h5>
              total das parcelas:{' '}
              {totalParcels !== Number(incomingDetails.value) || parcels.length !== incomingDetails.parcels ? (
                <b
                  title={
                    totalParcels !== Number(incomingDetails.value)
                      ? 'Valor total das parcelas diferente do valor total da despesa'
                      : 'Quantidade de parcelas cadastradas diferente da quantidade cadastrada na despesa'
                  }
                >
                  {totalParcels.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} ({parcels.length} parcela(s))
                </b>
              ) : (
                <>
                  {totalParcels.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} ({parcels.length} parcela(s))
                </>
              )}
            </h5>
            <h5>total restante: {Number(totalParcels - totalPaidParcels).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h5>
          </div>

          <div className={styles.box__content}>
            <table>
              <thead>
                <tr>
                  <th>data</th>
                  <th>valor</th>
                  <th align="right">
                    <button onClick={() => setModalCreateParcelOpen(true)}>
                      <Icon path={mdiPlusBox} size={1.2} color="green" title="Adicionar parcela" />
                    </button>
                  </th>
                </tr>
              </thead>

              <tbody>
                {parcels.map((parcel) => (
                  <Parcel parcel={parcel} getIncomingParcels={getIncomingParcels} handleToast={handleToast} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <DefaultModal isOpen={modalCreateParcelOpen} handleOpen={setModalCreateParcelOpen}>
        <ModalCreateParcel
          type="incoming"
          expenseId={incomingDetails.id}
          getParcels={getIncomingParcels}
          handleToast={handleToast}
          setModalCreateParcelOpen={setModalCreateParcelOpen}
        />
      </DefaultModal>

      <DefaultModal isOpen={modalEditIncomingOpen} handleOpen={setModalEditIncomingOpen}>
        <ModalEditExpense getIncoming={getIncoming} incoming={incomingDetails} handleToast={handleToast} setModalEditIncomingOpen={setModalEditIncomingOpen} />
      </DefaultModal>

      <DefaultModal isOpen={modalDeleteIncomingOpen} handleOpen={setModalDeleteIncomingOpen}>
        <ModalDeleteIncoming incoming={incomingDetails} handleToast={handleToast} setModalDeleteIncomingOpen={setModalDeleteIncomingOpen} />
      </DefaultModal>
    </div>
  )
}
