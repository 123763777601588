import axios from 'axios';
import Cookies from 'js-cookie';
import { variables } from '../variables';

const token = Cookies.get('token');

export const apiImages = axios.create({
  baseURL: variables.apiImages,
});

apiImages.interceptors.response.use((response) => {
  if (response.status === 401) {
    Cookies.remove('token');

    window.location.href = '/';
  }

  return response;
});

if (token) {
  apiImages.defaults.headers['Authorization'] = `Bearer ${token}`;
}
