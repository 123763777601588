import { BaseSyntheticEvent, useContext, useState } from 'react'
import { TextField } from '@mui/material'
import { ProductsContext, ProductType } from '../../../../contexts/ProductsContext'
import { api } from '../../../../services/api'
import { mdiPackageVariantClosed } from '@mdi/js'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import styles from './style.module.css'

type SubmitType = {
  description: string
}

type InputType = {
  value: string
  name: string
}

type ModalCreateProductProps = {
  setModalCreateProductOpen: any
  handleToast: any
  description?: string
  callback?: (product: ProductType) => void
  clearField?: () => void
}

export default function ModalCreateProduct({ setModalCreateProductOpen, handleToast, description, callback, clearField }: ModalCreateProductProps) {
  const [formValues, setFormValues] = useState({
    description: description || null
  } as SubmitType)

  const { getProducts } = useContext(ProductsContext)

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function handleCreate(e: BaseSyntheticEvent) {
    e.preventDefault()

    api
      .post(`/products`, formValues)
      .then((response) => {
        getProducts()
        handleToast(<Toast category="success" message={response.data.message} />)

        if (callback) {
          callback(response.data.product)
        }

        setModalCreateProductOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiPackageVariantClosed} size={1.5} />
            <h4 className={styles.title}>CADASTRAR PRODUTO</h4>
          </div>

          <form className={styles.form__create} onSubmit={(e) => handleCreate(e)}>
            <TextField
              label="Descrição"
              defaultValue={description}
              type="text"
              required
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'description',
                  value: e.target.value
                })
              }
            />

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button
                className={styles.cancel}
                type="button"
                onClick={() => {
                  setModalCreateProductOpen(false)
                  clearField()
                }}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
