import { useState } from 'react'
import DefaultModal from '../../../../../Modals/DefaultModal'
import { ScheduleType } from '../index'
import ModalScheduleBoxDetails from './ScheduleBoxDetails'
import styles from './style.module.css'

interface ScheduleBoxProps {
  schedule: ScheduleType
}

export default function ScheduleBox({ schedule }: ScheduleBoxProps) {
  const [modalScheduleBoxDetailsOpen, setModalScheduleBoxDetailsOpen] = useState(false)

  return (
    <>
      <div className={styles.schedule__box} onClick={() => setModalScheduleBoxDetailsOpen(true)}>
        <h4>{schedule.stage}</h4>
      </div>

      <DefaultModal isOpen={modalScheduleBoxDetailsOpen} handleOpen={setModalScheduleBoxDetailsOpen}>
        <ModalScheduleBoxDetails scheduleId={schedule.id} setModalScheduleBoxDetailsOpen={setModalScheduleBoxDetailsOpen} />
      </DefaultModal>
    </>
  )
}
