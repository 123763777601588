import { useEffect, useState } from 'react'
import Pagination from '@mui/material/Pagination'
import searchIcon from '../../assets/icons/search.svg'

import Product from '../Product'
import Tool from '../Tool'
import Provider from '../Provider'
import Client from '../Client'
import Colaborator from '../Colaborator'

import styles from './style.module.css'
import ProjectType from '../ProjectType'
import Expense from '../Expense'
import Incoming from '../Incoming'
import ConstructionNew from '../ConstructionNew'
import ColaboratorDiaryNew from '../ColaboratorDiaryNew'

interface TableProps {
  data: any[]
  headers: Header[]
  title: string
  componentToRender: string
  fieldToSearch: string
  handleToast: (toast: any) => void
}

interface Header {
  label: string
  ref: string
}

export default function TableGeneric({ title, headers, handleToast, componentToRender, fieldToSearch, data }: TableProps) {
  const [pageData, setPageData] = useState<any[]>(data.slice(0, 5))
  const [pages, setPages] = useState<number>(0)

  const [inputSearchValue, setInputSearchValue] = useState('')

  const COMPONENTS_PER_PAGE = 10

  useEffect(() => {
    handlePaginationChange(null, 1)

    const dataSplited = String(data.length / COMPONENTS_PER_PAGE).split('.')

    if (dataSplited[1]) {
      setPages(Number(dataSplited[0]) + 1)
    } else {
      setPages(Number(dataSplited[0]))
    }
  }, [data])

  function handlePaginationChange(event: React.ChangeEvent<unknown>, value: number) {
    const startIndex = value * COMPONENTS_PER_PAGE - COMPONENTS_PER_PAGE
    const lastIndex = startIndex + COMPONENTS_PER_PAGE

    if (inputSearchValue) {
      handleSearch(inputSearchValue)
    } else {
      setPageData(data.slice(startIndex, lastIndex))
    }
  }

  function handleSearch(term: string) {
    const fieldsToSearch = headers.map((header) => header.ref)

    let filteredItems = []

    for (let i = 0; i < fieldsToSearch.length; i++) {
      const items = data.filter((item) => `${item[fieldsToSearch[i]]}`.toLowerCase().includes(term.toLowerCase()))

      if (items.length) {
        filteredItems.push(items)
      }

      if (i + 1 === fieldsToSearch.length) {
        setPageData(filteredItems[0] ? filteredItems[0].slice(0, COMPONENTS_PER_PAGE) : [])
      }
    }
  }

  return (
    <div className={styles.table__box}>
      <div className={styles.card__header}>
        <h2>{title}</h2>

        <div className={styles.search__input}>
          <img src={searchIcon} alt="search" />
          <input
            type="text"
            placeholder="Pesquisar..."
            onChange={(e) => {
              handleSearch(e.target.value)
              setInputSearchValue(e.target.value)
            }}
          />
        </div>
      </div>

      {pageData.length ? (
        <>
          <table>
            <thead>
              <tr>
                {headers.map((header) => (
                  <td key={header.ref}>{header.label}</td>
                ))}
                <td></td>
              </tr>
            </thead>

            <tbody>
              {pageData.length ? (
                pageData.map((item) =>
                  // RECORDS COMPONENTS
                  componentToRender === 'product' ? (
                    <Product key={item.id} product={item} refs={headers} handleToast={handleToast} />
                  ) : componentToRender === 'tool' ? (
                    <Tool key={item.id} tool={item} refs={headers} handleToast={handleToast} />
                  ) : componentToRender === 'provider' ? (
                    <Provider key={item.id} refs={headers} provider={item} handleToast={handleToast} />
                  ) : componentToRender === 'client' ? (
                    <Client key={item.id} refs={headers} client={item} handleToast={handleToast} />
                  ) : componentToRender === 'colaborator' ? (
                    <Colaborator key={item.id} colaborator={item} refs={headers} handleToast={handleToast} />
                  ) : componentToRender === 'projectType' ? (
                    <ProjectType key={item.id} type={item} refs={headers} handleToast={handleToast} />
                  ) : componentToRender === 'expense' ? (
                    <Expense key={item.id} expense={item} refs={headers} handleToast={handleToast} />
                  ) : componentToRender === 'incoming' ? (
                    <Incoming key={item.id} incoming={item} refs={headers} handleToast={handleToast} />
                  ) : componentToRender === 'constructions' ? (
                    <ConstructionNew key={item.id} construction={item} refs={headers} handleToast={handleToast} />
                  ) : componentToRender === 'colaboratorsDiaries' ? (
                    <ColaboratorDiaryNew key={item.id} diary={item} refs={headers} handleToast={handleToast} />
                  ) : null
                )
              ) : (
                <span>Não encontramos nada para listar.</span>
              )}
            </tbody>
          </table>

          <div className={styles.table__footer}>
            {data.length > 5 ? <Pagination count={pages} color="primary" variant="outlined" shape="rounded" onChange={handlePaginationChange} /> : null}
          </div>
        </>
      ) : (
        <span>Não encontramos nada para listar.</span>
      )}
    </div>
  )
}
