import { BaseSyntheticEvent, useState } from 'react'
import { mdiCloseBox, mdiPlaySpeed } from '@mdi/js'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { api } from '../../../../../../../services/api'
import { ptBR } from 'date-fns/locale'
import { TextField } from '@mui/material'

import Icon from '@mdi/react'
import styles from './style.module.css'
import Toast from '../../../../../../Toast'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

export default function ModalScheduleBoxDesconfigured({ handleCloseModalAndRequestSchedules, schedule, setModalScheduleBoxDesconfiguredOpen, handleToast }) {
  const [startDatePrediction, setStartDatePrediction] = useState(null)
  const [finishDatePrediction, setFinishDatePrediction] = useState(null)

  async function configureSchedule(e: BaseSyntheticEvent) {
    e.preventDefault()

    try {
      const response = await api.put(`/constructions_schedules/configure/${schedule.id}`, {
        start_date_prediction: startDatePrediction,
        finish_date_prediction: finishDatePrediction
      })

      if (response.status === 200) {
        handleToast(<Toast category="success" message="Etapa configurada com sucesso." />)
        setModalScheduleBoxDesconfiguredOpen(false)
        handleCloseModalAndRequestSchedules()
      }
    } catch (err) {
      handleToast(<Toast category="error" message={err.message} />)
    }
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiPlaySpeed} size={1.5} />
            <h4 className={styles.title}>configurar etapa</h4>

            <div className={styles.close__button} onClick={() => setModalScheduleBoxDesconfiguredOpen(false)}>
              <Icon path={mdiCloseBox} size={1.5} />
            </div>
          </div>

          <form onSubmit={(e) => configureSchedule(e)}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Previsão de Início"
                mask="__/__/____"
                value={startDatePrediction}
                onChange={(newValue) => {
                  setStartDatePrediction(`${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`)
                }}
                renderInput={(params) => <TextField fullWidth {...params} required />}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Previsão de Finalização"
                mask="__/__/____"
                value={finishDatePrediction}
                onChange={(newValue) => {
                  setFinishDatePrediction(`${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`)
                }}
                renderInput={(params) => <TextField fullWidth {...params} required />}
              />
            </LocalizationProvider>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalScheduleBoxDesconfiguredOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
