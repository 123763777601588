const links = {
  // ROLES
  // 1 - Possui todas as permissões do sistema: Administrador.
  // 2 - Poderá lançar despesas e receitas além de ter acesso as configurações do sistema: Financeiro.
  // 3 - Lançar produtos/ferramentas/diários de obra: Engenheiro Júnior (Obra).
  // 4 - Lançar diários de obra, listas/compra de produtos: Engenheiro Júnior (Administrativo).
  // 5 - Irá gerenciar e lançar projetos para os projetistas: Projetista Chefe.
  // 6 - Terá acesso apenas aos projetos que lhe forem cadastrados pelo projetista chefe: Projetista.
  title: 'Financeiro',
  links: [
    { label: 'Despesas', href: '/expenses', roles: [1, 2] },
    { label: 'Receitas', href: '/incomings', roles: [1, 2] }
    // { label: 'Notas Fiscais', href: '/invoices', roles: [1, 2] },
  ]
}

export default links
