import React, { useContext } from 'react'

import iPencil from '../../assets/icons/pencil.svg'
import iTrash from '../../assets/icons/trash.svg'

import { Modal } from '@material-ui/core'
import { useState } from 'react'

import { useForm } from 'react-hook-form'
import { api } from '../../services/api'

import Toast from '../Toast'

import { ConstructionsContext } from '../../contexts/ConstructionsContext'
import { ColaboratorsContext } from '../../contexts/ColaboratorsContext'
import { MonetaryContext } from '../../contexts/MonetaryContext'

import { mdiKey, mdiEye, mdiTrashCan, mdiPencil, mdiClock, mdiCurrencyUsd } from '@mdi/js'

import Icon from '@mdi/react'
import DefaultModal from '../Modals/DefaultModal'
import ModalEditConstruction from '../Modals/Constructions/Edit'
import ModalDeleteConstruction from '../Modals/Constructions/Delete'
import ModalConstructionDetails from '../Modals/Constructions/Details'
import ModalConstructionBudget from '../Modals/Constructions/Budget/ConstructionStageBudget/Create'

import ConstructionBudget from '../ConstructionBudget'

import styles from './style.module.css'
import ModalEditColaboratorDiary from '../Modals/ColaboratorDiary/Edit'
import ModalDeleteColaboratorDiary from '../Modals/ColaboratorDiary/Delete'

type ColaboratorDiaryParam = {
  diary: DiaryType
  refs: Header[]
  handleToast: any
}

type DiaryType = {
  id: number
  employee: string
  employee_id: number
  address: string
  address_name: string
  address_number: number
  construction_id: number
  money_origin_id: number
  value: number
  date: string
  _date: string
}

type SubmitType = {
  client_id: number
  start_date: string
  start_date_formated: string
  delivery_forecast: string
  neighborhood: string
  name: string
  address: string
  address_number: number
  observation: string
}

type Header = {
  label: string
  ref: string
}

function ColaboratorDiaryNew({ diary, refs, handleToast }: ColaboratorDiaryParam) {
  console.log(refs)
  const [empDiary, setEmpDiary] = useState(diary)

  const { constructions } = useContext(ConstructionsContext)
  const { moneyOrigins } = useContext(MonetaryContext)
  const { getDiariesWithTheLastFilter } = useContext(ColaboratorsContext)

  const { register, handleSubmit } = useForm()

  const [open, setOpen] = useState(false)
  const [modalBody, setModalBody] = useState(<div></div>)

  const [modalEditConstructionOpen, setModalEditConstructionOpen] = useState(false)
  const [modalDeleteConstructionOpen, setModalDeleteConstructionOpen] = useState(false)
  const [modalConstructionDetailsOpen, setModalConstructionDetailsOpen] = useState(false)
  const [modalConstructionBudgetOpen, setModalConstructionBudgetOpen] = useState(false)

  const [modalEditColaboratorDiaryOpen, setModalEditColaboratorDiaryOpen] = useState(false)
  const [modalDeleteColaboratorDiaryOpen, setModalDeleteColaboratorDiaryOpen] = useState(false)

  return (
    <tr className={styles.box}>
      {refs.map((ref) => (
        <td key={ref.ref}>
          {ref.ref === 'value'
            ? Number(diary[ref.ref]).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              })
            : diary[ref.ref]}
        </td>
      ))}

      <td className={styles.buttons}>
        <span onClick={() => setModalEditColaboratorDiaryOpen(true)} title="Editar">
          <Icon path={mdiPencil} color="green" size={1.2} />
        </span>
        <span onClick={() => setModalDeleteColaboratorDiaryOpen(true)} title="Remover">
          <Icon path={mdiTrashCan} color="red" size={1.2} />
        </span>
      </td>

      <DefaultModal isOpen={modalEditColaboratorDiaryOpen} handleOpen={setModalEditColaboratorDiaryOpen}>
        <ModalEditColaboratorDiary diary={diary} handleToast={handleToast} setModalEditColaboratorDiaryOpen={setModalEditColaboratorDiaryOpen} />
      </DefaultModal>

      <DefaultModal isOpen={modalDeleteColaboratorDiaryOpen} handleOpen={setModalDeleteColaboratorDiaryOpen}>
        <ModalDeleteColaboratorDiary diaryId={diary.id} handleToast={handleToast} setModalDeleteColaboratorDiaryOpen={setModalDeleteColaboratorDiaryOpen} />
      </DefaultModal>
    </tr>
  )
}

export default React.memo(ColaboratorDiaryNew)
