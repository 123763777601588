import { useEffect, useState } from 'react'
import { api } from '../../../../services/api'
import { mdiChartBox } from '@mdi/js'

import Icon from '@mdi/react'
import styles from './style.module.css'
import DefaultModal from '../../DefaultModal'
import PDFProductsFromListGenerator from '../../../PDFProductsFromListGenerator'
import ModalAddProduct from './AddProduct'
import ListProduct from './ListProduct'

export default function ModalListDetails({ list, setModalListDetailsOpen, handleToast }) {
  const [modalAddProductOpen, setModalAddProductOpen] = useState(false)

  const [productsList, setProductsList] = useState([])

  async function loadProductsList() {
    try {
      const { data } = await api.get(`/lists/details/${list.id}`)
      setProductsList(data)
    } catch (err) {
      console.error('fail on load products list', { err })
    }
  }

  useEffect(() => {
    loadProductsList()
  }, [])

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiChartBox} size={1.5} />
            <h4 className={styles.title}>PRODUTOS CADASTRADOS</h4>
          </div>

          {productsList.length > 0 ? (
            <div>
              {productsList.map((product, index) => (
                <ListProduct product={product} index={index} refresh={loadProductsList} />
              ))}
            </div>
          ) : (
            <div>
              <h5>não existem produtos cadastrados na lista...</h5>
            </div>
          )}

          <div className={styles.buttons}>
            <button className={styles.confirm} type="button" onClick={() => setModalAddProductOpen(true)}>
              Cadastrar
            </button>
            <button
              className={styles.info}
              type="button"
              onClick={() => PDFProductsFromListGenerator(productsList, list.client, list.construction, list.description)}
            >
              Baixar PDF
            </button>
          </div>

          <button onClick={() => setModalListDetailsOpen(false)}>FECHAR</button>
        </div>
      </div>

      <DefaultModal handleOpen={setModalAddProductOpen} isOpen={modalAddProductOpen}>
        <ModalAddProduct listId={list.id} setModalAddProductOpen={setModalAddProductOpen} getProductsList={loadProductsList} handleToast={handleToast} />
      </DefaultModal>
    </div>
  )
}
