import React, { useEffect, useState, useContext } from 'react'
import { variables } from '../../variables'
import { Dialog, Fade, LinearProgress, Modal, Tooltip } from '@mui/material'
import { api } from '../../services/api'
import { ConstructionsContext } from '../../contexts/ConstructionsContext'
import { Slide } from 'react-slideshow-image'
import { mdiBadgeAccount, mdiBook } from '@mdi/js'
import { IStage } from '../../interfaces/Stage'
import { withStyles } from '@material-ui/core'

import iPencil from '../../assets/icons/pencil.svg'
import iTrash from '../../assets/icons/trash.svg'
import iEye from '../../assets/icons/eye.svg'
import iSuccess from '../../assets/icons/success.svg'
import iRemove from '../../assets/icons/remove.svg'

import Icon from '@mdi/react'

import Cookies from 'js-cookie'
import Toast from '../Toast'

import styles from './style.module.css'

import DefaultModal from '../Modals/DefaultModal'
import ModalEditDiary from '../Modals/Diary/Edit'
import ModalFullscreenImage from '../Modals/Diary/Edit/FullscreenImage'

import 'react-slideshow-image/dist/styles.css'

type DiaryParam = {
  diary: DiaryType
  handleToast?: any
  getDiaries?: any
  constructionScheduleId: number
}

type ScheduleAdditionalInfo = {
  stage: string
  totalWorkedArea: number
  totalArea: number
  conclusionPercentage: number
  measure: string
}

type DiaryReqType = {
  date: string
  summary: string
  measure: string
  images: DiaryImagesType[]
}

type DiaryType = {
  id: number
  construction_id: number
  client: string
  construction: string
  address: string
  address_number: string
  date: string
  date_unformated: string
  colaborators: string
  description: string
  status: number
  summary: string
  images: DiaryImagesType[]
  stages: IStage[]
}

type DiaryImagesType = {
  id: number
  image: string
}

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    width: '100%',
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  bar: {
    borderRadius: 5
  }
}))(LinearProgress)

function Diary({ diary, handleToast, getDiaries, constructionScheduleId }: DiaryParam) {
  console.log({ diary })
  const role = Number(Cookies.get('role'))

  const [scheduleAdditionalInfo, setScheduleAdditionalInfo] = useState({} as ScheduleAdditionalInfo)
  const { getConstructions } = useContext(ConstructionsContext)
  const [imageToFullscreen, setImageToFullscreen] = useState({})
  const [open, setOpen] = useState(false)
  const [modalBody, setModalBody] = useState(<div></div>)
  const [bodyDiaryImagesModal, setBodyDiaryImagesModal] = useState(<div></div>)
  const [bodyModalFullscreenImage, setBodyModalFullscreenImage] = useState(<div></div>)
  const [openModalDiariesImages, setOpenModalDiariesImages] = useState(false)
  const [modalFullscreenImage, setModalFullscreenImageOpen] = useState(false)
  const [checkedColaborators, setCheckedColaborators] = useState([])
  const [modalEditDiaryOpen, setModalEditDiaryOpen] = useState(false)

  const [diaryImages, setDiaryImages] = useState([])

  const properties = {
    duration: 5000,
    transitionDuration: 500,
    prevArrow: (
      <div style={{ width: '30px', marginRight: '-30px', paddingLeft: '5px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#00000">
          <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" />
        </svg>
      </div>
    ),
    nextArrow: (
      <div style={{ width: '30px', marginLeft: '-30px', paddingRight: '5px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#00000">
          <path d="M512 256L270 42.6v138.2H0v150.6h270v138z" />
        </svg>
      </div>
    )
  }

  useEffect(() => {
    getDiaryImages()
    // getScheduleAdditionalInfo()
    console.log(diary.colaborators)
    diary.colaborators && setCheckedColaborators(JSON.parse(diary.colaborators))
  }, [diary])

  async function getDiaryImages() {
    try {
      const { data } = await api.get(`/constructions_diaries/images/${diary.id}`)
      setDiaryImages(data)
    } catch (err) {
      console.log('fail on load diary images', {
        err
      })
    }
  }

  // function getScheduleAdditionalInfo() {
  //   api
  //     .get(`/constructions_schedules/${diary.construction_schedule_id}/additional_info`)
  //     .then((response) => {
  //       setScheduleAdditionalInfo(response.data)
  //       console.log({ scheduleAdditionalInfo })
  //     })
  //     .catch((err) => {
  //       handleToast(<Toast category="error" message={err.message} />)
  //     })
  // }

  function deleteDiary() {
    api
      .delete(`/constructions_diaries/${diary.id}`)
      .then((response) => {
        getDiaries()
        getConstructions()
        setOpen(false)
        handleToast(<Toast category="success" message={response.data.message} />)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  async function getConstructionDiariesDetails() {
    await api
      .get(`/constructions_diaries/details/${diary.id}`)
      .then((response) => {
        bodyDiaryImages()

        bodyDiaryDetails(response.data[0])
      })
      .catch((err) => {
        handleToast(<Toast category="error" message="Não foi possível listar os detalhes dessa diária, tente novamente." />)
      })
  }

  function handleModalOpen(body: string) {
    if (body === 'visualize') {
      getConstructionDiariesDetails()

      setOpen(true)
    } else if (body === 'delete') {
      setModalBody(bodyDeleteDiary)

      setOpen(true)
    } else if (body === 'enable') {
      enableDiary()
    } else if (body === 'disable') {
      disableDiary()
    }
  }

  function disableDiary() {
    api
      .put(`/constructions_diaries/disable/${diary.id}`)
      .then((response) => {
        getDiaries()

        handleToast(<Toast category="success" message={response.data.message} />)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  function enableDiary() {
    api
      .put(`/constructions_diaries/enable/${diary.id}`)
      .then((response) => {
        getDiaries()

        handleToast(<Toast category="success" message={response.data.message} />)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  function bodyDiaryDetails(d: DiaryReqType) {
    setModalBody(
      <div className={styles.box__container}>
        <div className={styles.box__modal}>
          <div className={styles.box__content}>
            <div className={styles.box__header}>
              <div>
                <Icon path={mdiBook} size={1.5} />
                <h4 className={styles.title}>DIÁRIO DE OBRA</h4>
              </div>

              <h5>{d.date.split('-').reverse().join('/')}</h5>
            </div>

            <div className={styles.box__details}>
              <div className={styles.colaborators}>
                <h2>informações</h2>

                <div className={styles.colaborator}>
                  <h4>
                    <b>Obra:</b> {diary.construction} ({diary.address} - {diary.address_number})
                  </h4>
                </div>

                <div className={styles.colaborator}>
                  <h4>
                    <b>Cliente:</b> {diary.client}
                  </h4>
                </div>
              </div>

              {diary.colaborators ? (
                <div className={styles.colaborators}>
                  <h2>colaboradores</h2>

                  {checkedColaborators.map((colaborator) => (
                    <div className={styles.colaborator}>
                      <Icon className={styles.icon} path={mdiBadgeAccount} size={1.5} />
                      <h4>{colaborator.name}</h4>
                    </div>
                  ))}
                </div>
              ) : null}

              <div className={styles.stages}>
                <h2>etapas do diário</h2>

                {diary.stages.map((stage) => (
                  <div className={styles.stage}>
                    <div className={styles.stage__info}>
                      <h4>{stage.stage}</h4>

                      <Tooltip title="Área trabalhada no dia" placement="top">
                        <h3>
                          {stage.worked_area} {stage.measure}
                        </h3>
                      </Tooltip>
                    </div>

                    <div className={styles.stage__progress}>
                      <Tooltip title="Área total trabalhada da etapa" placement="top">
                        <h3>
                          {stage.total_worked_area} {stage.measure}
                        </h3>
                      </Tooltip>

                      <Tooltip
                        title={`Etapa ${
                          Math.round((stage.total_worked_area / stage.total_area) * 100) > 100
                            ? 100
                            : Math.round((stage.total_worked_area / stage.total_area) * 100)
                        }% concluida`}
                        placement="top"
                      >
                        <BorderLinearProgress
                          variant="determinate"
                          color={Math.round((stage.total_worked_area / stage.total_area) * 100) > 100 ? 'error' : 'primary'}
                          value={
                            Math.round((stage.total_worked_area / stage.total_area) * 100) > 100
                              ? 100
                              : Math.round((stage.total_worked_area / stage.total_area) * 100)
                          }
                        />
                      </Tooltip>

                      <Tooltip title="Área total da etapa" placement="top">
                        <h3>
                          {stage.total_area} {stage.measure}
                        </h3>
                      </Tooltip>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className={styles.diary__summary}>
              <h5>RESUMO DO DIA</h5>
              <p>{diary.summary}</p>
            </div>

            <div className={styles.diary__buttons}>
              <button disabled={!diaryImages.length} className={styles.photos} onClick={() => setOpenModalDiariesImages(true)}>
                VER AS FOTOS
              </button>
              <button className={styles.close} onClick={() => setOpen(false)}>
                FECHAR
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function bodyDiaryImages() {
    setBodyDiaryImagesModal(
      <div className={styles.boxContainer}>
        <div className={styles.boxModal} style={{ width: '100%', maxWidth: '640px' }}>
          <div className={styles.boxContentImages}>
            <div className={styles.slideContainer}>
              <Slide {...properties}>
                {diaryImages.map((image, index) => (
                  <span
                    key={index}
                    onClick={() => {
                      setImageToFullscreen({ id: image.id, src: image.image })
                      setModalFullscreenImageOpen(true)
                    }}
                  >
                    <div className={styles.eachSlide}>
                      <div
                        style={{
                          backgroundImage: `url("${variables.api}/public/${image.image}")`
                        }}
                      ></div>
                    </div>
                  </span>
                ))}
              </Slide>
            </div>

            <button className={styles.buttonModal} onClick={() => setOpenModalDiariesImages(false)}>
              <span>Fechar</span>
            </button>
          </div>
        </div>
      </div>
    )
  }

  const bodyDeleteDiary = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Deseja realmente deletar esse diário?</h4>
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm} onClick={() => deleteDiary()}>
              Confirmar
            </button>
            <button className={styles.cancel} type="button" onClick={() => setOpen(false)}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className={styles.box}>
      <div className={styles.boxDate} title={diary.summary}>
        <span>{diary.summary}</span>
      </div>

      <div className={styles.boxDate}>
        <span style={{ fontWeight: 'bold' }}>{diary.date.split('-').reverse().join('/')}</span>
      </div>

      <div className={styles.buttons}>
        <img src={iEye} alt="" onClick={() => handleModalOpen('visualize')} />
        {/* <img src={iPencil} alt="" onClick={() => setModalEditDiaryOpen(true)} /> */}
        <img src={iTrash} alt="" onClick={() => handleModalOpen('delete')} />
        {role === 1 ? (
          diary.status === 1 ? (
            <img src={iSuccess} alt="" onClick={() => handleModalOpen('enable')} />
          ) : (
            <img src={iRemove} alt="" onClick={() => handleModalOpen('disable')} />
          )
        ) : null}
      </div>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        scroll="body"
        TransitionComponent={Fade}
        transitionDuration={200}
      >
        {modalBody}
      </Dialog>

      <Dialog
        open={openModalDiariesImages}
        onClose={() => setOpenModalDiariesImages(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        scroll="body"
        TransitionComponent={Fade}
        transitionDuration={200}
      >
        {bodyDiaryImagesModal}
      </Dialog>

      <Modal
        open={modalFullscreenImage}
        onClose={() => setModalFullscreenImageOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyModalFullscreenImage}
      </Modal>

      <DefaultModal isOpen={modalEditDiaryOpen} handleOpen={setModalEditDiaryOpen}>
        <ModalEditDiary diary={diary} handleToast={handleToast} setModalEditDiaryOpen={setModalEditDiaryOpen} getDiaries={getDiaries} />
      </DefaultModal>

      <DefaultModal isOpen={modalFullscreenImage} handleOpen={setModalFullscreenImageOpen}>
        <ModalFullscreenImage
          diary={diary}
          image={imageToFullscreen}
          setImages={setDiaryImages}
          getDiaries={getDiaries}
          handleToast={handleToast}
          setModalFullscreenImageOpen={setModalFullscreenImageOpen}
        />
      </DefaultModal>
    </div>
  )
}

export default React.memo(Diary)
