import { BaseSyntheticEvent, useContext, useState } from 'react'
import { Autocomplete, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import { ColaboratorsContext } from '../../../../contexts/ColaboratorsContext'
import { api } from '../../../../services/api'
import { mdiAccountTie } from '@mdi/js'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import styles from './style.module.css'

type SubmitType = {
  id: number
  name: string
  role: number
  cpf: string
  nickname: string
  mensalist: number
  salary?: string
  pix: string
  agency: string
  account: string
  bank: string
}

type InputType = {
  value: string
  name: string
}

type ColaboratorType = {
  id: number
  description: string
}

export default function ModalCreateColaborator({ setModalCreateColaboratorOpen, handleToast }) {
  const [formValues, setFormValues] = useState({} as SubmitType)

  const { getColaborators, colaboratorsTypes } = useContext(ColaboratorsContext)

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function handleCreate(e: BaseSyntheticEvent) {
    e.preventDefault()

    api
      .post(`/employees`, formValues)
      .then((response) => {
        getColaborators()
        handleToast(<Toast category="success" message={response.data.message} />)
        setModalCreateColaboratorOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.response.data.message} />)
      })
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiAccountTie} size={1.5} />
            <h4 className={styles.title}>CADASTRAR COLABORADOR</h4>
          </div>

          <form className={styles.form__create} onSubmit={(e) => handleCreate(e)}>
            <TextField
              label="Nome"
              type="text"
              required
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'name',
                  value: e.target.value
                })
              }
            />
            <TextField
              label="Apelido"
              type="text"
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'nickname',
                  value: e.target.value
                })
              }
            />
            <TextField
              label="CPF"
              type="text"
              required
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'cpf',
                  value: e.target.value
                })
              }
            />

            <Autocomplete
              value={undefined}
              onChange={(event, newValue: ColaboratorType) => {
                handleInputChange({
                  name: 'role',
                  value: String(newValue.id)
                })
              }}
              options={colaboratorsTypes}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Função" required />}
            />

            <TextField
              label="PIX"
              type="text"
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'pix',
                  value: e.target.value
                })
              }
            />

            <div className={styles.input__block}>
              <TextField
                label="Agência"
                type="text"
                autoComplete="off"
                onChange={(e) =>
                  handleInputChange({
                    name: 'agency',
                    value: e.target.value
                  })
                }
              />
              <TextField
                label="Conta"
                type="text"
                autoComplete="off"
                onChange={(e) =>
                  handleInputChange({
                    name: 'account',
                    value: e.target.value
                  })
                }
              />
            </div>

            <TextField
              label="Banco"
              type="text"
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'bank',
                  value: e.target.value
                })
              }
            />

            <RadioGroup
              aria-labelledby="radio-buttons"
              onChange={(e) =>
                handleInputChange({
                  name: 'mensalist',
                  value: e.target.value
                })
              }
            >
              <FormControlLabel value="1" control={<Radio />} label="Mensalista" />
              <FormControlLabel value="0" control={<Radio />} label="Diarista" />
            </RadioGroup>

            {formValues.mensalist == 1 && (
              <TextField
                label="Salário"
                type="text"
                autoComplete="off"
                onChange={(e) =>
                  handleInputChange({
                    name: 'salary',
                    value: e.target.value
                  })
                }
              />
            )}

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalCreateColaboratorOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
