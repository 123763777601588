import { createContext, useEffect } from 'react';
import { useState } from 'react';
import { api } from '../services/api';

type StagesContextType = {
  getStages: () => Promise<void>;
  stages: StageType[];
};

type StageType = {
  id: number;
  description: string;
};

export const StagesContext = createContext({} as StagesContextType);

export function StageProvider({ children }) {
  const [stages, setStages] = useState<StageType[]>([]);

  useEffect(() => {
    if (stages.length === 0) getStages();
  }, []);

  async function getStages() {
    await api
      .get('/stages')
      .then((response) => {
        setStages(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <StagesContext.Provider value={{ stages, getStages }}>
      {children}
    </StagesContext.Provider>
  );
}
