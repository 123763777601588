import { useState } from 'react'
import styles from './style.module.css'

interface TableProps {
  data: any[]
  headers: string[]
  title: string
}

export default function Table({ data, headers, title }: TableProps) {
  const [showAll, setShowAll] = useState<boolean>(false)

  return (
    <div className={styles.table__box}>
      <div className={styles.card__header}>
        <h2>{title}</h2>
        <a className={styles.btn} onClick={() => setShowAll(true)}>
          Ver Todas
        </a>
      </div>

      <table>
        <thead>
          <tr>
            {headers.map((header) => (
              <td>{header}</td>
            ))}
          </tr>
        </thead>

        <tbody>
          {(showAll ? data : data.slice(0, 10)).map((item) => (
            <tr>
              <td>{item.type}</td>
              <td>
                {Number(item.value).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL'
                })}
              </td>
              <td>
                {new Date(item.due_date).toLocaleDateString('pt-BR', {
                  timeZone: 'UTC'
                })}
              </td>
              <td>
                {item.payday
                  ? new Date(item.payday).toLocaleDateString('pt-BR', {
                      timeZone: 'UTC'
                    })
                  : null}
              </td>
              <td>{item.parcel != 0 ? 'Sim' : 'Não'}</td>
              <td>
                <span className={`${styles.status} ${item.payday ? styles.paid : new Date() > new Date(item.due_date) ? styles.expired : styles.opened}`}>
                  {item.payday ? 'Pago' : new Date() > new Date(item.due_date) ? 'Vencido' : 'Em aberto'}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
