import { Dialog, Fade, Modal } from '@material-ui/core'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { api } from '../../services/api'
import styles from './style.module.css'
import Toast from '../Toast'

import { useForm } from 'react-hook-form'
import { useContext } from 'react'
import { ToolsContext } from '../../contexts/ToolsContext'
import { ProductsContext } from '../../contexts/ProductsContext'

import Product from './Product'
import Tool from './Tool'

type ModalParams = {
  setOpenProductsAndTools: Dispatch<SetStateAction<boolean>>
  opening: boolean
  construction_id: number
}

export default function ConstructionTools({ setOpenProductsAndTools, opening, construction_id }: ModalParams) {
  const { tools } = useContext(ToolsContext)
  const { products } = useContext(ProductsContext)

  const { register, handleSubmit, reset } = useForm()

  const [open, setOpen] = useState(false)

  const [constructionTools, setConstructionTools] = useState([])
  const [constructionProducts, setConstructionProducts] = useState([])

  const [openNewConstructionProduct, setOpenNewConstructionProduct] = useState(false)
  const [openNewConstructionTool, setOpenNewConstructionTool] = useState(false)

  const [createOpen, setCreateOpen] = useState(false)

  const [toast, setToast] = useState(<div></div>)

  useEffect(() => {
    getConstructionTools()
    getConstructionProducts()

    setOpen(opening)
  }, [])

  function getConstructionProducts() {
    api
      .get(`/constructions/materials/${construction_id}`)
      .then((response) => {
        if (response.data.length) {
          setConstructionProducts(response.data)
        }
      })
      .catch((err) => console.log(err))
  }

  function getConstructionTools() {
    api
      .get(`/constructions/tools/${construction_id}`)
      .then((response) => {
        console.log(response.data)
        if (response.data.length) {
          setConstructionTools(response.data)
        }
      })
      .catch((err) => console.log(err))
  }

  function handleSubmitNewTool(data) {
    api
      .post(`/constructions/tools/${construction_id}`, data)
      .then((response) => {
        getConstructionTools()
        setToast(<Toast category={response.status === 202 ? 'error' : 'success'} message={response.data.message} />)
        reset()
        setOpenNewConstructionTool(false)

        setTimeout(() => {
          setToast(<div></div>)
        }, 5000)
      })
      .catch((err) => {
        setToast(<Toast category="error" message={err.message} />)

        setTimeout(() => {
          setToast(<div></div>)
        }, 5000)
      })
  }

  function handleSubmitNewProduct(data) {
    api
      .post(`/constructions/materials/${construction_id}`, data)
      .then((response) => {
        getConstructionProducts()
        setToast(<Toast category={response.status === 202 ? 'error' : 'success'} message={response.data.message} />)
        reset()
        setOpenNewConstructionProduct(false)

        setTimeout(() => {
          setToast(<div></div>)
        }, 5000)
      })
      .catch((err) => {
        setToast(<Toast category="error" message={err.message} />)

        setTimeout(() => {
          setToast(<div></div>)
        }, 5000)
      })
  }

  const bodyModalNewConstructionTool = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Cadastrar Ferramenta na Obra</h4>
          </div>

          <form onSubmit={handleSubmit(handleSubmitNewTool)}>
            <label htmlFor="tool">Ferramenta: *</label>
            <select required name="tool" {...register('tool_id')}>
              {tools.map((tool) => (
                <option key={tool.id} value={tool.id}>
                  {tool.description}
                </option>
              ))}
            </select>

            <label htmlFor="quantity">Quantidade: *</label>
            <input required step="0.01" name="quantity" type="number" {...register('quantity')} />

            <div className={styles.buttons}>
              <button className={styles.confirm}>
                <span>Confirmar</span>
              </button>
              <button
                type="button"
                className={styles.cancel}
                onClick={() => {
                  setOpenNewConstructionTool(false)
                  reset()
                }}
              >
                <span>Cancelar</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

  const bodyModalNewConstructionProduct = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Cadastrar Produto na Obra</h4>
          </div>

          <form onSubmit={handleSubmit(handleSubmitNewProduct)}>
            <label htmlFor="product">Produto: *</label>
            <select required name="product" {...register('product_id')}>
              {products.map((product) => (
                <option key={product.id} value={product.id}>
                  {product.description}
                </option>
              ))}
            </select>

            <label htmlFor="quantity">Quantidade: *</label>
            <input required step="0.01" name="quantity" type="number" {...register('quantity')} />

            <div className={styles.buttons}>
              <button className={styles.confirm}>
                <span>Confirmar</span>
              </button>
              <button
                type="button"
                className={styles.cancel}
                onClick={() => {
                  setOpenNewConstructionProduct(false)
                  reset()
                }}
              >
                <span>Cancelar</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

  function closeModal() {
    setOpen(false)
    setOpenProductsAndTools(false)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpenProductsAndTools(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        scroll="body"
        TransitionComponent={Fade}
        transitionDuration={200}
      >
        <div className={styles.boxContainer}>
          <div className={styles.boxModal} style={{ width: '500px' }}>
            <div className={styles.boxContent}>
              <div className={styles.boxHeader}>
                <h4 className={styles.boxHeaderTitle}>Produtos e Ferramentas</h4>
              </div>

              <div className={styles.modalSession}>
                <h4>Materiais</h4>
                {constructionProducts.length ? (
                  <>
                    {constructionProducts.map((product) => (
                      <Product key={product.id} product={product} getConstructionProducts={getConstructionProducts} setToast={setToast} />
                    ))}
                  </>
                ) : (
                  <span>Não existem produtos cadastrados nessa obra.</span>
                )}
                <button onClick={() => setOpenNewConstructionProduct(true)} className={styles.new}>
                  Cadastrar
                </button>
              </div>

              <div className={styles.modalSession}>
                <h4>Ferramentas</h4>
                {constructionTools.length ? (
                  <>
                    {constructionTools.map((tool) => (
                      <Tool key={tool.id} tool={tool} getConstructionTools={getConstructionTools} setToast={setToast} />
                    ))}
                  </>
                ) : (
                  <span>Não existem ferramentas cadastradas nessa obra.</span>
                )}
                <button onClick={() => setOpenNewConstructionTool(true)} className={styles.new}>
                  Cadastrar
                </button>
              </div>

              <button className={styles.buttonModal} onClick={() => closeModal()}>
                <span>Fechar</span>
              </button>
            </div>
          </div>

          <Modal open={createOpen} onClose={() => setCreateOpen(false)} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
            {bodyModalNewConstructionProduct}
          </Modal>

          <Modal
            open={openNewConstructionProduct}
            onClose={() => setOpenNewConstructionProduct(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyModalNewConstructionProduct}
          </Modal>

          <Modal
            open={openNewConstructionTool}
            onClose={() => setOpenNewConstructionTool(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyModalNewConstructionTool}
          </Modal>
        </div>
      </Dialog>

      {toast}
    </>
  )
}
