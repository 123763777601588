import { useContext } from 'react'
import { api } from '../../../../services/api'
import { mdiCreditCard } from '@mdi/js'
import { MonetaryContext } from '../../../../contexts/MonetaryContext'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import styles from './style.module.css'

export default function ModalDeleteExpense({ expense, setModalDeleteExpenseOpen, handleToast }) {
  const { getExpensesUsingLastFilter } = useContext(MonetaryContext)

  async function handleDelete() {
    api
      .delete(`/expenses/${expense.id}`)
      .then((response) => {
        getExpensesUsingLastFilter()
        handleToast(<Toast category="success" message={response.data.message} />)
        setModalDeleteExpenseOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.response.data.message} />)
      })
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiCreditCard} size={1.5} />
            <h4 className={styles.title}>DELETAR DESPESA</h4>
          </div>

          <span>
            Deseja realmente deletar a despesa no valor de {Number(expense.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} cadastrada
            como uma receita do tipo {expense.expense_type} parcelada em {expense.parcels}x?
          </span>

          <div className={styles.buttons}>
            <button className={styles.confirm} onClick={() => handleDelete()}>
              Confirmar
            </button>
            <button className={styles.cancel} type="button" onClick={() => setModalDeleteExpenseOpen(false)}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
