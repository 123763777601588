import { mdiHammer, mdiPlusBox, mdiTrashCan } from '@mdi/js'

import Icon from '@mdi/react'
import { randomBytes } from 'crypto'
import { useState } from 'react'
import { api } from '../../../../../services/api'
import DefaultModal from '../../../DefaultModal'
import ModalAddStageToForm from './AddStage'
import ModalDeleteStage from './DeleteStage'

import styles from './style.module.css'

export interface StageToAdd {
  id: string
  construction_schedule_id: number
  worked_area: string
}

export default function ModalDiaryStages({ savedStages, saveStages, constructionSchedules, setModalDiaryStagesOpen }) {
  const [modalAddStageOpen, setModalAddStageToFormOpen] = useState(false)
  const [modalDeleteStageOpen, setModalDeleteStageOpen] = useState(false)

  const [stagesToAdd, setStagesToAdd] = useState<StageToAdd[]>(savedStages)

  const [stageIdToDelete, setStageIdToDelete] = useState<number | string>(null)

  function addStage(stage: StageToAdd) {
    setStagesToAdd((prev) => [...prev, { ...stage, id: randomBytes(10).toString('hex') }])
    setModalAddStageToFormOpen(false)
  }

  function close() {
    saveStages(stagesToAdd)
    setModalDiaryStagesOpen(false)
  }

  function setupRemove(stageId: string) {
    if (typeof stageId === 'string') {
      setStagesToAdd((prev) => prev.filter((s) => s.id !== stageId))
    } else {
      setStageIdToDelete(stageId)
      setModalDeleteStageOpen(true)
    }
  }

  async function remove() {
    setModalDeleteStageOpen(false)

    try {
      await api.delete(`/constructions_diaries/stages/${stageIdToDelete}`)
      setStagesToAdd((prev) => prev.filter((s) => s.id !== stageIdToDelete.toString()))
    } catch (err) {
      console.error('fail on delete stage', { err })
    }
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <div className={styles.title__box}>
              <Icon path={mdiHammer} size={1.5} />
              <h4 className={styles.title}>ETAPAS DO DIÁRIO</h4>
            </div>

            <div onClick={() => setModalAddStageToFormOpen(true)}>
              <Icon path={mdiPlusBox} size={1.5} color="green" />
            </div>
          </div>

          {stagesToAdd.length ? (
            stagesToAdd.map((stage) => (
              <div className={styles.stage}>
                <div className={styles.stage__progress}>
                  <h4>
                    {stage.worked_area} {constructionSchedules.find((cs) => cs.id === stage.construction_schedule_id).measure}
                  </h4>

                  <h1>{constructionSchedules.find((cs) => cs.id === stage.construction_schedule_id).stage}</h1>
                </div>

                <div className={styles.actions}>
                  <span onClick={() => setupRemove(stage.id)}>
                    <Icon path={mdiTrashCan} size={1.2} color="red" />
                  </span>
                </div>
              </div>
            ))
          ) : (
            <h3 className={styles.no__content}>nenhuma etapa adicionada</h3>
          )}

          <button type="button" className={styles.button__close} onClick={() => close()}>
            <span>FECHAR</span>
          </button>
        </div>
      </div>

      <DefaultModal handleOpen={setModalDeleteStageOpen} isOpen={modalDeleteStageOpen}>
        <ModalDeleteStage setModalDeleteStageOpen={setModalDeleteStageOpen} remove={remove} />
      </DefaultModal>

      <DefaultModal handleOpen={setModalAddStageToFormOpen} isOpen={modalAddStageOpen}>
        <ModalAddStageToForm addStage={addStage} setModalAddStageToFormOpen={setModalAddStageToFormOpen} constructionSchedules={constructionSchedules} />
      </DefaultModal>
    </div>
  )
}
