import { useEffect, useState } from 'react'
import { mdiListStatus, mdiPencil, mdiPlus, mdiPlusBox, mdiTrashCan } from '@mdi/js'
import { api } from '../../../../services/api'
import { IExpense } from '../../../../interfaces/Expense'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import styles from './style.module.css'
import Parcel from './Parcel'
import DefaultModal from '../../DefaultModal'
import ModalDeleteExpense from '../Delete'
import ModalEditExpense from '../Edit'
import ModalCreateParcel from '../../Parcel/Create'

export default function ModalExpenseParcels({ expense, setModalExpenseParcelsOpen, handleToast }) {
  const [parcels, setParcels] = useState([])
  const [expenseDetails, setExpenseDetails] = useState<IExpense>({} as IExpense)
  const [totalParcels, setTotalParcels] = useState(0)
  const [totalPaidParcels, setTotalPaidParcels] = useState(0)

  const [modalDeleteExpenseOpen, setModalDeleteExpenseOpen] = useState(false)
  const [modalEditExpenseOpen, setModalEditExpenseOpen] = useState(false)
  const [modalCreateParcelOpen, setModalCreateParcelOpen] = useState(false)

  const isParcel = !!expense.parcel

  useEffect(() => {
    getExpenseParcels()
    getExpense()
  }, [])

  async function getExpenseParcels() {
    const { data, status } = await api.get(`/expenses_parcels/expense/${isParcel ? expense.expense_id : expense.id}`)

    if (status !== 200) {
      handleToast(<Toast category="error" message={data.message} />)
      return
    }

    let total = 0
    let totalPaid = 0

    for (const parcel of data) {
      if (parcel.payday) {
        totalPaid += Number(parcel.value)
      }

      total += Number(parcel.value)
    }

    setTotalPaidParcels(totalPaid)
    setTotalParcels(total)
    setParcels(data)
  }

  async function getExpense() {
    const { data, status } = await api.get(`/expenses/${isParcel ? expense.expense_id : expense.id}`)

    if (status !== 200) {
      handleToast(<Toast category="error" message={data.message} />)
      return
    }

    setExpenseDetails(data)
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiListStatus} size={1.5} />
            <h4 className={styles.title}>PARCELAS DA DESPESA</h4>
          </div>

          <div className={styles.expense__details}>
            <h1>
              DETALHES DA DESPESA
              <div>
                <span onClick={() => setModalEditExpenseOpen(true)} title="Editar">
                  <Icon path={mdiPencil} size={1.2} color="green" />
                </span>
                <span onClick={() => setModalDeleteExpenseOpen(true)} title="Excluir">
                  <Icon path={mdiTrashCan} size={1.2} color="red" />
                </span>
              </div>
            </h1>
            <h5>tipo: {expenseDetails.expense_type}</h5>
            {expenseDetails.client && <h5>cliente: {expenseDetails.client}</h5>}
            {expenseDetails.provider && <h5>fornecedor: {expenseDetails.provider}</h5>}
            {expenseDetails.construction && <h5>obra: {expenseDetails.construction}</h5>}
            {expenseDetails.money_origin && <h5>origem: {expenseDetails.money_origin}</h5>}
            <h5>
              valor: {Number(expenseDetails.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} parcelado em {expenseDetails.parcels}x
            </h5>

            <h5>
              total das parcelas:{' '}
              {totalParcels !== Number(expenseDetails.value) || parcels.length !== expenseDetails.parcels ? (
                <b
                  title={
                    totalParcels !== Number(expenseDetails.value)
                      ? 'Valor total das parcelas diferente do valor total da despesa'
                      : 'Quantidade de parcelas cadastradas diferente da quantidade cadastrada na despesa'
                  }
                >
                  {totalParcels.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} ({parcels.length} parcela(s))
                </b>
              ) : (
                <>
                  {totalParcels.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} ({parcels.length} parcela(s))
                </>
              )}
            </h5>
            <h5>total restante: {Number(totalParcels - totalPaidParcels).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h5>
          </div>

          <div className={styles.box__content}>
            <table>
              <thead>
                <tr>
                  <th>data</th>
                  <th>valor</th>
                  <th align="right">
                    <button onClick={() => setModalCreateParcelOpen(true)}>
                      <Icon path={mdiPlusBox} size={1.2} color="green" title="Adicionar parcela" />
                    </button>
                  </th>
                </tr>
              </thead>

              <tbody>
                {parcels.map((parcel) => (
                  <Parcel parcel={parcel} getExpenseParcels={getExpenseParcels} handleToast={handleToast} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <DefaultModal isOpen={modalCreateParcelOpen} handleOpen={setModalCreateParcelOpen}>
        <ModalCreateParcel
          type="expense"
          expenseId={expenseDetails.id}
          getParcels={getExpenseParcels}
          handleToast={handleToast}
          setModalCreateParcelOpen={setModalCreateParcelOpen}
        />
      </DefaultModal>

      <DefaultModal isOpen={modalEditExpenseOpen} handleOpen={setModalEditExpenseOpen}>
        <ModalEditExpense getExpense={getExpense} expense={expenseDetails} handleToast={handleToast} setModalEditExpenseOpen={setModalEditExpenseOpen} />
      </DefaultModal>

      <DefaultModal isOpen={modalDeleteExpenseOpen} handleOpen={setModalDeleteExpenseOpen}>
        <ModalDeleteExpense expense={expenseDetails} handleToast={handleToast} setModalDeleteExpenseOpen={setModalDeleteExpenseOpen} />
      </DefaultModal>
    </div>
  )
}
