import { BaseSyntheticEvent } from 'react'
import { api } from '../../../../services/api'
import { mdiPackageVariantClosed } from '@mdi/js'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import styles from './style.module.css'

export default function ModalDeleteList({ list, setModalDeleteListOpen, handleToast }) {
  async function remove(e: BaseSyntheticEvent) {
    e.preventDefault()

    api
      .delete(`/lists/${list.id}`)
      .then((response) => {
        handleToast(<Toast category="success" message={response.data.message} />)
        setModalDeleteListOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message="Erro ao remover o produto" />)
      })
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiPackageVariantClosed} size={1.5} />
            <h4 className={styles.title}>DELETAR LISTA DE COMPRAS</h4>
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm} onClick={(e) => remove(e)}>
              Confirmar
            </button>
            <button className={styles.cancel} type="button" onClick={() => setModalDeleteListOpen(false)}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
