import { useContext, useState } from 'react'

import styles from './style.module.css'

import Sidebar from '../../../components/Sidebar'
import links from '../../../components/configs/links-configs'

import iPlus from '../../../assets/icons/plus.svg'

import { useForm } from 'react-hook-form'
import { Modal } from '@material-ui/core'
import { api } from '../../../services/api'

import Toast from '../../../components/Toast'
import MoneyOrigin from '../../../components/MoneyOrigin'

import { MonetaryContext } from '../../../contexts/MonetaryContext'

type MOriginSubmitType = {
  description: string
  opening_balance: number
}

export default function MoneyOrigins() {
  const { moneyOrigins, getMoneyOrigins } = useContext(MonetaryContext)

  const { register, handleSubmit, reset } = useForm()

  const [createOriginOpen, setCreateOriginOpen] = useState(false)

  const [toast, setToast] = useState<any>()

  function newMoneyOrigin(data: MOriginSubmitType) {
    api
      .post('/money_origins', {
        description: data.description,
        opening_balance: 1
      })
      .then((response) => {
        handleToast(<Toast category="success" message={response.data.message} />)
        getMoneyOrigins()
        setCreateOriginOpen(false)
        reset()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function handleToast(toast) {
    setToast(toast)

    setTimeout(() => {
      setToast(null)
    }, 5000)
  }

  const bodyNewMOrigin = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ maxWidth: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Cadastrar Origem/Destino Monetário</h4>
          </div>

          <form className={styles.formEdit} onSubmit={handleSubmit(newMoneyOrigin)}>
            <label htmlFor="description">Descrição: *</label>
            <input name="description" required type="text" {...register('description')} />

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button
                className={styles.cancel}
                onClick={() => {
                  setCreateOriginOpen(false)
                  reset()
                }}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

  return (
    <div className={styles.main}>
      <Sidebar config={links} />

      {toast}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>Origens/Destinos Monetários</h1>

          <button onClick={() => setCreateOriginOpen(true)}>
            <span>Nova Origem/Destino</span>
            <img src={iPlus} alt="" />
          </button>
        </div>

        <div className={styles.table}>
          {moneyOrigins.length ? (
            moneyOrigins.map((origin) => <MoneyOrigin key={origin.id} origin={origin} handleToast={handleToast} />)
          ) : (
            <span>Não encontramos informações para listar.</span>
          )}
        </div>

        <Modal
          open={createOriginOpen}
          onClose={() => setCreateOriginOpen(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyNewMOrigin}
        </Modal>
      </div>
    </div>
  )
}
