import { useContext, useEffect, useState } from 'react'
import { api } from '../../services/api'
import Cookies from 'js-cookie'

import ArccoLogo from '../../assets/grupo-arcco.png'
import ExitIcon from '../../assets/icons/exit.svg'

import styles from './style.module.css'
import Construction from '../../components/ClientDashboard/Construction'
import Project from '../../components/ClientDashboard/Project'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { ptBR } from 'date-fns/locale'
import { Autocomplete, TextField } from '@mui/material'
import Billing from '../../components/ClientDashboard/Billing'
import { AuthContext } from '../../contexts/AuthContext'
import { ConstructionType } from '../../contexts/ConstructionsContext'
import PurchaseRequest from '../../components/ClientDashboard/PurchaseRequest'
import Icon from '@mdi/react'
import { mdiClipboardList, mdiCurrencyUsd, mdiFileDocumentMultiple, mdiHome } from '@mdi/js'

type StatusToFilter = {
  value: string
  label: string
}

export default function ClientDashboard() {
  const { logout } = useContext(AuthContext)

  const statusToFilterOptions = [
    { label: 'Pagas', value: 'paid' },
    { label: 'A pagar', value: 'due' }
  ]

  const [constructions, setConstructions] = useState([])
  const [projects, setProjects] = useState([])
  const [incomings, setIncomings] = useState([])

  const [purchaseRequests, setPurchaseRequests] = useState([])

  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [statusToFilter, setStatusToFilter] = useState(statusToFilterOptions.find((st) => st.value === 'paid'))
  const [constructionToFilter, setConstructionToFilter] = useState(null)
  const [constructionToFilterOnBilling, setConstructionToFilterOnBilling] = useState(null)

  const [totalIncomings, setTotalIncomings] = useState(0)

  const [content, setContent] = useState<'constructions' | 'billing' | 'projects' | 'purchaseRequests'>('constructions')

  const user_id = Cookies.get('user_id')

  useEffect(() => {
    handlerDates()
    getClientConstructions()
    getClientProjects()
  }, [])

  function getClientConstructions() {
    api
      .get(`/constructions/client/${user_id}`)
      .then((response) => {
        setConstructions(response.data)
      })
      .catch((err) => {
        console.error('fail on load client constructions', { err })
      })
  }

  function getClientProjects() {
    api
      .get(`/projects/client/${user_id}`)
      .then((response) => {
        setProjects(response.data)
      })
      .catch((err) => {
        console.error('fail on load client projects', { err })
      })
  }

  function getClientIncomings() {
    api
      .get(
        `/incomings/filter/custom?client_id=${user_id}&construction_id=${constructionToFilterOnBilling?.id || null}&initialDate=${
          initialDate.split(',')[0]
        }&finalDate=${finalDate.split(',')[0]}&condition=1`
      )
      .then((response) => {
        let total = 0

        response.data.map((inc) => (total += Number(inc.value)))

        setTotalIncomings(total)
        setIncomings(response.data)
      })
      .catch((err) => {
        console.error('fail on load client incomings', { err })
      })
  }

  async function getPurchaseRequests() {
    try {
      const { data } = await api.get(`/purchases/requests/filter?construction_id=${constructionToFilter.id}&client_id=${user_id}`)
      setPurchaseRequests(data)
    } catch (err) {
      console.error('fail on load unpaid purchase requests', { err })
    }
  }

  function handlerDates() {
    var date = new Date()

    var day: any = date.getDate()
    var month: any = date.getMonth() + 1
    var year: any = date.getFullYear()

    var fifty: any = 0

    if (day <= 15) {
      fifty = 1
    } else {
      fifty = day - 15
    }

    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }
    if (fifty < 10) {
      fifty = '0' + fifty
    }

    const today = `${year}-${month}-${day}`
    const fiftyDays = `${year}-${month}-${fifty}`

    setInitialDate(`${fiftyDays}, 00:00:00`)
    setFinalDate(`${today}, 00:00:00`)
  }

  return (
    <div className={styles.content}>
      <header>
        <img src={ArccoLogo} alt="arcco-logo" />

        <div className={styles.title__box}>
          <span className={styles.exit} onClick={() => logout()}>
            sair
            <img src={ExitIcon} alt="sair" />
          </span>
          <h1>ESPAÇO DO CLIENTE</h1>
        </div>
      </header>

      <div className={styles.section__select}>
        <div className={styles.section__option} onClick={() => setContent('constructions')}>
          <h1>OBRAS</h1>

          <div className={styles.section__icon}>
            <Icon path={mdiHome} size={2} className={styles.icon__key} />
          </div>
        </div>
        <div className={styles.section__option} onClick={() => setContent('billing')}>
          <h1>FINANCEIRO</h1>

          <div className={styles.section__icon}>
            <Icon path={mdiCurrencyUsd} size={2} className={styles.icon__key} />
          </div>
        </div>
        <div className={styles.section__option} onClick={() => setContent('projects')}>
          <h1>PROJETOS</h1>
          <div className={styles.section__icon}>
            <Icon path={mdiFileDocumentMultiple} size={2} className={styles.icon__key} />
          </div>
        </div>
        <div className={styles.section__option} onClick={() => setContent('purchaseRequests')}>
          <h1>PEDIDOS DE COMPRA</h1>
          <div className={styles.section__icon}>
            <Icon path={mdiClipboardList} size={2} className={styles.icon__key} />
          </div>
        </div>
      </div>

      {content === 'constructions' ? (
        <div className={styles.content}>
          {constructions.map((construction) => (
            <Construction construction={construction} />
          ))}
        </div>
      ) : content === 'billing' ? (
        <div className={styles.content}>
          {constructionToFilterOnBilling?.value ? (
            <h4 className={styles.construction__value}>
              VALOR DA OBRA:
              <b>
                {Number(constructionToFilterOnBilling?.value).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL'
                })}
              </b>
            </h4>
          ) : null}

          <div className={styles.filter__box}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Data Inicial"
                mask="__/__/____"
                value={initialDate}
                renderInput={(params) => <TextField {...params} style={{ minWidth: 150 }} />}
                onChange={(newValue) => {
                  setInitialDate(`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`)
                }}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Data Final"
                mask="__/__/____"
                value={finalDate}
                renderInput={(params) => <TextField {...params} style={{ minWidth: 150 }} />}
                onChange={(newValue) => {
                  setFinalDate(`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`)
                }}
              />
            </LocalizationProvider>

            <Autocomplete
              value={constructionToFilterOnBilling}
              id="construction"
              onChange={(event, newValue: ConstructionType) => {
                console.log(newValue)
                if (newValue) {
                  setConstructionToFilterOnBilling(newValue)
                } else {
                  setConstructionToFilterOnBilling(null)
                }
              }}
              options={constructions}
              getOptionLabel={(option) => `${option.name}`}
              renderInput={(params) => <TextField {...params} label="Obra" />}
              style={{ width: 250 }}
            />

            <button onClick={() => getClientIncomings()} className={styles.confirm}>
              <span>FILTRAR</span>
            </button>
          </div>

          {incomings.length ? (
            <table>
              <thead>
                <tr>
                  <td>TIPO</td>
                  <td>VALOR</td>
                  <td>{statusToFilter.value === 'paid' ? 'DATA PAGAMENTO' : 'DATA VENCIMENTO'}</td>
                  <td>PARCELAS</td>
                </tr>
              </thead>
              <tbody>
                {incomings.map((incoming) => (
                  <Billing incoming={incoming} />
                ))}

                <tr>
                  {/* <td style={{ color: 'green' }}>TOTAL PAGO</td> */}
                  <td style={{ color: 'green' }}>TOTAL</td>
                  <td style={{ color: 'green' }}>
                    {totalIncomings.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                    })}
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                {/* 
                {constructionToFilterOnBilling?.value ? (
                  <tr>
                    <td style={{ color: 'red' }}>TOTAL RESTANTE</td>
                    <td style={{ color: 'red' }}>
                      {(Number(constructionToFilterOnBilling.value) - totalIncomings).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL'
                      })}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                ) : null} */}
              </tbody>
            </table>
          ) : (
            <h3>não encontramos nada para listar</h3>
          )}
        </div>
      ) : content === 'projects' ? (
        <div className={styles.content}>
          {projects.map((project) => (
            <Project project={project} />
          ))}
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.filter__box}>
            <Autocomplete
              value={statusToFilter}
              id="construction__schedules"
              onChange={(event, newValue: StatusToFilter) => {
                setStatusToFilter(newValue)
              }}
              options={statusToFilterOptions}
              getOptionLabel={(option) => `${option.label}`}
              renderInput={(params) => <TextField {...params} label="Status" required />}
              style={{ width: 250 }}
            />

            <Autocomplete
              value={constructionToFilter}
              id="construction"
              onChange={(event, newValue: ConstructionType) => {
                newValue && setConstructionToFilter(newValue)
              }}
              options={constructions}
              getOptionLabel={(option) => `${option.name}`}
              renderInput={(params) => <TextField {...params} label="Obra" />}
              style={{ width: 250 }}
            />

            <button onClick={() => getPurchaseRequests()} className={styles.confirm}>
              <span>FILTRAR</span>
            </button>
          </div>

          {purchaseRequests
            .filter((pr) => (statusToFilter.value === 'paid' ? !!pr.payment_date : !pr.payment_date))
            .map((r) => (
              <PurchaseRequest request={r} />
            ))}
        </div>
      )}
    </div>
  )
}
