import { useContext, useEffect, useState } from 'react'

import styles from './style.module.css'

import Sidebar from '../../../components/Sidebar'
import links from '../../../components/configs/links-records'

import { useForm } from 'react-hook-form'
import { Dialog, Fade } from '@material-ui/core'
import { api } from '../../../services/api'

import { ClientsContext, ClientType } from '../../../contexts/ClientsContext'
import { ProjectsContext } from '../../../contexts/ProjectsContext'
import { UsersContext } from '../../../contexts/UsersContext'

import ProjectClientFolder from '../../../components/ProjectClientFolder'
import Toast from '../../../components/Toast'
import PDFFinishedProjects from '../../../components/PDFFinishedProjects'
import ProjectsColaborator from '../ProjectsColaborator'
import Cookies from 'js-cookie'
import ModalCreateProject from '../../../components/Modals/Project/Create'
import DefaultModal from '../../../components/Modals/DefaultModal'

type SubmitType = {
  client_id: number
  project_type_id: number
  area: string
  criterion: string
  local: string
  start_date: string
  finish_date_prediction: string
  responsable: number
}

type NewFolderType = {
  client_id: number
}

type FilterType = {
  filter_month: string
  filter_year: string
  colaborator_id: number
}

export default function Projects() {
  const [modalCreateProjectOpen, setModalCreateProjectOpen] = useState(false)

  const date = new Date()

  const { getProjects, projectsTypes, handlePageVisible, seeMyProjects } = useContext(ProjectsContext)
  const { clients, getClients } = useContext(ClientsContext)
  const { professionals } = useContext(UsersContext)

  const { register, handleSubmit, reset } = useForm()

  const [open, setOpen] = useState(false)
  const [openNewFolder, setOpenNewFolder] = useState(false)

  const [toast, setToast] = useState<any>()

  const [filterMonth, setFilterMonth] = useState(String(date.getMonth() + 1))
  const [filterYear, setFilterYear] = useState(String(date.getFullYear()))

  const [listArchiveds, setListArchiveds] = useState(false)

  const [clienstWithFolderCreated, setClientsWithFolderCreatd] = useState<ClientType[]>([])

  const [projectPDF, setProjectPDF] = useState({} as File)

  useEffect(() => {
    listClienstWithFolderCreated()
  }, [])

  async function listClienstWithFolderCreated() {
    await api
      .get('/clients/folders')
      .then((response) => {
        setClientsWithFolderCreatd(response.data)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message="Não foi possível listar os clientes com pastas criadas." />)
      })
  }

  async function validateIfProjectCanBeCreated(client_id: number, finish_date_prediction: string) {
    const client = clients.filter((client) => client.id == client_id)[0]

    if (!client.projects_start_date && !client.projects_finish_date) {
      handleToast(<Toast category="error" message="Primeiramente cadastre as datas para o período de projetos do cliente." />)

      return false
    }

    if (finish_date_prediction > client.unformated_projects_finish_date) {
      handleToast(
        <Toast
          category="error"
          message="Data prevista para finalização do projeto ultrapassou a data limite cadastrada para o período de projetos do cliente."
        />
      )

      return false
    }

    return true
  }

  async function newProject(data: SubmitType) {
    const canBeCreated = await validateIfProjectCanBeCreated(data.client_id, data.finish_date_prediction)

    const formData = new FormData()
    Object.keys(data).map((key) => formData.append(key, data[key]))
    formData.set('document', projectPDF)

    if (canBeCreated) {
      api
        .post('/projects', formData)
        .then((response) => {
          if (response.data.status === 400) {
            handleToast(<Toast category="error" message={response.data.message} />)
          } else {
            getProjects()
            handleToast(<Toast category="success" message={response.data.message} />)
            setOpen(false)
            reset()
          }
        })
        .catch((err) => {
          handleToast(<Toast category="error" message={err.message} />)
        })
    }
  }

  async function newFolder(data: NewFolderType) {
    api
      .put(`/projects/new_folder`, data)
      .then((response) => {
        if (response.data.status === 400) {
          handleToast(<Toast category="error" message={response.data.message} />)
        } else {
          getClients()
          handleToast(<Toast category="success" message={response.data.message} />)
          setOpenNewFolder(false)
          reset()
        }
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  function handleToast(toast) {
    setToast(toast)

    setTimeout(() => {
      setToast(null)
    }, 5000)
  }

  async function handleFilter(data: FilterType) {
    await api
      .get(`/projects/filter/finished/${data.filter_month}/${data.filter_year}/${data.colaborator_id || undefined}`)
      .then((response) => {
        if (response.data.length) {
          PDFFinishedProjects(
            response.data,
            data.colaborator_id && professionals.filter((professional) => professional.id === Number(data.colaborator_id))[0].name,
            data.filter_month,
            data.filter_year
          )
        } else {
          handleToast(<Toast category="error" message="Não existem projetos finalizado no mês selecionado." />)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const bodyNewProject = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '450px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Cadastrar Projeto</h4>
          </div>

          <form className={styles.formEdit} onSubmit={handleSubmit(newProject)}>
            <label htmlFor="client_id">Cliente: *</label>
            <select required id="client_id" name="client_id" {...register('client_id')} defaultValue="0">
              <option value="0">Nenhum</option>
              {clients.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </select>

            <label htmlFor="project_type_id">Tipo de Projeto/Atividade: *</label>
            <select required id="project_type_id" name="project_type_id" {...register('project_type_id')} defaultValue="0">
              <option value="0">Nenhum</option>
              {projectsTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.description} {type.project_type_category === 0 ? `(Projeto / ${type.measure})` : `(Atividade / ${type.measure})`}
                </option>
              ))}
            </select>

            <label htmlFor="area">Área/Tempo: *</label>
            <input name="area" required type="text" {...register('area')} />

            <label htmlFor="criterion">Critério de Aceitação:</label>
            <input name="criterion" type="text" {...register('criterion')} />

            <label htmlFor="local">Local:</label>
            <input name="local" type="text" {...register('local')} />

            <label htmlFor="start_date">Data de Início: *</label>
            <input name="start_date" required type="date" {...register('start_date')} />

            <label htmlFor="finish_date_prediction">Data de Finalização (previsão): *</label>
            <input required name="finish_date_prediction" type="date" {...register('finish_date_prediction')} />

            <label htmlFor="responsable">Responsável:</label>
            <select id="responsable" name="responsable" {...register('responsable')} defaultValue="0">
              <option value="0">Nenhum</option>
              {professionals.map((professional) => (
                <option key={professional.id} value={professional.id}>
                  {professional.name}
                </option>
              ))}
            </select>

            <label htmlFor="image">Imagem</label>
            <input type="file" name="image" onChange={(e) => setProjectPDF(e.target.files[0])} />

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

  const bodyNewFolder = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '450px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Criar Nova Pasta</h4>
          </div>

          <form className={styles.formEdit} onSubmit={handleSubmit(newFolder)}>
            <label htmlFor="client_id">Cliente: *</label>
            <select required id="client_id" name="client_id" {...register('client_id')}>
              <option value="0">Nenhum</option>
              {clients.map((client) =>
                client.folder_created == 0 || client.folder_created === null ? (
                  <option key={client.id} value={client.id}>
                    {client.name}
                  </option>
                ) : null
              )}
            </select>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Criar Pasta
              </button>
              <button className={styles.cancel} type="button" onClick={() => setOpenNewFolder(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

  return seeMyProjects ? (
    <ProjectsColaborator />
  ) : (
    <div className={styles.main}>
      <Sidebar config={links} />

      {toast}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>PROJETOS</h1>

          <button
            onClick={() => setModalCreateProjectOpen(true)}
            style={{
              marginRight: 16
            }}
          >
            <span>NOVO PROJETO</span>
          </button>

          <button onClick={() => setOpenNewFolder(true)}>
            <span>NOVA PASTA DE PROJETO</span>
          </button>
        </div>

        <div className={styles.filter}>
          <form className={styles.formFilterDetails} onSubmit={handleSubmit(handleFilter)}>
            <div className={styles.inputs}>
              <div className={styles.group}>
                <label htmlFor="filter_month">Mês</label>
                <select name="filter_month" {...register('filter_month')} defaultValue={filterMonth}>
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </div>

              <div className={styles.group}>
                <label htmlFor="filter_month">Ano</label>
                <select name="filter_year" {...register('filter_year')} defaultValue={filterYear}>
                  <option value="2019">2019</option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                </select>
              </div>

              <div className={styles.group}>
                <label htmlFor="filter_month">Colaborador</label>
                <select name="colaborator_id" {...register('colaborator_id')}>
                  <option value="">Nenhum</option>
                  {professionals.map((professional) => (
                    <option key={professional.id} value={professional.id}>
                      {professional.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <button className={styles.button_filter} type="submit">
              <span>GERAR RELATÓRIO (PROJETOS FINALIZADOS)</span>
            </button>
          </form>
        </div>

        <div className={styles.handle__arch}>
          <h1>{listArchiveds ? 'Pastas Arquivadas' : 'Pastas Ativas'}</h1>
          <div style={{ display: 'flex', columnGap: 10 }}>
            <div className={styles.archive__button} onClick={() => setListArchiveds((prev) => !prev)}>
              <h2>{listArchiveds ? 'VER Pastas Ativas' : 'VER Pastas Arquivadas'}</h2>
            </div>
            <div className={styles.archive__button} onClick={() => handlePageVisible()}>
              <h2>VER MEUS PROJETOS</h2>
            </div>
          </div>
        </div>

        <div className={styles.table}>
          {listArchiveds
            ? clienstWithFolderCreated.map((client) =>
                client.folder_created === 1 && client.folder_archived ? (
                  <ProjectClientFolder refresh={listClienstWithFolderCreated} key={client.id} client={client} handleToast={handleToast} />
                ) : null
              )
            : clienstWithFolderCreated.map((client) =>
                client.folder_created === 1 && !client.folder_archived ? (
                  <ProjectClientFolder refresh={listClienstWithFolderCreated} key={client.id} client={client} handleToast={handleToast} />
                ) : null
              )}
        </div>

        <Dialog
          open={openNewFolder}
          onClose={() => setOpenNewFolder(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          scroll="body"
          TransitionComponent={Fade}
          transitionDuration={200}
        >
          {bodyNewFolder}
        </Dialog>
      </div>

      <DefaultModal isOpen={modalCreateProjectOpen} handleOpen={setModalCreateProjectOpen}>
        <ModalCreateProject handleToast={handleToast} setModalCreateProjectOpen={setModalCreateProjectOpen} />
      </DefaultModal>
    </div>
  )
}
