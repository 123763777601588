import React, { useState } from 'react';
import { mdiFileDocumentEdit, mdiTrashCan, mdiPencil } from '@mdi/js';

import Icon from '@mdi/react';
import DefaultModal from '../Modals/DefaultModal';
import ModalEditColaborator from '../Modals/Colaborator/Edit';
import ModalDeleteColaborator from '../Modals/Colaborator/Delete';
import ModalColaboratorDetails from '../Modals/Colaborator/Details';

import styles from './style.module.css';

type ColaboratorParam = {
  colaborator: ColaboratorType;
  refs: Header[];
  handleToast: any;
};

type Header = {
  label: string;
  ref: string;
};

type ColaboratorType = {
  id: number;
  name: string;
  role: number;
  cpf: string;
  nickname: string;
  mensalist: number;
  salary?: string;
  pix: string;
  agency: string;
  account: string;
  bank: string;
};

function Colaborator({ colaborator, refs, handleToast }: ColaboratorParam) {
  const [modalColaboratorDetailsOpen, setModalColaboratorDetailsOpen] =
    useState(false);
  const [modalEditColaboratorOpen, setModalEditColaboratorOpen] =
    useState(false);
  const [modalDeleteColaboratorOpen, setModalDeleteColaboratorOpen] =
    useState(false);

  return (
    <tr className={styles.box}>
      {refs.map((ref) => (
        <td key={ref.ref}>{colaborator[ref.ref]}</td>
      ))}

      <td className={styles.buttons}>
        <span onClick={() => setModalColaboratorDetailsOpen(true)}>
          <Icon
            path={mdiFileDocumentEdit}
            size={1.2}
            className={styles.icon__key}
          />
        </span>
        <span onClick={() => setModalEditColaboratorOpen(true)}>
          <Icon path={mdiPencil} size={1.2} className={styles.icon__pencil} />
        </span>
        <span onClick={() => setModalDeleteColaboratorOpen(true)}>
          <Icon path={mdiTrashCan} size={1.2} className={styles.icon__trash} />
        </span>
      </td>

      <DefaultModal
        handleOpen={setModalColaboratorDetailsOpen}
        isOpen={modalColaboratorDetailsOpen}
      >
        <ModalColaboratorDetails
          colaborator={colaborator}
          setModalColaboratorDetailsOpen={setModalColaboratorDetailsOpen}
          handleToast={handleToast}
        />
      </DefaultModal>

      <DefaultModal
        handleOpen={setModalEditColaboratorOpen}
        isOpen={modalEditColaboratorOpen}
      >
        <ModalEditColaborator
          colaborator={colaborator}
          setModalEditColaboratorOpen={setModalEditColaboratorOpen}
          handleToast={handleToast}
        />
      </DefaultModal>

      <DefaultModal
        handleOpen={setModalDeleteColaboratorOpen}
        isOpen={modalDeleteColaboratorOpen}
      >
        <ModalDeleteColaborator
          colaborator={colaborator}
          setModalDeleteColaboratorOpen={setModalDeleteColaboratorOpen}
          handleToast={handleToast}
        />
      </DefaultModal>
    </tr>
  );
}

export default React.memo(Colaborator);
