import React from 'react'
import styles from './style.module.css'

import iPencil from '../../assets/icons/pencil.svg'
import iTrash from '../../assets/icons/trash.svg'
import iEye from '../../assets/icons/eye.svg'

import { Modal } from '@material-ui/core'
import { useState } from 'react'

import { useForm } from 'react-hook-form'
import { api } from '../../services/api'

import Toast from '../Toast'
import { useContext } from 'react'
import { MonetaryContext } from '../../contexts/MonetaryContext'

type MoneyOriginParam = {
  origin: TypeType
  handleToast: any
}

type TypeType = {
  id: number
  description: string
}

type SubmitType = {
  description: string
}

interface BalanceType {
  balance: string
  openingBalance: string
  incomings?: string
  expenses?: string
  colaboratorsExpenses?: string
}

function MoneyOrigin({ origin, handleToast }: MoneyOriginParam) {
  const { getMoneyOrigins } = useContext(MonetaryContext)

  const { register, handleSubmit } = useForm()

  const [open, setOpen] = useState(false)

  const [balance, setBalance] = useState({} as BalanceType)
  const [showBalance, setShowBalance] = useState(false)

  // function getOriginBalance() {
  //   api
  //     .get(`/money_origins/balance/${origin.id}`)
  //     .then((response) => {
  //       setBalance(response.data)
  //     })
  //     .catch((err) => {
  //       handleToast(<Toast category="error" message={err.message} />)
  //     })
  // }

  // function handleShowBalance() {
  //   if (!showBalance === true) {
  //     // getOriginBalance()
  //   }

  //   setShowBalance(!showBalance)
  // }

  function deleteMoneyOrigin() {
    api
      .delete(`/money_origins/${origin.id}`)
      .then((response) => {
        getMoneyOrigins()
        setOpen(false)
        handleToast(<Toast category="success" message={response.data.message} />)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  async function handleEdit(data: SubmitType) {
    api
      .put(`/money_origins/${origin.id}`, {
        description: data.description,
        actual_balance: 0,
        opening_balance: 0
      })
      .then((response) => {
        getMoneyOrigins()
        handleToast(<Toast category="success" message={response.data.message} />)
        setOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  async function handleModalOpen(body: string) {
    if (body === 'edit') {
      setModalBody(bodyEditMoneyOrigin)
    } else if (body === 'delete') {
      setModalBody(bodyDeleteMoneyOrigin)
    }

    setOpen(true)
  }

  const bodyDeleteMoneyOrigin = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Deseja realmente deletar essa origem monetária?</h4>
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm} onClick={() => deleteMoneyOrigin()}>
              Confirmar
            </button>
            <button className={styles.cancel} type="button" onClick={() => setOpen(false)}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )

  const bodyEditMoneyOrigin = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Editar origem monetária</h4>
          </div>

          <form className={styles.formEdit} onSubmit={handleSubmit(handleEdit)}>
            <div className={styles.inputBlock}>
              <label htmlFor="description">Descrição: *</label>
              <input defaultValue={origin.description} name="description" type="text" {...register('description')} />
            </div>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

  const [modalBody, setModalBody] = useState(<div></div>)

  return (
    <div className={styles.box}>
      <div className={styles.boxName}>
        <span>{origin.description}</span>
      </div>
      {/* <div
        className={styles.boxName}
        title={
          showBalance
            ? `Balanço Inicial + Receitas - Despesas - Despesas dos Colaboradores \n\nBalanço Inicial: ${balance.openingBalance} \nReceitas: ${
                balance.incomings || 0
              } \nDespesas: ${balance.expenses || 0} \nDespesas dos Colaboradores: ${balance.colaboratorsExpenses || 0}`
            : ''
        }
      >
        <span>Balanço:</span>
        {showBalance ? <span style={{ margin: '0 8px' }}>{balance.balance}</span> : <div className={styles.hiddenBalance}></div>}
        <img src={iEye} alt="" onClick={() => handleShowBalance()} />
      </div> */}

      <div className={styles.buttons}>
        <img src={iPencil} alt="" onClick={() => handleModalOpen('edit')} />
        <img src={iTrash} alt="" onClick={() => handleModalOpen('delete')} />
      </div>

      <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        {modalBody}
      </Modal>
    </div>
  )
}

export default React.memo(MoneyOrigin)
