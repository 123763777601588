import { useEffect, useState } from 'react'
import { api } from '../../../../services/api'
import { mdiAccountTie, mdiOfficeBuilding } from '@mdi/js'
import { mdiBook, mdiClock, mdiTools } from '@mdi/js'

import Icon from '@mdi/react'
import styles from './style.module.css'
import { useHistory } from 'react-router-dom'
import DefaultModal from '../../DefaultModal'
import ModalConstructionSchedule from './Schedule'
import ConstructionTools from '../../../ConstructionTools'
import NoImage from '../../../../assets/construction_no_image.png'
import { variables } from '../../../../variables'

export default function ModalConstructionDetails({ construction, setModalConstructionDetailsOpen, handleToast }) {
  const [modalConstructionScheduleOpen, setModalConstructionScheduleOpen] = useState<boolean>(false)
  const [modalConstructionTools, setModalConstructionToolsOpen] = useState<boolean>(false)

  const history = useHistory()

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiOfficeBuilding} size={1.5} />
            <h4 className={styles.title}>DETALHES DA OBRA</h4>
          </div>

          {construction.image ? (
            <img src={`${variables.api}/public/${construction.image}`} alt="construction-image" />
          ) : (
            <img src={NoImage} alt="construction-image" />
          )}

          <div className={styles.infos}>
            <h5>
              <b>CLIENTE:</b> {construction.client}
            </h5>
            <h5>
              <b>ENDEREÇO:</b> {construction.address}, {construction.address_number} - {construction.neighborhood}
            </h5>
            <h5>
              <b>OBRA:</b> {construction.name}
            </h5>

            <hr />

            <h5>
              <b>INÍCIO:</b> {construction.start_date.split('-').reverse().join('/')}
            </h5>
            <h5>
              <b>PREVISÃO DE FIM:</b> {construction.delivery_forecast.split('-').reverse().join('/')}
            </h5>
          </div>

          <div className={styles.buttons}>
            <button title="Diários de Obra" onClick={() => history.push('/diaries', { constructionId: construction.id })}>
              <Icon path={mdiBook} color="white" size={1.5} />
            </button>

            <button title="Cronogramas de Obra" onClick={() => setModalConstructionScheduleOpen(true)}>
              <Icon path={mdiClock} color="white" size={1.5} />
            </button>

            <button title="Ferramentas/Produtos na Obra" onClick={() => setModalConstructionToolsOpen(true)}>
              <Icon path={mdiTools} color="white" size={1.5} />
            </button>
          </div>
        </div>
      </div>

      <DefaultModal handleOpen={setModalConstructionScheduleOpen} isOpen={modalConstructionScheduleOpen}>
        <ModalConstructionSchedule construction={construction} setModalConstructionScheduleOpen={setModalConstructionScheduleOpen} handleToast={handleToast} />
      </DefaultModal>

      {modalConstructionTools && <ConstructionTools construction_id={construction.id} opening={true} setOpenProductsAndTools={setModalConstructionToolsOpen} />}
    </div>
  )
}
