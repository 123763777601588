import { useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContext'

import './style.css'

import InternalSidebar from '../../components/InsideSidebar'

import arccoBranca from '../../assets/arcco_branca_mini.png'

import iPower from '../../assets/icons/power.svg'
import iHome from '../../assets/icons/home.svg'
import iPlus from '../../assets/icons/plus-white.svg'
import iGear from '../../assets/icons/gear.svg'
import iDiary from '../../assets/icons/diary.svg'
import iUsers from '../../assets/icons/users.svg'
import iPrint from '../../assets/icons/print.svg'
import iMoney from '../../assets/icons/money.svg'
import iMessage from '../../assets/icons/message.svg'

import Cookies from 'js-cookie'

type ConfigType = {
  title: string
  links: ConfigLinksType[]
}

type ConfigLinksType = {
  label: string
  href: string
  roles: number[]
}

type PropsSidebar = {
  config?: ConfigType
}

// ROLES
// 1 - Possui todas as permissões do sistema: Administrador.
// 2 - Poderá lançar despesas e receitas além de ter acesso as configurações do sistema: Financeiro.
// 3 - Lançar produtos/ferramentas/diários de obra: Engenheiro Júnior (Obra).
// 4 - Lançar diários de obra, listas/compra de produtos: Engenheiro Júnior (Administrativo).
// 5 - Irá gerenciar e lançar projetos para os projetistas: Projetista Chefe.
// 6 - Terá acesso apenas aos projetos que lhe forem cadastrados pelo projetista chefe: Projetista.

export default function Sidebar({ config }: PropsSidebar) {
  const { logout } = useContext(AuthContext)

  let role = Number(Cookies.get('role'))

  return (
    <>
      <aside className="sidebar">
        <img src={arccoBranca} alt="logo-arcco" />

        <div className="pages-list">
          <ul>
            <li>
              <img alt="icon-dashboard" title="Home" src={iHome} onClick={() => (window.location.pathname = '/dashboard')} />
            </li>
            {role === 1 || role === 2 || role === 3 || role === 4 || role === 5 || role === 6 ? (
              <li>
                <img
                  alt="icon-products"
                  title="Cadastros"
                  src={iPlus}
                  onClick={() => (window.location.pathname = `/records/${role === 4 ? 'lists' : role === 5 || role === 6 ? 'projects' : 'products'}`)}
                />
              </li>
            ) : null}
            {role === 1 || role === 2 ? (
              <li>
                <img alt="icon-monetary" title="Despesas e Receitas" src={iMoney} onClick={() => (window.location.pathname = '/expenses')} />
              </li>
            ) : null}
            {role === 1 || role === 2 || role === 5 || role === 3 || role === 4 ? (
              <li>
                <img
                  alt="icon-configs"
                  title="Configurações"
                  src={iGear}
                  onClick={() => (window.location.pathname = `${role === 5 ? '/types/projects' : '/configs'}`)}
                />
              </li>
            ) : null}
            {/* <li>
              <img
                alt="icon-reports"
                title="Relatórios"
                src={iPrint}
                onClick={() => (window.location.pathname = '/records/reports')}
              />
            </li> */}
            {Number(role) === 1 ? (
              <li>
                <img alt="icon-users" title="Usuários" src={iUsers} onClick={() => (window.location.pathname = '/users')} />
              </li>
            ) : null}
            {role === 1 || role === 2 || role === 3 || role === 4 ? (
              <li>
                <img alt="icon-diaries" title="Diários de Obra" src={iDiary} onClick={() => (window.location.pathname = '/diaries')} />
              </li>
            ) : null}
            {/* {role === 1 || role === 2 ? (
              <li>
                <img
                  alt="icon-message"
                  title="Mensagens"
                  src={iMessage}
                  onClick={() => (window.location.pathname = '/messages')}
                />
              </li>
            ) : null} */}
          </ul>
        </div>

        <img alt="icon-logout" onClick={() => logout()} src={iPower} />
      </aside>

      {config ? <InternalSidebar config={config} /> : null}
    </>
  )
}
