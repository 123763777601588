import { Dialog, Fade } from '@mui/material'
import { ReactChildren } from 'react'

type DefaultModalProps = {
  children: unknown
  isOpen: boolean
  handleOpen: (isOpen: boolean) => void
}

export default function DefaultModal(props: DefaultModalProps) {
  return (
    <Dialog open={props.isOpen} onClose={() => props.handleOpen(false)} TransitionComponent={Fade} transitionDuration={200} scroll="body" maxWidth="lg">
      {props.children}
    </Dialog>
  )
}
