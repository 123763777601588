import { createContext, useEffect } from 'react'
import { useState } from 'react'
import { api } from '../services/api'

type ColaboratorsContextType = {
  getColaborators: () => Promise<void>
  getDiariesWithTheLastFilter: () => Promise<void>
  getColaboratorsTypes: () => Promise<void>

  diaries: Diary[]

  setLastFilter: any
  lastFilter: any

  colaboratorsTypes: ColaboratorsTypesType[]
  colaborators: ColaboratorsType[]
  cltColaborators: ColaboratorsType[]
}

export type ColaboratorsType = {
  id: number
  name: string
  role: number
  cpf: string
  nickname: string
  mensalist: number
  salary?: string
  pix: string
  agency: string
  account: string
  bank: string
}

type ColaboratorsTypesType = {
  id: number
  description: string
  tax: string
}

type LastFilterType = {
  start_date: Date
  finish_date: Date
  employee_id: number
  construction_id: number
}

type Diary = {
  id: number
  employee: string
  employee_id: number
  address: string
  address_name: string
  address_number: number
  construction_id: number
  money_origin_id: number
  value: number
  date: string
  _date: string
}

export const ColaboratorsContext = createContext({} as ColaboratorsContextType)

export function ColaboratorProvider({ children }) {
  const [colaborators, setColaborators] = useState<ColaboratorsType[]>([])
  const [colaboratorsTypes, setColaboratorsTypes] = useState<ColaboratorsTypesType[]>([])
  const [lastFilter, setLastFilter] = useState<LastFilterType>()
  const [diaries, setDiaries] = useState<Diary[]>([])
  const [cltColaborators, setCLTColaborators] = useState<ColaboratorsType[]>([])

  useEffect(() => {
    if (colaborators.length === 0) getColaborators()
    if (colaboratorsTypes.length === 0) getColaboratorsTypes()

    getCLTColaborators()
  }, [])

  async function getColaborators() {
    await api
      .get('/employees')
      .then((response) => {
        setColaborators(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async function getColaboratorsTypes() {
    await api
      .get('/types/employees')
      .then((response) => {
        setColaboratorsTypes(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async function getDiariesWithTheLastFilter() {
    api
      .get(`/employees/diaries/filter/${lastFilter.start_date}/${lastFilter.finish_date}/${lastFilter.employee_id}/${lastFilter.construction_id}`)
      .then((response) => {
        setDiaries(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async function getCLTColaborators() {
    try {
      const { data } = await api.get('/employees/clt')
      setCLTColaborators(data)
    } catch (err) {
      console.error('fail on load CLT colaborators', { err })
    }
  }

  return (
    <ColaboratorsContext.Provider
      value={{
        colaborators,
        colaboratorsTypes,
        getColaboratorsTypes,
        getColaborators,
        getDiariesWithTheLastFilter,
        setLastFilter,
        diaries,
        lastFilter,
        cltColaborators
      }}
    >
      {children}
    </ColaboratorsContext.Provider>
  )
}
