import { mdiCheck, mdiCancel, mdiTrashCan, mdiPencil } from '@mdi/js'
import Icon from '@mdi/react'
import { useState } from 'react'
import { api } from '../../../../../services/api'
import DefaultModal from '../../../DefaultModal'
import ModalConfirmActionParcel from '../../../Parcel/ConfirmAction'
import ModalDeleteParcel from '../../../Parcel/Delete'
import ModalEditParcel from '../../../Parcel/Edit'

import styles from './style.module.css'

export default function Parcel({ parcel, getIncomingParcels, handleToast }) {
  const [modalDeleteParcelOpen, setModalDeleteParcelOpen] = useState(false)
  const [modalEditParcelOpen, setModalEditParcelOpen] = useState(false)
  const [modalConfirmActionParcelOpen, setModalConfirmActionParcelOpen] = useState(false)

  const [action, setAction] = useState('')

  function handlePaymentOrCancelRequest(action: string) {
    setAction(action)
    setModalConfirmActionParcelOpen(true)
  }

  return (
    <tr className={`${styles.parcel__box} ${parcel.status === 2 && styles.paid}`}>
      <td>{parcel.date}</td>
      <td>
        {Number(parcel.value).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL'
        })}
      </td>
      <td align="right">
        {parcel.status === 1 ? (
          <span onClick={() => handlePaymentOrCancelRequest('pay')} title="Pagar">
            <Icon path={mdiCancel} size={1.2} className={styles.icon__pencil} color="red" />
          </span>
        ) : (
          <span onClick={() => handlePaymentOrCancelRequest('cancel')} title="Cancelar pagamento">
            <Icon path={mdiCheck} size={1.2} className={styles.icon__pencil} color="green" />
          </span>
        )}

        <span onClick={() => setModalEditParcelOpen(true)} title="Editar parcela">
          <Icon path={mdiPencil} size={1.2} color="green" />
        </span>
        <span onClick={() => setModalDeleteParcelOpen(true)} title="Remover parcela">
          <Icon path={mdiTrashCan} size={1.2} color="red" />
        </span>
      </td>

      <DefaultModal isOpen={modalConfirmActionParcelOpen} handleOpen={setModalConfirmActionParcelOpen}>
        <ModalConfirmActionParcel
          type="incoming"
          action={action}
          parcel={parcel}
          getParcels={getIncomingParcels}
          handleToast={handleToast}
          setModalConfirmActionParcelOpen={setModalConfirmActionParcelOpen}
        />
      </DefaultModal>

      <DefaultModal isOpen={modalEditParcelOpen} handleOpen={setModalEditParcelOpen}>
        <ModalEditParcel
          type="incoming"
          parcel={parcel}
          getParcels={getIncomingParcels}
          handleToast={handleToast}
          setModalEditParcelOpen={setModalEditParcelOpen}
        />
      </DefaultModal>

      <DefaultModal isOpen={modalDeleteParcelOpen} handleOpen={setModalDeleteParcelOpen}>
        <ModalDeleteParcel
          type="incoming"
          parcelId={parcel.id}
          getParcels={getIncomingParcels}
          handleToast={handleToast}
          setModalDeleteParcelOpen={setModalDeleteParcelOpen}
        />
      </DefaultModal>
    </tr>
  )
}
