import { createContext, useEffect } from 'react'
import { useState } from 'react'
import { api } from '../services/api'

type MeasureContextType = {
  measures: MeasuresType[]

  getMeasures: any
}

export type MeasuresType = {
  id: number
  description: string
}

export const MeasureContext = createContext({} as MeasureContextType)

export function MeasureProvider({ children }) {
  const [measures, setMeasures] = useState<MeasuresType[]>([])

  useEffect(() => {
    if (measures.length === 0) getMeasures()
  }, [])

  async function getMeasures() {
    await api
      .get('/measures')
      .then((response) => {
        setMeasures(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <MeasureContext.Provider
      value={{
        measures,
        getMeasures
      }}
    >
      {children}
    </MeasureContext.Provider>
  )
}
