import { useEffect, useState } from 'react'
import { api } from '../../../../services/api'
import { mdiAccountTie, mdiImage, mdiOfficeBuilding } from '@mdi/js'
import { mdiBook, mdiClock, mdiTools } from '@mdi/js'

import Icon from '@mdi/react'
import styles from './style.module.css'
import { useHistory } from 'react-router-dom'
import DefaultModal from '../../DefaultModal'
import { variables } from '../../../../variables'

export interface PurchaseRequestFile {
  id: number
  description: string
  filename: string
}

export default function PurchaseRequestDetails({ purchase, setPurchaseRequestDetailsOpen, handleToast }) {
  const [purchaseRequestFiles, setPurchaseRequestFiles] = useState<PurchaseRequestFile[]>([])

  useEffect(() => {
    loadPurchaseRequestFiles()
  }, [])

  async function loadPurchaseRequestFiles() {
    try {
      const { data } = await api.get(`/purchases/requests/files/${purchase.id}`)
      setPurchaseRequestFiles(data)
    } catch (err) {
      console.error('fail on load purchase request files', { err })
    }
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiOfficeBuilding} size={1.5} />
            <h4 className={styles.title}>DETALHES DO PEDIDO</h4>
          </div>

          <h5>
            Cliente: <b>{purchase.client}</b>
          </h5>

          <h5>
            Obra: <b>{purchase.construction}</b>
          </h5>

          <h5>
            Enviado para o cliente: <b>{purchase.send_to_client_date || 'Sem data.'}</b>
          </h5>

          <h5>
            Data de pagamento: <b>{purchase.payment_date || 'Sem data.'}</b>
          </h5>

          <h5>
            Chegou na obra em: <b>{purchase.arrival_in_construction_date || 'Sem data.'}</b>
          </h5>

          <h5>
            Registrado: <b>{purchase.created_by}</b>
          </h5>

          <h5>
            Última atualização: <b>{purchase.last_user_update}</b>
          </h5>

          <hr />
          <h5>Valor: {purchase.value ? <b>R$ {purchase.value}</b> : '...'}</h5>
          <h5>
            PIX/Dados Bancários: <b>{purchase.bank}</b>
          </h5>

          {purchase.observation ? (
            <>
              <hr />
              <h5>
                Observação: <b>{purchase.observation}</b>
              </h5>
            </>
          ) : null}

          <hr />

          <div className={styles.files}>
            {purchaseRequestFiles.map((file) => (
              <div className={styles.new__file} onClick={() => window.open(`${variables.api}/public/${file.filename}`)}>
                <Icon path={mdiImage} size={1.2} color="#000" /> <h3>{file.description}</h3>
              </div>
            ))}
          </div>

          <div className={styles.buttons}>
            <button onClick={() => setPurchaseRequestDetailsOpen(false)}>FECHAR</button>
          </div>
        </div>
      </div>
    </div>
  )
}
