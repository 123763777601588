import React from 'react';
import styles from './style.module.css';

import iPencil from '../../assets/icons/pencil.svg';
import iTrash from '../../assets/icons/trash.svg';

import { mdiPencil } from '@mdi/js';
import { mdiTrashCan } from '@mdi/js';
import Icon from '@mdi/react';
import { Modal } from '@material-ui/core';
import { useState } from 'react';

import { useForm } from 'react-hook-form';
import { api } from '../../services/api';
import { useEffect } from 'react';

import Toast from '../Toast';
import { useContext } from 'react';
import { ProvidersContext } from '../../contexts/ProvidersContext';

import InputMask from 'react-input-mask';
import ModalEditProvider from '../Modals/Provider/Edit';
import ModalDeleteProvider from '../Modals/Provider/Delete';
import DefaultModal from '../Modals/DefaultModal';

type ProviderParam = {
  provider: ProviderType;
  refs: Header[];
  handleToast: any;
};

type Header = {
  label: string;
  ref: string;
};

type ProviderType = {
  id: number;
  name: string;
};

type SubmitType = {
  name: string;
  city: string;
  state: string;
  cnpj: string;
  cpf: string;
  phone: string;
  email: string;
  address: string;
  observation: string;
};

function Provider({ provider, refs, handleToast }: ProviderParam) {
  const [providerInformation, setProviderInformation] = useState<SubmitType>(
    {} as SubmitType
  );

  useEffect(() => {
    getProviderInformation();
  }, [provider]);

  function getProviderInformation() {
    api
      .get(`/providers/information/${provider.id}`)
      .then((response) => {
        setProviderInformation(response.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const [modalEditProviderOpen, setModalEditProviderOpen] = useState(false);
  const [modalDeleteProviderOpen, setModalDeleteProviderOpen] = useState(false);

  return (
    <tr className={styles.box}>
      {refs.map((ref) => (
        <td key={ref.ref}>{provider[ref.ref]}</td>
      ))}

      <td className={styles.buttons}>
        <span onClick={() => setModalEditProviderOpen(true)}>
          <Icon path={mdiPencil} size={1.2} className={styles.icon__pencil} />
        </span>
        <span onClick={() => setModalDeleteProviderOpen(true)}>
          <Icon path={mdiTrashCan} size={1.2} className={styles.icon__trash} />
        </span>
      </td>

      <DefaultModal
        handleOpen={setModalEditProviderOpen}
        isOpen={modalEditProviderOpen}
      >
        <ModalEditProvider
          provider={provider}
          setModalEditProviderOpen={setModalEditProviderOpen}
          handleToast={handleToast}
        />
      </DefaultModal>

      <DefaultModal
        handleOpen={setModalDeleteProviderOpen}
        isOpen={modalDeleteProviderOpen}
      >
        <ModalDeleteProvider
          provider={provider}
          setModalDeleteProviderOpen={setModalDeleteProviderOpen}
          handleToast={handleToast}
        />
      </DefaultModal>
    </tr>
  );
}

export default React.memo(Provider);
