import { BaseSyntheticEvent, useContext, useState } from 'react';
import { TextField } from '@mui/material';
import { ToolsContext } from '../../../../contexts/ToolsContext';
import { api } from '../../../../services/api';
import { mdiTools } from '@mdi/js';

import Icon from '@mdi/react';
import Toast from '../../../Toast';
import styles from './style.module.css';

type SubmitType = {
  description: string;
};

type InputType = {
  value: string;
  name: string;
};

export default function ModalCreateTool({
  setModalCreateToolOpen,
  handleToast,
}) {
  const [formValues, setFormValues] = useState({} as SubmitType);

  const { getTools } = useContext(ToolsContext);

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value,
    }));
  }

  async function handleCreate(e: BaseSyntheticEvent) {
    e.preventDefault();

    api
      .post(`/tools`, formValues)
      .then((response) => {
        getTools();
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
        setModalCreateToolOpen(false);
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />);
      });
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiTools} size={1.5} />
            <h4 className={styles.title}>CADASTRAR FERRAMENTA</h4>
          </div>

          <form
            className={styles.form__create}
            onSubmit={(e) => handleCreate(e)}
          >
            <TextField
              label="Descrição"
              type="text"
              required
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'description',
                  value: e.target.value,
                })
              }
            />

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button
                className={styles.cancel}
                type="button"
                onClick={() => setModalCreateToolOpen(false)}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
