import { BaseSyntheticEvent, ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { Autocomplete, InputAdornment, TextField } from '@mui/material'
import { mdiCreditCard } from '@mdi/js'
import { api } from '../../../../services/api'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { ptBR } from 'date-fns/locale'
import { MonetaryContext, MoneyOriginType } from '../../../../contexts/MonetaryContext'
import { ProviderType } from '../../../../contexts/ProvidersContext'
import { ClientsContext } from '../../../../contexts/ClientsContext'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import styles from './style.module.css'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { IIncoming } from '../../../../interfaces/Incoming'
import { ConstructionsContext, ConstructionType } from '../../../../contexts/ConstructionsContext'

interface SubmitType extends IIncoming {
  images: File[]
}

type InputType = {
  value: string
  name: string
}

type IncomingType = {
  id: number
  description: string
}

type ModalEditIncomingProps = {
  incoming: IIncoming
  setModalEditIncomingOpen: Dispatch<SetStateAction<boolean>>
  handleToast(toast: JSX.Element): Promise<void>
  getIncoming?: () => Promise<void>
}

export default function ModalEditIncoming({ incoming, setModalEditIncomingOpen, handleToast, getIncoming }: ModalEditIncomingProps) {
  console.log(incoming)
  const defaultFormValues = incoming.parcels ? { parcels: incoming.parcels } : { parcels: 1 }

  const [formValues, setFormValues] = useState(defaultFormValues as SubmitType)
  const [incomingInvoice, setIncomingInvoice] = useState<File | null>(null)
  const [clientConstructions, setClientConstructions] = useState([])

  const { incomingsTypes, moneyOrigins } = useContext(MonetaryContext)
  const { clients } = useContext(ClientsContext)
  const { constructions } = useContext(ConstructionsContext)

  useEffect(() => {
    getClientConstructions(incoming.client_id)
  }, [])

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function handleEdit(e: BaseSyntheticEvent) {
    e.preventDefault()

    if (incoming.parcels === null && formValues.parcels > 1) {
      formValues.value = incoming.value
    }

    const formData = new FormData()
    Object.keys(formValues).map((key) => formData.append(key, formValues[key]))

    if (incomingInvoice) {
      formData.set('invoice', incomingInvoice)
    }

    api
      .put(`/incomings/${incoming.id}`, formData)
      .then((response) => {
        handleToast(<Toast category="success" message={response.data.message} />)
        if (getIncoming) getIncoming()
        setModalEditIncomingOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  async function getClientConstructions(clientId: number) {
    try {
      const { data } = await api.get(`/constructions/client/${clientId}`)
      console.log(data)
      setClientConstructions(data)
    } catch (err) {
      console.error('fail on load client constructions', err)
    }
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiCreditCard} size={1.5} />
            <h4 className={styles.title}>EDITAR RECEITA</h4>
          </div>

          <form className={styles.form__edit} onSubmit={(e) => handleEdit(e)}>
            <TextField
              label="Valor"
              type="text"
              required
              autoComplete="off"
              value={formValues.value ? formValues.value : incoming.value}
              onChange={(e) => {
                handleInputChange({
                  name: 'value',
                  value: e.target.value.replace(',', '.')
                })
              }}
            />

            <Autocomplete
              value={undefined}
              defaultValue={incomingsTypes.find((type) => type.id === incoming.incoming_type_id)}
              onChange={(event, newValue: IncomingType) => {
                handleInputChange({
                  name: 'incoming_type_id',
                  value: newValue && String(newValue.id)
                })
              }}
              options={incomingsTypes}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Tipo da receita" required />}
            />

            <Autocomplete
              value={undefined}
              defaultValue={
                incoming.subtype === 0 ? null : incoming.subtype === 1 ? { description: 'Construtora', value: 1 } : { description: 'Escritório', value: 2 }
              }
              onChange={(event, newValue: { description: string; value: number }) => {
                handleInputChange({
                  name: 'subtype',
                  value: newValue && String(newValue.value)
                })
              }}
              options={[
                { description: 'Construtora', value: 1 },
                { description: 'Escritório', value: 2 }
              ]}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Subtipo da Despesa" required />}
            />

            <Autocomplete
              value={undefined}
              defaultValue={clients.find((client) => client.id === incoming.client_id)}
              onChange={(event, newValue: ProviderType) => {
                if (newValue) {
                  handleInputChange({
                    name: 'client_id',
                    value: String(newValue.id)
                  })

                  getClientConstructions(newValue.id)
                }
              }}
              options={clients}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Cliente" />}
            />

            {incoming.construction_id || formValues.client_id || incoming.client_id ? (
              <Autocomplete
                value={undefined}
                defaultValue={constructions.find((construction) => construction.id === incoming.construction_id)}
                onChange={(event, newValue: ConstructionType) => {
                  if (newValue) {
                    handleInputChange({
                      name: 'construction_id',
                      value: String(newValue.id)
                    })
                  } else {
                    handleInputChange({
                      name: 'construction_id',
                      value: String(0)
                    })
                  }
                }}
                options={clientConstructions}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Obra" />}
              />
            ) : null}

            <TextField
              label="Parcelas"
              type="number"
              required
              helperText={
                incoming.parcels > 1 && formValues.parcels < 2
                  ? '* Essa receita possuí mais de uma parcela cadastrada, se você alterar para uma parcela todas as parcelas cadastradas serão canceladas.'
                  : ''
              }
              value={formValues.parcels ? formValues.parcels : incoming.parcels}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {formValues.value
                      ? formValues.value
                      : incoming.value &&
                        `${formValues.parcels}x de ${(Number(formValues.value ? formValues.value : incoming.value) / Number(formValues.parcels)).toFixed(2)}`}
                  </InputAdornment>
                )
              }}
              onChange={(e) => {
                handleInputChange({
                  name: 'parcels',
                  value: e.target.value
                })
              }}
            />

            {formValues.parcels == 1 ? (
              <>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                  <DatePicker
                    label="Data de Pagamento"
                    mask="__/__/____"
                    value={formValues.payday ? formValues.payday : incoming.payday ? `${incoming.payday.split('/').reverse().join('-')}, 00:00:00` : null}
                    onChange={(newValue) => {
                      handleInputChange({
                        name: 'payday',
                        value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                      })
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>

                {(formValues.payday || incoming.payday) && (
                  <Autocomplete
                    value={undefined}
                    defaultValue={moneyOrigins.find((origin) => origin.id === incoming.money_origin_id)}
                    onChange={(event, newValue: MoneyOriginType) => {
                      handleInputChange({
                        name: 'money_origin_id',
                        value: newValue && String(newValue.id)
                      })
                    }}
                    options={moneyOrigins}
                    getOptionLabel={(option) => option.description}
                    renderInput={(params) => <TextField {...params} label="Origem do Pagamento" />}
                  />
                )}

                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                  <DatePicker
                    label="Data de Vencimento"
                    mask="__/__/____"
                    value={
                      formValues.due_date ? formValues.due_date : incoming.due_date ? `${incoming.due_date.split('/').reverse().join('-')}, 00:00:00` : null
                    }
                    onChange={(newValue) => {
                      handleInputChange({
                        name: 'due_date',
                        value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                      })
                    }}
                    renderInput={(params) => <TextField {...params} required />}
                  />
                </LocalizationProvider>
              </>
            ) : (
              <>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                  <DatePicker
                    label="Data da Primeira Parcela"
                    mask="__/__/____"
                    value={
                      formValues.payday_first_parcel
                        ? formValues.payday_first_parcel
                        : incoming.payday_first_parcel
                        ? `${incoming.payday_first_parcel.split('/').reverse().join('-')}, 00:00:00`
                        : null
                    }
                    onChange={(newValue) => {
                      handleInputChange({
                        name: 'payday_first_parcel',
                        value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                      })
                    }}
                    renderInput={(params) => <TextField {...params} required />}
                  />
                </LocalizationProvider>

                <Autocomplete
                  value={undefined}
                  defaultValue={moneyOrigins.find((origin) => origin.id === incoming.money_origin_id)}
                  onChange={(event, newValue: MoneyOriginType) => {
                    handleInputChange({
                      name: 'money_origin_id',
                      value: newValue && String(newValue.id)
                    })
                  }}
                  options={moneyOrigins}
                  getOptionLabel={(option) => option.description}
                  renderInput={(params) => <TextField {...params} label="Origem do Pagamento" />}
                />
              </>
            )}

            <TextField
              label="Descrição"
              type="text"
              autoComplete="off"
              defaultValue={incoming.description}
              onChange={(e) => {
                handleInputChange({
                  name: 'description',
                  value: e.target.value
                })
              }}
            />

            <label htmlFor="images">Nota Fiscal/Comprovante:</label>
            <input
              name="images"
              className={styles.inputfile}
              type="file"
              onChange={(e) => setIncomingInvoice(e.target.files[0])}
              style={{ padding: '0.9rem 1rem' }}
            />

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalEditIncomingOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
