import { useEffect, useState } from 'react'
import { api } from '../../../../../services/api'
import { mdiClockFast, mdiPackageVariant } from '@mdi/js'

import Icon from '@mdi/react'
import ScheduleBox from './ScheduleBox'

import styles from './style.module.css'

export interface ScheduleType {
  id: number
  start_date_prediction: string
  start_date_prediction_unformated: string
  finish_date_prediction: string
  finish_date_prediction_unformated: string
  stage: string
  start_date: string
  start_date_unformated: string
  finish_date: string
  finish_date_unformated: string
  days_left_to_start?: number
  days_left_to_finish?: number
  days_passed_or_lefted_of_start?: number
  days_passed_or_lefted_of_finish?: number
  quantity: string
  total_worked_area: string
  cost_price: string
  proposed_quantity: string
  proposed_cost_price: string
  measure: string
  measure_id: number
  observation?: string
  status: number
}

interface SchedulesParams {
  active: ScheduleType[]
  finished: ScheduleType[]
  waiting: ScheduleType[]
  deconfigured: ScheduleType[]
}

export default function ModalClientViewConstructionSchedule({ construction, setModalClientViewConstructionScheduleOpen }) {
  let defaultSchedulesFormat = {
    active: [] as ScheduleType[],
    finished: [] as ScheduleType[],
    waiting: [] as ScheduleType[],
    deconfigured: [] as ScheduleType[]
  }

  const [schedules, setSchedules] = useState<SchedulesParams>(defaultSchedulesFormat)

  useEffect(() => {
    getConstructionSchedules()
  }, [])

  async function getConstructionSchedules() {
    try {
      const { data, status } = await api.get(`/constructions_schedules/${construction.id}`)

      if (status === 200) {
        setSchedules(data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <div className={styles.box__title}>
              <Icon path={mdiClockFast} size={1.5} />
              <h4 className={styles.title}>CRONOGRAMA DA OBRA</h4>
            </div>
          </div>

          <div className={styles.sheet}>
            <div id="waiting" className={styles.section}>
              <h1>aguardando</h1>
              {schedules.deconfigured?.length ? schedules.deconfigured.map((schedule) => <ScheduleBox schedule={schedule} key={schedule.id} />) : null}
              {schedules.waiting?.length ? schedules.waiting?.map((schedule) => <ScheduleBox schedule={schedule} key={schedule.id} />) : null}
              {!schedules.waiting?.length && !schedules.deconfigured?.length ? (
                <div className={styles.empty__section}>
                  <Icon path={mdiPackageVariant} size={1.5} />
                  <h3>não encontramos nada para listar</h3>
                </div>
              ) : null}
            </div>
            <div id="active" className={styles.section}>
              <h1>em andamento</h1>
              {schedules.active?.length ? (
                schedules.active?.map((schedule) => <ScheduleBox schedule={schedule} key={schedule.id} />)
              ) : (
                <div className={styles.empty__section}>
                  <Icon path={mdiPackageVariant} size={1.5} />
                  <h3>não encontramos nada para listar</h3>
                </div>
              )}
            </div>
            <div id="finished" className={styles.section}>
              <h1>finalizado</h1>
              {schedules.finished?.length ? (
                schedules.finished?.map((schedule) => <ScheduleBox schedule={schedule} key={schedule.id} />)
              ) : (
                <div className={styles.empty__section}>
                  <Icon path={mdiPackageVariant} size={1.5} />
                  <h3>não encontramos nada para listar</h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
