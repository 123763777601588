import { BaseSyntheticEvent, useContext, useState } from 'react';
import { ColaboratorsContext } from '../../../../contexts/ColaboratorsContext';
import {
  Autocomplete,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { Checkbox } from '@mui/material';
import { api } from '../../../../services/api';
import { mdiAccountTie } from '@mdi/js';

import Icon from '@mdi/react';
import Toast from '../../../Toast';
import styles from './style.module.css';

type SubmitType = {
  id: number;
  name: string;
  role: number;
  cpf: string;
  nickname: string;
  mensalist: number;
  salary?: string;
  pix: string;
  agency: string;
  account: string;
  bank: string;
};

type ColaboratorType = {
  id: number;
  description: string;
};

type InputType = {
  value: string;
  name: string;
};

export default function ModalEditColaborator({
  colaborator,
  setModalEditColaboratorOpen,
  handleToast,
}) {
  const [formValues, setFormValues] = useState({} as SubmitType);

  const { getColaborators, colaboratorsTypes } =
    useContext(ColaboratorsContext);

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value,
    }));
  }

  async function handleEdit(e: BaseSyntheticEvent) {
    e.preventDefault();

    api
      .put(`/employees/${colaborator.id}`, formValues)
      .then((response) => {
        getColaborators();
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
        setModalEditColaboratorOpen(false);
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />);
      });
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiAccountTie} size={1.5} />
            <h4 className={styles.title}>EDITAR COLABORADOR</h4>
          </div>

          <form className={styles.form__edit} onSubmit={(e) => handleEdit(e)}>
            <TextField
              defaultValue={colaborator.name}
              label="Nome"
              type="text"
              required
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'name',
                  value: e.target.value,
                })
              }
            />
            <TextField
              defaultValue={colaborator.nickname}
              label="Apelido"
              type="text"
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'nickname',
                  value: e.target.value,
                })
              }
            />
            <TextField
              defaultValue={colaborator.cpf}
              label="CPF"
              type="text"
              required
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'cpf',
                  value: e.target.value,
                })
              }
            />

            <Autocomplete
              value={undefined}
              defaultValue={
                colaboratorsTypes.filter(
                  (type) => type.id == colaborator.role
                )[0]
              }
              onChange={(event, newValue: ColaboratorType) => {
                handleInputChange({
                  name: 'role',
                  value: String(newValue.id),
                });
              }}
              options={colaboratorsTypes}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => (
                <TextField {...params} label="Função" required />
              )}
            />

            <TextField
              defaultValue={colaborator.pix}
              label="PIX"
              type="text"
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'pix',
                  value: e.target.value,
                })
              }
            />

            <div className={styles.input__block}>
              <TextField
                defaultValue={colaborator.agency}
                label="Agência"
                type="text"
                autoComplete="off"
                onChange={(e) =>
                  handleInputChange({
                    name: 'agency',
                    value: e.target.value,
                  })
                }
              />
              <TextField
                defaultValue={colaborator.account}
                label="Conta"
                type="text"
                autoComplete="off"
                onChange={(e) =>
                  handleInputChange({
                    name: 'account',
                    value: e.target.value,
                  })
                }
              />
            </div>

            <TextField
              defaultValue={colaborator.bank}
              label="Banco"
              type="text"
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'bank',
                  value: e.target.value,
                })
              }
            />

            <RadioGroup
              aria-labelledby="radio-buttons"
              defaultValue={colaborator.mensalist}
              onChange={(e) =>
                handleInputChange({
                  name: 'mensalist',
                  value: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Mensalista"
              />
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="Diarista"
              />
            </RadioGroup>

            {(colaborator.mensalist || formValues.mensalist) && (
              <TextField
                defaultValue={colaborator.salary}
                label="Salário"
                type="text"
                autoComplete="off"
                onChange={(e) =>
                  handleInputChange({
                    name: 'salary',
                    value: e.target.value,
                  })
                }
              />
            )}

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button
                className={styles.cancel}
                type="button"
                onClick={() => setModalEditColaboratorOpen(false)}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
