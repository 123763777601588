import { useContext, useEffect, useState } from 'react'

import styles from './style.module.css'

import Sidebar from '../../../components/Sidebar'
import links from '../../../components/configs/links-records'

import iPlus from '../../../assets/icons/plus.svg'

import { useForm } from 'react-hook-form'
import { Modal } from '@material-ui/core'
import { api } from '../../../services/api'

import Toast from '../../../components/Toast'
import { ColaboratorsContext } from '../../../contexts/ColaboratorsContext'
import { ConstructionsContext } from '../../../contexts/ConstructionsContext'

import InputMask from 'react-input-mask'
import Cookies from 'js-cookie'
import { MonetaryContext } from '../../../contexts/MonetaryContext'
import TableGeneric from '../../../components/TableGeneric'
import DefaultModal from '../../../components/Modals/DefaultModal'
import ModalCreateColaborator from '../../../components/Modals/Colaborator/Create'

type SubmitType = {
  id: number
  name: string
  role: number
  cpf: string
  nickname: string
  mensalist: number
  salary?: string
  pix: string
  agency: string
  account: string
  bank: string
}

type DiarySubmitType = {
  construction_id: number
  employee_id: number
  value: number
  date: string
  money_origin_id: number
}

type Header = {
  label: string
  ref: string
}

export default function Colaborators() {
  const { register, handleSubmit, reset } = useForm()

  const role = Number(Cookies.get('role'))

  const [open, setOpen] = useState(false)
  const [createDiaryOpen, setCreateDiaryOpen] = useState(false)

  const [modalCreateColaboratorOpen, setModalCreateColaboratorOpen] = useState(false)

  const [toast, setToast] = useState<any>()

  const { constructions } = useContext(ConstructionsContext)
  const { moneyOrigins } = useContext(MonetaryContext)

  const { colaborators, getColaborators, colaboratorsTypes } = useContext(ColaboratorsContext)

  const [isMensalist, setIsMensalist] = useState(false)

  const [totalSalaries, setTotalSalaries] = useState('')

  useEffect(() => {
    getTotalSalaries()
  }, [])

  async function getTotalSalaries() {
    await api
      .get('/employees/salaries')
      .then((response) => {
        setTotalSalaries(
          response.data[0].total_salaries.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL'
          })
        )
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function newColaborator(data: SubmitType) {
    api
      .post('/employees', data)
      .then((response) => {
        getColaborators()
        getTotalSalaries()
        handleToast(<Toast category="success" message={response.data.message} />)
        setOpen(false)
        reset()
      })
      .catch((err) => {
        console.log(err)
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  function newColaboratorDiary(data: DiarySubmitType) {
    api
      .post('/employees/diaries', {
        employee_id: data.employee_id,
        construction_id: data.construction_id,
        value: data.value,
        date: data.date,
        money_origin_id: data.money_origin_id
      })
      .then((response) => {
        handleToast(<Toast category="success" message={response.data.message} />)
        setCreateDiaryOpen(false)
        reset()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function handleToast(toast) {
    setToast(toast)

    setTimeout(() => {
      setToast(null)
    }, 5000)
  }

  const bodyNewColaboratorDiary = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ maxWidth: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Cadastrar diária de colaborador</h4>
          </div>

          <form className={styles.formEdit} onSubmit={handleSubmit(newColaboratorDiary)}>
            <label htmlFor="date">Data: *</label>
            <input name="date" required type="date" {...register('date')} />

            <label htmlFor="value">Valor: *</label>
            <input required name="value" type="text" {...register('value')} />

            <label htmlFor="construction_id">Obra: *</label>
            <select required name="construction_id" {...register('construction_id')} defaultValue="0">
              <option value="0">Selecione uma obra</option>
              {constructions.map((construction) => (
                <option key={construction.id} value={construction.id}>
                  {construction.name} ({construction.client})
                </option>
              ))}
            </select>

            <label htmlFor="employee_id">Colaborador: *</label>
            <select name="employee_id" required {...register('employee_id')}>
              <option value="0">Selecione um colaborador</option>
              {colaborators.map((colaborator) => (
                <option key={colaborator.id} value={colaborator.id}>
                  {colaborator.name}
                </option>
              ))}
            </select>

            <label htmlFor="money_origin_id">Origem do dinheiro: *</label>
            <select name="money_origin_id" required {...register('money_origin_id')}>
              <option value="0">Selecione uma origem</option>
              {moneyOrigins.map((origin) => (
                <option key={origin.id} value={origin.id}>
                  {origin.description}
                </option>
              ))}
            </select>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button
                className={styles.cancel}
                onClick={() => {
                  setCreateDiaryOpen(false)
                  reset()
                }}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

  const bodyNewColaborator = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ maxWidth: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Cadastrar colaborador</h4>
          </div>

          <form className={styles.formEdit} onSubmit={handleSubmit(newColaborator)}>
            <label htmlFor="name">Nome: *</label>
            <input name="name" required type="text" {...register('name')} />

            <label htmlFor="nickname">Apelido:</label>
            <input name="nickname" type="text" {...register('nickname')} />

            <label htmlFor="cpf">CPF: *</label>
            <InputMask required mask="999.999.999-99" name="cpf" {...register('cpf')} />

            <label htmlFor="role">Função: *</label>
            <select name="role" required {...register('role')}>
              {colaboratorsTypes.map((type) => (
                <option value={type.id} key={type.id}>
                  {type.description}
                </option>
              ))}
            </select>

            <label htmlFor="pix">PIX:</label>
            <input name="pix" type="text" {...register('pix')} />

            <label htmlFor="agency">Agência:</label>
            <input name="agency" type="text" {...register('agency')} />

            <label htmlFor="account">Conta:</label>
            <input name="account" type="text" {...register('account')} />

            <label htmlFor="bank">Banco:</label>
            <input name="bank" type="text" {...register('bank')} />

            <div className={styles.inputRadios}>
              <input type="radio" name="mensalist" value="0" {...register('mensalist')} onClick={() => setIsMensalist(false)} />
              Diarista
              <input type="radio" name="mensalist" value="1" {...register('mensalist')} onClick={() => setIsMensalist(true)} />
              Mensalista
            </div>

            {isMensalist ? (
              <>
                <label htmlFor="salary">Salário: *</label>
                <input name="salary" required type="text" {...register('salary')} />
              </>
            ) : null}

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

  const tableHeaders: Header[] = [
    {
      label: 'Nome',
      ref: 'name'
    },
    {
      label: 'Apelido',
      ref: 'nickname'
    }
  ]

  return (
    <div className={styles.main}>
      <Sidebar config={links} />

      {toast}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>COLABORADORES</h1>

          <button className={styles.button} onClick={() => setModalCreateColaboratorOpen(true)} style={{ marginRight: '1rem' }}>
            <span>NOVO COLABORADOR</span>
            <img src={iPlus} alt="" />
          </button>

          <button className={styles.button} onClick={() => setCreateDiaryOpen(true)}>
            <span>NOVA DIÁRIA DE COLABORADOR</span>
            <img src={iPlus} alt="" />
          </button>
        </div>

        {totalSalaries ? (
          role === 1 || role === 2 ? (
            <h4 style={{ marginBottom: 20, padding: 4 }}>Gasto mensal com salários (CLT): {totalSalaries}</h4>
          ) : null
        ) : null}

        <div className={styles.table}>
          <TableGeneric headers={tableHeaders} data={colaborators} handleToast={handleToast} componentToRender="colaborator" fieldToSearch="name" title="" />
        </div>

        <DefaultModal handleOpen={setModalCreateColaboratorOpen} isOpen={modalCreateColaboratorOpen}>
          <ModalCreateColaborator setModalCreateColaboratorOpen={setModalCreateColaboratorOpen} handleToast={handleToast} />
        </DefaultModal>

        <Modal
          open={createDiaryOpen}
          onClose={() => setCreateDiaryOpen(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyNewColaboratorDiary}
        </Modal>
      </div>
    </div>
  )
}
