import EyeIcon from '../../../assets/icons/eye.svg'

import styles from './styles.module.css'
import { useState } from 'react'
import ModalPurchaseRequestDetails from './Modals/Details'
import DefaultModal from '../../Modals/DefaultModal'

export default function PurchaseRequest({ request }) {
  const [modalPurchaseRequestDetailsOpen, setModalPurchaseRequestDetailsOpen] = useState(false)

  return (
    <div className={styles.construction__box}>
      <div className={styles.details}>
        <div className={styles.info}>
          <h1>{request.description}</h1>
        </div>

        <div className={styles.actions}>
          <img src={EyeIcon} alt="eye" onClick={() => setModalPurchaseRequestDetailsOpen(true)} />
        </div>
      </div>

      <DefaultModal isOpen={modalPurchaseRequestDetailsOpen} handleOpen={setModalPurchaseRequestDetailsOpen}>
        <ModalPurchaseRequestDetails setModalPurchaseRequestDetailsOpen={setModalPurchaseRequestDetailsOpen} request={request} />
      </DefaultModal>
    </div>
  )
}
