import React, { useContext } from 'react'

import styles from './style.module.css'

import iEye from '../../assets/icons/eye.svg'
import iPencil from '../../assets/icons/pencil.svg'
import iTrash from '../../assets/icons/trash.svg'

import { Dialog, Fade, Modal } from '@material-ui/core'
import { useState } from 'react'

import { useForm } from 'react-hook-form'
import { api } from '../../services/api'

import Toast from '../Toast'

import { ProductsContext } from '../../contexts/ProductsContext'

import ProductOfList from '../ProductOfList'
import PDFProductsFromListGenerator from '../PDFProductsFromListGenerator'
import { apiImages } from '../../services/apiImages'
import { variables } from '../../variables'
import DefaultModal from '../Modals/DefaultModal'
import PurchaseRequestDetails from '../Modals/PurchaseRequest/Details'
import ModalEditPurchaseRequest from '../Modals/PurchaseRequest/Edit'
import ModalDeleteProject from '../Modals/Project/Delete'
import ModalDeletePurchaseRequest from '../Modals/PurchaseRequest/Delete'

type PurchasesParams = {
  purchase: PurchaseType
  handleToast: any
}

type PurchaseType = {
  id: number
  client: string
  created_at: string
  created_by: string
  last_user_update: string
  construction: string
  send_to_client_date: string
  payment_date: string
  arrival_in_construction_date: string
  send_to_client_date_un: string
  payment_date_un: string
  arrival_in_construction_date_un: string
  observation: string
  invoice: string
  list: string
}

type PurchaseEditType = {
  send_to_client_date: string
  payment_date: string
  arrival_in_construction_date: string
  observation: string
  invoice?: any
}

function PurchaseRequest({ purchase, handleToast }: PurchasesParams) {
  console.log(purchase)

  const [open, setOpen] = useState(false)
  const [modalBody, setModalBody] = useState(<div></div>)

  const [purchaseRequestDetailsModalOpen, setPurchaseRequestDetailsModalOpen] = useState(false)
  const [purchaseRequestEditModalOpen, setPurchaseRequestEditModalOpen] = useState(false)
  const [deletePurchaseRequestModalOpen, setDeletePurchaseRequestModalOpen] = useState(false)

  const { register, handleSubmit, reset } = useForm()

  function deletePurchase() {
    api
      .delete(`/purchases/requests/${purchase.id}`)
      .then((response) => {
        setOpen(false)
        handleToast(<Toast category="success" message={response.data.message} />)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  function handleEditWithInvoice(data: PurchaseEditType) {
    let formDataImage = new FormData()

    if (data.invoice.length) {
      formDataImage.append('invoice', data.invoice[0])

      apiImages
        .post('/images/invoices', formDataImage)
        .then((response) => {
          data['invoice'] = response.data.filename

          editPurchaseRequest(data)
        })
        .catch((err) => {
          handleToast(<Toast category="error" message={err.message} />)
        })
    } else {
      delete data.invoice

      editPurchaseRequest(data)
    }
  }

  function editPurchaseRequest(data: PurchaseEditType) {
    api
      .put(`/purchases/requests/${purchase.id}`, data)
      .then((response) => {
        handleToast(<Toast category="success" message={response.data.message} />)
        setOpen(false)

        reset()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const bodyVisualizePurchase = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContentDetails}>
          <div className={styles.boxHeader}>
            <h3 className={styles.title}>Pedido de Compra:</h3>
          </div>

          <span>
            Cliente: <b>{purchase.client}</b>
          </span>

          <span>
            Obra: <b>{purchase.construction}</b>
          </span>

          <span>
            Enviado para o cliente: <b>{purchase.send_to_client_date || 'Sem data.'}</b>
          </span>

          <span>
            Data de pagamento: <b>{purchase.payment_date || 'Sem data.'}</b>
          </span>

          <span>
            Chegou na obra em: <b>{purchase.arrival_in_construction_date || 'Sem data.'}</b>
          </span>

          <span>
            Registrado: <b>{purchase.created_by}</b>
          </span>

          <span>
            Última atualização: <b>{purchase.last_user_update}</b>
          </span>

          {purchase.observation ? (
            <span>
              <i>
                <b>OBS: </b>
                {purchase.observation}
              </i>
            </span>
          ) : null}

          {purchase.invoice ? (
            <button className={styles.buttonInvoice} onClick={() => window.open(`${variables.api}/public/${purchase.invoice}`)}>
              <span>Nota Fiscal</span>
            </button>
          ) : null}

          <button className={styles.buttonModal} onClick={() => setOpen(false)}>
            <span>Fechar</span>
          </button>
        </div>
      </div>
    </div>
  )

  const bodyEditPurchase = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ maxWidth: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Editar Pedido de Compra</h4>
          </div>

          <form className={styles.formEdit} onSubmit={handleSubmit(handleEditWithInvoice)}>
            <label htmlFor="send_to_client_date">Data de envio para o cliente:</label>
            <input defaultValue={purchase.send_to_client_date_un} name="send_to_client_date" type="date" {...register('send_to_client_date')} />

            <label htmlFor="payment_date">Data do pagamento:</label>
            <input defaultValue={purchase.payment_date_un} name="payment_date" type="date" {...register('payment_date')} />

            <label htmlFor="arrival_in_construction_date">Data da chegada na obra:</label>
            <input
              defaultValue={purchase.arrival_in_construction_date_un}
              name="arrival_in_construction_date"
              type="date"
              {...register('arrival_in_construction_date')}
            />

            <div>
              <label htmlFor="invoice">Nota Fiscal:</label>
              <input type="file" name="invoice" {...register('invoice')} accept=".png, .pdf" />
            </div>

            <label htmlFor="observation">Observação:</label>
            <textarea defaultValue={purchase.observation} name="observation" {...register('observation')} style={{ marginBottom: '1rem' }} />

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button
                type="button"
                className={styles.cancel}
                onClick={() => {
                  setOpen(false)
                  reset()
                }}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

  function handleModalOpen(body: string) {
    if (body === 'delete') {
      setModalBody(bodyDeletePurchase)
    } else if (body === 'edit') {
      setModalBody(bodyEditPurchase)
    } else if (body === 'visualize') {
      setModalBody(bodyVisualizePurchase)
    }

    setOpen(true)
  }

  const bodyDeletePurchase = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Deseja realmente deletar esse pedido de compra?</h4>
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm} onClick={() => deletePurchase()}>
              Confirmar
            </button>
            <button className={styles.cancel} type="button" onClick={() => setOpen(false)}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className={styles.box}>
      <div className={styles.boxInfoConstruction}>
        <span>Obra: {purchase.construction}</span>
      </div>
      <div className={styles.boxInfoConstruction}>
        <span>Lista: {purchase.list}</span>
      </div>

      {purchase.send_to_client_date ? (
        <div className={styles.boxInfoConstruction} style={{ width: '50%' }}>
          <span>Enviado em: {purchase.send_to_client_date}</span>
        </div>
      ) : null}

      {purchase.payment_date ? (
        <div className={styles.boxInfoConstruction} style={{ width: '50%' }}>
          <span>Pago em: {purchase.payment_date}</span>
        </div>
      ) : null}

      {purchase.arrival_in_construction_date ? (
        <div className={styles.boxInfoConstruction} style={{ width: '50%' }}>
          <span>Chegou em: {purchase.arrival_in_construction_date}</span>
        </div>
      ) : null}

      <div className={styles.buttons}>
        <img src={iEye} alt="" onClick={() => setPurchaseRequestDetailsModalOpen(true)} />
        <img src={iPencil} alt="" onClick={() => setPurchaseRequestEditModalOpen(true)} />
        <img src={iTrash} alt="" onClick={() => setDeletePurchaseRequestModalOpen(true)} />
      </div>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        scroll="body"
        TransitionComponent={Fade}
        transitionDuration={200}
      >
        {modalBody}
      </Dialog>

      <DefaultModal handleOpen={setPurchaseRequestDetailsModalOpen} isOpen={purchaseRequestDetailsModalOpen}>
        <PurchaseRequestDetails purchase={purchase} setPurchaseRequestDetailsOpen={setPurchaseRequestDetailsModalOpen} handleToast={handleToast} />
      </DefaultModal>

      <DefaultModal handleOpen={setPurchaseRequestEditModalOpen} isOpen={purchaseRequestEditModalOpen}>
        <ModalEditPurchaseRequest purchase={purchase} setPurchaseRequestEditModalOpen={setPurchaseRequestEditModalOpen} handleToast={handleToast} />
      </DefaultModal>

      <DefaultModal handleOpen={setDeletePurchaseRequestModalOpen} isOpen={deletePurchaseRequestModalOpen}>
        <ModalDeletePurchaseRequest purchase={purchase} setModalDeletePurchaseRequestOpen={setDeletePurchaseRequestModalOpen} handleToast={handleToast} />
      </DefaultModal>
    </div>
  )
}

export default React.memo(PurchaseRequest)
