import { BaseSyntheticEvent, useContext, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { MeasureContext, MeasuresType } from '../../../../../../contexts/MeasureContext'
import { api } from '../../../../../../services/api'
import { mdiPackageVariantClosed } from '@mdi/js'

import Icon from '@mdi/react'

import styles from './style.module.css'

type SubmitType = {
  measure_id: number
  quantity: number
}

type InputType = {
  value: string
  name: string
}

export default function ModalEditListProduct({ product, refresh, setModalEditListProductOpen }) {
  const [formValues, setFormValues] = useState({} as SubmitType)

  const { measures } = useContext(MeasureContext)

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function edit(e: BaseSyntheticEvent) {
    e.preventDefault()

    api
      .put(`/lists/product/edit/${product.id}`, formValues)
      .then((response) => {
        refresh()
        setModalEditListProductOpen(false)
      })
      .catch((err) => {
        console.error('fail on edit list product', { err })
      })
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiPackageVariantClosed} size={1.5} />
            <h4 className={styles.title}>EDITAR PRODUTO DA LISTA</h4>
          </div>

          <form className={styles.form__edit} onSubmit={(e) => edit(e)}>
            <Autocomplete
              value={undefined}
              defaultValue={measures.find((m) => m.id == product.measure_id)}
              onChange={(e, newValue: MeasuresType) => {
                handleInputChange({
                  name: 'measure_id',
                  value: newValue && String(newValue.id)
                })
              }}
              options={measures}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Un. de Medida" required />}
            />

            <TextField
              label="Quantidade"
              type="text"
              required
              defaultValue={product.quantity}
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'quantity',
                  value: e.target.value
                })
              }
            />

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalEditListProductOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
