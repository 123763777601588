import { BaseSyntheticEvent, useContext, useState, SetStateAction, Dispatch } from 'react'
import { mdiCloseBox, mdiPlaySpeed } from '@mdi/js'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { api } from '../../../../../../../../services/api'
import { ptBR } from 'date-fns/locale'
import { Autocomplete, InputAdornment, TextField, Tooltip } from '@mui/material'
import { MeasureContext } from '../../../../../../../../contexts/MeasureContext'

import Icon from '@mdi/react'
import styles from './style.module.css'
import Toast from '../../../../../../../Toast'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ScheduleType } from '../../..'

interface SubmitType {
  start_date_prediction?: string
  finish_date_prediction?: string
  proposed_quantity?: string
  proposed_cost_price?: string
  quantity?: string
  cost_price?: string
  finish_date?: string
  start_date?: string
  measure_id: string
  observation?: string
}

interface InputType {
  value: string
  name: string
}

interface AutoCompleteTypes {
  id: number
  description: string
}

interface ScheduleBoxProps {
  schedule: ScheduleType
  setModalScheduleBoxEditOpen: Dispatch<SetStateAction<boolean>>
  handleCloseModalAndRequestSchedules: () => void
  handleToast: (toast: JSX.Element) => void
}

export default function ModalScheduleBoxEdit({ schedule, setModalScheduleBoxEditOpen, handleCloseModalAndRequestSchedules, handleToast }: ScheduleBoxProps) {
  const [formValues, setFormValues] = useState({} as SubmitType)
  const { measures } = useContext(MeasureContext)

  const isWaiting = schedule.status === 1

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function editSchedule(e: BaseSyntheticEvent) {
    e.preventDefault()

    try {
      const response = await api.put(`/constructions_schedules/${schedule.id}`, formValues)

      if (response.status === 200) {
        handleToast(<Toast category="success" message="Etapa editada com sucesso." />)
        handleCloseModalAndRequestSchedules()
      }
    } catch (err) {
      handleToast(<Toast category="error" message={err.message} />)
    }
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiPlaySpeed} size={1.5} />
            <h4 className={styles.title}>editar etapa</h4>

            <div className={styles.close__button} onClick={() => setModalScheduleBoxEditOpen(false)}>
              <Icon path={mdiCloseBox} size={1.5} />
            </div>
          </div>

          <form onSubmit={(e) => editSchedule(e)}>
            {schedule.start_date && (
              <>
                <div className={styles.input__row}>
                  <TextField
                    value={undefined}
                    defaultValue={formValues.cost_price ? formValues.cost_price : schedule.cost_price}
                    label="Preço por UN."
                    type="text"
                    required
                    autoComplete="off"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>
                    }}
                    onChange={(e) =>
                      handleInputChange({
                        name: isWaiting ? 'proposed_cost_price' : 'cost_price',
                        value: e.target.value.replace(',', '.')
                      })
                    }
                  />

                  <Tooltip title="Esse valor é vinculado com os diárias de obra" placement="top">
                    <TextField
                      value={schedule.total_worked_area}
                      label="Quantidade"
                      disabled
                      type="number"
                      autoComplete="off"
                      onChange={(e) =>
                        handleInputChange({
                          name: isWaiting ? 'proposed_quantity' : 'quantity',
                          value: e.target.value
                        })
                      }
                    />
                  </Tooltip>
                </div>

                <Autocomplete
                  value={undefined}
                  onChange={(event, newValue: AutoCompleteTypes) => {
                    handleInputChange({
                      name: 'measure_id',
                      value: newValue && String(newValue.id)
                    })
                  }}
                  options={measures}
                  defaultValue={measures.find((measureDB) => measureDB.id === (formValues.measure_id || schedule.measure_id))}
                  getOptionLabel={(option) => option.description}
                  renderInput={(params) => <TextField {...params} label="Un. de Medida" required />}
                />
              </>
            )}

            <div className={styles.input__row}>
              {!schedule.start_date && (
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                  <DatePicker
                    label={isWaiting ? 'Previsão de Início' : 'Data de Início'}
                    mask="__/__/____"
                    value={
                      isWaiting
                        ? formValues.start_date_prediction
                          ? formValues.start_date_prediction
                          : `${schedule.start_date_prediction?.split('/').reverse().join('-')}, 00:00:00`
                        : formValues.start_date
                        ? formValues.start_date
                        : formValues.start_date_prediction
                        ? formValues.start_date_prediction
                        : `${schedule.start_date_prediction.split('/').reverse().join('-')}, 00:00:00`
                    }
                    onChange={(newValue) =>
                      handleInputChange({
                        name: schedule.start_date ? 'start_date' : 'start_date_prediction',
                        value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                      })
                    }
                    renderInput={(params) => <TextField fullWidth {...params} required />}
                  />
                </LocalizationProvider>
              )}

              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  label={schedule.finish_date ? 'Data de Finalização' : 'Previsão de Finalização'}
                  mask="__/__/____"
                  value={
                    isWaiting
                      ? formValues.finish_date_prediction
                        ? formValues.finish_date_prediction
                        : `${schedule.finish_date_prediction?.split('/').reverse().join('-')}, 00:00:00`
                      : formValues.finish_date
                      ? formValues.finish_date
                      : formValues.finish_date_prediction
                      ? formValues.finish_date_prediction
                      : `${schedule.finish_date_prediction.split('/').reverse().join('-')}, 00:00:00`
                  }
                  onChange={(newValue) =>
                    handleInputChange({
                      name: schedule.finish_date ? 'finish_date' : 'finish_date_prediction',
                      value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                    })
                  }
                  renderInput={(params) => <TextField fullWidth {...params} required />}
                />
              </LocalizationProvider>
            </div>

            {schedule.status === 3 ? (
              <TextField
                defaultValue={schedule.observation}
                label="Observação"
                type="text"
                multiline
                minRows={3}
                autoComplete="off"
                onChange={(e) =>
                  handleInputChange({
                    name: 'observation',
                    value: e.target.value
                  })
                }
              />
            ) : null}

            {!isWaiting ? (
              <span className={styles.warning}>
                OBS: a alteração dos valores acima serão nos valores de produção, para alterar os valores de venda vá até o menu <b>Orçamento de Execução</b>.
              </span>
            ) : (
              <span className={styles.warning}>
                OBS: para alterar os valores relacionados ao preço de venda vá até o menu <b>Orçamento de Execução</b>.
              </span>
            )}

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalScheduleBoxEditOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
