import { createContext, useEffect } from 'react'
import { useState } from 'react'
import { api } from '../services/api'

type ProvidersContextType = {
  getProviders: () => Promise<void>
  providers: ProviderType[]
}

export type ProviderType = {
  id: number
  name: string
}

export const ProvidersContext = createContext({} as ProvidersContextType)

export function ProviderProvider({ children }) {
  const [providers, setProviders] = useState<ProviderType[]>([])

  useEffect(() => {
    if (providers.length === 0) getProviders()
  }, [])

  async function getProviders() {
    await api
      .get('/providers')
      .then((response) => {
        setProviders(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return <ProvidersContext.Provider value={{ providers, getProviders }}>{children}</ProvidersContext.Provider>
}
