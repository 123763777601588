import { BaseSyntheticEvent, useContext, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { api } from '../../../../services/api'
import { mdiPackageVariantClosed } from '@mdi/js'
import { ConstructionsContext, ConstructionType } from '../../../../contexts/ConstructionsContext'

import Icon from '@mdi/react'
import Toast from '../../../Toast'

import styles from './style.module.css'

type SubmitType = {
  description: string
  construction_id: number
}

type InputType = {
  value: string | File
  name: string
}

export default function ModalEditList({ list, setModalEditListOpen, handleToast }) {
  const [formValues, setFormValues] = useState({} as SubmitType)

  const { constructions } = useContext(ConstructionsContext)

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function edit(e: BaseSyntheticEvent) {
    e.preventDefault()

    api
      .put(`/lists/${list.id}`, formValues)
      .then((response) => {
        handleToast(<Toast category="success" message={response.data.message} />)
        setModalEditListOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiPackageVariantClosed} size={1.5} />
            <h4 className={styles.title}>EDITAR LISTA DE COMPRAS</h4>
          </div>

          <form className={styles.form__create} onSubmit={(e) => edit(e)}>
            <TextField
              label="Descrição"
              type="text"
              required
              autoComplete="off"
              defaultValue={list.description}
              onChange={(e) =>
                handleInputChange({
                  name: 'description',
                  value: e.target.value
                })
              }
            />

            <Autocomplete
              value={undefined}
              defaultValue={constructions.find((c) => c.id === list.construction_id)}
              onChange={(e, newValue: ConstructionType) => {
                handleInputChange({
                  name: 'construction_id',
                  value: String(newValue.id)
                })
              }}
              options={constructions}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Obra" required />}
            />

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalEditListOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
