import { BaseSyntheticEvent, useState } from 'react'
import { Autocomplete, InputAdornment, TextField } from '@mui/material'
import { ScheduleType } from '../../../../Constructions/Details/Schedule'
import { mdiHome } from '@mdi/js'

import Icon from '@mdi/react'

import styles from './style.module.css'

export default function ModalAddStageToForm({ addStage, constructionSchedules, setModalAddStageToFormOpen }) {
  const [constructionScheduleId, setConstructionScheduleId] = useState<number>(null)
  const [workedArea, setWorkedArea] = useState<string>(null)

  function handleAddStage(e: BaseSyntheticEvent) {
    e.preventDefault()
    addStage({ worked_area: workedArea, construction_schedule_id: constructionScheduleId })
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiHome} size={1.5} />
            <h4 className={styles.title}>CADASTRAR ETAPA NO DIÁRIO</h4>
          </div>

          <form className={styles.form__create} onSubmit={(e) => handleAddStage(e)}>
            <Autocomplete
              value={undefined}
              onChange={(event, newValue: ScheduleType) => {
                newValue && setConstructionScheduleId(newValue.id)
              }}
              options={constructionSchedules}
              getOptionLabel={(option) => option.stage}
              renderInput={(params) => <TextField {...params} label="Etapa" required />}
            />

            <TextField
              label="Área trabalhada"
              type="text"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {constructionScheduleId && constructionSchedules.find((cs) => cs.id === constructionScheduleId).measure}
                  </InputAdornment>
                )
              }}
              autoComplete="off"
              onChange={(e) => {
                setWorkedArea(e.target.value)
              }}
            />

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalAddStageToFormOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
