import { Dialog, Fade, Modal } from '@material-ui/core'
import { useContext, useEffect, useState } from 'react'
import { api } from '../../services/api'
import { useForm } from 'react-hook-form'
import { ColaboratorsContext } from '../../contexts/ColaboratorsContext'

import Toast from '../Toast'
import iPlusWhite from '../../assets/icons/plus-white.svg'
import iPrint from '../../assets/icons/print.svg'
import iExcel from '../../assets/icons/excel.svg'
import pencil from '../../assets/icons/pencil.svg'
import ColaboratorBudget from './ColaboratorBudget'
import BudgetAddon from './BudgetAddon'
import loadingGIF from '../../assets/loading.gif'

import ModalCreateConstructionStageBudget from '../Modals/Constructions/Budget/ConstructionStageBudget/Create'
import StageBudget from './StageBudget'
import PDFConstructionStagesBudgetTable from '../PDFConstructionStagesBudgetTable'

import * as XLSX from 'xlsx'

import styles from './style.module.css'

type ModalParams = {
  opening: boolean
  construction_id: number
  setModalBudgetOpen: any
}

type BudgetType = {
  id: number
  duration: number
  square_meters: string
  budget_clt: ColaboratorBudgetType[]
  budget_diary: ColaboratorBudgetType[]
  budget_addons: AddonsExpenses[]
  budget_stages: StagesType[]
  total_budget_clt: number
  total_budget_diary: number
  total_addons: number
  total_stages: number
  sell_price: number
  employees_total_coust: number
  total_budget_during_construction: number
  financial_settlement: number
  square_meter_price: number
  total_during_construction: number
  salary_tax: number
}

type AddonsExpenses = {
  id: number
  description: string
  value?: number
  percent?: number
}

type ColaboratorBudgetType = {
  id: number
  quantity: number
  employee_type: string
  employee_type_id: number
  days: number
  price_diary: number
  type: number
}

type StagesType = {
  id: number
  construction_budget_id: number
  stage_id: number
  stage: number
  measure_id: number
  measure: number
  quantity: number
  unitary_value: number
}

type EditDurationType = {
  duration: number
}

type UpdateSquareMetersType = {
  square_meters: string
}

export default function ConstructionBudget({ opening, construction_id, setModalBudgetOpen }: ModalParams) {
  const { colaboratorsTypes, cltColaborators } = useContext(ColaboratorsContext)

  const defaultBudget: BudgetType = {
    id: 0,
    duration: 0,
    square_meters: null,
    budget_clt: [],
    budget_diary: [],
    budget_addons: [],
    budget_stages: [],
    total_budget_clt: 0,
    total_budget_diary: 0,
    total_addons: 0,
    total_stages: 0,
    sell_price: 0,
    employees_total_coust: 0,
    total_budget_during_construction: 0,
    total_during_construction: 0,
    financial_settlement: 0,
    square_meter_price: 0,
    salary_tax: 0
  }

  const { register, handleSubmit, reset } = useForm()

  const [open, setOpen] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)
  const [editDurationOpen, setEditDurationOpen] = useState(false)
  const [editSquareMetersOpen, setEditSquareMetersOpen] = useState(false)
  const [modalAddCLTColaboratorOpen, setModalAddCLTColaboratorOpen] = useState(false)

  const [budget, setBudget] = useState<BudgetType>(defaultBudget)
  const [schedules, setSchedules] = useState([])
  const [alreadyExistsBudget, setAlreadyExistsBudget] = useState(false)
  const [toast, setToast] = useState(<div></div>)
  const [loading, setLoading] = useState(true)

  const [createAddonOpen, setCreateAddonOpen] = useState(false)
  const [modalCreateConstructionStageBudgetOpen, setModalCreateConstructionStageBudgetOpen] = useState(false)

  const [value, setValue] = useState('')
  const [percent, setPercent] = useState('')

  const [totalBudgetStages, setTotalBudgetStages] = useState(0)

  useEffect(() => {
    verifyIfAlreadyExistsABudget()

    getConstructionSchedules()

    setOpen(opening)
  }, [])

  function handleToast(toast) {
    setToast(toast)

    setTimeout(() => {
      setToast(null)
    }, 5000)
  }

  function generateConstructionStagesBudgetPDF() {
    PDFConstructionStagesBudgetTable(schedules)
  }

  function verifyIfAlreadyExistsABudget() {
    api
      .get(`/constructions/budgets/verify/${construction_id}`)
      .then((response) => {
        if (response.data) {
          getConstructionBudget()

          setAlreadyExistsBudget(true)
        } else {
          setLoading(false)
        }
      })
      .catch((err) => console.log(err))
  }

  function getConstructionBudget() {
    api
      .get(`/constructions/budgets/${construction_id}`)
      .then(async (response) => {
        setBudget(response.data)
      })
      .catch((err) => {
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function getConstructionSchedules() {
    api
      .get(`/constructions_schedules/${construction_id}`)
      .then(async (response) => {
        let total: number = 0
        const { data } = response

        const arr = Object.keys(data).length ? [...data.active, ...data.deconfigured, ...data.waiting, ...data.finished] : []

        arr.map((item, index) => {
          total += Number(item.proposed_quantity) * Number(item.proposed_cost_price)
        })

        setTotalBudgetStages(total)

        setSchedules(arr)
      })
      .catch((err) => {
        setLoading(false)

        setTimeout(() => {
          setToast(<Toast category="error" message="Não foi possível listar as etapas do cronograma." />)
        }, 5000)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function editSquareMeters(data: UpdateSquareMetersType) {
    let newData = {
      square_meters: data.square_meters.replace(',', '.')
    }

    api
      .put(`/constructions/budgets/square_meters/${budget.id}`, newData)
      .then((response) => {
        getConstructionBudget()
        setToast(<Toast category={response.status === 202 ? 'error' : 'success'} message={response.data.message} />)
        reset()
        setEditSquareMetersOpen(false)

        setTimeout(() => {
          setToast(<div></div>)
        }, 5000)
      })
      .catch((err) => {
        setToast(<Toast category="error" message="Não foi possível listar todas as informações do cronograma." />)
      })
  }

  function editDuration(data: EditDurationType) {
    api
      .put(`/constructions/budgets/duration/${budget.id}`, data)
      .then((response) => {
        getConstructionBudget()
        setToast(<Toast category={response.status === 202 ? 'error' : 'success'} message={response.data.message} />)
        reset()
        setEditDurationOpen(false)

        setTimeout(() => {
          setToast(<div></div>)
        }, 5000)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function handleSubmitNewColaboratorDiary(data) {
    data['construction_budget_id'] = budget.id

    api
      .post(`/constructions/budgets/employee`, data)
      .then((response) => {
        getConstructionBudget()
        setToast(<Toast category={response.status === 202 ? 'error' : 'success'} message={response.data.message} />)
        reset()
        setCreateOpen(false)
        setModalAddCLTColaboratorOpen(false)

        setTimeout(() => {
          setToast(<div></div>)
        }, 5000)
      })
      .catch((err) => {
        setToast(<Toast category="error" message={err.message} />)

        setTimeout(() => {
          setToast(<div></div>)
        }, 5000)
      })
  }

  function createBudgetAddon(data) {
    data['construction_budget_id'] = budget.id

    api
      .post(`/constructions/budgets/addon`, data)
      .then((response) => {
        getConstructionBudget()
        getConstructionSchedules()
        setToast(<Toast category={response.status === 202 ? 'error' : 'success'} message={response.data.message} />)
        reset()
        setCreateAddonOpen(false)

        setTimeout(() => {
          setToast(<div></div>)
        }, 5000)
      })
      .catch((err) => {
        setToast(<Toast category="error" message={err.message} />)

        setTimeout(() => {
          setToast(<div></div>)
        }, 5000)
      })
  }

  function startBudget(data) {
    api
      .post(`/constructions/budgets/new/${construction_id}`, data)
      .then((response) => {
        verifyIfAlreadyExistsABudget()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function kkk() {
    var data = document.getElementById('table')

    var file = XLSX.utils.table_to_book(data, { sheet: 'sheet1' })

    XLSX.write(file, { bookType: 'xlsx', bookSST: true, type: 'base64' })

    XLSX.writeFile(file, 'orcamento.' + 'xlsx')
  }

  const bodyModalNewColaboratorDiary = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Cadastrar diárias de colaboradores</h4>
          </div>

          <form onSubmit={handleSubmit(handleSubmitNewColaboratorDiary)}>
            <div>
              <label htmlFor="employee_type_id">Tipo de colaborador: *</label>
              <select name="employee_type_id" {...register('employee_type_id')}>
                {colaboratorsTypes.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.description}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="quantity">Quantidade de colaboradores: *</label>
              <input type="text" name="quantity" {...register('quantity')} />
            </div>

            <div>
              <label htmlFor="days">Dias: *</label>
              <input type="number" name="days" {...register('days')} />
            </div>

            <div>
              <label htmlFor="price_diary">Preço da diária: *</label>
              <input type="text" name="price_diary" {...register('price_diary')} />
            </div>

            <div>
              <label htmlFor="type">CLT ou Diária: *</label>
              <select name="type" {...register('type')}>
                <option value="1">CLT</option>
                <option value="2">Diária</option>
              </select>
            </div>

            <div className={styles.buttons}>
              <button className={styles.crConfirm}>
                <span>Confirmar</span>
              </button>
              <button
                className={styles.cancel}
                onClick={() => {
                  setCreateOpen(false)
                  reset()
                }}
                type="button"
              >
                <span>Cancelar</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

  const bodyModalAddCLTColaborator = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Cadastrar colaborador</h4>
          </div>

          <form onSubmit={handleSubmit(handleSubmitNewColaboratorDiary)}>
            <div>
              <label htmlFor="employee_id">Colaborador: *</label>
              <select name="employee_id" {...register('employee_id')}>
                {cltColaborators.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>

            <div className={styles.buttons}>
              <button className={styles.crConfirm}>
                <span>Confirmar</span>
              </button>
              <button
                className={styles.cancel}
                onClick={() => {
                  setModalAddCLTColaboratorOpen(false)
                  reset()
                }}
                type="button"
              >
                <span>Cancelar</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

  const bodyEditDuration = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Editar duração</h4>
          </div>

          <form className={styles.formEdit} onSubmit={handleSubmit(editDuration)}>
            <div>
              <label htmlFor="duration">Duração: *</label>
              <input type="number" name="duration" {...register('duration')} defaultValue={budget.duration} />
            </div>

            <div className={styles.buttons}>
              <button className={styles.crConfirm}>
                <span>Confirmar</span>
              </button>
              <button
                className={styles.cancel}
                onClick={() => {
                  setEditDurationOpen(false)
                  reset()
                }}
                type="button"
              >
                <span>Cancelar</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

  const bodyEditSquareMeters = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Editar metros quadrados</h4>
          </div>

          <form className={styles.formEdit} onSubmit={handleSubmit(editSquareMeters)}>
            <div>
              <label htmlFor="square_meters">Metros quadrados: *</label>
              <input required type="text" name="square_meters" {...register('square_meters')} defaultValue={budget.square_meters && budget.square_meters} />
            </div>

            <div className={styles.buttons}>
              <button className={styles.crConfirm}>
                <span>Confirmar</span>
              </button>
              <button
                className={styles.cancel}
                onClick={() => {
                  setEditDurationOpen(false)
                  reset()
                }}
                type="button"
              >
                <span>Cancelar</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

  const bodyModalNewBudgetAddon = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Cadastrar Despesa</h4>
          </div>

          <form className={styles.formEdit} onSubmit={handleSubmit(createBudgetAddon)}>
            <div>
              <label htmlFor="description">Descrição: *</label>
              <input type="text" name="description" {...register('description')} />
            </div>

            <div>
              <label htmlFor="percent">Porcentagem: *</label>
              <input disabled={!!value} type="text" name="percent" {...register('percent')} onChange={(e) => setPercent(e.target.value)} />
            </div>

            <div>
              <label htmlFor="value">Valor: *</label>
              <input disabled={!!percent} type="text" name="value" {...register('value')} onChange={(e) => setValue(e.target.value)} />
            </div>

            <div className={styles.buttons}>
              <button className={styles.crConfirm}>
                <span>Confirmar</span>
              </button>
              <button
                className={styles.cancel}
                onClick={() => {
                  setCreateAddonOpen(false)
                  reset()
                }}
                type="button"
              >
                <span>Cancelar</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

  function closeModal() {
    setModalBudgetOpen(false)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setModalBudgetOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        scroll="body"
        maxWidth="md"
        TransitionComponent={Fade}
        transitionDuration={200}
      >
        <div className={styles.boxContainer}>
          <div className={styles.boxModal}>
            <div className={styles.boxContent}>
              <div className={styles.boxHeader}>
                <h4 className={styles.clientName}>Orçamento de Execução</h4>
              </div>

              {loading ? (
                <img src={loadingGIF} alt="loading-icon" className={styles.loading} />
              ) : alreadyExistsBudget ? (
                <>
                  <h5>
                    Tempo de Obra: <b>{budget.duration} meses</b>
                    <img
                      src={pencil}
                      alt="pencil-icon"
                      onClick={() => {
                        setEditDurationOpen(true)
                      }}
                      style={{ marginLeft: 10 }}
                    />
                  </h5>

                  <h5>
                    Metros Quadrados: <b>{Number(budget.square_meters)} m²</b>
                    <img
                      src={pencil}
                      alt="pencil-icon"
                      onClick={() => {
                        setEditSquareMetersOpen(true)
                      }}
                      style={{ marginLeft: 10 }}
                    />
                  </h5>

                  {/* <div className={styles.expensesBlock}>
                    <div className={styles.budgetBox}>
                      <div className={styles.budgetBoxHeader}>
                        <span>Orçamento de Colaboradores (CLT)</span>

                        <button onClick={() => setModalAddCLTColaboratorOpen(true)}>
                          <img src={iPlusWhite} alt="" />
                        </button>
                      </div>

                      {budget.budget_clt.length ? (
                        budget.budget_clt.map((cb) => (
                          <ColaboratorBudget key={cb.id} colaboratorBudget={cb} getConstructionBudget={getConstructionBudget} setToast={setToast} />
                        ))
                      ) : (
                        <span className={styles.noDataText}>Não existem CLTS cadastrados.</span>
                      )}

                      {budget.total_budget_clt > 0 ? (
                        <div className={styles.budgetBoxTotal}>
                          <span>
                            <b>Total: </b>{' '}
                            {budget.total_budget_clt.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL'
                            })}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className={styles.expensesBlock}>
                    <div className={styles.budgetBox}>
                      <div className={styles.budgetBoxHeader}>
                        <span>Orçamento de Colaboradores (Diárias)</span>

                        <button onClick={() => setCreateOpen(true)}>
                          <img src={iPlusWhite} alt="" />
                        </button>
                      </div>

                      {budget.budget_diary.length ? (
                        budget.budget_diary.map((cb) => (
                          <ColaboratorBudget key={cb.id} colaboratorBudget={cb} getConstructionBudget={getConstructionBudget} setToast={setToast} />
                        ))
                      ) : (
                        <span className={styles.noDataText}>Não existem diárias cadastradas.</span>
                      )}

                      {budget.total_budget_diary > 0 ? (
                        <div className={styles.budgetBoxTotal}>
                          <span>
                            <b>Total:</b>{' '}
                            {budget.total_budget_diary.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL'
                            })}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className={styles.totals} title="Soma dos salários mensais totais (CLT/Diárias) multiplicado pelo tempo da obra.">
                    <div className={styles.totalBox}>
                      <span>
                        <b>Salários por tempo da obra: </b>{' '}
                        {budget.employees_total_coust.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })}
                      </span>
                    </div>
                  </div>

                  {budget.financial_settlement != 0 ? (
                    <div className={styles.totals}>
                      <div className={styles.totalBox}>
                        <span>
                          <b>Acertos/Salário:</b>{' '}
                          {budget.financial_settlement.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                          })}
                        </span>
                      </div>
                    </div>
                  ) : null}

                  {budget.salary_tax != 0 ? (
                    <div className={styles.totals}>
                      <div className={styles.totalBox}>
                        <span>
                          <b>Imposto sob salário:</b>{' '}
                          {budget.salary_tax.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                          })}
                        </span>
                      </div>
                    </div>
                  ) : null}

                  {budget.total_budget_during_construction != 0 ? (
                    <div className={styles.totals}>
                      <div className={styles.totalBox}>
                        <span>
                          <b>Gasto total durante a obra:</b>{' '}
                          {budget.total_budget_during_construction.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </span>
                      </div>
                    </div>
                  ) : null}

                  <div className={styles.expensesBlock}>
                    <div className={styles.budgetBox}>
                      <div className={styles.budgetBoxHeader}>
                        <span>Despesas adicionais</span>

                        <button onClick={() => setCreateAddonOpen(true)}>
                          <img src={iPlusWhite} alt="" />
                        </button>
                      </div>

                      {budget.budget_addons.length ? (
                        budget.budget_addons.map((addon) => (
                          <BudgetAddon key={addon.id} addon={addon} getConstructionBudget={getConstructionBudget} setToast={setToast} />
                        ))
                      ) : (
                        <span className={styles.noDataText}>Não existe despesas adicionais cadastradas.</span>
                      )}

                      {budget.total_addons != 0 ? (
                        <div className={styles.budgetBoxTotal}>
                          <span>
                            <b>Total: </b>{' '}
                            {budget.total_addons.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL'
                            })}
                          </span>
                        </div>
                      ) : null}
                    </div>

                    {budget.sell_price != 0 ? (
                      <div className={styles.totals}>
                        <div className={styles.totalBox}>
                          <span>
                            <b>Preço de venda:</b>{' '}
                            {budget.sell_price.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL'
                            })}
                          </span>
                        </div>
                      </div>
                    ) : null}

                    {budget.square_meter_price != null ? (
                      <div className={styles.totals}>
                        <div className={styles.totalBox}>
                          <span>
                            <b>Preço por m²:</b>{' '}
                            {budget.square_meter_price.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL'
                            })}
                          </span>
                        </div>
                      </div>
                    ) : null}
                  </div> */}

                  <table id="table" style={{ display: 'none' }}>
                    <thead>
                      <tr>
                        <th>Serviços</th>
                        <th>Quantidade</th>
                        <th>UND.</th>
                        <th>Valor UN.</th>
                        <th>Preço de Venda</th>
                      </tr>
                    </thead>
                    <tbody>
                      {schedules.map((schedule) => (
                        <tr key={schedule.id}>
                          <td>{schedule.stage}</td>
                          <td>{schedule.proposed_quantity}</td>
                          <td>{schedule.measure}</td>
                          <td>
                            {Number(schedule.proposed_cost_price).toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL'
                            })}
                          </td>
                          <td>
                            {(Number(schedule.proposed_quantity) * Number(schedule.proposed_cost_price)).toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL'
                            })}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={4}>VALOR DE MÃO DE OBRA TOTAL</td>
                        <td>
                          <b>
                            {totalBudgetStages.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL'
                            })}
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div className={styles.expensesBlock}>
                    <div className={styles.budgetBox}>
                      <div className={styles.budgetBoxHeader}>
                        <span>Planejamento da Obra</span>

                        <div>
                          <button className={styles.excel} onClick={() => kkk()}>
                            <img src={iExcel} alt="" />
                          </button>
                          <button className={styles.print} onClick={() => generateConstructionStagesBudgetPDF()}>
                            <img src={iPrint} alt="" />
                          </button>
                          <button onClick={() => setModalCreateConstructionStageBudgetOpen(true)}>
                            <img src={iPlusWhite} alt="" />
                          </button>
                        </div>
                      </div>

                      {schedules.length ? (
                        schedules.map((stage) => (
                          <StageBudget key={stage.id} stage={stage} getConstructionSchedules={getConstructionSchedules} setToast={setToast} />
                        ))
                      ) : (
                        <span className={styles.noDataText}>Não existe etapas cadastradas.</span>
                      )}

                      <div className={styles.budgetBoxTotal}>
                        <span>
                          <b>Total: </b>{' '}
                          {Number(totalBudgetStages).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                          })}
                        </span>
                      </div>
                    </div>
                  </div>

                  <button className={styles.buttonModal} onClick={() => closeModal()} type="button">
                    <span>Fechar</span>
                  </button>
                </>
              ) : (
                <form onSubmit={handleSubmit(startBudget)} style={{ maxWidth: '300px', alignSelf: 'center' }}>
                  <div>
                    <label htmlFor="duration">Duração da obra (meses): *</label>
                    <input required type="text" name="duration" {...register('duration')} />
                  </div>

                  <div className={styles.buttons}>
                    <button type="submit" className={styles.crConfirm}>
                      <span>Iniciar</span>
                    </button>
                    <button
                      type="button"
                      className={styles.cancel}
                      onClick={() => {
                        setModalBudgetOpen(false)
                        reset()
                      }}
                    >
                      <span>Cancelar</span>
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>

          <Dialog
            open={createOpen}
            onClose={() => setCreateOpen(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            scroll="body"
            TransitionComponent={Fade}
            transitionDuration={200}
          >
            {bodyModalNewColaboratorDiary}
          </Dialog>

          <Modal
            open={createAddonOpen}
            onClose={() => setCreateAddonOpen(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyModalNewBudgetAddon}
          </Modal>

          <Modal
            open={editDurationOpen}
            onClose={() => setEditDurationOpen(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyEditDuration}
          </Modal>

          <Modal
            open={editSquareMetersOpen}
            onClose={() => setEditSquareMetersOpen(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyEditSquareMeters}
          </Modal>

          <Modal
            open={modalAddCLTColaboratorOpen}
            onClose={() => setModalAddCLTColaboratorOpen(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyModalAddCLTColaborator}
          </Modal>

          <Dialog
            open={modalCreateConstructionStageBudgetOpen}
            onClose={() => setModalCreateConstructionStageBudgetOpen(false)}
            TransitionComponent={Fade}
            transitionDuration={200}
            scroll="body"
          >
            <ModalCreateConstructionStageBudget
              constructionId={construction_id}
              budgetId={budget.id}
              handleToast={handleToast}
              getConstructionBudgetStages={getConstructionSchedules}
              setModalCreateConstructionStageBudgetOpen={setModalCreateConstructionStageBudgetOpen}
            />
          </Dialog>
        </div>
      </Dialog>

      {toast}
    </>
  )
}
