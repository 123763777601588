import { mdiImagePlus } from '@mdi/js'
import Icon from '@mdi/react'
import { useEffect, useState } from 'react'
import ToolIcon from '../../../../assets/icons/tool.svg'
import { api } from '../../../../services/api'
import { variables } from '../../../../variables'

import styles from './styles.module.css'

export default function ModalProjectDetails({ setModalProjectDetailsOpen, project }) {
  const [projectFiles, setProjectFiles] = useState([])

  useEffect(() => {
    loadProjectFiles()
  }, [])

  async function loadProjectFiles() {
    try {
      const { data } = await api.get(`/projects/files/${project.id}`)
      setProjectFiles(data)
    } catch (err) {
      console.error('fail on load project files', { err })
    }
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <img src={ToolIcon} alt="icon" />
            <h4 className={styles.title}>DETALHES DO PROJETO</h4>
          </div>

          <div>
            <h3>
              tipo: <b>{project.project_type}</b>
            </h3>
            <h3>
              área: <b>{project.area}m²</b>
            </h3>
            <h3>
              data de início: <b>{project.start_date}</b>
            </h3>

            {project.finish_date ? (
              <h3>
                data de finalização: <b>{project.finish_date_prediction}</b>
              </h3>
            ) : null}
          </div>

          <div className={styles.files}>
            {projectFiles.length
              ? projectFiles.map((file) => (
                  <div className={styles.new__file} onClick={() => window.open(`${variables.api}/public/${file.filename}`)}>
                    <Icon path={mdiImagePlus} size={1.2} color="#000" /> <h3>{file.description}</h3>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  )
}
