import { useEffect, useState } from 'react'

import DocumentIcon from '../../../../../assets/icons/contract.svg'
import PDFProductsFromListGenerator from '../../../../PDFProductsFromListGenerator'

import { api } from '../../../../../services/api'

import { PurchaseRequestFile } from '../../../../Modals/PurchaseRequest/Details'
import { mdiImage } from '@mdi/js'

import styles from './styles.module.css'

import Icon from '@mdi/react'
import { variables } from '../../../../../variables'

export default function ModalPurchaseRequestDetails({ setModalPurchaseRequestDetailsOpen, request }) {
  const [purchaseRequestFiles, setPurchaseRequestFiles] = useState<PurchaseRequestFile[]>([])

  useEffect(() => {
    loadPurchaseRequestFiles()
  }, [])

  async function loadPurchaseRequestFiles() {
    try {
      const { data } = await api.get(`/purchases/requests/files/${request.id}`)
      setPurchaseRequestFiles(data)
    } catch (err) {
      console.error('fail on load purchase request files', { err })
    }
  }

  async function generatePDF() {
    try {
      const { data } = await api.get(`/lists/details/${request.list_id}`)
      PDFProductsFromListGenerator(data, request.client, request.construction, request.description)
    } catch (err) {
      console.error('fail on generate pdf of list products', { err })
    }
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <img src={DocumentIcon} alt="icon" />
            <h4 className={styles.title}>DETALHES DO PEDIDO</h4>
          </div>

          <div>
            <h3>
              descrição: <b>{request.description}</b>
            </h3>
            <h3>
              obra: <b>{request.construction}</b>
            </h3>
            <h3>
              data da solicitação: <b>{request.send_to_client_date || 'sem data'}</b>
            </h3>
            <h3>
              data de pagamento: <b>{request.payment_date || 'sem data'}</b>
            </h3>
            <h3>
              chegada na obra: <b>{request.arrival_in_construction_date || 'sem data'}</b>
            </h3>

            <hr />
            <h5>Valor: {request.value ? <b>R$ {request.value}</b> : '...'}</h5>
            <h5>
              PIX/Dados Bancários: <b>{request.bank}</b>
            </h5>

            {request.observation ? (
              <>
                <hr />
                <h3>
                  observação: <b>{request.observation}</b>
                </h3>
              </>
            ) : null}
          </div>

          <div className={styles.files}>
            {purchaseRequestFiles.map((file) => (
              <div className={styles.new__file} onClick={() => window.open(`${variables.api}/public/${file.filename}`)}>
                <Icon path={mdiImage} size={1.2} color="#000" /> <h3>{file.description}</h3>
              </div>
            ))}
          </div>

          <button onClick={() => generatePDF()}>
            <span>BAIXAR (PDF)</span>
          </button>

          <button className={styles.close} onClick={() => setModalPurchaseRequestDetailsOpen(false)}>
            <span>FECHAR</span>
          </button>
        </div>
      </div>
    </div>
  )
}
