import React, { useState } from 'react'
import { IExpense } from '../../interfaces/Expense'

import { mdiTrashCan, mdiListStatus, mdiPencil, mdiFileDocument } from '@mdi/js'
import Icon from '@mdi/react'

import DefaultModal from '../Modals/DefaultModal'
import ModalExpenseParcels from '../Modals/Expense/Parcels'
import ModalDeleteExpense from '../Modals/Expense/Delete'

import styles from './style.module.css'
import ModalEditExpense from '../Modals/Expense/Edit'
import { variables } from '../../variables'

type ClientParam = {
  expense: IExpense
  refs: Header[]
  handleToast: any
}

type Header = {
  label: string
  ref: string
}

function Expense({ expense, refs, handleToast }: ClientParam) {
  const [modalEditExpenseOpen, setModalEditExpenseOpen] = useState(false)
  const [modalDeleteExpenseOpen, setModalDeleteExpenseOpen] = useState(false)
  const [modalExpenseParcelsOpen, setModalExpenseParcelsOpen] = useState(false)

  return (
    <tr className={styles.box}>
      {refs.map((ref) => (
        <td key={ref.ref} title={expense[ref.ref]}>
          {expense[ref.ref] ? (
            ref.ref === 'value' ? (
              Number(expense[ref.ref]).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              })
            ) : (
              expense[ref.ref]
            )
          ) : (
            <span>------</span>
          )}
          {}
        </td>
      ))}

      <td className={styles.buttons}>
        {expense.invoice ? (
          <span onClick={() => window.open(`${variables.api}/public/${expense.invoice}`)} title="Nota Fiscal/Comprovante">
            <Icon path={mdiFileDocument} size={1.2} className={styles.icon__invoice} />
          </span>
        ) : null}

        {(expense.parcels > 1 || expense.parcel) && (
          <span onClick={() => setModalExpenseParcelsOpen(true)} title="Parcelas">
            <Icon path={mdiListStatus} size={1.2} className={styles.icon__key} />
          </span>
        )}

        {!expense.parcel && (
          <>
            <span onClick={() => setModalDeleteExpenseOpen(true)} title="Excluir">
              <Icon path={mdiTrashCan} size={1.2} className={styles.icon__trash} />
            </span>

            <span onClick={() => setModalEditExpenseOpen(true)} title="Editar">
              <Icon path={mdiPencil} size={1.2} className={styles.icon__pencil} />
            </span>
          </>
        )}
      </td>

      <DefaultModal isOpen={modalEditExpenseOpen} handleOpen={setModalEditExpenseOpen}>
        <ModalEditExpense expense={expense} handleToast={handleToast} setModalEditExpenseOpen={setModalEditExpenseOpen} />
      </DefaultModal>

      <DefaultModal isOpen={modalExpenseParcelsOpen} handleOpen={setModalExpenseParcelsOpen}>
        <ModalExpenseParcels expense={expense} handleToast={handleToast} setModalExpenseParcelsOpen={setModalExpenseParcelsOpen} />
      </DefaultModal>

      <DefaultModal isOpen={modalDeleteExpenseOpen} handleOpen={setModalDeleteExpenseOpen}>
        <ModalDeleteExpense expense={expense} handleToast={handleToast} setModalDeleteExpenseOpen={setModalDeleteExpenseOpen} />
      </DefaultModal>
    </tr>
  )
}

export default React.memo(Expense)
