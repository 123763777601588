import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import Routes from './routes'
import { AuthProvider } from './contexts/AuthContext'

ReactDOM.render(
  <AuthProvider>
    <Routes />
  </AuthProvider>,
  document.getElementById('root')
)
