import { BaseSyntheticEvent, useContext } from 'react';
import { ProjectsContext } from '../../../../contexts/ProjectsContext';
import { api } from '../../../../services/api';
import { mdiPackageVariantClosed } from '@mdi/js';

import Icon from '@mdi/react';
import Toast from '../../../Toast';
import styles from './style.module.css';

export default function ModalDeleteProjectType({
  type,
  setModalDeleteProjectTypeOpen,
  handleToast,
}) {
  const { getProjectsTypes } = useContext(ProjectsContext);

  async function handleDelete(e: BaseSyntheticEvent) {
    e.preventDefault();

    api
      .delete(`/types/projects/${type.id}`)
      .then((response) => {
        getProjectsTypes();
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
        setModalDeleteProjectTypeOpen(false);
      })
      .catch((err) => {
        handleToast(
          <Toast category="error" message={err.response.data.message} />
        );
      });
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiPackageVariantClosed} size={1.5} />
            <h4 className={styles.title}>DELETAR TIPO DE PROJETO/ATIVIDADE</h4>
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm} onClick={(e) => handleDelete(e)}>
              Confirmar
            </button>
            <button
              className={styles.cancel}
              type="button"
              onClick={() => setModalDeleteProjectTypeOpen(false)}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
