import { createContext, useEffect } from 'react'
import { useState } from 'react'
import { api } from '../services/api'

type MonetaryContextType = {
  getIncomingsTypes: any
  getExpensesTypes: any
  getMoneyOrigins: any

  setExpenses: any
  setIncomings: any
  setExpensesTypes: any
  setIncomingsTypes: any

  incomings: IncomingType[]
  incomingsTypes: TypesType[]

  expenses: ExpensesType[]
  expensesTypes: TypesType[]
  moneyOrigins: MoneyOriginType[]

  lastFilter: string
  setLastFilter: any

  totalFilteredExpenses: number
  setTotalFilteredExpenses: any
  totalFilteredIncomings: number
  setTotalFilteredIncomings: any

  getExpensesUsingLastFilter: () => Promise<void>
  getIncomingsUsingLastFilter: () => Promise<void>
}

type ExpensesType = {
  id: number
  value: number
  payday: string
  due_date: string
  payday_unformated: string
  due_date_unformated: string
  description: string
  expense_type: string
  expense_type_id: number
  payment_type: number
  parcels?: number
  paid_parcels?: number
  payday_first_parcel: string
  due_day_parcel_in_month: string
  money_origin: string
  money_origin_id: number
  provider: string
  provider_id: number
  construction_id: number
  client: string
  client_id: number
  parcels_in_period?: number
  invoice: string
}

type IncomingType = {
  id: number
  value: number
  payday: string
  payday_unformated: string
  due_date: string
  due_date_unformated: string
  description: string
  incoming_type: string
  incoming_type_id: number
  payment_type: string
  money_origin: string
  client: string
  client_id: number
  invoice: string
}

export type TypesType = {
  id: number
  description: string
}

export type MoneyOriginType = {
  id: number
  description: string
}

export const MonetaryContext = createContext({} as MonetaryContextType)

export function MonetaryProvider({ children }) {
  const [expenses, setExpenses] = useState<ExpensesType[]>([])
  const [incomings, setIncomings] = useState<IncomingType[]>([])

  const [expensesTypes, setExpensesTypes] = useState<TypesType[]>([])
  const [incomingsTypes, setIncomingsTypes] = useState<TypesType[]>([])

  const [moneyOrigins, setMoneyOrigins] = useState<MoneyOriginType[]>([])

  const [lastFilter, setLastFilter] = useState<string>('')
  const [totalFilteredExpenses, setTotalFilteredExpenses] = useState<number>(0)
  const [totalFilteredIncomings, setTotalFilteredIncomings] = useState<number>(0)

  useEffect(() => {
    if (expensesTypes.length === 0) getExpensesTypes()
    if (incomingsTypes.length === 0) getIncomingsTypes()
    if (moneyOrigins.length === 0) getMoneyOrigins()
  }, [])

  async function getExpensesTypes() {
    await api
      .get('/types/expenses')
      .then((response) => {
        setExpensesTypes(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async function getIncomingsTypes() {
    await api
      .get('/types/incomings')
      .then((response) => {
        setIncomingsTypes(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async function getMoneyOrigins() {
    await api
      .get('/money_origins')
      .then((response) => {
        setMoneyOrigins(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async function getExpensesUsingLastFilter() {
    await api
      .get(lastFilter)
      .then((response) => {
        setExpenses(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async function getIncomingsUsingLastFilter() {
    await api
      .get(lastFilter)
      .then((response) => {
        setIncomings(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <MonetaryContext.Provider
      value={{
        getIncomingsTypes,
        getExpensesTypes,
        getMoneyOrigins,

        incomings,
        expenses,

        setExpenses,
        setIncomings,
        setIncomingsTypes,
        setExpensesTypes,

        expensesTypes,
        incomingsTypes,
        moneyOrigins,

        lastFilter,
        setLastFilter,

        totalFilteredExpenses,
        setTotalFilteredExpenses,

        totalFilteredIncomings,
        setTotalFilteredIncomings,

        getExpensesUsingLastFilter,
        getIncomingsUsingLastFilter
      }}
    >
      {children}
    </MonetaryContext.Provider>
  )
}
