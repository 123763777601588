import { useContext, useState, useEffect, FormEvent } from 'react'

import styles from './style.module.css'

import Sidebar from '../../components/Sidebar'
import links from '../../components/configs/links-monetary'
import Toast from '../../components/Toast'
import PDFIncomingsGenerator from '../../components/PDFIncomingsGenerator'

import iPlus from '../../assets/icons/plus.svg'

import { api } from '../../services/api'

import { MonetaryContext, MoneyOriginType, TypesType } from '../../contexts/MonetaryContext'
import { ClientsContext, ClientType } from '../../contexts/ClientsContext'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { ptBR } from 'date-fns/locale'
import { Autocomplete, TextField } from '@mui/material'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import TableGeneric from '../../components/TableGeneric'
import DefaultModal from '../../components/Modals/DefaultModal'
import ModalCreateIncoming from '../../components/Modals/Incoming/Create'

type InputType = {
  value: string
  name: string
}

type FilterType = {
  initialDate: string
  finalDate: string
  client_id?: number
  condition: string
}

type Header = {
  label: string
  ref: string
}

export default function Incomings() {
  const { incomings, setIncomings, moneyOrigins, totalFilteredIncomings, setTotalFilteredIncomings, setLastFilter, incomingsTypes } =
    useContext(MonetaryContext)
  const { clients } = useContext(ClientsContext)

  const [modalCreateIncomingOpen, setModalCreateIncomingOpen] = useState(false)
  const [toast, setToast] = useState<any>()
  const [formValues, setFormValues] = useState({} as FilterType)

  const tableHeaders: Header[] = [
    {
      label: formValues.condition === '1' ? 'Dt. de Pagamento' : formValues.condition === '2' ? 'Dt. de Vencimento' : 'Dt. de Cadastro',
      ref: formValues.condition === '1' ? 'payday' : formValues.condition === '2' ? 'due_date' : 'created_at'
    },
    {
      label: 'Valor',
      ref: 'value'
    },
    {
      label: 'Tipo da Receita',
      ref: 'incoming_type'
    },
    {
      label: 'Origem',
      ref: 'money_origin'
    },
    {
      label: 'Parcela',
      ref: 'parcel'
    },
    {
      label: 'Cliente',
      ref: 'client'
    },
    {
      label: 'Obra',
      ref: 'construction'
    },
    {
      label: 'Descrição',
      ref: 'description'
    }
  ]

  useEffect(() => {
    handlerDates()
  }, [])

  function handlerDates() {
    var date = new Date()

    var day: any = date.getDate()
    var month: any = date.getMonth() + 1
    var year: any = date.getFullYear()

    var fifty: any = 0

    if (day <= 15) {
      fifty = 1
    } else {
      fifty = day - 15
    }

    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }
    if (fifty < 10) {
      fifty = '0' + fifty
    }

    const today = `${year}-${month}-${day}`
    const fiftyDays = `${year}-${month}-${fifty}`

    formValues.initialDate = `${fiftyDays}, 00:00:00`
    formValues.finalDate = `${today}, 00:00:00`
  }

  function filterIncomings(e: FormEvent) {
    e.preventDefault()

    if (!formValues.condition) {
      handleToast(<Toast category="error" message="Selecione o método de busca do filtro. (Pagas, A Pagar ou Data de Criação)" />)

      return
    }

    const filter = {
      ...formValues,
      initialDate: formValues.initialDate.split(',')[0],
      finalDate: formValues.finalDate.split(',')[0]
    }

    let paramQueries: string = `?`

    const fieldsToFilter = Object.keys(filter)
    fieldsToFilter.map((field) => !!filter[field] && (paramQueries = `${paramQueries}${field}=${filter[field]}&`))

    setLastFilter(`/incomings/filter/custom${paramQueries}`)

    api
      .get(`/incomings/filter/custom${paramQueries}`)
      .then((response) => {
        let total = 0

        for (const incoming of response.data) {
          total = Number(total) + Number(incoming.value)
        }

        setTotalFilteredIncomings(total)
        setIncomings(response.data)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  function downloadPDF() {
    PDFIncomingsGenerator(incomings, formValues.initialDate.split(',')[0], formValues.finalDate.split(',')[0])
  }

  function handleToast(toast) {
    setToast(toast)

    setTimeout(() => {
      setToast(null)
    }, 5000)
  }

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  return (
    <div className={styles.main}>
      <Sidebar config={links} />

      {toast}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>RECEITAS</h1>

          <button className={styles.button} onClick={() => setModalCreateIncomingOpen(true)}>
            <span>NOVA RECEITA</span>
            <img src={iPlus} alt="" />
          </button>
        </div>

        <form className={styles.form__filter} onSubmit={(e) => filterIncomings(e)}>
          <div className={styles.row}>
            <div className={styles.input__radios}>
              <input
                type="radio"
                name="condition"
                value="1"
                onClick={() =>
                  handleInputChange({
                    name: 'condition',
                    value: '1'
                  })
                }
              />
              Recebidas
              <input
                type="radio"
                name="condition"
                value="2"
                onClick={() =>
                  handleInputChange({
                    name: 'condition',
                    value: '2'
                  })
                }
              />
              A Receber
              <input
                type="radio"
                name="condition"
                value="3"
                onClick={() =>
                  handleInputChange({
                    name: 'condition',
                    value: '3'
                  })
                }
              />
              Dt. de Cadastro
            </div>

            {totalFilteredIncomings != 0 && (
              <h3 style={{ alignSelf: 'end' }}>
                TOTAL:{' '}
                {totalFilteredIncomings.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL'
                })}
              </h3>
            )}
          </div>
          <div className={styles.row}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Data Inicial"
                mask="__/__/____"
                value={formValues.initialDate}
                renderInput={(params) => <TextField {...params} style={{ minWidth: 150, width: '100%' }} />}
                onChange={(newValue) => {
                  handleInputChange({
                    name: 'initialDate',
                    value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                  })
                }}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Data Final"
                mask="__/__/____"
                value={formValues.finalDate}
                renderInput={(params) => <TextField {...params} style={{ minWidth: 150, width: '100%' }} />}
                onChange={(newValue) => {
                  handleInputChange({
                    name: 'finalDate',
                    value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                  })
                }}
              />
            </LocalizationProvider>

            <Autocomplete
              value={undefined}
              fullWidth
              onChange={(event, newValue: TypesType) => {
                handleInputChange({
                  name: 'incoming_type_id',
                  value: newValue ? String(newValue.id) : undefined
                })
              }}
              options={incomingsTypes}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Tipo da Receita" />}
            />
          </div>

          <div className={styles.row}>
            <Autocomplete
              value={undefined}
              fullWidth
              onChange={(event, newValue: { description: string; value: number }) => {
                handleInputChange({
                  name: 'subtype',
                  value: newValue && String(newValue.value)
                })
              }}
              options={[
                { description: 'Construtora', value: 1 },
                { description: 'Escritório', value: 2 }
              ]}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Subtipo da Receita" />}
            />

            <Autocomplete
              value={undefined}
              fullWidth
              onChange={(event, newValue: ClientType) => {
                handleInputChange({
                  name: 'client_id',
                  value: newValue ? String(newValue.id) : undefined
                })
              }}
              options={clients}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Cliente" />}
            />

            <Autocomplete
              value={undefined}
              fullWidth
              onChange={(event, newValue: MoneyOriginType) => {
                handleInputChange({
                  name: 'money_origin_id',
                  value: newValue ? String(newValue.id) : undefined
                })
              }}
              options={moneyOrigins}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Origem Monetária" />}
            />
          </div>
          <div className={styles.buttons}>
            <button type="submit" className={styles.confirm}>
              FILTRAR
            </button>
            <button disabled={!incomings.length} className={styles.info} onClick={() => downloadPDF()}>
              BAIXAR EM PDF
            </button>
          </div>
        </form>

        <div className={styles.table}>
          <TableGeneric headers={tableHeaders} data={incomings} handleToast={handleToast} componentToRender="incoming" fieldToSearch="incoming_type" title="" />
        </div>

        <DefaultModal isOpen={modalCreateIncomingOpen} handleOpen={setModalCreateIncomingOpen}>
          <ModalCreateIncoming handleToast={handleToast} setModalCreateIncomingOpen={setModalCreateIncomingOpen} />
        </DefaultModal>
      </div>
    </div>
  )
}
