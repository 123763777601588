import { BaseSyntheticEvent, useContext, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { ColaboratorsContext } from '../../../../contexts/ColaboratorsContext'
import { api } from '../../../../services/api'
import { mdiAccountTie } from '@mdi/js'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { ptBR } from 'date-fns/locale'
import { ConstructionsContext, ConstructionType } from '../../../../contexts/ConstructionsContext'
import { MonetaryContext, MoneyOriginType } from '../../../../contexts/MonetaryContext'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import styles from './style.module.css'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

type SubmitType = {
  value: string
  date: string
  construction_id: number
  employee_id: number
  money_origin_id: number
}

type InputType = {
  value: string
  name: string
}

type ColaboratorType = {
  id: number
  name: string
}

export default function ModalEditColaboratorDiary({ diary, setModalEditColaboratorDiaryOpen, handleToast }) {
  const [formValues, setFormValues] = useState({} as SubmitType)

  const { getColaborators, colaborators } = useContext(ColaboratorsContext)
  const { constructions } = useContext(ConstructionsContext)
  const { moneyOrigins } = useContext(MonetaryContext)

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function handleCreate(e: BaseSyntheticEvent) {
    e.preventDefault()

    api
      .put(`/employees/diaries/${diary.id}`, formValues)
      .then((response) => {
        getColaborators()
        handleToast(<Toast category="success" message={response.data.message} />)
        setModalEditColaboratorDiaryOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.response.data.message} />)
      })
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiAccountTie} size={1.5} />
            <h4 className={styles.title}>CADASTRAR DIÁRIA DE COLABORADOR</h4>
          </div>

          <form className={styles.form__create} onSubmit={(e) => handleCreate(e)}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Data"
                mask="__/__/____"
                value={formValues.date ? formValues.date : `${diary.date.split('/').reverse().join('-')}, 00:00:00`}
                onChange={(newValue) => {
                  handleInputChange({
                    name: 'date',
                    value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                  })
                }}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>

            <TextField
              label="Valor"
              type="text"
              defaultValue={diary.value}
              required
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'value',
                  value: e.target.value.replace(',', '.')
                })
              }
            />

            <Autocomplete
              value={undefined}
              defaultValue={constructions.find((c) => c.id === diary.construction_id)}
              onChange={(event, newValue: ConstructionType) => {
                handleInputChange({
                  name: 'construction_id',
                  value: newValue && String(newValue.id)
                })
              }}
              options={constructions}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Obra" required />}
            />

            <Autocomplete
              value={undefined}
              defaultValue={colaborators.find((c) => c.id === diary.employee_id)}
              onChange={(event, newValue: ColaboratorType) => {
                handleInputChange({
                  name: 'employee_id',
                  value: newValue && String(newValue.id)
                })
              }}
              options={colaborators}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Colaborador" required />}
            />

            <Autocomplete
              value={undefined}
              defaultValue={moneyOrigins.find((my) => my.id === diary.money_origin_id)}
              onChange={(event, newValue: MoneyOriginType) => {
                handleInputChange({
                  name: 'money_origin_id',
                  value: newValue && String(newValue.id)
                })
              }}
              options={moneyOrigins}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Origem Monetária" required />}
            />

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalEditColaboratorDiaryOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
