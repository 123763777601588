import { BaseSyntheticEvent, useEffect, useContext, useState } from 'react'

import Sidebar from '../../../components/Sidebar'
import links from '../../../components/configs/links-records'
import iPlus from '../../../assets/icons/plus.svg'
import List from '../../../components/List'

import { api } from '../../../services/api'
import { ClientType } from '../../../contexts/ClientsContext'
import { Autocomplete, TextField } from '@mui/material'
import { ConstructionsContext, ConstructionType } from '../../../contexts/ConstructionsContext'

import styles from './style.module.css'
import DefaultModal from '../../../components/Modals/DefaultModal'
import ModalCreateList from '../../../components/Modals/Lists/Create'

type ListsType = {
  id: number
  user: string
  construction: string
  construction_id: string
  description: string
  created_at: string
  client: string
}

export default function Lists() {
  const { constructions } = useContext(ConstructionsContext)

  const [modalCreateListOpen, setModalCreateListOpen] = useState(false)

  const [lists, setLists] = useState<ListsType[]>([])

  const [toast, setToast] = useState<any>()

  const [messageDefault] = useState('Busque as informações utilizando os filtros acima.')

  const [filterClient, setFilterClient] = useState<number>(null)
  const [filterConstruction, setFilterConstruction] = useState<number>(null)

  const [activeClients, setActiveClients] = useState([])

  useEffect(() => {
    loadActiveClients()
  }, [])

  async function loadActiveClients() {
    try {
      const { data } = await api.get('/clients/active/constructions')
      setActiveClients(data)
    } catch (err) {
      console.error('fail on load active clients', { err })
    }
  }

  async function filterLists(e: BaseSyntheticEvent) {
    e.preventDefault()

    try {
      const { data } = await api.get(`/lists/filter?client_id=${filterClient}&construction_id=${filterConstruction}`)
      setLists(data)
    } catch (err) {
      console.error('fail on filter lists', { err })
    }
  }

  function handleToast(toast) {
    setToast(toast)

    setTimeout(() => {
      setToast(null)
    }, 5000)
  }

  return (
    <div className={styles.main}>
      <Sidebar config={links} />

      {toast}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>LISTAS DE COMPRAS</h1>

          <button className={styles.button} onClick={() => setModalCreateListOpen(true)}>
            <span>CRIAR LISTA</span>
            <img src={iPlus} alt="" />
          </button>
        </div>

        <form className={styles.form__filter} onSubmit={(e) => filterLists(e)}>
          <div className={styles.row}>
            <Autocomplete
              value={undefined}
              fullWidth
              onChange={(event, newValue: ClientType) => {
                setFilterClient(newValue?.id)
              }}
              options={activeClients}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Cliente" />}
            />

            <Autocomplete
              value={undefined}
              fullWidth
              onChange={(event, newValue: ConstructionType) => {
                setFilterConstruction(newValue?.id)
              }}
              options={setFilterClient ? constructions.filter((c) => c.client_id === filterClient) : []}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Obra" />}
            />
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm}>
              <span>FILTRAR</span>
            </button>
          </div>
        </form>

        <div className={styles.table}>
          {lists.length ? lists.map((list) => <List key={list.id} list={list} handleToast={handleToast} />) : <span>{messageDefault}</span>}
        </div>
      </div>

      <DefaultModal isOpen={modalCreateListOpen} handleOpen={setModalCreateListOpen}>
        <ModalCreateList handleToast={handleToast} setModalCreateListOpen={setModalCreateListOpen} />
      </DefaultModal>
    </div>
  )
}
