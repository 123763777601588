import { useEffect } from 'react'
import { useContext, useState } from 'react'

import styles from './style.module.css'

import Sidebar from '../../../components/Sidebar'
import links from '../../../components/configs/links-records'

import iPlus from '../../../assets/icons/plus.svg'

import { useForm } from 'react-hook-form'
import { Modal } from '@material-ui/core'
import { api } from '../../../services/api'

import Toast from '../../../components/Toast'
import { ColaboratorsContext } from '../../../contexts/ColaboratorsContext'
import { ConstructionsContext } from '../../../contexts/ConstructionsContext'
import ColaboratorAbsent from '../../../components/ColaboratorAbsent'
import PDFColaboratorsAbsentsGenerator from '../../../components/PDFColaboratorsAbsentsGenerator'

type AbsentSubmitType = {
  employee_id: number
  construction_id: number
  date: string
  integral: number
}

type AbsentsType = {
  id: number
  employee: string
  employee_id: number
  construction: string
  construction_id: number
  date: string
  integral: number
  _date: string
}

type DiariesFilterType = {
  start_date: Date
  finish_date: Date
  construction_id_filter: number
  employee_id_filter: number
}

export default function ColaboratorsAbsents() {
  const { register, handleSubmit, reset } = useForm()

  const [createDiaryOpen, setCreateDiaryOpen] = useState(false)
  const [colaboratorsAbsents, setColaboratorsAbsents] = useState<AbsentsType[]>([])

  const [toast, setToast] = useState<any>()

  const { constructions } = useContext(ConstructionsContext)
  const { colaborators } = useContext(ColaboratorsContext)

  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')

  const [messageDefault, setMessageDefault] = useState('Busque as informações utilizando os filtros acima.')

  useEffect(() => {
    handlerDates()
  }, [])

  function handlerDates() {
    var date = new Date()

    var day: any = date.getDate()
    var month: any = date.getMonth() + 1
    var year: any = date.getFullYear()

    var fifty: any = 0

    if (day <= 15) {
      fifty = 1
    } else {
      fifty = day - 15
    }

    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }
    if (fifty < 10) {
      fifty = '0' + fifty
    }

    const today = `${year}-${month}-${day}`
    const fiftyDays = `${year}-${month}-${fifty}`

    setInitialDate(fiftyDays)
    setFinalDate(today)
  }

  function downloadPDF() {
    PDFColaboratorsAbsentsGenerator(colaboratorsAbsents, initialDate, finalDate)
  }

  function newColaboratorAbsent(data: AbsentSubmitType) {
    api
      .post('/employees/absents', data)
      .then((response) => {
        handleToast(<Toast category="success" message={response.data.message} />)
        setCreateDiaryOpen(false)

        reset()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function filterDiaries(data: DiariesFilterType) {
    let filter = {
      start_date: data.start_date || initialDate,
      finish_date: data.finish_date || finalDate,
      employee_id: data.employee_id_filter,
      construction_id: data.construction_id_filter
    }

    api
      .get(
        `/employees/absents/filter/${data.start_date || initialDate}/${data.finish_date || finalDate}/${data.employee_id_filter}/${data.construction_id_filter}`
      )
      .then((response) => {
        if (response.data.length === 0) {
          setMessageDefault('Não foram encontradas diárias de acordo com o filtro.')
        }

        setColaboratorsAbsents(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function handleToast(toast) {
    setToast(toast)

    setTimeout(() => {
      setToast(null)
    }, 5000)
  }

  const bodyNewColaboratorAbsent = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ maxWidth: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Lançar falta de colaborador</h4>
          </div>

          <form className={styles.formEdit} onSubmit={handleSubmit(newColaboratorAbsent)}>
            <label htmlFor="date">Data: *</label>
            <input name="date" type="date" {...register('date')} />

            <label htmlFor="employee_id">Colaborador: *</label>
            <select name="employee_id" required {...register('employee_id')}>
              <option value="0">Selecione um colaborador</option>
              {colaborators.map((employee) => (
                <option value={employee.id}>{employee.name}</option>
              ))}
            </select>

            <label htmlFor="construction_id">Obra: *</label>
            <select name="construction_id" required {...register('construction_id')}>
              <option value="0">Selecione uma obra</option>
              {constructions.map((construction) => (
                <option value={construction.id}>{construction.name}</option>
              ))}
            </select>

            <div className={styles.inputRadios}>
              <input type="radio" name="integral" {...register('integral')} value="0" />
              Meio Período
              <input type="radio" name="integral" {...register('integral')} value="1" />
              Integral
            </div>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button
                className={styles.cancel}
                onClick={() => {
                  setCreateDiaryOpen(false)
                  reset()
                }}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

  return (
    <div className={styles.main}>
      <Sidebar config={links} />

      {toast}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>FALTAS DE COLABORADORES</h1>

          <button className={styles.button} onClick={() => setCreateDiaryOpen(true)}>
            <span>LANÇAR FALTA</span>
            <img src={iPlus} alt="" />
          </button>
        </div>

        <form className={styles.formFilter} onSubmit={handleSubmit(filterDiaries)}>
          <div className={styles.row}>
            <div className={styles.inputBlock}>
              <label htmlFor="start_date">Data inicial</label>
              <input
                required
                type="date"
                id="start_date"
                name="start_date"
                {...register('start_date')}
                defaultValue={initialDate}
                onChange={(e) => setInitialDate(e.target.value)}
              />
            </div>

            <div className={styles.inputBlock}>
              <label htmlFor="finish_date">Data final</label>
              <input
                required
                type="date"
                id="finish_date"
                name="finish_date"
                {...register('finish_date')}
                defaultValue={finalDate}
                onChange={(e) => setFinalDate(e.target.value)}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.inputBlock}>
              <label htmlFor="employee_id_filter">Colaborador</label>
              <select required id="employee_id_filter" name="employee_id_filter" {...register('employee_id_filter')} defaultValue="0">
                <option value="0">Nenhum</option>
                {colaborators.map((colaborators) => (
                  <option value={colaborators.id}>{colaborators.name}</option>
                ))}
              </select>
            </div>

            <div className={styles.inputBlock}>
              <label htmlFor="construction_id_filter">Obra</label>
              <select required id="construction_id_filter" name="construction_id_filter" {...register('construction_id_filter')} defaultValue="0">
                <option value="0">Nenhum</option>
                {constructions.map((construction) => (
                  <option value={construction.id}>
                    {construction.address}, {construction.address_number}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm}>FILTRAR</button>
            <button disabled={!colaboratorsAbsents.length} onClick={() => downloadPDF()} className={styles.info} type="button">
              BAIXAR EM PDF
            </button>
          </div>
        </form>

        <div className={styles.table}>
          {colaboratorsAbsents.length ? (
            colaboratorsAbsents.map((absent) => <ColaboratorAbsent key={absent.id} absent={absent} handleToast={handleToast} />)
          ) : (
            <span>{messageDefault}</span>
          )}
        </div>

        <Modal
          open={createDiaryOpen}
          onClose={() => setCreateDiaryOpen(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyNewColaboratorAbsent}
        </Modal>
      </div>
    </div>
  )
}
