import './style.css';

export default function Toast(props) {
  let categoryTranslated: string;

  switch (props.category) {
    case 'success':
      categoryTranslated = 'Sucesso!';
      break;
    case 'error':
      categoryTranslated = 'Erro!';
      break;
    case 'warning':
      categoryTranslated = 'Atenção!';
      break;
    default:
      categoryTranslated = '...';
  }

  return (
    <div className={`Toast Toast--${props.category}`}>
      <div className="Toast__message">
        <header className="Toast__message-category">
          {categoryTranslated}
        </header>
        <p className="Toast__message-text">{props.message}</p>
      </div>
    </div>
  );
}
