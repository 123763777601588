const links = {
  // ROLES
  // 1 - Possui todas as permissões do sistema: Administrador.
  // 2 - Poderá lançar despesas e receitas além de ter acesso as configurações do sistema: Financeiro.
  // 3 - Lançar produtos/ferramentas/diários de obra: Engenheiro Júnior (Obra).
  // 4 - Lançar diários de obra, listas/compra de produtos: Engenheiro Júnior (Administrativo).
  // 5 - Irá gerenciar e lançar projetos para os projetistas: Projetista Chefe.
  // 6 - Terá acesso apenas aos projetos que lhe forem cadastrados pelo projetista chefe: Projetista.
  title: 'Configurações',
  links: [
    { label: 'Tipos de Receitas', href: '/types/incomings', roles: [1, 2] },
    { label: 'Tipos de Despesas', href: '/types/expenses', roles: [1, 2] },
    { label: 'Tipos de Projeto', href: '/types/projects', roles: [1, 2, 5] },
    {
      label: 'Tipos de Colaboradores',
      href: '/types/colaborators',
      roles: [1, 2, 3, 4],
    },
    { label: 'Etapas de Obra', href: '/stages', roles: [1, 2, 3, 4] },
    {
      label: 'Origem/Destino Monetário',
      href: '/monetary/origins',
      roles: [1, 2],
    },
    {
      label: 'Unidades de Medida',
      href: '/measures',
      roles: [1, 2, 3, 4],
    },
  ],
};

export default links;
