import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Dialog, Fade } from '@material-ui/core';

import styles from './style.module.css';

import Sidebar from '../../../components/Sidebar';
import links from '../../../components/configs/links-configs';
import Toast from '../../../components/Toast';

import iPlus from '../../../assets/icons/plus.svg';
import empty from '../../../assets/empty.svg';

import { api } from '../../../services/api';
import { ColaboratorsContext } from '../../../contexts/ColaboratorsContext';
import ColaboratorType from '../../../components/ColaboratorType';

type ColaboratorType = {
  description: string;
  tax: string;
};

export default function ColaboratorTypes() {
  const { colaboratorsTypes, getColaboratorsTypes } =
    useContext(ColaboratorsContext);
  const { register, handleSubmit, reset } = useForm();

  const [open, setOpen] = useState(false);

  const [toast, setToast] = useState<any>();

  function handleToast(toast) {
    setToast(toast);

    setTimeout(() => {
      setToast(null);
    }, 5000);
  }

  function newType(data: ColaboratorType) {
    api
      .post('/types/employees', data)
      .then((response) => {
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
        getColaboratorsTypes();
        setOpen(false);
        reset();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const bodyNewExpense = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Cadastrar Tipo de Colaborador</h4>
          </div>

          <form className={styles.formCreate} onSubmit={handleSubmit(newType)}>
            <div>
              <label htmlFor="description">Descrição:</label>
              <input
                type="text"
                required
                id="description"
                name="description"
                {...register('description')}
              />
            </div>

            <div>
              <label htmlFor="tax">Taxa de imposto (%):</label>
              <input
                type="text"
                placeholder="Coloque apenas o número"
                required
                id="tax"
                name="tax"
                {...register('tax')}
              />
            </div>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button
                className={styles.cancel}
                type="button"
                onClick={() => setOpen(false)}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.main}>
      <Sidebar config={links} />

      {toast}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>Tipos de Colaboradores</h1>

          <button onClick={() => setOpen(true)}>
            <span>Novo tipo de colaborador</span>
            <img src={iPlus} alt="" />
          </button>
        </div>

        <div className={styles.table}>
          {colaboratorsTypes.length ? (
            colaboratorsTypes.map((type) => (
              <ColaboratorType
                key={type.id}
                type={type}
                handleToast={handleToast}
              />
            ))
          ) : (
            <div className={styles.emptyDataBox}>
              <h4>Não encontramos informações para listar.</h4>
              <img src={empty} alt="empty" />
            </div>
          )}
        </div>

        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          scroll="body"
          TransitionComponent={Fade}
          transitionDuration={200}
        >
          {bodyNewExpense}
        </Dialog>
      </div>
    </div>
  );
}
