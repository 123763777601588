import React, { useState, useEffect } from 'react'

import iEye from '../../assets/icons/eye.svg'
import iPencil from '../../assets/icons/pencil.svg'
import iTrash from '../../assets/icons/trash.svg'

import { api } from '../../services/api'

import DefaultModal from '../Modals/DefaultModal'
import ModalDeleteList from '../Modals/Lists/Delete'
import ModalListDetails from '../Modals/Lists/Details'
import ModalEditList from '../Modals/Lists/Edit'

import styles from './style.module.css'

type ListParam = {
  list: ListType
  handleToast: any
}

type ListType = {
  id: number
  user: string
  construction: string
  construction_id: string
  description: string
  created_at: string
  client: string
}

type ListedProductsType = {
  id: number
  description: string
  quantity: number
  measure: string
  measure_id: number
}

function List({ list, handleToast }: ListParam) {
  const [modalEditListOpen, setModalEditListOpen] = useState(false)
  const [modalListDetailsOpen, setModalListDetailsOpen] = useState(false)
  const [modalDeleteListOpen, setModalDeleteListOpen] = useState(false)

  return (
    <div className={styles.box}>
      <div className={styles.boxInfoConstruction}>
        <span>Descrição: {list.description}</span>
      </div>

      <div className={styles.boxInfoConstruction}>
        <span>Obra: {list.construction}</span>
      </div>

      <div className={styles.boxInfoConstruction} style={{ width: '50%' }}>
        <span>Criado em: {list.created_at}</span>
      </div>

      <div className={styles.buttons}>
        <img src={iEye} alt="" onClick={() => setModalListDetailsOpen(true)} />
        <img src={iPencil} alt="" onClick={() => setModalEditListOpen(true)} />
        <img src={iTrash} alt="" onClick={() => setModalDeleteListOpen(true)} />
      </div>

      <DefaultModal handleOpen={setModalListDetailsOpen} isOpen={modalListDetailsOpen}>
        <ModalListDetails list={list} setModalListDetailsOpen={setModalListDetailsOpen} handleToast={handleToast} />
      </DefaultModal>

      <DefaultModal handleOpen={setModalEditListOpen} isOpen={modalEditListOpen}>
        <ModalEditList list={list} setModalEditListOpen={setModalEditListOpen} handleToast={handleToast} />
      </DefaultModal>

      <DefaultModal handleOpen={setModalDeleteListOpen} isOpen={modalDeleteListOpen}>
        <ModalDeleteList list={list} setModalDeleteListOpen={setModalDeleteListOpen} handleToast={handleToast} />
      </DefaultModal>
    </div>
  )
}

export default React.memo(List)
