import styles from './style.module.css';

import { useState } from 'react';

import iPlus from '../../assets/icons/plus.svg';
import iLess from '../../assets/icons/less.svg';
import iRefresh from '../../assets/icons/refresh.svg';

import { api } from '../../services/api';
import ColaboratorProject from '../ColaboratorProject';

type ScheduleParams = {
  client: ClientType;
  projects: ProjectsType[];
  getColaboratorProjects: any;
};

type ProjectsType = {
  id: number;
  client: string;
  client_id: number;
  project_type: string;
  project_type_id: number;
  value: number;
  area: string;
  criterion: string;
  local: string;
  start_date: string;
  start_date_unformated: number;
  finish_date: string;
  finish_date_prediction: string;
  finish_date_prediction_unformated: string;
  duration: number;
  responsable: string;
  responsable_id: number;
  leader_observation: string;
  status: number;
};

type ClientType = {
  name: string;
  id: number;
};

type ClientProjectsType = {
  id: number;
  client: string;
  client_id: number;
  project_type: string;
  project_type_id: number;
  value: number;
  area: string;
  criterion: string;
  local: string;
  start_date: string;
  start_date_unformated: number;
  finish_date: string;
  finish_date_prediction: string;
  finish_date_prediction_unformated: string;
  duration: number;
  responsable: string;
  responsable_id: number;
  status: number;
};

export default function ProjectClientFolderToColaborator({
  client,
  projects,
  getColaboratorProjects,
}: ScheduleParams) {
  const [detailsOpened, setDetailsOpened] = useState(false);

  const [toast, setToast] = useState<any>();

  function openScheduleDetails() {
    setDetailsOpened(!detailsOpened);
  }

  function handleToast(toast) {
    setToast(toast);

    setTimeout(() => {
      setToast(null);
    }, 5000);
  }

  return (
    <div className={styles.modalSession}>
      <div className={styles.modalHeader}>
        <h4>{client.name}</h4>

        <div className={styles.icons}>
          <img src={iRefresh} alt="plus-icon" />

          {detailsOpened ? (
            <div onClick={() => openScheduleDetails()}>
              <img src={iLess} alt="plus-icon" />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => openScheduleDetails()}
            >
              <img src={iPlus} alt="plus-icon" />
            </div>
          )}
        </div>
      </div>

      <div
        className={styles.modalContent}
        style={{ display: detailsOpened ? 'block' : 'none' }}
      >
        {projects.length ? (
          projects.map((project) => (
            <ColaboratorProject
              key={project.id}
              project={project}
              handleToast={handleToast}
              getColaboratorProjects={getColaboratorProjects}
            />
          ))
        ) : (
          <span>Esse cliente ainda não possui projetos cadastrados.</span>
        )}
      </div>

      {toast}
    </div>
  );
}
