import { useContext, useState } from 'react'
import { ProvidersContext } from '../../../contexts/ProvidersContext'

import styles from './style.module.css'
import Sidebar from '../../../components/Sidebar'
import links from '../../../components/configs/links-records'
import iPlus from '../../../assets/icons/plus.svg'
import DefaultModal from '../../../components/Modals/DefaultModal'
import TableGeneric from '../../../components/TableGeneric'
import ModalCreateProvider from '../../../components/Modals/Provider/Create'

type Header = {
  label: string
  ref: string
}

export default function Providers() {
  const [toast, setToast] = useState<any>()
  const [modalCreateProviderOpen, setModalCreateProviderOpen] = useState(false)

  const { providers } = useContext(ProvidersContext)

  const tableHeaders: Header[] = [
    {
      label: 'Nome',
      ref: 'name'
    }
  ]

  function handleToast(toast) {
    setToast(toast)

    setTimeout(() => {
      setToast(null)
    }, 5000)
  }

  return (
    <div className={styles.main}>
      <Sidebar config={links} />

      {toast}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>FORNECEDORES</h1>

          <button className={styles.button} onClick={() => setModalCreateProviderOpen(true)}>
            <span>NOVO FORNECEDOR</span>
            <img src={iPlus} alt="" />
          </button>
        </div>

        <div className={styles.table}>
          <TableGeneric headers={tableHeaders} data={providers} handleToast={handleToast} componentToRender="provider" fieldToSearch="name" title="" />
        </div>

        <DefaultModal isOpen={modalCreateProviderOpen} handleOpen={setModalCreateProviderOpen}>
          <ModalCreateProvider handleToast={handleToast} setModalCreateProviderOpen={setModalCreateProviderOpen} />
        </DefaultModal>
      </div>
    </div>
  )
}
