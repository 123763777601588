import React from 'react'
import styles from './style.module.css'

import iPencil from '../../assets/icons/pencil.svg'
import iTrash from '../../assets/icons/trash.svg'
import iEye from '../../assets/icons/eye.svg'
import iSuccess from '../../assets/icons/success.svg'

import { Dialog, Fade } from '@material-ui/core'
import { useState } from 'react'

import { useForm } from 'react-hook-form'
import { api } from '../../services/api'

import Toast from '../Toast'
import { useContext } from 'react'

import { ClientsContext } from '../../contexts/ClientsContext'
import { ProjectsContext } from '../../contexts/ProjectsContext'
import { UsersContext } from '../../contexts/UsersContext'
import { useEffect } from 'react'
import DefaultModal from '../Modals/DefaultModal'
import ModalEditProject from '../Modals/Project/Edit'
import ModalProjectDetails from '../Modals/Project/Details'
import ModalDeleteProject from '../Modals/Project/Delete'
import ModalFinishProject from '../Modals/Project/Finish'

type ProjectParam = {
  project: ProjectType
  handleToast: any
  refresh: any
  getClientProjects: any
}

type ProjectType = {
  id: number
  client: string
  client_id: number
  project_type: string
  project_type_id: number
  project_type_category: number
  value: number
  area: string
  criterion: string
  local: string
  start_date: string
  start_date_unformated: number
  finish_date: string
  finish_date_prediction: string
  finish_date_prediction_unformated: string
  colaborator_delivery_date: string
  duration: number
  responsable: string
  responsable_id: number
  status: number | string
}

type SubmitType = {
  client: string
  project_type: string
  value: number
  area: string
  criterion: string
  local: string
  start_date: Date
  finish_date: Date
  finish_date_prediction: Date
  duration: number
  responsable: string
}

function Project({ project, handleToast, refresh, getClientProjects }: ProjectParam) {
  const [modalEditProjectOpen, setModalEditProjectOpen] = useState(false)
  const [modalProjectDetailsOpen, setModalProjectDetailsOpen] = useState(false)
  const [modalDeleteProjectOpen, setModalDeleteProjectOpen] = useState(false)
  const [modalFinishProjectOpen, setModalFinishProjectOpen] = useState(false)

  const [stage, setStage] = useState('')

  const { getProjects, projectsTypes } = useContext(ProjectsContext)
  const { clients } = useContext(ClientsContext)
  const { professionals } = useContext(UsersContext)

  const { register, handleSubmit } = useForm()

  const [open, setOpen] = useState(false)
  const [modalBody, setModalBody] = useState(<div></div>)

  // useEffect(() => {
  //   switch (project.status) {
  //     case 1:
  //       setStage('Em aberto')
  //       break
  //     case 2:
  //       setStage('Em andamento')
  //       break
  //     case 3:
  //       setStage('Aguardando análise')
  //       break
  //     case 4:
  //       setStage('Finalizado')
  //       break
  //     default:
  //       setStage('Indefinido')
  //   }
  // }, [])

  function deleteProject() {
    api
      .delete(`/projects/${project.id}`)
      .then((response) => {
        getClientProjects()
        setOpen(false)
        handleToast(<Toast category="success" message={response.data.message} />)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function aproveProject() {
    api
      .put(`/projects/aprove/${project.id}`)
      .then((response) => {
        setOpen(false)
        handleToast(<Toast category="success" message={response.data.message} />)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function recuseProject(data: any) {
    api
      .put(`/projects/recuse/${project.id}`, data)
      .then((response) => {
        setOpen(false)
        handleToast(<Toast category="success" message={response.data.message} />)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async function handleEdit(data: SubmitType) {
    api
      .put(`/projects/${project.id}`, data)
      .then((response) => {
        getProjects()
        handleToast(<Toast category="success" message={response.data.message} />)
        setOpen(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function handleModalOpen(body: string) {
    if (body === 'visualize') {
      bodyVisualizeProjectDetails(project)
    } else if (body === 'edit') {
      setModalBody(bodyEditClient)
    } else if (body === 'delete') {
      setModalBody(bodyDeleteProject)
    } else if (body === 'recuseObservation') {
      setModalBody(bodyPutRecuseObservation)
    }

    setOpen(true)
  }

  function bodyVisualizeProjectDetails(p: ProjectType) {
    setModalBody(
      <div className={styles.boxContainer}>
        <div className={styles.boxModal} style={{ width: '400px' }}>
          <div className={styles.boxContentDetails}>
            <div className={styles.boxHeader}>
              <h3 className={styles.clientName}>Projeto: {p.project_type}</h3>
            </div>

            <span>
              Cliente: <b>{p.client}</b>
            </span>

            <span>
              Status: <b>{stage}</b>
            </span>

            <span>
              Início do Projeto: <b>{p.start_date}</b>
            </span>

            <span>
              Finalização (previsão): <b>{p.finish_date_prediction}</b>
            </span>

            {p.colaborator_delivery_date ? (
              <span>
                Data do envio para análise: <b>{p.colaborator_delivery_date}</b>
              </span>
            ) : null}

            {p.status === 3 ? (
              <div className={styles.actions}>
                <button className={styles.confirm} onClick={() => aproveProject()}>
                  Aprovar Projeto
                </button>
                <button className={styles.cancel} onClick={() => handleModalOpen('recuseObservation')}>
                  Recusar Projeto
                </button>
              </div>
            ) : null}

            <button className={styles.buttonModal} onClick={() => setOpen(false)}>
              <span>Fechar</span>
            </button>
          </div>
        </div>
      </div>
    )
  }

  const bodyDeleteProject = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Deseja realmente deletar esse projeto?</h4>
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm} onClick={() => deleteProject()}>
              Confirmar
            </button>
            <button className={styles.cancel} type="button" onClick={() => setOpen(false)}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )

  const bodyEditClient = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '450px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Editar Projeto</h4>
          </div>

          <form className={styles.formEdit} onSubmit={handleSubmit(handleEdit)}>
            <label htmlFor="client_id">Cliente: *</label>
            <select required id="client_id" name="client_id" {...register('client_id')} defaultValue={project.client_id}>
              {clients.map((client) => (
                <option value={client.id}>{client.name}</option>
              ))}
            </select>

            <label htmlFor="project_type_id">Tipo de Projeto: *</label>
            <select required id="project_type_id" name="project_type_id" {...register('project_type_id')} defaultValue={project.project_type_id}>
              <option value="0">Nenhum</option>
              {projectsTypes.map((type) => (
                <option value={type.id}>{type.description}</option>
              ))}
            </select>

            <label htmlFor="area">Área (m²): *</label>
            <input name="area" required type="text" {...register('area')} defaultValue={project.area} />

            <label htmlFor="criterion">Critério de Aceitação:</label>
            <input name="criterion" type="text" {...register('criterion')} defaultValue={project.criterion} />

            <label htmlFor="local">Local:</label>
            <input name="local" type="text" {...register('local')} defaultValue={project.local} />

            <label htmlFor="start_date">Data de Início: *</label>
            <input name="start_date" required type="date" {...register('start_date')} defaultValue={project.start_date_unformated} />

            <label htmlFor="finish_date_prediction">Data de Finalização (previsão): *</label>
            <input
              required
              name="finish_date_prediction"
              type="date"
              {...register('finish_date_prediction')}
              defaultValue={project.finish_date_prediction_unformated}
            />

            <label htmlFor="responsable">Responsável: *</label>
            <select required id="responsable" name="responsable" {...register('responsable')} defaultValue={project.responsable_id}>
              {professionals.map((professional) => (
                <option value={professional.id}>{professional.name}</option>
              ))}
            </select>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

  const bodyPutRecuseObservation = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '450px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Deseja relatar alguma observação sobre esse projeto?</h4>
          </div>

          <form className={styles.formEdit} onSubmit={handleSubmit(recuseProject)}>
            <label htmlFor="leader_observation">Observação:</label>
            <textarea name="leader_observation" {...register('leader_observation')} />

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Enviar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

  return (
    <div className={styles.box}>
      <div className={styles.boxInfoConstruction}>
        <span>
          {project.project_type} / {project.project_type_category === 0 ? 'Projeto' : 'Atividade'}
        </span>
      </div>

      <div className={styles.boxInfoConstruction}>
        <span>Início: {project.start_date}</span>
      </div>

      <div className={styles.boxInfoConstruction}>
        <span>{project.status === 4 ? 'Finalizado' : 'Em aberto'}</span>
      </div>

      <div className={styles.buttons}>
        {project.status !== 4 ? <img src={iSuccess} alt="" title="Finalizar projeto" onClick={() => setModalFinishProjectOpen(true)} /> : null}
        <img src={iEye} alt="" onClick={() => setModalProjectDetailsOpen(true)} />
        <img src={iPencil} alt="" onClick={() => setModalEditProjectOpen(true)} />
        <img src={iTrash} alt="" onClick={() => setModalDeleteProjectOpen(true)} />
      </div>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        scroll="body"
        TransitionComponent={Fade}
        transitionDuration={200}
      >
        {modalBody}
      </Dialog>

      <DefaultModal handleOpen={setModalFinishProjectOpen} isOpen={modalFinishProjectOpen}>
        <ModalFinishProject handleToast={handleToast} refresh={refresh} project={project} setModalFinishProjectOpen={setModalFinishProjectOpen} />
      </DefaultModal>

      <DefaultModal handleOpen={setModalProjectDetailsOpen} isOpen={modalProjectDetailsOpen}>
        <ModalProjectDetails project={project} setModalProjectDetailsOpen={setModalProjectDetailsOpen} />
      </DefaultModal>

      <DefaultModal handleOpen={setModalEditProjectOpen} isOpen={modalEditProjectOpen}>
        <ModalEditProject project={project} refresh={refresh} setModalEditProjectOpen={setModalEditProjectOpen} handleToast={handleToast} />
      </DefaultModal>

      <DefaultModal handleOpen={setModalDeleteProjectOpen} isOpen={modalDeleteProjectOpen}>
        <ModalDeleteProject project={project} refresh={refresh} setModalDeleteProjectOpen={setModalDeleteProjectOpen} handleToast={handleToast} />
      </DefaultModal>
    </div>
  )
}

export default Project
