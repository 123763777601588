import React from 'react';
import styles from './style.module.css';

import iPencil from '../../assets/icons/pencil.svg';
import iTrash from '../../assets/icons/trash.svg';

import { Modal } from '@material-ui/core';
import { useState } from 'react';

import { useForm } from 'react-hook-form';
import { api } from '../../services/api';

import Toast from '../Toast';
import { useContext } from 'react';

import { UsersContext } from '../../contexts/UsersContext';

type UserParam = {
  user: UserType;
  handleToast: any;
};

type UserType = {
  id: number;
  name: string;
  email: string;
  password: string;
  role: number;
};

type SubmitType = {
  id: number;
  name: string;
  email: string;
  password: string;
  role: number;
};

function User({ user, handleToast }: UserParam) {
  let userRole = '';

  const { getUsers, roles } = useContext(UsersContext);

  const { register, handleSubmit } = useForm();

  const [open, setOpen] = useState(false);
  const [modalBody, setModalBody] = useState(<div></div>);

  roles.map((role) => {
    if (role.id === user.role) {
      userRole = role.description;
    }
  });

  function deleteClient() {
    api
      .delete(`/users/${user.id}`)
      .then((response) => {
        getUsers();
        setOpen(false);
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function handleEdit(data: SubmitType) {
    console.log(data);
    api
      .put(`/users/${user.id}`, data)
      .then((response) => {
        getUsers();
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleModalOpen(body: string) {
    if (body === 'edit') {
      setModalBody(bodyEditUser);
    } else if (body === 'delete') {
      setModalBody(bodyDeleteUser);
    }

    setOpen(true);
  }

  const bodyDeleteUser = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>
              Deseja realmente deletar esse usuário?
            </h4>
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm} onClick={() => deleteClient()}>
              Confirmar
            </button>
            <button
              className={styles.cancel}
              type="button"
              onClick={() => setOpen(false)}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const bodyEditUser = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Editar Usuário</h4>
          </div>

          <form className={styles.formEdit} onSubmit={handleSubmit(handleEdit)}>
            <label htmlFor="name">Nome Completo: *</label>
            <input
              name="name"
              type="text"
              {...register('name')}
              defaultValue={user.name}
            />

            <label htmlFor="email">E-mail: *</label>
            <input
              name="email"
              type="email"
              {...register('email')}
              defaultValue={user.email}
            />

            <label htmlFor="password">Senha: *</label>
            <input name="password" type="password" {...register('password')} />

            <label htmlFor="role">Tipo de Usuário</label>
            <select
              id="role"
              name="role"
              {...register('role')}
              defaultValue={user.role}
            >
              <option value="0">Nenhum</option>
              {roles.map((role) => (
                <option key={role.id} value={role.id} title={role.title}>
                  {role.description}
                </option>
              ))}
            </select>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button
                className={styles.cancel}
                type="button"
                onClick={() => setOpen(false)}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.box}>
      <div className={styles.boxInfoConstruction}>
        <span>{user.name}</span>
      </div>

      <div className={styles.boxInfoConstructionRole}>
        <span>{userRole || 'Não definido.'}</span>
      </div>

      <div className={styles.buttons}>
        <img src={iPencil} alt="" onClick={() => handleModalOpen('edit')} />
        <img src={iTrash} alt="" onClick={() => handleModalOpen('delete')} />
      </div>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>
    </div>
  );
}

export default React.memo(User);
