import { useEffect, useState } from 'react'
import ToolIcon from '../../../../../assets/icons/tool.svg'
import { api } from '../../../../../services/api'

import styles from './styles.module.css'

export default function ModalClientViewMaterials({ setModalClientViewMaterialsOpen, constructionId }) {
  const [materials, setMaterials] = useState([])

  useEffect(() => {
    listMaterials()
  }, [])

  function listMaterials() {
    api
      .get(`/constructions/materials/${constructionId}`)
      .then((response) => setMaterials(response.data))
      .catch((err) => console.log(err))
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <img src={ToolIcon} alt="icon" />
            <h4 className={styles.title}>MATERIAIS</h4>
          </div>

          {materials.map((material) => (
            <div className={styles.material}>
              <h3>{material.quantity}x</h3>
              <h4>{material.product}</h4>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
