import { useContext, useState } from 'react'
import { ToolsContext } from '../../../contexts/ToolsContext'

import styles from './style.module.css'
import Sidebar from '../../../components/Sidebar'
import links from '../../../components/configs/links-records'
import iPlus from '../../../assets/icons/plus.svg'
import TableGeneric from '../../../components/TableGeneric'
import DefaultModal from '../../../components/Modals/DefaultModal'
import ModalCreateTool from '../../../components/Modals/Tool/Create'

type Header = {
  label: string
  ref: string
}

export default function Tools() {
  const [modalCreateToolOpen, setModalCreateToolOpen] = useState(false)
  const [toast, setToast] = useState<any>()

  const { tools } = useContext(ToolsContext)

  const tableHeaders: Header[] = [
    {
      label: 'Descrição',
      ref: 'description'
    }
  ]

  function handleToast(toast) {
    setToast(toast)

    setTimeout(() => {
      setToast(null)
    }, 5000)
  }

  return (
    <div className={styles.main}>
      <Sidebar config={links} />

      {toast}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>FERRAMENTAS</h1>

          <button className={styles.button} onClick={() => setModalCreateToolOpen(true)}>
            <span>NOVA FERRAMENTA</span>
            <img src={iPlus} alt="" />
          </button>
        </div>

        <div className={styles.table}>
          <TableGeneric headers={tableHeaders} data={tools} handleToast={handleToast} componentToRender="tool" fieldToSearch="description" title="" />
        </div>

        <DefaultModal isOpen={modalCreateToolOpen} handleOpen={setModalCreateToolOpen}>
          <ModalCreateTool handleToast={handleToast} setModalCreateToolOpen={setModalCreateToolOpen} />
        </DefaultModal>
      </div>
    </div>
  )
}
