import { useContext, useEffect, useState } from 'react'
import { api } from '../../services/api'
import { Autocomplete, TextField } from '@mui/material'
import { ConstructionsContext } from '../../contexts/ConstructionsContext'
import { IStage } from '../../interfaces/Stage'

import styles from './style.module.css'
import iPlus from '../../assets/icons/plus.svg'

import Sidebar from '../../components/Sidebar'
import Toast from '../../components/Toast'
import Diary from '../../components/Diary'
import DefaultModal from '../../components/Modals/DefaultModal'
import ModalCreateDiary from '../../components/Modals/Diary/Create'

type DiariesReqType = {
  id: number
  construction_id: number
  client: string
  construction: string
  address: string
  address_number: string
  date: string
  date_unformated: string
  colaborators: string
  description: string
  status: number
  summary: string
  images: DiaryImagesType[]
  stages: IStage[]
}

type DiaryImagesType = {
  id: number
  image: string
}

type LocationType = {
  pathname: string
  state: {
    construction_id: number
  }
}

type DiariesParams = {
  location: LocationType
}

type ConstructionType = {
  id: number
  address: string
  name: string
  address_number: number
  client: string
}

type ConstructionScheduleType = {
  id: number
  stage: string
  total_worked_area: number
  quantity: number
  measure: string
}

export default function Diaries({ location }) {
  const routeParams = location.state

  const { constructions } = useContext(ConstructionsContext)

  const [modalCreateDiaryOpen, setModalCreateDiaryOpen] = useState(false)
  const [diaries, setDiaries] = useState<DiariesReqType[]>([])
  const [toast, setToast] = useState<any>()
  const [constructionSelected, setConstructionSelected] = useState(routeParams?.constructionId || 0)
  const [constructionScheduleSelected, setConstructionScheduleSelected] = useState(0)
  const [constructionSchedules, setConstructionSchedules] = useState([])

  useEffect(() => {
    if (routeParams?.constructionId) {
      getConstructionSchedules(routeParams?.constructionId)
    }
  }, [])

  function handleToast(toast) {
    setToast(toast)

    setTimeout(() => {
      setToast(null)
    }, 5000)
  }

  async function getDiariesByConstruction() {
    try {
      const { data } = await api.get(`/constructions_diaries/construction/${constructionSelected}`)
      setDiaries(data)
    } catch (err) {
      console.error('fail on load construction diaries', { err })
    }
  }

  async function getDiariesBySchedule(constructionScheduleId?: any) {
    setConstructionScheduleSelected((prevItem) => (constructionScheduleId ? constructionScheduleId : prevItem))

    try {
      const { data } = await api.get(`/constructions_diaries/${constructionSelected}/schedule/${constructionScheduleId || constructionScheduleSelected}`)
      setDiaries(data)
    } catch (err) {
      console.error('fail on load construction diaries', { err })
    }
  }

  async function getConstructionSchedules(construction_id: number) {
    const inputSchedules = document.getElementById('construction__schedules') as HTMLInputElement
    inputSchedules.focus()

    console.log(construction_id)

    if (!construction_id) return

    api
      .get(`/constructions_schedules/${construction_id}`)
      .then((response) => {
        console.log(response.data)
        setConstructionSchedules([...response.data?.active, ...response.data?.waiting, ...response.data?.finished])
      })
      .catch((err) => handleToast(<Toast category="error" message="Não foi possível listar os cronogramas da obra selecionada, tente novamente." />))
  }

  async function handleFilter() {
    if (constructionScheduleSelected) {
      await getDiariesBySchedule()
    } else {
      getDiariesByConstruction()
    }
  }

  return (
    <div className={styles.main}>
      <Sidebar />

      {toast}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>Diários de Obras</h1>

          <button onClick={() => setModalCreateDiaryOpen(true)}>
            <span>Novo diário</span>
            <img src={iPlus} alt="" />
          </button>
        </div>

        <div className={styles.form__construction}>
          <div className={styles.row}>
            <Autocomplete
              fullWidth
              value={undefined}
              onChange={(event, newValue: ConstructionType) => {
                if (newValue) {
                  setConstructionSelected(Number(newValue.id))
                  getConstructionSchedules(Number(newValue.id))
                } else {
                  setConstructionSelected(null)
                }
              }}
              defaultValue={constructionSelected ? constructions.find((construction) => construction.id === constructionSelected) : null}
              options={constructions}
              getOptionLabel={(option) => `${option.name} - ${option.address}, ${option.address_number}`}
              renderInput={(params) => <TextField {...params} label="Obra" required />}
            />

            <Autocomplete
              fullWidth
              value={undefined}
              id="construction__schedules"
              onChange={(event, newValue: ConstructionScheduleType) => {
                newValue ? setConstructionScheduleSelected(newValue.id) : setConstructionScheduleSelected(null)
              }}
              options={constructionSchedules}
              getOptionLabel={(option) => `${option.stage}`}
              renderInput={(params) => <TextField {...params} label="Etapa" />}
            />
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm} onClick={() => handleFilter()}>
              <span>FILTRAR</span>
            </button>
          </div>
        </div>

        <br />

        {constructionSelected ? (
          diaries.length ? (
            <div className={styles.table}>
              {diaries.map((diary) => (
                <Diary key={diary.id} diary={diary} handleToast={handleToast} getDiaries={handleFilter} constructionScheduleId={constructionScheduleSelected} />
              ))}
            </div>
          ) : (
            <span>Não encontramos diários cadastrados nessa obra.</span>
          )
        ) : (
          <span>Para listar os diários de obra de uma obra selecione-a.</span>
        )}

        <DefaultModal isOpen={modalCreateDiaryOpen} handleOpen={setModalCreateDiaryOpen}>
          <ModalCreateDiary handleToast={handleToast} setModalCreateDiaryOpen={setModalCreateDiaryOpen} />
        </DefaultModal>
      </div>
    </div>
  )
}
