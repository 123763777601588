import { createContext, useEffect } from 'react'
import { useState } from 'react'
import { api } from '../services/api'

type ProductsContextType = {
  getProducts: () => Promise<void>
  products: ProductType[]
}

export type ProductType = {
  id: number
  description: string
}

export const ProductsContext = createContext({} as ProductsContextType)

export function ProductProvider({ children }) {
  const [products, setProducts] = useState<ProductType[]>([])

  useEffect(() => {
    if (products.length === 0) getProducts()
  }, [])

  async function getProducts() {
    await api
      .get('/products')
      .then((response) => {
        setProducts(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return <ProductsContext.Provider value={{ products, getProducts }}>{children}</ProductsContext.Provider>
}
