import { useContext, useState } from 'react';
import { MonetaryContext } from '../../../../../contexts/MonetaryContext';
import { Autocomplete, MenuItem, Select, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { mdiCurrencyUsd } from '@mdi/js';
import { api } from '../../../../../services/api';
import { ptBR } from 'date-fns/locale';

import Icon from '@mdi/react';
import Toast from '../../../../Toast';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import styles from './style.module.css';

type SubmitType = {
  is_voucher: number;
  payday: string;
  value: string;
  money_origin_id: number;
  description?: string;
};

type InputType = {
  value: string;
  name: string;
};

type ProductType = {
  id: number;
  description: string;
};

export default function ModalEditColaboratorExpense({
  expense,
  setModalEditColaboratorExpenseOpen,
  getColaboratorDetails,
  handleToast,
}) {
  const [formValues, setFormValues] = useState({
    is_voucher: expense.is_voucher,
    money_origin_id: expense.money_origin_id,
  } as SubmitType);

  const { moneyOrigins } = useContext(MonetaryContext);

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value,
    }));
  }

  function handleEditExpense(e) {
    e.preventDefault();

    if (formValues.payday) {
      const date = new Date(formValues.payday);

      const payday = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

      formValues.payday = payday;
    }

    api
      .put(`/employees/expenses/${expense.id}`, formValues)
      .then((response) => {
        getColaboratorDetails();
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
        setModalEditColaboratorExpenseOpen(false);
      })
      .catch((err) => {
        handleToast(
          <Toast category="error" message={err.response.data.message} />
        );
      });
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal} style={{ maxWidth: '400px' }}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiCurrencyUsd} size={1.5} />
            <h4 className={styles.title}>EDITAR DESPESA</h4>
          </div>

          <form
            className={styles.form__create}
            onSubmit={(e) => handleEditExpense(e)}
          >
            <TextField
              defaultValue={expense.value}
              label="Valor"
              type="text"
              autoComplete="off"
              required
              onChange={(e) =>
                handleInputChange({
                  name: 'value',
                  value: e.target.value,
                })
              }
            />

            <Select
              defaultValue={expense.is_voucher}
              value={formValues.is_voucher}
              label="Tipo:"
              onChange={(e) =>
                handleInputChange({
                  name: 'is_voucher',
                  value: String(e.target.value),
                })
              }
            >
              <MenuItem value={0}>Despesa Adicional</MenuItem>
              <MenuItem value={1}>Vale</MenuItem>
            </Select>

            {formValues.is_voucher == 0 && (
              <TextField
                defaultValue={expense.description}
                label="Observação"
                onChange={(e) =>
                  handleInputChange({
                    name: 'description',
                    value: e.target.value,
                  })
                }
              />
            )}

            <Autocomplete
              defaultValue={
                moneyOrigins.filter(
                  (origin) => origin.id == formValues.money_origin_id
                )[0]
              }
              onChange={(event, newValue: ProductType) => {
                handleInputChange({
                  name: 'money_origin_id',
                  value: newValue ? String(newValue.id) : undefined,
                });
              }}
              options={moneyOrigins}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => (
                <TextField {...params} label="Origem" required />
              )}
            />

            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Data de Pagamento"
                value={expense.payday_unformated}
                onChange={(newValue) => {
                  handleInputChange({
                    name: 'payday',
                    value: newValue,
                  });
                }}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button
                className={styles.cancel}
                type="button"
                onClick={() => {
                  setModalEditColaboratorExpenseOpen(false);
                }}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
