import { BaseSyntheticEvent, useContext, useEffect, useState } from 'react'
import { Autocomplete, TextField, Tooltip } from '@mui/material'
import { api } from '../../../../services/api'
import { mdiImagePlus, mdiPackageVariantClosed } from '@mdi/js'
import { ConstructionsContext, ConstructionType } from '../../../../contexts/ConstructionsContext'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { ptBR } from 'date-fns/locale'
import { PurchaseRequestFile } from '../Details'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

import styles from './style.module.css'
import DefaultModal from '../../DefaultModal'
import ModalAddFile from '../AddFile'
import ModalConfirmActionFile from './ConfirmAction'

type InputType = {
  value: string | File
  name: string
}

type ListType = {
  id: number
  description: string
}

type AddedFile = {
  description: string
  file: File
}

interface SubmitType {
  list_id: number
  description: string
  observation?: string
  payment_date?: string
  send_to_client_date?: string
  arrival_in_construction_date?: string
}

export default function ModalEditPurchaseRequest({ purchase, setPurchaseRequestEditModalOpen, handleToast }) {
  const [modalAddFileOpen, setModalAddFileOpen] = useState(false)
  const [modalConfirmActionOpen, setModalConfirmActionOpen] = useState(false)

  const [fileIdToRemove, setFileIdToRemove] = useState<number>(null)

  const [formValues, setFormValues] = useState({} as SubmitType)

  const { constructions } = useContext(ConstructionsContext)

  const [constructionLists, setConstructionLists] = useState([])

  const [purchaseFiles, setPurchaseFiles] = useState<PurchaseRequestFile[]>([])
  const [newPurchaseFiles, setNewPurchaseFiles] = useState<AddedFile[]>([])

  useEffect(() => {
    loadPurchaseRequestFiles()
    loadConstructionLists(purchase.construction_id)
  }, [])

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function handleEdit(e: BaseSyntheticEvent) {
    e.preventDefault()

    console.log(formValues)

    api
      .put(`/purchases/requests/${purchase.id}`, formValues)
      .then((response) => {
        handleToast(<Toast category="success" message={response.data.message} />)
        saveFiles(purchase.id)
        setPurchaseRequestEditModalOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  async function saveFiles(purchaseRequestId: number) {
    for (const file in newPurchaseFiles) {
      const formData = new FormData()
      formData.append('file', newPurchaseFiles[file].file)
      formData.append('description', newPurchaseFiles[file].description)
      formData.append('purchase_request_id', purchaseRequestId.toString())

      try {
        await api.post(`/purchases/requests/files`, formData)
      } catch (err) {
        console.error({ message: 'fail on save purchase request image', err })
      }
    }
  }

  async function loadPurchaseRequestFiles() {
    try {
      const { data } = await api.get(`/purchases/requests/files/${purchase.id}`)
      setPurchaseFiles(data)
    } catch (err) {
      console.error('fail on load purchase request files', { err })
    }
  }

  function loadConstructionLists(construction_id: number) {
    api
      .get(`/lists/construction/${construction_id}`)
      .then((response) => {
        setConstructionLists(response.data)
      })
      .catch((err) => {
        console.error({ message: 'fail on load construction lists', err })
      })
  }

  function addFile(description: string, file: File) {
    setNewPurchaseFiles((prev) => [...prev, { description, file }])
    setModalAddFileOpen(false)
  }

  function spliceFile(description: string) {
    setNewPurchaseFiles((prev) => [...prev.filter((f) => f.description !== description)])
  }

  async function deleteFile() {
    try {
      await api.delete(`/purchases/requests/files/${fileIdToRemove}`)
      setPurchaseFiles((prev) => [...prev.filter((f) => f.id !== fileIdToRemove)])
      setModalConfirmActionOpen(false)
    } catch (err) {
      console.error({ message: 'fail on delete file', err })
    }
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiPackageVariantClosed} size={1.5} />
            <h4 className={styles.title}>EDITAR PEDIDO DE COMPRA</h4>
          </div>

          <form className={styles.form__create} onSubmit={(e) => handleEdit(e)}>
            <TextField
              label="Descrição"
              type="text"
              required
              autoComplete="off"
              defaultValue={purchase.description}
              onChange={(e) =>
                handleInputChange({
                  name: 'description',
                  value: e.target.value
                })
              }
            />
            <Autocomplete
              value={undefined}
              defaultValue={constructions.find((c) => c.id === purchase.construction_id)}
              onChange={(event, newValue: ConstructionType) => {
                loadConstructionLists(newValue.id)
              }}
              options={constructions}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Obra" required />}
            />

            {constructionLists.length > 0 ? (
              <Autocomplete
                value={undefined}
                defaultValue={constructionLists.find((cl) => cl.id === purchase.list_id)}
                onChange={(event, newValue: ListType) => {
                  handleInputChange({
                    name: 'list_id',
                    value: String(newValue.id)
                  })
                }}
                options={constructionLists}
                getOptionLabel={(option) => option.description}
                renderInput={(params) => <TextField {...params} label="Lista" required />}
              />
            ) : null}

            {purchase.list_id ? (
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  label="Enviado para o cliente"
                  mask="__/__/____"
                  value={
                    formValues.send_to_client_date
                      ? formValues.send_to_client_date
                      : purchase.send_to_client_date
                      ? `${purchase.send_to_client_date?.split('/').reverse().join('-')}, 00:00:00`
                      : null
                  }
                  onChange={(newValue) => {
                    handleInputChange({
                      name: 'send_to_client_date',
                      value: newValue ? `${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00` : null
                    })
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            ) : null}

            {purchase.send_to_client_date ? (
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  label="Data de pagamento"
                  mask="__/__/____"
                  value={
                    formValues.payment_date
                      ? formValues.payment_date
                      : purchase.payment_date
                      ? `${purchase.payment_date?.split('/').reverse().join('-')}, 00:00:00`
                      : null
                  }
                  onChange={(newValue) => {
                    console.log(newValue)
                    handleInputChange({
                      name: 'payment_date',
                      value: newValue ? `${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00` : null
                    })
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            ) : null}

            {purchase.payment_date ? (
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  label="Chegou na obra"
                  mask="__/__/____"
                  value={
                    formValues.arrival_in_construction_date
                      ? formValues.arrival_in_construction_date
                      : purchase.arrival_in_construction_date
                      ? `${purchase.arrival_in_construction_date?.split('/').reverse().join('-')}, 00:00:00`
                      : null
                  }
                  onChange={(newValue) => {
                    handleInputChange({
                      name: 'arrival_in_construction_date',
                      value: newValue ? `${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00` : null
                    })
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            ) : null}

            <TextField
              label="Valor"
              defaultValue={purchase.value}
              type="text"
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'value',
                  value: e.target.value
                })
              }
            />

            <TextField
              label="PIX/Dados Bancários"
              defaultValue={purchase.bank}
              type="text"
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'bank',
                  value: e.target.value
                })
              }
            />

            <TextField
              label="Observação"
              defaultValue={purchase.observation}
              type="text"
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'observation',
                  value: e.target.value
                })
              }
            />

            {purchaseFiles.length || newPurchaseFiles.length ? (
              <div className={styles.files}>
                {purchaseFiles.length
                  ? purchaseFiles.map((file) => (
                      <Tooltip title="Clique para remover" placement="top">
                        <div
                          className={styles.new__file}
                          onClick={() => {
                            setFileIdToRemove(file.id)
                            setModalConfirmActionOpen(true)
                          }}
                        >
                          <Icon path={mdiImagePlus} size={1.2} color="#000" /> <h3>{file.description}</h3>
                        </div>
                      </Tooltip>
                    ))
                  : null}

                {newPurchaseFiles.length
                  ? newPurchaseFiles.map((file) => (
                      <Tooltip title="Clique para remover" placement="top">
                        <div className={styles.new__file} onClick={() => spliceFile(file.description)}>
                          <Icon path={mdiImagePlus} size={1.2} color="#000" /> <h3>{file.description}</h3>
                        </div>
                      </Tooltip>
                    ))
                  : null}
              </div>
            ) : null}

            <div className={styles.add__file__button} onClick={() => setModalAddFileOpen(true)}>
              <Icon path={mdiImagePlus} size={1.2} color="#FFF" />
            </div>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setPurchaseRequestEditModalOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>

      <DefaultModal handleOpen={setModalConfirmActionOpen} isOpen={modalConfirmActionOpen}>
        <ModalConfirmActionFile setModalConfirmActionOpen={setModalConfirmActionOpen} removeFile={deleteFile} />
      </DefaultModal>

      <DefaultModal handleOpen={setModalAddFileOpen} isOpen={modalAddFileOpen}>
        <ModalAddFile setModalAddFileOpen={setModalAddFileOpen} addFile={addFile} />
      </DefaultModal>
    </div>
  )
}
