import { useEffect, useState } from 'react'

import { AccountBalanceWallet, AttachMoney, MoneyOff } from '@mui/icons-material'
import { api } from '../../services/api'

import Sidebar from '../../components/Sidebar'
import Table from '../../components/Table'
import Toast from '../../components/Toast'

import Cookies from 'js-cookie'

import arcco from '../../assets/grupo-arcco.png'

import styles from './style.module.css'

interface ExpensesSummary {
  total: number
  expenses: RowType[]
}

interface IncomingsSummary {
  total: number
  incomings: RowType[]
}

interface LastMonthBalance {
  total: number
}

interface RowType {
  type: string
  value: string
  due_date: string
  payday?: string
  status: string
}

export default function Dashboard() {
  let role = Number(Cookies.get('role'))

  const [toast, setToast] = useState<any>()

  const [expensesSummary, setExpensesSummary] = useState<ExpensesSummary>({} as ExpensesSummary)
  const [incomingsSummary, setIncomingsSummary] = useState<IncomingsSummary>({} as IncomingsSummary)

  const [lastMonthExpensesSummary, setLastMonthExpensesSummary] = useState<LastMonthBalance>({} as LastMonthBalance)
  const [lastMonthIncomingsSummary, setLastMonthIncomingsSummary] = useState<LastMonthBalance>({} as LastMonthBalance)

  const [showIncomings, setShowIncomings] = useState(false)
  const [showExpenses, setShowExpenses] = useState(false)
  const [showBalance, setShowBalance] = useState(false)
  const [showLastMonthBalance, setShowLastMonthBalance] = useState(false)

  useEffect(() => {
    getExpensesSummary()
    getIncomingsSummary()

    getExpensesSummaryFromLastMonth()
    getIncomingsSummaryFromLastMonth()
  }, [])

  function handleToast(toast) {
    setToast(toast)

    setTimeout(() => {
      setToast(null)
    }, 5000)
  }

  function getExpensesSummary() {
    api
      .get('/expenses/month/summary')
      .then((response) => {
        setExpensesSummary(response.data)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  function getIncomingsSummary() {
    api
      .get('/incomings/month/summary')
      .then((response) => {
        setIncomingsSummary(response.data)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  function getExpensesSummaryFromLastMonth() {
    api
      .get('/expenses/last_month/summary')
      .then((response) => {
        setLastMonthExpensesSummary(response.data)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  function getIncomingsSummaryFromLastMonth() {
    api
      .get('/incomings/last_month/summary')
      .then((response) => {
        setLastMonthIncomingsSummary(response.data)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  const date = new Date()
  const today = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`

  const headers = ['Tipo', 'Preço', 'Vencimento', 'Pagamento', 'Parcela', 'Status']

  return (
    <>
      <Sidebar config={null} />

      {toast}

      {role == 1 || role == 2 ? (
        <div className={styles.home}>
          <h1>
            Olá, seja bem vindo(a). <br />
            <br />
            Hoje é dia {today}
          </h1>
          <div className={styles.cards}>
            <div className={styles.card} title="RECEITAS" onClick={() => setShowIncomings((prevValue) => !prevValue)}>
              <div>
                {showIncomings ? (
                  <div className={styles.numbers}>
                    {incomingsSummary.total
                      ? incomingsSummary.total.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })
                      : 'R$ 0,00'}
                  </div>
                ) : (
                  <div className={styles.hide__values}></div>
                )}
                <div className={styles.card__name}>Receitas do Mês</div>
              </div>
              <div className={styles.icon}>
                <AttachMoney className={styles.ic} />
              </div>
            </div>

            <div className={styles.card} title="DESPESAS + DESPESAS DE COLABORADORES" onClick={() => setShowExpenses((prevValue) => !prevValue)}>
              <div>
                {showExpenses ? (
                  <div className={styles.numbers}>
                    {expensesSummary.total
                      ? (expensesSummary.total * -1).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })
                      : 'R$ 0,00'}
                  </div>
                ) : (
                  <div className={styles.hide__values}></div>
                )}
                <div className={styles.card__name}>Despesas do Mês</div>
              </div>
              <div className={styles.icon}>
                <MoneyOff className={styles.ic} />
              </div>
            </div>

            <div className={styles.card} onClick={() => setShowBalance((prevValue) => !prevValue)}>
              <div>
                {showBalance ? (
                  <div className={styles.numbers}>
                    {incomingsSummary && expensesSummary
                      ? (incomingsSummary.total - expensesSummary.total).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })
                      : 0}
                  </div>
                ) : (
                  <div className={styles.hide__values}></div>
                )}
                <div className={styles.card__name}>Balanço do Mês Atual</div>
              </div>
              <div className={styles.icon}>
                <AccountBalanceWallet className={styles.ic} />
              </div>
            </div>

            <div className={styles.card} onClick={() => setShowLastMonthBalance((prevValue) => !prevValue)}>
              <div>
                {showLastMonthBalance ? (
                  <div className={styles.numbers}>
                    {lastMonthExpensesSummary && lastMonthIncomingsSummary
                      ? (lastMonthIncomingsSummary.total - lastMonthExpensesSummary.total).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })
                      : 0}
                  </div>
                ) : (
                  <div className={styles.hide__values}></div>
                )}
                <div className={styles.card__name}>Balanço do Mês Anterior</div>
              </div>
              <div className={styles.icon}>
                <AccountBalanceWallet className={styles.ic} />
              </div>
            </div>
          </div>

          <div className={styles.details}>
            {expensesSummary.expenses && expensesSummary.expenses.length ? (
              <Table data={expensesSummary.expenses} headers={headers} title="Despesas do Mês" />
            ) : null}
            {incomingsSummary.incomings && incomingsSummary.incomings.length ? (
              <Table data={incomingsSummary.incomings} headers={headers} title="Receitas do Mês" />
            ) : null}
          </div>
        </div>
      ) : (
        <div className={styles.no__admin}>
          <img src={arcco} alt="" />

          <h1>Quando nós nos esforçamos para nos tornar melhor do que somos, tudo à nossa volta se torna melhor também. – Paulo Coelho;</h1>
        </div>
      )}
    </>
  )
}
