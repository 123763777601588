import { useEffect, useState } from 'react';
import styles from './style.module.css';
import Sidebar from '../../components/Sidebar';
import Message from '../../components/Message';
import { api } from '../../services/api';

type MessagesReqType = {
  id: number;
  name: string;
  email: string;
  message: string;
  created_at: string;
};

export default function Diaries() {
  const [messages, setMessages] = useState<MessagesReqType[]>([]);

  const [toast, setToast] = useState<any>();

  useEffect(() => {
    getMessages();
  }, []);

  function getMessages() {
    api
      .get('/contacts')
      .then((response) => {
        setMessages(response.data);
      })
      .then((err) => {
        console.log(err);
      });
  }

  function handleToast(toast) {
    setToast(toast);

    setTimeout(() => {
      setToast(null);
    }, 5000);
  }

  return (
    <div className={styles.main}>
      <Sidebar />

      {toast}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>Mensagens</h1>
        </div>

        {messages.map((message) => (
          <Message message={message} handleToast={handleToast} />
        ))}
      </div>
    </div>
  );
}
