import { BrowserRouter, Route, Router, Switch } from 'react-router-dom'

import { createBrowserHistory } from 'history'

import Cookies from 'js-cookie'

import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import Products from './pages/Records/Products'
import Tools from './pages/Records/Tools'
import Providers from './pages/Records/Providers'
import Clients from './pages/Records/Clients'
import Constructions from './pages/Records/Constructions'
import Colaborators from './pages/Records/Colaborators'
import ColaboratorsDiaries from './pages/Records/ColaboratorsDiaries'
import Diaries from './pages/Diaries'
import Configs from './pages/Configs'
import IncomingTypes from './pages/Configs/IncomingTypes'
import Expenses from './pages/Expenses'
import Incomings from './pages/Incomings'
import ExpenseTypes from './pages/Configs/ExpenseTypes'
import ProjectTypes from './pages/Configs/ProjectTypes'
import Users from './pages/Users'
import MoneyOrigins from './pages/Configs/MoneyOrigins'
import Stages from './pages/Configs/Stages'
import ColaboratorTypes from './pages/Configs/ColaboratorTypes'
import Projects from './pages/Records/Projects'
import ColaboratorsAbsents from './pages/Records/ColaboratorsAbsents'
import Lists from './pages/Records/Lists'
import PurchasesRequests from './pages/Records/PurchasesRequests'
import Measures from './pages/Configs/Measures'
import Messages from './pages/Messages'
import ProjectsColaborator from './pages/Records/ProjectsColaborator'
import ClientDashboard from './pages/ClientDashboard'
import ClientInvoiceRequestDashboard from './pages/ClientInvoiceRequestDashboard'
import Invoices from './pages/Invoices'

import { ClientProvider } from './contexts/ClientsContext'
import { ProductProvider } from './contexts/ProductsContext'
import { ToolProvider } from './contexts/ToolsContext'
import { ProviderProvider } from './contexts/ProvidersContext'
import { ConstructionProvider } from './contexts/ConstructionsContext'
import { ColaboratorProvider } from './contexts/ColaboratorsContext'
import { MonetaryProvider } from './contexts/MonetaryContext'
import { ProjectsProvider } from './contexts/ProjectsContext'
import { UserProvider } from './contexts/UsersContext'
import { StageProvider } from './contexts/StageContext'
import { MeasureProvider } from './contexts/MeasureContext'

const history = createBrowserHistory()

export default function Routes() {
  const role = Number(Cookies.get('role'))

  return (
    <BrowserRouter>
      <Switch>
        <Router history={history}>
          <UserProvider>
            <Route exact path="/" component={Login} />
            <Route path="/dashboard" component={Dashboard} />

            <Route path="/configs" component={Configs} />

            <ProductProvider>
              <Route path="/records/products" component={Products} />
            </ProductProvider>

            <ToolProvider>
              <Route path="/records/tools" component={Tools} />
            </ToolProvider>

            <ConstructionProvider>
              <ColaboratorProvider>
                <MonetaryProvider>
                  <Route path="/records/employees" component={Colaborators} />
                  <Route path="/records/diaries/colaborators" component={ColaboratorsDiaries} />
                </MonetaryProvider>
                <Route path="/records/absents/colaborators" component={ColaboratorsAbsents} />
                <Route path="/types/colaborators" component={ColaboratorTypes} />

                <Route path="/diaries" component={Diaries} />
              </ColaboratorProvider>

              <ClientProvider>
                <ColaboratorProvider>
                  <ProductProvider>
                    <ToolProvider>
                      <MeasureProvider>
                        <StageProvider>
                          <Route path="/stages" component={Stages} />
                          <Route path="/records/constructions" component={Constructions} />
                        </StageProvider>
                        <Route path="/measures" component={Measures} />

                        <Route path="/records/lists" component={Lists} />

                        <ProjectsProvider>
                          <Route path="/types/projects" component={ProjectTypes} />
                          <Route path="/records/projects" component={role === 6 || role === 4 ? ProjectsColaborator : Projects} />
                        </ProjectsProvider>
                      </MeasureProvider>
                    </ToolProvider>
                  </ProductProvider>
                </ColaboratorProvider>
                <Route path="/records/clients" component={Clients} />

                <ProviderProvider>
                  <Route path="/records/providers" component={Providers} />
                  <MonetaryProvider>
                    <ColaboratorProvider>
                      <Route path="/expenses" component={Expenses} />
                      <Route path="/incomings" component={Incomings} />
                    </ColaboratorProvider>
                  </MonetaryProvider>
                </ProviderProvider>

                <Route path="/records/purchases" component={PurchasesRequests} />
              </ClientProvider>
            </ConstructionProvider>

            <MonetaryProvider>
              <Route path="/types/incomings" component={IncomingTypes} />
              <Route path="/types/expenses" component={ExpenseTypes} />

              <Route path="/monetary/origins" component={MoneyOrigins} />
            </MonetaryProvider>

            <Route path="/users" component={Users} />
          </UserProvider>

          <Route path="/messages" component={Messages} />

          <Route path="/client" component={ClientDashboard} />
          <Route path="/invoices" component={Invoices} />
          <Route path="/invoices/request" component={ClientInvoiceRequestDashboard} />
        </Router>
      </Switch>
    </BrowserRouter>
  )
}
