import styles from './style.module.css';

import Toast from '../../Toast';

import iPencil from '../../../assets/icons/pencil.svg';
import iTrash from '../../../assets/icons/trash.svg';

import { api } from '../../../services/api';
import { useForm } from 'react-hook-form';
import { useContext, useState } from 'react';
import { ToolsContext } from '../../../contexts/ToolsContext';
import { Dialog, Fade } from '@material-ui/core';

type ToolType = {
  id: number;
  product: string;
  quantity: number;
  tool_id: number;
};

type ToolParams = {
  tool: ToolType;
  getConstructionTools: any;
  setToast: any;
};

export default function Tool({
  tool,
  getConstructionTools,
  setToast,
}: ToolParams) {
  const [open, setOpen] = useState(false);

  const { register, handleSubmit, reset } = useForm();

  const { tools } = useContext(ToolsContext);

  function deleteProduct() {
    api
      .delete(`/constructions/tools/${tool.id}`)
      .then((response) => {
        getConstructionTools();
        setToast(<Toast category="success" message={response.data.message} />);
        setOpen(false);

        setTimeout(() => {
          setToast(<div></div>);
        }, 5000);
      })
      .catch((err) => {
        setToast(<Toast category="error" message={err.message} />);

        setTimeout(() => {
          setToast(<div></div>);
        }, 5000);
      });
  }

  function handleEdit(data) {
    api
      .put(`/constructions/tools/${tool.id}`, data)
      .then((response) => {
        getConstructionTools();
        setOpen(false);
        setToast(<Toast category="success" message={response.data.message} />);
      })
      .catch((err) => {
        setToast(
          <Toast category="error" message="Erro ao atualizar o produto" />
        );
      });
  }

  const bodyEditTool = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Editar Ferramenta da Obra</h4>
          </div>

          <form className={styles.formEdit} onSubmit={handleSubmit(handleEdit)}>
            <label htmlFor="quantity">Quantidade: *</label>
            <input
              defaultValue={tool.quantity}
              step="0.01"
              name="quantity"
              type="number"
              {...register('quantity')}
            />

            <label htmlFor="date">Ferramenta: *</label>
            <select
              defaultValue={tool.tool_id}
              name="tool_id"
              required
              {...register('tool_id')}
            >
              {tools.map((tool) => (
                <option value={tool.id}>{tool.description}</option>
              ))}
            </select>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button
                className={styles.cancel}
                type="button"
                onClick={() => setOpen(false)}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  const bodyDeleteTool = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>
              Deseja realmente remover essa ferramenta da obra?
            </h4>
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm} onClick={() => deleteProduct()}>
              Confirmar
            </button>
            <button
              className={styles.cancel}
              type="button"
              onClick={() => setOpen(false)}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  async function handleModalOpen(body: string) {
    if (body === 'delete') {
      setModalBody(bodyDeleteTool);
    } else if (body === 'edit') {
      setModalBody(bodyEditTool);
    }

    setOpen(true);
  }

  const [modalBody, setModalBody] = useState<any>(<div></div>);

  return (
    <div className={styles.componentSession}>
      <div className={styles.modalContent}>
        <h4>
          {tool.quantity} - {tool.product}
        </h4>

        <div className={styles.buttons}>
          <img src={iPencil} alt="" onClick={() => handleModalOpen('edit')} />
          <img src={iTrash} alt="" onClick={() => handleModalOpen('delete')} />
        </div>

        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          scroll="body"
          TransitionComponent={Fade}
          transitionDuration={200}
        >
          {modalBody}
        </Dialog>
      </div>
    </div>
  );
}
