import styles from './style.module.css'

import { useContext, useState } from 'react'

import iPlus from '../../assets/icons/plus.svg'
import iPencil from '../../assets/icons/pencil.svg'
import iLess from '../../assets/icons/less.svg'
import iRefresh from '../../assets/icons/refresh.svg'

import { api } from '../../services/api'
import Project from '../Project'
import { Dialog, Fade } from '@material-ui/core'
import Toast from '../Toast'
import { useForm } from 'react-hook-form'
import { ClientsContext } from '../../contexts/ClientsContext'

import PDFClientProjectsGenerator from '../PDFClientProjectsGenerator'
import Icon from '@mdi/react'
import { mdiArchiveArrowDown, mdiArchiveArrowUp } from '@mdi/js'

type ScheduleParams = {
  client: ClientType
  refresh: () => void
  handleToast: any
}

type ClientType = {
  id: number
  name: string
  projects_start_date: string
  projects_finish_date: string
  unformated_projects_start_date: string
  unformated_projects_finish_date: string
  folder_created: number
  folder_archived: number
}

type ClientProjectsType = {
  id: number
  client: string
  client_id: number
  project_type: string
  project_type_id: number
  project_type_category: number
  value: number
  area: string
  criterion: string
  local: string
  start_date: string
  start_date_unformated: number
  finish_date: string
  finish_date_prediction: string
  finish_date_prediction_unformated: string
  colaborator_delivery_date: string
  duration: number
  responsable: string
  responsable_id: number
  status: number | string
}

interface EditDatesType {
  projects_start_date: string
  projects_finish_date: string
}

export default function ProjectClientFolder({ refresh, client, handleToast }: ScheduleParams) {
  const { getClients } = useContext(ClientsContext)

  const [detailsOpened, setDetailsOpened] = useState(false)
  const [editDatesOpen, setEditDatesOpen] = useState(false)
  const [clientProjects, setClientProjects] = useState<ClientProjectsType[]>([])

  const { register, handleSubmit, reset } = useForm()

  const [toast, setToast] = useState<any>()

  function generateClientProjectsPDF() {
    clientProjects.map((project) => {
      switch (project.status) {
        case 1:
          project.status = 'Em aberto'
          break
        case 2:
          project.status = 'Em andamento'
          break
        case 3:
          project.status = 'Aguardando análise'
          break
        case 4:
          project.status = 'Finalizado'
          break
        default:
          project.status = 'Indefinido'
      }
    })

    PDFClientProjectsGenerator(clientProjects, client.name)
  }

  function getClientProjects() {
    api
      .get(`/projects/client/${client.id}`)
      .then((response) => {
        setClientProjects(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function openScheduleDetails() {
    getClientProjects()
    setDetailsOpened(!detailsOpened)
  }

  function editDates(data: EditDatesType) {
    api
      .put(`/clients/edit/dates/${client.id}`, data)
      .then((response) => {
        getClients()
        handleToast(<Toast category="success" message={response.data.message} />)
        setEditDatesOpen(false)
        reset()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function archiveFolder() {
    api
      .put(`/projects/folder/archive/${client.id}`)
      .then((response) => {
        refresh()
        handleToast(<Toast category="success" message={response.data.message} />)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function unarchiveFolder() {
    api
      .put(`/projects/folder/unarchive/${client.id}`)
      .then((response) => {
        refresh()
        handleToast(<Toast category="success" message={response.data.message} />)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const bodyEditDates = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '450px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Editar Datas</h4>
          </div>

          <form className={styles.formEdit} onSubmit={handleSubmit(editDates)}>
            <div className={styles.inputBlock}>
              <label htmlFor="projects_start_date">Data de Início: *</label>
              <input
                name="projects_start_date"
                required
                type="date"
                {...register('projects_start_date')}
                defaultValue={client.unformated_projects_start_date}
              />
            </div>

            <div className={styles.inputBlock}>
              <label htmlFor="projects_finish_date">Data de Finalização: *</label>
              <input
                name="projects_finish_date"
                required
                type="date"
                {...register('projects_finish_date')}
                defaultValue={client.unformated_projects_finish_date}
              />
            </div>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setEditDatesOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

  return (
    <div className={styles.modalSession}>
      <div className={styles.modalHeader}>
        <h4>{client.name}</h4>

        {detailsOpened ? (
          <button
            className={styles.buttonModal}
            onClick={() => generateClientProjectsPDF()}
            style={{
              background: 'rgb(4, 41, 79)'
            }}
          >
            <span>Exportar em PDF</span>
          </button>
        ) : null}

        <div className={styles.icons}>
          <img src={iRefresh} alt="plus-icon" onClick={() => getClientProjects()} />

          {detailsOpened ? (
            <div onClick={() => openScheduleDetails()}>
              <img src={iLess} alt="plus-icon" />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              onClick={() => openScheduleDetails()}
            >
              <img src={iPlus} alt="plus-icon" />
            </div>
          )}

          {client.folder_archived ? (
            <div onClick={() => unarchiveFolder()}>
              <Icon path={mdiArchiveArrowUp} size={1} title="Desarquivar" />
            </div>
          ) : (
            <div onClick={() => archiveFolder()}>
              <Icon path={mdiArchiveArrowDown} size={1} title="Arquivar" />
            </div>
          )}
        </div>
      </div>

      <div className={styles.modalContent} style={{ display: detailsOpened ? 'block' : 'none' }}>
        {clientProjects.length ? (
          clientProjects.map((project) => (
            <Project key={project.id} refresh={getClientProjects} project={project} handleToast={handleToast} getClientProjects={getClientProjects} />
          ))
        ) : (
          <span>Esse cliente ainda não possui projetos cadastrados.</span>
        )}
      </div>

      {toast}

      <Dialog
        open={editDatesOpen}
        onClose={() => setEditDatesOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        scroll="body"
        TransitionComponent={Fade}
        transitionDuration={200}
      >
        {bodyEditDates}
      </Dialog>
    </div>
  )
}
