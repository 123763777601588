import { useState } from 'react'

import DefaultModal from '../../../DefaultModal'
import ModalEditListProduct from './Edit'

import iPencil from '../../../../../assets/icons/pencil.svg'
import iTrash from '../../../../../assets/icons/trash.svg'

import styles from './styles.module.css'
import ModalRemoveProductFromList from './Delete'

export default function ListProduct({ product, index, refresh }) {
  const [modalEditListProductOpen, setModalEditListProductOpen] = useState(false)
  const [modalRemoveProductFromListOpen, setModalRemoveProductFromListOpen] = useState(false)

  return (
    <div className={styles.product}>
      <div className={styles.text}>
        <span>{index + 1}.</span>
        <h4>
          {product.description} ({product.quantity} {product.measure})
        </h4>
      </div>

      <div className={styles.actions}>
        <img src={iPencil} alt="" onClick={() => setModalEditListProductOpen(true)} />
        <img src={iTrash} alt="" onClick={() => setModalRemoveProductFromListOpen(true)} />
      </div>

      <DefaultModal handleOpen={setModalEditListProductOpen} isOpen={modalEditListProductOpen}>
        <ModalEditListProduct product={product} refresh={refresh} setModalEditListProductOpen={setModalEditListProductOpen} />
      </DefaultModal>

      <DefaultModal handleOpen={setModalRemoveProductFromListOpen} isOpen={modalRemoveProductFromListOpen}>
        <ModalRemoveProductFromList productId={product.id} refresh={refresh} setModalRemoveProductFromListOpen={setModalRemoveProductFromListOpen} />
      </DefaultModal>
    </div>
  )
}
