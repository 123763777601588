import { mdiChevronDown, mdiEye } from '@mdi/js'
import Icon from '@mdi/react'
import { useState } from 'react'
import { ScheduleType } from '../index'
import DefaultModal from '../../../../DefaultModal'
import ModalScheduleBoxDetails from './ScheduleBoxDetails'
import styles from './style.module.css'

interface ScheduleBoxProps {
  getConstructionSchedules?: () => void
  schedule: ScheduleType
  handleToast: (toast: JSX.Element) => void
}

export default function ScheduleBox({ getConstructionSchedules, schedule, handleToast }: ScheduleBoxProps) {
  const [modalScheduleBoxDetailsOpen, setModalScheduleBoxDetailsOpen] = useState<boolean>(false)

  return (
    <>
      <div className={styles.schedule__box} onClick={() => setModalScheduleBoxDetailsOpen(true)}>
        <h4>{schedule.stage}</h4>
        <Icon path={mdiEye} color="gray" size="24" style={{ minWidth: 24 }} />
      </div>

      <DefaultModal isOpen={modalScheduleBoxDetailsOpen} handleOpen={setModalScheduleBoxDetailsOpen}>
        <ModalScheduleBoxDetails
          getConstructionSchedules={getConstructionSchedules}
          scheduleId={schedule.id}
          handleToast={handleToast}
          setModalScheduleBoxDetailsOpen={setModalScheduleBoxDetailsOpen}
        />
      </DefaultModal>
    </>
  )
}
