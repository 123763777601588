import React from 'react';

import styles from './style.module.css';

import iTrash from '../../assets/icons/trash.svg';

import { Modal } from '@material-ui/core';
import { useState } from 'react';

import { api } from '../../services/api';

import Toast from '../Toast';

type ClientInvoiceRequestParams = {
  invoice: InvoiceType;
  handleToast: any;
  getMyInvoices: () => Promise<void>;
};

type InvoiceType = {
  id: number;
  user_id: number;
  number: string;
  value: string;
  date: string;
  date_unformated: string;
  status: number;
};

function ClientInvoiceRequest({
  invoice,
  handleToast,
  getMyInvoices,
}: ClientInvoiceRequestParams) {
  const [open, setOpen] = useState(false);
  const [modalBody, setModalBody] = useState(<div></div>);

  function deleteInvoice() {
    api
      .delete(`/invoices/${invoice.id}`)
      .then((response) => {
        getMyInvoices();
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
        setOpen(false);
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />);
      });
  }

  function handleModalOpen(body: string) {
    if (body === 'delete') {
      setModalBody(bodyDeleteInvvoice);
    }

    setOpen(true);
  }

  const bodyDeleteInvvoice = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>
              Deseja realmente cancelar essa solicitação?
            </h4>
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm} onClick={() => deleteInvoice()}>
              Confirmar
            </button>
            <button
              className={styles.cancel}
              type="button"
              onClick={() => setOpen(false)}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.box}>
      <div className={styles.boxInfoConstruction}>
        Nº da nota:&nbsp;<span>{invoice.number}</span>
      </div>

      <div className={styles.boxInfoConstruction}>
        Data da nota:&nbsp;
        <span>{invoice.date_unformated}</span>
      </div>

      <div className={styles.boxInfoConstruction} style={{ width: '50%' }}>
        <span>
          {Number(invoice.value).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </span>
      </div>

      <div className={styles.boxInfoConstruction} style={{ width: '50%' }}>
        <span>
          {invoice.status === 1
            ? 'Pendente'
            : invoice.status === 2
            ? 'Paga'
            : invoice.status === 3
            ? 'Cancelada'
            : 'Indefinido'}
        </span>
      </div>

      <div className={styles.buttons}>
        {invoice.status === 1 ? (
          <img src={iTrash} alt="" onClick={() => handleModalOpen('delete')} />
        ) : null}
      </div>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>
    </div>
  );
}

export default React.memo(ClientInvoiceRequest);
