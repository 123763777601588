import { BaseSyntheticEvent, useContext } from 'react'
import { api } from '../../../../../../services/api'
import { mdiPackageVariantClosed } from '@mdi/js'

import Icon from '@mdi/react'
import styles from './style.module.css'

export default function ModalRemoveProductFromList({ productId, setModalRemoveProductFromListOpen, refresh }) {
  async function remove(e: BaseSyntheticEvent) {
    e.preventDefault()

    api
      .delete(`/lists/product/remove/${productId}`)
      .then((response) => {
        refresh()
        setModalRemoveProductFromListOpen(false)
      })
      .catch((err) => {
        console.error('fail on remove product from list', { err })
      })
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiPackageVariantClosed} size={1.5} />
            <h4 className={styles.title}>DELETAR PRODUTO DA LISTA</h4>
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm} onClick={(e) => remove(e)}>
              Confirmar
            </button>
            <button className={styles.cancel} type="button" onClick={() => setModalRemoveProductFromListOpen(false)}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
