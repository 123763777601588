import { mdiArrowLeft, mdiCheckBold, mdiCloseBox, mdiPencil, mdiPlayCircle, mdiProgressAlert, mdiTrashCan } from '@mdi/js'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ScheduleType } from '../..'
import { api } from '../../../../../../../services/api'

import Icon from '@mdi/react'
import DefaultModal from '../../../../../DefaultModal'
import ModalScheduleBoxStart from './ScheduleBoxStart'
import ModalScheduleBoxDelete from './ScheduleBoxDelete'
import ModalScheduleBoxEdit from './ScheduleBoxEdit'
import ModalScheduleBoxFinish from './ScheduleBoxFinish'
import ModalScheduleBoxDesconfigured from '../ScheduleBoxDesconfigured'

import Toast from '../../../../../../Toast'

import styles from './style.module.css'
import { Tooltip } from '@material-ui/core'
import ScheduleBoxDowngradeState from './ScheduleBoxDowngradeState'

interface ScheduleBoxProps {
  getConstructionSchedules?: () => void
  scheduleId: number
  setModalScheduleBoxDetailsOpen: Dispatch<SetStateAction<boolean>>
  handleToast: (toast: JSX.Element) => void
}

export default function ModalScheduleBoxDetails({ getConstructionSchedules, scheduleId, setModalScheduleBoxDetailsOpen, handleToast }: ScheduleBoxProps) {
  const [modalScheduleBoxStartOpen, setModalScheduleBoxStartOpen] = useState(false)
  const [modalScheduleBoxFinishOpen, setModalScheduleBoxFinishOpen] = useState(false)
  const [modalScheduleBoxDeleteOpen, setModalScheduleBoxDeleteOpen] = useState(false)
  const [modalScheduleBoxEditOpen, setModalScheduleBoxEditOpen] = useState(false)
  const [modalScheduleBoxDesconfiguredOpen, setModalScheduleBoxDesconfiguredOpen] = useState(false)
  const [modalScheduleBoxDowngradeStateOpen, setModalScheduleBoxDowngradeStateOpen] = useState(false)

  const [schedule, setSchedule] = useState({} as ScheduleType)

  const {
    cost_price,
    finish_date,
    finish_date_prediction,
    finish_date_prediction_unformated,
    finish_date_unformated,
    measure,
    proposed_cost_price,
    proposed_quantity,
    quantity,
    total_worked_area,
    stage,
    start_date,
    start_date_prediction,
    start_date_prediction_unformated,
    start_date_unformated,
    status,
    observation
  } = schedule

  const result = Number(proposed_cost_price) * Number(proposed_quantity) - Number(cost_price) * Number(total_worked_area || 0)

  const daysToFinish = Math.ceil(Math.abs(new Date(finish_date_prediction_unformated).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))
  const lateFinished = Math.ceil(
    Math.abs(new Date(finish_date_unformated).getTime() - new Date(finish_date_prediction_unformated).getTime()) / (1000 * 60 * 60 * 24)
  )
  const lateStarted = Math.ceil(
    Math.abs(new Date(start_date_unformated).getTime() - new Date(start_date_prediction_unformated).getTime()) / (1000 * 60 * 60 * 24)
  )
  const finishDateIsLate = !!(new Date(finish_date_unformated) > new Date(finish_date_prediction_unformated))
  const startDateIsLate = !!(new Date(start_date_unformated) > new Date(start_date_prediction_unformated))

  useEffect(() => {
    getScheduleDetails()
  }, [])

  function handleCloseModalAndRequestSchedules() {
    getConstructionSchedules()
    getScheduleDetails()
    setModalScheduleBoxEditOpen(false)
  }

  async function getScheduleDetails() {
    try {
      const { data } = await api.get(`/constructions_schedules/${scheduleId}/details`)
      setSchedule(data[0])
    } catch (err) {
      handleToast(<Toast category="error" message="Falha ao listar os detalhes dessa etapa." />)
    }
  }

  async function downgradeSchedule() {
    try {
      await api.put(`/constructions_schedules/downgrade/${schedule.id}`)

      handleToast(<Toast category="success" message="Status da etapa alterado com sucesso." />)
      setModalScheduleBoxDowngradeStateOpen(false)
      getConstructionSchedules()
      getScheduleDetails()
    } catch (err) {
      handleToast(<Toast category="error" message={err.message} />)
    }
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiProgressAlert} size={1.5} />
            <h4 className={styles.title}>{stage}</h4>

            <div className={styles.close__button} onClick={() => setModalScheduleBoxDetailsOpen(false)}>
              <Icon path={mdiCloseBox} size={1.5} />
            </div>
          </div>

          {!start_date_prediction && !finish_date_prediction ? (
            <div className={styles.infos}>
              <div className={styles.unconfigured}>
                <h5>etapa não configurada, determine as datas de início e finalização para liberação.</h5>
                <span onClick={() => setModalScheduleBoxDesconfiguredOpen(true)}>configurar</span>
              </div>
            </div>
          ) : status === 2 ? (
            <div>
              <div className={styles.infos}>
                <div className={styles.separated}>
                  <div className={styles.sell__info}>
                    <h1>venda</h1>
                    <h5>
                      <b>QUANTIDADE:</b> {proposed_quantity} {measure}
                    </h5>
                    <h5>
                      <b>PREÇO UN.:</b> {Number(proposed_cost_price).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </h5>

                    <h5>
                      <b>PREÇO DE VENDA:</b>{' '}
                      {(Number(proposed_cost_price) * Number(proposed_quantity)).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL'
                      })}
                    </h5>

                    <hr color="#f5f5f5" />

                    <h5>
                      <b>PREVISÃO DE INÍCIO:</b> {start_date_prediction}
                    </h5>
                    <h5>
                      <b>PREVISÃO DE FIM:</b> {finish_date_prediction}
                    </h5>
                  </div>
                  <div className={styles.production__info}>
                    <h1>produção</h1>
                    <h5>
                      <b>QUANTIDADE:</b> {total_worked_area || 0} {measure}
                      <Tooltip title="Esse valor é vinculado com os diárias de obra" placement="top">
                        <span>&#128712;</span>
                      </Tooltip>
                    </h5>
                    <h5>
                      <b>PREÇO UN.:</b> {Number(cost_price).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </h5>

                    <h5>
                      <b>CUSTO REAL:</b>{' '}
                      {(Number(cost_price) * Number(total_worked_area || 0)).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL'
                      })}
                    </h5>

                    <h5 style={{ color: result > 0 ? 'green' : 'red' }}>
                      <b>RESULTADO:</b>{' '}
                      {result.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL'
                      })}
                    </h5>

                    <hr color="#f5f5f5" />

                    <h5>
                      <b>INÍCIO:</b> {start_date}
                    </h5>
                    <h5>
                      <b>PREVISÃO DE FIM:</b> {finish_date_prediction}
                    </h5>
                    <h5>Restam {daysToFinish} dias para a finalização planejada.</h5>
                  </div>
                </div>
              </div>

              <div className={styles.buttons}>
                <button title="Finalizar Etapa" onClick={() => setModalScheduleBoxFinishOpen(true)}>
                  <Icon path={mdiCheckBold} color="white" size={1.5} />
                </button>

                <button title="Editar Etapa" onClick={() => setModalScheduleBoxEditOpen(true)}>
                  <Icon path={mdiPencil} color="white" size={1.5} />
                </button>

                <button title="Remover Etapa" onClick={() => setModalScheduleBoxDeleteOpen(true)}>
                  <Icon path={mdiTrashCan} color="white" size={1.5} />
                </button>
              </div>
            </div>
          ) : status === 3 ? (
            <div>
              <div className={styles.infos}>
                <div className={styles.separated}>
                  <div className={styles.sell__info}>
                    <h1>venda</h1>
                    <h5>
                      <b>QUANTIDADE:</b> {proposed_quantity} {measure}
                    </h5>
                    <h5>
                      <b>PREÇO UN.:</b> {Number(proposed_cost_price).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </h5>

                    <h5>
                      <b>PREÇO DE VENDA:</b>{' '}
                      {(Number(proposed_cost_price) * Number(proposed_quantity)).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL'
                      })}
                    </h5>

                    <hr color="#f5f5f5" />

                    <h5>
                      <b>PREVISÃO DE INÍCIO:</b> {start_date_prediction}
                    </h5>
                    <h5>
                      <b>PREVISÃO DE FIM:</b> {finish_date_prediction}
                    </h5>
                  </div>
                  <div className={styles.production__info}>
                    <h1>produção</h1>
                    <h5>
                      <b>QUANTIDADE:</b> {total_worked_area || 0} {measure}
                      <Tooltip title="Esse valor é vinculado com os diárias de obra" placement="top">
                        <span>&#128712;</span>
                      </Tooltip>
                    </h5>
                    <h5>
                      <b>PREÇO UN.:</b> {Number(cost_price).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </h5>

                    <h5>
                      <b>CUSTO REAL:</b>{' '}
                      {(Number(cost_price) * Number(total_worked_area || 0)).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL'
                      })}
                    </h5>

                    <h5 style={{ color: result > 0 ? 'green' : 'red' }}>
                      <b>RESULTADO:</b>{' '}
                      {result.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL'
                      })}
                    </h5>

                    <hr color="#f5f5f5" />

                    <h5>
                      <b>INÍCIO:</b> {start_date} :: {startDateIsLate ? `${lateStarted} dia(s) atrasado(s)` : ` ${lateStarted} dia(s) adiantado(s)`}
                    </h5>
                    <h5>
                      <b>FINALIZAÇÃO:</b> {finish_date} :: {finishDateIsLate ? `${lateFinished} dia(s) atrasado(s).` : `${lateFinished} dia(s) adiantado(s).`}
                    </h5>

                    {observation ? (
                      <>
                        <hr />

                        <h5>
                          <b>OBSERVAÇÃO:</b> {observation}
                        </h5>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className={styles.buttons}>
                <button title="Retornar status da etapa" onClick={() => setModalScheduleBoxDowngradeStateOpen(true)}>
                  <Icon path={mdiArrowLeft} color="white" size={1.5} />
                </button>

                <button title="Editar Etapa" onClick={() => setModalScheduleBoxEditOpen(true)}>
                  <Icon path={mdiPencil} color="white" size={1.5} />
                </button>

                <button title="Remover Etapa" onClick={() => setModalScheduleBoxDeleteOpen(true)}>
                  <Icon path={mdiTrashCan} color="white" size={1.5} />
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className={styles.infos}>
                <h5>
                  <b>QUANTIDADE:</b> {proposed_quantity} {measure}
                </h5>
                <h5>
                  <b>PREÇO UN.:</b> {Number(proposed_cost_price).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </h5>

                <h5>
                  <b>PREÇO DE VENDA:</b>{' '}
                  {(Number(proposed_cost_price) * Number(proposed_quantity)).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </h5>

                <hr color="#f5f5f5" />

                <h5>
                  <b>PREVISÃO DE INÍCIO:</b> {start_date_prediction}
                </h5>
                <h5>
                  <b>PREVISÃO DE FIM:</b> {finish_date_prediction}
                </h5>

                <span className={styles.warning}>
                  OBS: os valores acima são referentes ao preço de venda, o preço de custo poderá ser alterado após a etapa ser iniciada.
                </span>
              </div>

              <div className={styles.buttons}>
                <button title="Iniciar Etapa" onClick={() => setModalScheduleBoxStartOpen(true)}>
                  <Icon path={mdiPlayCircle} color="white" size={1.5} />
                </button>

                <button title="Editar Etapa" onClick={() => setModalScheduleBoxEditOpen(true)}>
                  <Icon path={mdiPencil} color="white" size={1.5} />
                </button>

                <button title="Remover Etapa" onClick={() => setModalScheduleBoxDeleteOpen(true)}>
                  <Icon path={mdiTrashCan} color="white" size={1.5} />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <DefaultModal isOpen={modalScheduleBoxStartOpen} handleOpen={setModalScheduleBoxStartOpen}>
        <ModalScheduleBoxStart
          handleClose={handleCloseModalAndRequestSchedules}
          schedule={schedule}
          handleToast={handleToast}
          setModalScheduleBoxStartOpen={setModalScheduleBoxStartOpen}
        />
      </DefaultModal>

      <DefaultModal isOpen={modalScheduleBoxFinishOpen} handleOpen={setModalScheduleBoxFinishOpen}>
        <ModalScheduleBoxFinish
          schedule={schedule}
          handleToast={handleToast}
          handleCloseModalAndRequestSchedules={handleCloseModalAndRequestSchedules}
          setModalScheduleBoxFinishOpen={setModalScheduleBoxFinishOpen}
        />
      </DefaultModal>

      <DefaultModal isOpen={modalScheduleBoxEditOpen} handleOpen={setModalScheduleBoxEditOpen}>
        <ModalScheduleBoxEdit
          schedule={schedule}
          handleToast={handleToast}
          handleCloseModalAndRequestSchedules={handleCloseModalAndRequestSchedules}
          setModalScheduleBoxEditOpen={setModalScheduleBoxEditOpen}
        />
      </DefaultModal>

      <DefaultModal isOpen={modalScheduleBoxDeleteOpen} handleOpen={setModalScheduleBoxDeleteOpen}>
        <ModalScheduleBoxDelete
          schedule={schedule}
          handleToast={handleToast}
          handleCloseModalAndRequestSchedules={handleCloseModalAndRequestSchedules}
          setModalScheduleBoxDeleteOpen={setModalScheduleBoxDeleteOpen}
        />
      </DefaultModal>

      <DefaultModal isOpen={modalScheduleBoxDesconfiguredOpen} handleOpen={setModalScheduleBoxDesconfiguredOpen}>
        <ModalScheduleBoxDesconfigured
          handleCloseModalAndRequestSchedules={handleCloseModalAndRequestSchedules}
          schedule={schedule}
          handleToast={handleToast}
          setModalScheduleBoxDesconfiguredOpen={setModalScheduleBoxDesconfiguredOpen}
        />
      </DefaultModal>

      <DefaultModal isOpen={modalScheduleBoxDowngradeStateOpen} handleOpen={setModalScheduleBoxDowngradeStateOpen}>
        <ScheduleBoxDowngradeState
          downgradeSchedule={downgradeSchedule}
          schedule={schedule}
          handleToast={handleToast}
          setScheduleBoxDowngradeStateOpen={setModalScheduleBoxDowngradeStateOpen}
        />
      </DefaultModal>
    </div>
  )
}
