import { BaseSyntheticEvent, useContext, useState } from 'react'
import { Autocomplete, TextField, Tooltip } from '@mui/material'
import { api } from '../../../../services/api'
import { mdiImagePlus, mdiPackageVariantClosed } from '@mdi/js'
import { ConstructionsContext, ConstructionType } from '../../../../contexts/ConstructionsContext'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { ptBR } from 'date-fns/locale'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

import styles from './style.module.css'
import DefaultModal from '../../DefaultModal'
import ModalAddFile from '../AddFile'

type SubmitType = {
  construction_id: number
  list_id: number
  observation: string
  send_to_client_date: string
  payment_date: string
  arrival_in_construction_date: string
  images?: File[]
}

type InputType = {
  value: string | File
  name: string
}

type ListType = {
  id: number
  description: string
}

type AddedFile = {
  description: string
  file: File
}

export default function ModalCreatePurchaseRequest({ setModalCreatePurchaseRequestOpen, handleToast }) {
  const [modalAddFileOpen, setModalAddFileOpen] = useState(false)

  const [formValues, setFormValues] = useState({} as SubmitType)

  const { constructions } = useContext(ConstructionsContext)

  const [constructionLists, setConstructionLists] = useState([])

  const [files, setFiles] = useState<AddedFile[]>([])

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function handleCreate(e: BaseSyntheticEvent) {
    e.preventDefault()

    api
      .post(`/purchases/requests`, formValues)
      .then((response) => {
        handleToast(<Toast category="success" message={response.data.message} />)
        saveFiles(response.data.purchase.id)
        setModalCreatePurchaseRequestOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  async function saveFiles(purchaseRequestId: number) {
    for (const file in files) {
      const formData = new FormData()
      formData.append('file', files[file].file)
      formData.append('description', files[file].description)
      formData.append('purchase_request_id', purchaseRequestId.toString())

      try {
        await api.post(`purchases/requests/files`, formData)
      } catch (err) {
        console.error({ message: 'fail on save purchase request image', err })
      }
    }
  }

  function loadConstructionLists(construction_id: number) {
    api
      .get(`/lists/construction/${construction_id}`)
      .then((response) => {
        setConstructionLists(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function addFile(description: string, file: File) {
    setFiles((prev) => [...prev, { description, file }])
    setModalAddFileOpen(false)
  }

  function removeFile(description: string) {
    setFiles((prev) => [...prev.filter((f) => f.description !== description)])
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiPackageVariantClosed} size={1.5} />
            <h4 className={styles.title}>NOVO PEDIDO DE COMPRA</h4>
          </div>

          <form className={styles.form__create} onSubmit={(e) => handleCreate(e)}>
            <TextField
              label="Descrição"
              type="text"
              required
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'description',
                  value: e.target.value
                })
              }
            />

            <Autocomplete
              value={undefined}
              onChange={(event, newValue: ConstructionType) => {
                loadConstructionLists(newValue.id)
              }}
              options={constructions}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Obra" required />}
            />

            {constructionLists.length > 0 ? (
              <Autocomplete
                value={undefined}
                onChange={(event, newValue: ListType) => {
                  handleInputChange({
                    name: 'list_id',
                    value: String(newValue.id)
                  })
                }}
                options={constructionLists}
                getOptionLabel={(option) => option.description}
                renderInput={(params) => <TextField {...params} label="Lista" required />}
              />
            ) : null}

            {formValues.list_id ? (
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  label="Enviado para o cliente"
                  mask="__/__/____"
                  value={formValues.send_to_client_date ? formValues.send_to_client_date : null}
                  onChange={(newValue) => {
                    handleInputChange({
                      name: 'send_to_client_date',
                      value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                    })
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            ) : null}

            {formValues.send_to_client_date ? (
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  label="Data de pagamento"
                  mask="__/__/____"
                  value={formValues.payment_date ? formValues.payment_date : null}
                  onChange={(newValue) => {
                    handleInputChange({
                      name: 'payment_date',
                      value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                    })
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            ) : null}

            {formValues.payment_date ? (
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  label="Chegou na obra"
                  mask="__/__/____"
                  value={formValues.arrival_in_construction_date ? formValues.arrival_in_construction_date : null}
                  onChange={(newValue) => {
                    handleInputChange({
                      name: 'arrival_in_construction_date',
                      value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                    })
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            ) : null}

            <TextField
              label="Valor"
              type="text"
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'value',
                  value: e.target.value
                })
              }
            />

            <TextField
              label="PIX/Dados Bancários"
              type="text"
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'bank',
                  value: e.target.value
                })
              }
            />

            <TextField
              label="Observação"
              type="text"
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'observation',
                  value: e.target.value
                })
              }
            />

            {files.length ? (
              <div className={styles.files}>
                {files.map((file) => (
                  <Tooltip title="Clique para remover" placement="top">
                    <div className={styles.new__file} onClick={() => removeFile(file.description)}>
                      <Icon path={mdiImagePlus} size={1.2} color="#000" /> <h3>{file.description}</h3>{' '}
                    </div>
                  </Tooltip>
                ))}
              </div>
            ) : null}

            <div className={styles.add__file__button} onClick={() => setModalAddFileOpen(true)}>
              <Icon path={mdiImagePlus} size={1.2} color="#FFF" />
            </div>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalCreatePurchaseRequestOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>

      <DefaultModal handleOpen={setModalAddFileOpen} isOpen={modalAddFileOpen}>
        <ModalAddFile setModalAddFileOpen={setModalAddFileOpen} addFile={addFile} />
      </DefaultModal>
    </div>
  )
}
