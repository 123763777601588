import { BaseSyntheticEvent, useContext } from 'react';
import { ProvidersContext } from '../../../../contexts/ProvidersContext';
import { api } from '../../../../services/api';
import { mdiAccountTie } from '@mdi/js';

import Icon from '@mdi/react';
import Toast from '../../../Toast';
import styles from './style.module.css';

export default function ModalDeleteProvider({
  provider,
  setModalDeleteProviderOpen,
  handleToast,
}) {
  const { getProviders } = useContext(ProvidersContext);

  async function handleDelete(e: BaseSyntheticEvent) {
    e.preventDefault();

    api
      .delete(`/providers/${provider.id}`)
      .then((response) => {
        getProviders();
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
        setModalDeleteProviderOpen(false);
      })
      .catch((err) => {
        handleToast(
          <Toast category="error" message="Erro ao remover o fornecedor." />
        );
      });
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiAccountTie} size={1.5} />
            <h4 className={styles.title}>DELETAR FORNECEDOR</h4>
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm} onClick={(e) => handleDelete(e)}>
              Confirmar
            </button>
            <button
              className={styles.cancel}
              type="button"
              onClick={() => setModalDeleteProviderOpen(false)}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
