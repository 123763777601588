import { BaseSyntheticEvent, useState } from 'react'
import { TextField } from '@mui/material'
import { api } from '../../../../services/api'
import { mdiPackageVariantClosed } from '@mdi/js'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import styles from './style.module.css'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ptBR } from 'date-fns/locale'

type SubmitType = {
  date: string
  value: string
}

type InputType = {
  value: string
  name: string
}

export default function ModalEditParcel({ parcel, getParcels, setModalEditParcelOpen, handleToast, type }) {
  const [formValues, setFormValues] = useState({} as SubmitType)

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function handleEdit(e: BaseSyntheticEvent) {
    e.preventDefault()

    api
      .put(`${type === 'expense' ? '/expenses_parcels' : '/incomings_parcels'}/${parcel.id}`, formValues)
      .then((response) => {
        getParcels()
        handleToast(<Toast category="success" message="Parcela atualizada com sucesso." />)
        setModalEditParcelOpen(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiPackageVariantClosed} size={1.5} />
            <h4 className={styles.title}>EDITAR PARCELA</h4>
          </div>

          <form className={styles.form__edit} onSubmit={(e) => handleEdit(e)}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Data de Vencimento"
                mask="__/__/____"
                value={formValues.date ? formValues.date : `${parcel.date.split('/').reverse().join('-')}, 00:00:00`}
                onChange={(newValue) => {
                  handleInputChange({
                    name: 'date',
                    value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                  })
                }}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>

            <TextField
              label="Valor"
              type="text"
              required
              value={formValues.value ? formValues.value : parcel.value}
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'value',
                  value: e.target.value.replace(',', '.')
                })
              }
            />

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalEditParcelOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
