import React from 'react';
import styles from './style.module.css';

import iPencil from '../../assets/icons/pencil.svg';
import iTrash from '../../assets/icons/trash.svg';

import { Modal } from '@material-ui/core';
import { useState } from 'react';

import { useForm } from 'react-hook-form';
import { api } from '../../services/api';
import { useEffect } from 'react';

import Toast from '../Toast';
import { useContext } from 'react';
import { MonetaryContext } from '../../contexts/MonetaryContext';

type ProviderParam = {
  type: TypeType;
  handleToast: any;
};

type TypeType = {
  id: number;
  description: string;
};

type SubmitType = {
  description: string;
};

function IncomingType({ type, handleToast }: ProviderParam) {
  const { getIncomingsTypes } = useContext(MonetaryContext);

  const { register, handleSubmit } = useForm();

  const [open, setOpen] = useState(false);

  function deleteIncomingType() {
    api
      .delete(`/types/incomings/${type.id}`)
      .then((response) => {
        getIncomingsTypes();
        setOpen(false);
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function handleEdit(data: SubmitType) {
    api
      .put(`/types/incomings/${type.id}`, data)
      .then((response) => {
        getIncomingsTypes();
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function handleModalOpen(body: string) {
    if (body === 'edit') {
      setModalBody(bodyEditType);
    } else if (body === 'delete') {
      setModalBody(bodyDeleteProvider);
    }

    setOpen(true);
  }

  const bodyDeleteProvider = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>
              Deseja realmente deletar esse tipo de receita?
            </h4>
          </div>

          <div className={styles.buttons}>
            <button
              className={styles.confirm}
              onClick={() => deleteIncomingType()}
            >
              Confirmar
            </button>
            <button
              className={styles.cancel}
              type="button"
              onClick={() => setOpen(false)}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const bodyEditType = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Editar tipo de receita</h4>
          </div>

          <form className={styles.formEdit} onSubmit={handleSubmit(handleEdit)}>
            <div className={styles.inputBlock}>
              <label htmlFor="description">Descrição: *</label>
              <input
                defaultValue={type.description}
                name="description"
                type="text"
                {...register('description')}
              />
            </div>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button
                className={styles.cancel}
                type="button"
                onClick={() => setOpen(false)}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  const [modalBody, setModalBody] = useState(<div></div>);

  return (
    <div className={styles.box}>
      <div className={styles.boxName}>
        <span>{type.description}</span>
      </div>

      <div className={styles.buttons}>
        <img src={iPencil} alt="" onClick={() => handleModalOpen('edit')} />
        <img src={iTrash} alt="" onClick={() => handleModalOpen('delete')} />
      </div>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>
    </div>
  );
}

export default React.memo(IncomingType);
