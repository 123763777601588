import { BaseSyntheticEvent, useContext } from 'react'
import { ConstructionsContext } from '../../../../contexts/ConstructionsContext'
import { api } from '../../../../services/api'
import { mdiOfficeBuilding } from '@mdi/js'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import styles from './style.module.css'

export default function ModalDeleteConstruction({ constructionId, setModalDeleteConstructionOpen, handleToast }) {
  const { getConstructions } = useContext(ConstructionsContext)

  async function handleDelete(e: BaseSyntheticEvent) {
    e.preventDefault()

    api
      .delete(`/constructions/${constructionId}`)
      .then((response) => {
        getConstructions()
        handleToast(<Toast category="success" message={response.data.message} />)
        setModalDeleteConstructionOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiOfficeBuilding} size={1.5} />
            <h4 className={styles.title}>DELETAR OBRA</h4>
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm} onClick={(e) => handleDelete(e)}>
              Confirmar
            </button>
            <button className={styles.cancel} type="button" onClick={() => setModalDeleteConstructionOpen(false)}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
