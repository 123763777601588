import { BaseSyntheticEvent, useContext, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { ConstructionsContext } from '../../../../contexts/ConstructionsContext'
import { ClientsContext } from '../../../../contexts/ClientsContext'
import { mdiCheck, mdiFileDocument, mdiFilePlus, mdiHome, mdiImagePlus } from '@mdi/js'
import { api } from '../../../../services/api'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { ptBR } from 'date-fns/locale'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import styles from './style.module.css'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

type SubmitType = {
  client_id: number
  start_date: string
  start_date_formated: string
  delivery_forecast: string
  neighborhood: string
  name: string
  address: string
  address_number: number
  observation: string
  image?: File
  contract?: File
}

type InputType = {
  value: string | File
  name: string
}

type ClientType = {
  id: number
  name: string
}

export default function ModalAddFile({ setModalAddFileOpen, addFile }) {
  const [description, setDescription] = useState<string>(null)
  const [file, setFile] = useState<File>(null)

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiFilePlus} size={1.5} />
            <h4 className={styles.title}>ADICIONAR ARQUIVO</h4>
          </div>

          <form
            className={styles.form__create}
            onSubmit={(e) => {
              e.preventDefault()
              addFile(description, file)
            }}
          >
            <TextField label="Descição" type="text" required autoComplete="off" onChange={(e) => setDescription(e.target.value)} />

            <div className={styles.add__image}>
              <div className={styles.field__box} title="Adicionar Imagem">
                <input style={{ display: 'none' }} onChange={(e) => setFile(e.target.files[0])} name="file" required id="file" type="file" />
                <label htmlFor="file">
                  <Icon path={mdiImagePlus} size={1.2} color="#FFF" />
                </label>
                {file ? (
                  <div className={styles.field__filled}>
                    <Icon path={mdiCheck} size={1.2} color="#FFF" />
                  </div>
                ) : null}
              </div>
            </div>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalAddFileOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
