import { BaseSyntheticEvent, useContext, useState } from 'react';
import { TextField } from '@mui/material';
import { ProductsContext } from '../../../../contexts/ProductsContext';
import { api } from '../../../../services/api';
import { mdiPackageVariantClosed } from '@mdi/js';

import Icon from '@mdi/react';
import Toast from '../../../Toast';
import styles from './style.module.css';

type SubmitType = {
  description: string;
};

type InputType = {
  value: string;
  name: string;
};

export default function ModalEditProduct({
  product,
  setModalEditProductOpen,
  handleToast,
}) {
  const [formValues, setFormValues] = useState({} as SubmitType);

  const { getProducts } = useContext(ProductsContext);

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value,
    }));
  }

  async function handleEdit(e: BaseSyntheticEvent) {
    e.preventDefault();

    api
      .put(`/products/${product.id}`, formValues)
      .then((response) => {
        getProducts();
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
        setModalEditProductOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiPackageVariantClosed} size={1.5} />
            <h4 className={styles.title}>EDITAR PRODUTO</h4>
          </div>

          <form className={styles.form__edit} onSubmit={(e) => handleEdit(e)}>
            <TextField
              defaultValue={product.description}
              label="Descrição"
              type="text"
              required
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'description',
                  value: e.target.value,
                })
              }
            />

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button
                className={styles.cancel}
                type="button"
                onClick={() => setModalEditProductOpen(false)}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
