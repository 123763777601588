import { BaseSyntheticEvent, ChangeEvent, useContext, useState } from 'react'
import { Autocomplete, InputAdornment, TextField } from '@mui/material'
import { mdiCreditCard } from '@mdi/js'
import { api } from '../../../../services/api'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { ptBR } from 'date-fns/locale'
import { MonetaryContext, MoneyOriginType } from '../../../../contexts/MonetaryContext'
import { ProviderType } from '../../../../contexts/ProvidersContext'
import { ClientsContext } from '../../../../contexts/ClientsContext'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import styles from './style.module.css'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ConstructionType } from '../../../../contexts/ConstructionsContext'

type SubmitType = {
  value: string
  date: string
  due_date: string
  payday_first_parcel?: string
  client_id?: number
  parcels: string
  payday: string
  images: File[]
}

type InputType = {
  value: string
  name: string
}

type ExpenseType = {
  id: number
  description: string
}

export default function ModalCreateIncoming({ setModalCreateIncomingOpen, handleToast }) {
  const defaultFormValues = {
    parcels: '1'
  }

  const [formValues, setFormValues] = useState(defaultFormValues as SubmitType)
  const [incomingInvoice, setIncomingInvoice] = useState({} as File)
  const [clientConstructions, setClientConstructions] = useState([])

  const { incomingsTypes, moneyOrigins } = useContext(MonetaryContext)
  const { clients } = useContext(ClientsContext)

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function handleCreate(e: BaseSyntheticEvent) {
    e.preventDefault()

    const formData = new FormData()
    Object.keys(formValues).map((key) => formData.append(key, formValues[key]))

    formData.set('invoice', incomingInvoice)

    api
      .post(`/incomings`, formData)
      .then((response) => {
        handleToast(<Toast category="success" message={response.data.message} />)
        setModalCreateIncomingOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  async function getClientConstructions(clientId: number) {
    try {
      const { data } = await api.get(`/constructions/client/${clientId}`)
      setClientConstructions(data)
    } catch (err) {
      console.error('fail on load client constructions', err)
    }
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiCreditCard} size={1.5} />
            <h4 className={styles.title}>CADASTRAR RECEITA</h4>
          </div>

          <form className={styles.form__create} onSubmit={(e) => handleCreate(e)}>
            <TextField
              label="Valor"
              type="text"
              required
              autoComplete="off"
              onChange={(e) => {
                handleInputChange({
                  name: 'value',
                  value: e.target.value.replace(',', '.')
                })
              }}
            />

            <Autocomplete
              value={undefined}
              onChange={(event, newValue: ExpenseType) => {
                handleInputChange({
                  name: 'incoming_type_id',
                  value: newValue && String(newValue.id)
                })
              }}
              options={incomingsTypes}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Tipo da Receita" required />}
            />

            <Autocomplete
              value={undefined}
              onChange={(event, newValue: { description: string; value: number }) => {
                handleInputChange({
                  name: 'subtype',
                  value: newValue && String(newValue.value)
                })
              }}
              options={[
                { description: 'Construtora', value: 1 },
                { description: 'Escritório', value: 2 }
              ]}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Subtipo da Receita" required />}
            />

            <Autocomplete
              value={undefined}
              onChange={(event, newValue: ProviderType) => {
                if (newValue) {
                  handleInputChange({
                    name: 'client_id',
                    value: String(newValue.id)
                  })

                  getClientConstructions(newValue.id)
                }
              }}
              options={clients}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Cliente" />}
            />

            {formValues.client_id ? (
              <Autocomplete
                value={undefined}
                onChange={(event, newValue: ConstructionType) => {
                  if (newValue) {
                    handleInputChange({
                      name: 'construction_id',
                      value: String(newValue.id)
                    })
                  }
                }}
                options={clientConstructions}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Obra" />}
              />
            ) : null}

            <TextField
              label="Parcelas"
              type="number"
              required
              defaultValue="1"
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {formValues.value &&
                      formValues.parcels &&
                      `${formValues.parcels}x de ${(Number(formValues.value) / Number(formValues.parcels)).toFixed(2)}`}
                  </InputAdornment>
                )
              }}
              onChange={(e) => {
                handleInputChange({
                  name: 'parcels',
                  value: e.target.value
                })
              }}
            />

            {formValues.parcels === '1' ? (
              <>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                  <DatePicker
                    label="Data de Pagamento"
                    mask="__/__/____"
                    value={formValues.payday ? formValues.payday : null}
                    onChange={(newValue) => {
                      handleInputChange({
                        name: 'payday',
                        value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                      })
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                  <DatePicker
                    label="Data de Vencimento"
                    mask="__/__/____"
                    value={formValues.due_date ? formValues.due_date : null}
                    onChange={(newValue) => {
                      handleInputChange({
                        name: 'due_date',
                        value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                      })
                    }}
                    renderInput={(params) => <TextField {...params} required />}
                  />
                </LocalizationProvider>
              </>
            ) : (
              <>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                  <DatePicker
                    label="Data da Primeira Parcela"
                    mask="__/__/____"
                    value={formValues.payday_first_parcel ? formValues.payday_first_parcel : null}
                    onChange={(newValue) => {
                      handleInputChange({
                        name: 'payday_first_parcel',
                        value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                      })
                    }}
                    renderInput={(params) => <TextField {...params} required />}
                  />
                </LocalizationProvider>
              </>
            )}

            {(formValues.payday || Number(formValues.parcels) > 1) && (
              <Autocomplete
                value={undefined}
                onChange={(event, newValue: MoneyOriginType) => {
                  handleInputChange({
                    name: 'money_origin_id',
                    value: newValue && String(newValue.id)
                  })
                }}
                options={moneyOrigins}
                getOptionLabel={(option) => option.description}
                renderInput={(params) => <TextField {...params} label="Destino do Pagamento" />}
              />
            )}

            <TextField
              label="Descrição"
              type="text"
              autoComplete="off"
              onChange={(e) => {
                handleInputChange({
                  name: 'description',
                  value: e.target.value
                })
              }}
            />

            <label htmlFor="images">Nota Fiscal/Comprovante:</label>
            <input
              name="images"
              className={styles.inputfile}
              type="file"
              onChange={(e) => setIncomingInvoice(e.target.files[0])}
              style={{ padding: '0.9rem 1rem' }}
            />

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalCreateIncomingOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
