import { BaseSyntheticEvent, useContext, useEffect, useState } from 'react'
import { ConstructionsContext, ConstructionType } from '../../../contexts/ConstructionsContext'
import { ClientType } from '../../../contexts/ClientsContext'
import { api } from '../../../services/api'
import { Autocomplete, TextField } from '@mui/material'

import Sidebar from '../../../components/Sidebar'
import links from '../../../components/configs/links-records'

import iPlus from '../../../assets/icons/plus.svg'

import PurchaseRequest from '../../../components/PurchaseRequest'
import DefaultModal from '../../../components/Modals/DefaultModal'
import ModalCreatePurchaseRequest from '../../../components/Modals/PurchaseRequest/Create'
import PDFUnpaidPurchaseRequestsGenerator from '../../../components/PDFUnpaidPurchaseRequestsGenerator'

import styles from './style.module.css'
import PDFPaidPurchaseRequestsGenerator from '../../../components/PDFPaidPurchaseRequestsGenerator'

type PurchasesRequestsType = {
  id: number
  client: string
  created_at: string
  created_by: string
  last_user_update: string
  construction: string
  send_to_client_date: string
  payment_date: string
  arrival_in_construction_date: string
  send_to_client_date_un: string
  payment_date_un: string
  observation: string
  arrival_in_construction_date_un: string
  invoice: string
  list: string
}

export default function PurchasesRequests() {
  const { constructions } = useContext(ConstructionsContext)

  const [purchasesRequests, setPurchasesRequests] = useState<PurchasesRequestsType[]>([])

  const [filterClient, setFilterClient] = useState<number>(null)
  const [filterConstruction, setFilterConstruction] = useState<number>(null)

  const [toast, setToast] = useState<any>()

  const [messageDefault, setMessageDefault] = useState('Busque as informações utilizando os filtros acima.')

  const [modalCreatePurchaseRequestOpen, setModalCreatePurchaseRequestOpen] = useState(false)

  const [clients, setClients] = useState([])

  useEffect(() => {
    loadClientsWithConstructions()
  }, [])

  async function loadClientsWithConstructions() {
    try {
      const { data } = await api.get('/clients/active/constructions')
      setClients(data)
    } catch (err) {
      console.error('fail to load clients with constructions', { err })
    }
  }

  function filterPurchaseRequests(e: BaseSyntheticEvent) {
    e.preventDefault()

    api
      .get(`/purchases/requests/filter?client_id=${filterClient}&construction_id=${filterConstruction}`)
      .then((response) => {
        if (response.data.length === 0) {
          setMessageDefault('Não foram encontradas listas de acordo com o filtro.')
        }

        setPurchasesRequests(response.data)
      })
      .catch((err) => {
        console.error('fail on filter lists', { err })
      })
  }

  async function generatePDFPaidPurchaseRequests() {
    try {
      const { data } = await api.get(`/purchases/requests/paid/${filterClient}`)
      console.log(data)
      PDFPaidPurchaseRequestsGenerator(data, clients.find((c) => c.id === filterClient).name)
    } catch (err) {
      console.error('fail on generate unpaid purchase requests PDF', { err })
    }
  }

  async function generatePDFUnpaidPurchaseRequests() {
    try {
      const { data } = await api.get(`/purchases/requests/unpaid/${filterClient}`)
      PDFUnpaidPurchaseRequestsGenerator(data, clients.find((c) => c.id === filterClient).name)
    } catch (err) {
      console.error('fail on generate unpaid purchase requests PDF', { err })
    }
  }

  function handleToast(toast) {
    setToast(toast)

    setTimeout(() => {
      setToast(null)
    }, 5000)
  }

  return (
    <div className={styles.main}>
      <Sidebar config={links} />

      {toast}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>PEDIDOS DE COMPRA</h1>

          <button className={styles.button} onClick={() => setModalCreatePurchaseRequestOpen(true)}>
            <span>LANÇAR PEDIDO</span>
            <img src={iPlus} alt="" />
          </button>
        </div>

        <form className={styles.form__filter} onSubmit={(e) => filterPurchaseRequests(e)}>
          <div className={styles.row}>
            <Autocomplete
              value={undefined}
              fullWidth
              onChange={(event, newValue: ClientType) => {
                setFilterClient(newValue?.id)
              }}
              options={clients}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Cliente" />}
            />

            <Autocomplete
              value={undefined}
              fullWidth
              onChange={(event, newValue: ConstructionType) => {
                setFilterConstruction(newValue?.id)
              }}
              options={setFilterClient ? constructions.filter((c) => c.client_id === filterClient) : []}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Obra" />}
            />
          </div>

          <div className={styles.buttons}>
            {filterClient ? (
              <>
                <button className={styles.info} type="button" onClick={() => generatePDFPaidPurchaseRequests()}>
                  <span>PDF (pagos)</span>
                </button>

                <button className={styles.info} type="button" onClick={() => generatePDFUnpaidPurchaseRequests()}>
                  <span>PDF (não pagos)</span>
                </button>
              </>
            ) : null}

            <button className={styles.confirm}>
              <span>FILTRAR</span>
            </button>
          </div>
        </form>

        <div className={styles.table}>
          {purchasesRequests.length ? (
            purchasesRequests.map((purchase) => <PurchaseRequest key={purchase.id} purchase={purchase} handleToast={handleToast} />)
          ) : (
            <span>{messageDefault}</span>
          )}
        </div>

        <DefaultModal isOpen={modalCreatePurchaseRequestOpen} handleOpen={setModalCreatePurchaseRequestOpen}>
          <ModalCreatePurchaseRequest handleToast={handleToast} setModalCreatePurchaseRequestOpen={setModalCreatePurchaseRequestOpen} />
        </DefaultModal>
      </div>
    </div>
  )
}
