import styles from './style.module.css'

import pencil from '../../../assets/icons/pencil.svg'
import trash from '../../../assets/icons/trash.svg'

import { BaseSyntheticEvent, useContext, useState } from 'react'

import Toast from '../../Toast'
import { api } from '../../../services/api'
import { Modal } from '@material-ui/core'
import { Autocomplete, TextField } from '@mui/material'
import { StagesContext } from '../../../contexts/StageContext'
import { MeasureContext } from '../../../contexts/MeasureContext'

type SubmitType = {
  stage_id?: number
  measure_id?: number
  proposed_quantity?: number
  proposed_cost_price?: number
}

type StageType = {
  id: number
  construction_budget_id: number
  stage_id: number
  stage: number
  measure_id: number
  measure: number
  proposed_quantity: number
  proposed_cost_price: number
}

type StageBudgetParams = {
  stage: StageType
  getConstructionSchedules: any
  setToast: any
}

type BodyModalProps = {
  action: string
  id: number
}

type InputType = {
  value: string
  name: string
}

type AutocompleteTypes = {
  id: number
  description: string
}

export default function StageBudget({ stage, getConstructionSchedules, setToast }: StageBudgetParams) {
  const [bodyModalParams, setBodyModalParams] = useState<BodyModalProps>()
  const [confirmOpen, setConfirmOpen] = useState(false)

  const [editOpen, setEditOpen] = useState(false)

  const [formValues, setFormValues] = useState({} as SubmitType)

  const { stages } = useContext(StagesContext)
  const { measures } = useContext(MeasureContext)

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function handleUpdate(e: BaseSyntheticEvent) {
    e.preventDefault()

    api
      .put(`/constructions_schedules/${stage.id}`, formValues)
      .then((response) => {
        setToast(<Toast category="success" message={response.data.message} />)
        getConstructionSchedules()
        setEditOpen(false)
      })
      .catch((err) => {
        setToast(<Toast category="error" message={err.message} />)
      })
  }

  const bodyModalConfirm = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Deseja realmente fazer isso?</h4>
          </div>

          <div className={styles.buttons}>
            <button
              className={styles.confirm}
              onClick={() => {
                deleteStage()
              }}
            >
              <span>Confirmar</span>
            </button>
            <button className={styles.cancel} onClick={() => setConfirmOpen(false)}>
              <span>Cancelar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )

  const bodyEditStage = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Editar Etapa de Obra</h4>
          </div>

          <form className={styles.form__create} onSubmit={(e) => handleUpdate(e)}>
            <Autocomplete
              value={undefined}
              onChange={(event, newValue: AutocompleteTypes) => {
                handleInputChange({
                  name: 'stage_id',
                  value: String(newValue.id)
                })
              }}
              options={stages}
              getOptionLabel={(option) => option.description}
              defaultValue={stages.find((stageDB) => stageDB.id === stage.stage_id)}
              renderInput={(params) => <TextField {...params} label="Etapa" required />}
            />

            <Autocomplete
              value={undefined}
              onChange={(event, newValue: AutocompleteTypes) => {
                handleInputChange({
                  name: 'measure_id',
                  value: String(newValue.id)
                })
              }}
              options={measures}
              defaultValue={measures.find((measureDB) => measureDB.id === stage.measure_id)}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Un. de Medida" required />}
            />

            <TextField
              label="Quantidade"
              type="text"
              required
              autoComplete="off"
              defaultValue={Number(stage.proposed_quantity)}
              onChange={(e) =>
                handleInputChange({
                  name: 'proposed_quantity',
                  value: e.target.value.includes(',') ? e.target.value.replace(',', '.') : e.target.value
                })
              }
            />

            <TextField
              label="Valor Unitário"
              type="text"
              required
              autoComplete="off"
              defaultValue={stage.proposed_cost_price}
              onChange={(e) =>
                handleInputChange({
                  name: 'proposed_cost_price',
                  value: e.target.value.includes(',') ? e.target.value.replace(',', '.') : e.target.value
                })
              }
            />

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setEditOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

  function deleteStage() {
    api
      .delete(`/constructions_schedules/${stage.id}`)
      .then((response) => {
        getConstructionSchedules()
        setToast(<Toast category="success" message={response.data.message} />)
        setConfirmOpen(false)

        setTimeout(() => {
          setToast(<div></div>)
        }, 5000)
      })
      .catch((err) => {
        setToast(<Toast category="error" message={err.message} />)
        setConfirmOpen(false)

        setTimeout(() => {
          setToast(<div></div>)
        }, 5000)
      })
  }

  function handleConfirmModalProps(action: string, id: number) {
    setBodyModalParams({
      action,
      id
    })

    setConfirmOpen(true)
  }

  return (
    <div className={styles.colaboratorBudgetBox}>
      <div className={styles.colaboratorBudgetInfos}>
        <span>{stage.stage}</span>

        <span>
          {Number(stage.proposed_quantity)} {stage.measure}
        </span>

        <span>
          {Number(stage.proposed_cost_price).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL'
          })}{' '}
          por {stage.measure}
        </span>

        <span>
          {(Number(stage.proposed_quantity) * Number(stage.proposed_cost_price)).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL'
          })}
        </span>

        <Modal open={editOpen} onClose={() => setEditOpen(false)} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
          {bodyEditStage}
        </Modal>

        <Modal open={confirmOpen} onClose={() => setConfirmOpen(false)} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
          {bodyModalConfirm}
        </Modal>
      </div>

      <div className={styles.buttons}>
        <img
          src={pencil}
          alt="pencil-icon"
          onClick={() => {
            setEditOpen(true)
          }}
        />
        <img
          src={trash}
          alt="trash-icon"
          onClick={() => {
            handleConfirmModalProps('delete', stage.id)
          }}
        />
      </div>
    </div>
  )
}
