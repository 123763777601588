import './styles.css';
import Arcco from '../../assets/arcco_mini.png';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';

interface SignInData {
  email: string;
  password: string;
}

export default function Login() {
  const { register, handleSubmit } = useForm();
  const { signIn } = useContext(AuthContext);

  const [returnSignIn, setReturnSignIn] = useState('');

  async function handleSignIn(data: SignInData) {
    const messageReturn = await signIn({
      email: data.email,
      password: data.password,
    });

    setReturnSignIn(messageReturn);
  }

  return (
    <main>
      <div className="login">
        <img src={Arcco} alt="" />
        <span>Login</span>
        <form onSubmit={handleSubmit(handleSignIn)}>
          <input
            {...register('email')}
            name="email"
            type="email"
            placeholder="E-mail"
          />
          <input
            {...register('password')}
            name="password"
            type="password"
            placeholder="Senha"
          />
          <h5 className="return-error">{returnSignIn}</h5>
          <button type="submit">ENTRAR</button>
        </form>
      </div>
    </main>
  );
}
