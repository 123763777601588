import { createContext, useEffect } from 'react'
import { useState } from 'react'
import { api } from '../services/api'

type ProjectContextType = {
  projects: ProjectType[]
  getProjects: any
  projectsTypes: TypesType[]
  getProjectsTypes: any
  handlePageVisible: () => void
  seeMyProjects: boolean
}

type ProjectType = {
  id: number
  client: string
  client_id: number
  project_type: string
  project_type_id: number
  value: number
  area: string
  criterion: string
  local: string
  start_date: string
  start_date_unformated: number
  finish_date: string
  finish_date_prediction: string
  finish_date_prediction_unformated: string
  duration: number
  responsable: string
  responsable_id: number
  status: number
}

export type TypesType = {
  id: number
  description: string
  unit_value: string
  category: string
  project_type_category: number
  measure: string
  measure_id: number
}

export const ProjectsContext = createContext({} as ProjectContextType)

export function ProjectsProvider({ children }) {
  const [projects, setProjects] = useState<ProjectType[]>([])
  const [projectsTypes, setProjectsTypes] = useState<TypesType[]>([])

  const [seeMyProjects, setSeeMyProjects] = useState(false)

  useEffect(() => {
    if (projects.length === 0) getProjects()
    if (projectsTypes.length === 0) getProjectsTypes()
  }, [])

  function handlePageVisible() {
    setSeeMyProjects((prev) => !prev)
  }

  async function getProjectsTypes() {
    await api
      .get('/types/projects')
      .then((response) => {
        setProjectsTypes(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async function getProjects() {
    await api
      .get('/projects')
      .then((response) => {
        setProjects(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <ProjectsContext.Provider
      value={{
        projects,
        getProjects,
        getProjectsTypes,
        projectsTypes,
        handlePageVisible,
        seeMyProjects
      }}
    >
      {children}
    </ProjectsContext.Provider>
  )
}
