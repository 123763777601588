import React, { useState } from 'react';
import { mdiPencil } from '@mdi/js';
import { mdiTrashCan } from '@mdi/js';

import styles from './style.module.css';
import Icon from '@mdi/react';
import DefaultModal from '../Modals/DefaultModal';
import ModalEditTool from '../Modals/Tool/Edit';
import ModalDeleteTool from '../Modals/Tool/Delete';

type ToolParam = {
  tool: ToolType;
  refs: Header[];
  handleToast: any;
};

type Header = {
  label: string;
  ref: string;
};

type ToolType = {
  id: number;
  description: string;
};

function Tool({ tool, refs, handleToast }: ToolParam) {
  const [modalEditToolOpen, setModalEditToolOpen] = useState(false);
  const [modalDeleteToolOpen, setModalDeleteToolOpen] = useState(false);

  return (
    <tr className={styles.box}>
      {refs.map((ref) => (
        <td key={ref.ref}>{tool[ref.ref]}</td>
      ))}

      <td className={styles.buttons}>
        <span onClick={() => setModalEditToolOpen(true)}>
          <Icon path={mdiPencil} size={1.2} className={styles.icon__pencil} />
        </span>
        <span onClick={() => setModalDeleteToolOpen(true)}>
          <Icon path={mdiTrashCan} size={1.2} className={styles.icon__trash} />
        </span>
      </td>

      <DefaultModal
        handleOpen={setModalEditToolOpen}
        isOpen={modalEditToolOpen}
      >
        <ModalEditTool
          tool={tool}
          setModalEditToolOpen={setModalEditToolOpen}
          handleToast={handleToast}
        />
      </DefaultModal>

      <DefaultModal
        handleOpen={setModalDeleteToolOpen}
        isOpen={modalDeleteToolOpen}
      >
        <ModalDeleteTool
          tool={tool}
          setModalDeleteToolOpen={setModalDeleteToolOpen}
          handleToast={handleToast}
        />
      </DefaultModal>
    </tr>
  );
}

export default React.memo(Tool);
