import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Cookies from 'js-cookie';

import arrowBack from '../../assets/icons/arrow-back.svg';

import './style.css';

type ConfigType = {
  title: string;
  links: ConfigLinksType[];
};

type ConfigLinksType = {
  label: string;
  href: string;
  roles: number[];
};

type PropsSidebar = {
  config: ConfigType;
};

export default function InternalSidebar({ config }: PropsSidebar) {
  const [isOpened, setIsOpened] = useState(true);

  const role = Number(Cookies.get('role'));
  const history = useHistory();
  const pathname = window.location.pathname;

  function closeInternalSidebar() {
    const bar = document.getElementById('aside');

    bar.style.transition = '0.5s all';
    bar.style.marginLeft = '-55px';
    bar.style.cursor = 'pointer';

    setIsOpened(false);
  }

  function openBackInternalSidebar() {
    if (!isOpened) {
      const bar = document.getElementById('aside');

      bar.style.transition = '0.5s all';
      bar.style.marginLeft = '90px';
      bar.style.cursor = 'unset';

      setIsOpened(true);
    } else {
      return;
    }
  }

  return (
    <aside
      id="aside"
      className="internal-sidebar"
      onClick={() => openBackInternalSidebar()}
    >
      <div>
        <h5 className="title">{config.title}</h5>

        <ul>
          {config.links.map((link, index) =>
            link.roles.find((number) => number === role) ? (
              <li
                onClick={() => {
                  history.push({
                    pathname: `${link.href}`,
                    state: {
                      pageTitle: link.label,
                    },
                  });
                }}
                className={pathname === link.href ? 'actual' : ''}
                key={index}
              >
                {link.label}
              </li>
            ) : null
          )}
        </ul>
      </div>

      <img
        alt="arrow-back"
        src={arrowBack}
        onClick={() => closeInternalSidebar()}
      />
    </aside>
  );
}
