import { createContext, useEffect } from 'react'
import { useState } from 'react'
import { api } from '../services/api'

type ClientsContextType = {
  getClients: () => Promise<void>
  clients: ClientType[]
}

export type ClientType = {
  id: number
  name: string
  projects_start_date: string
  projects_finish_date: string
  unformated_projects_start_date: string
  unformated_projects_finish_date: string
  folder_created: number
  folder_archived: number
}

export const ClientsContext = createContext({} as ClientsContextType)

export function ClientProvider({ children }) {
  const [clients, setClients] = useState<ClientType[]>([])

  useEffect(() => {
    if (clients.length === 0) getClients()
  }, [])

  async function getClients() {
    await api
      .get('/clients')
      .then((response) => {
        setClients(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return <ClientsContext.Provider value={{ clients, getClients }}>{children}</ClientsContext.Provider>
}
