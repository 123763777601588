import { useState, BaseSyntheticEvent } from 'react'
import { mdiCheckBold, mdiCloseBox } from '@mdi/js'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { api } from '../../../../services/api'
import { ptBR } from 'date-fns/locale'
import { TextField } from '@mui/material'

import Icon from '@mdi/react'
import styles from './style.module.css'
import Toast from '../../../Toast'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

export default function ModalFinishProject({ project, setModalFinishProjectOpen, refresh, handleToast }) {
  const [finishDate, setFinishDate] = useState(null)

  async function finishProject(e: BaseSyntheticEvent) {
    e.preventDefault()

    try {
      const response = await api.put(`/projects/finish/${project.id}`, { finish_date: finishDate })

      if (response.status === 200) {
        handleToast(<Toast category="success" message="Projeto finalizada com sucesso." />)
        refresh()
        setModalFinishProjectOpen(false)
      }
    } catch (err) {
      handleToast(<Toast category="error" message={err.message} />)
    }
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiCheckBold} size={1.5} />
            <h4 className={styles.title}>finalizar projeto</h4>

            <div className={styles.close__button} onClick={() => setModalFinishProjectOpen(false)}>
              <Icon path={mdiCloseBox} size={1.5} />
            </div>
          </div>

          <form onSubmit={(e) => finishProject(e)}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Data de Finalização"
                mask="__/__/____"
                value={finishDate}
                onChange={(newValue) => {
                  setFinishDate(`${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`)
                }}
                renderInput={(params) => <TextField fullWidth {...params} required />}
              />
            </LocalizationProvider>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalFinishProjectOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
