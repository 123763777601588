import styles from './style.module.css';

import Sidebar from '../../components/Sidebar';
import links from '../../components/configs/links-configs';

export default function Configs() {
  return (
    <div className={styles.main}>
      <Sidebar config={links} />
    </div>
  );
}
