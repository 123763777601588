import { useContext, useEffect, useState } from 'react';
import { api } from '../../services/api';
import { useForm } from 'react-hook-form';

import Cookies from 'js-cookie';

import Loading from '../../assets/loading.gif';
import ArccoRox from '../../assets/grupo-arcco.png';
import empty from '../../assets/empty.svg';
import iPower from '../../assets/icons/power-black.svg';

import ContractIcon from '../../assets/icons/contract.svg';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ptBR from 'date-fns/locale/pt-BR';

import ClientInvoiceRequest from '../../components/ClientInvoiceRequest';

import styles from './style.module.css';
import { apiImages } from '../../services/apiImages';
import Toast from '../../components/Toast';
import { AuthContext } from '../../contexts/AuthContext';

interface InvoiceRequestSubmitType {
  number: number;
  date: string;
  value: string;
}

interface MyInvoicesType {
  id: number;
  user_id: number;
  number: string;
  value: string;
  date: string;
  date_unformated: string;
  status: number;
}

export default function ClientInvoiceRequestDashboard() {
  const { logout } = useContext(AuthContext);

  const [myInvoices, setMyInvoices] = useState<MyInvoicesType[]>([]);

  const [preLoading, setPreLoading] = useState(false);
  const [value, setValue] = useState<string | null>(null);
  const [invoice, setInvoice] = useState<FileList | null>(null);

  const [toast, setToast] = useState<any>();

  const { register, handleSubmit, reset } = useForm();

  const user_id = Cookies.get('user_id');

  useEffect(() => {
    getMyInvoices();
  }, []);

  function newRequirement(data) {
    if (!invoice) {
      handleToast(
        <Toast category="error" message="É necessário anexar a nota fiscal." />
      );

      return;
    }

    let formDataImage = new FormData();

    if (invoice[0]) {
      formDataImage.append('invoice', invoice[0]);

      apiImages
        .post('/images/invoices', formDataImage)
        .then((response) => {
          data['invoice'] = response.data.filename;

          newInvoice(data);
        })
        .catch((err) => {
          handleToast(<Toast category="error" message={err.message} />);
        });
    } else {
      delete data.invoice;

      newInvoice(data);
    }
  }

  function newInvoice(data: InvoiceRequestSubmitType) {
    const date = new Date(value);

    data['date'] = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, '0')}-${date.getDate()}`;
    data['user_id'] = user_id;

    console.log(data);
    api
      .post('/invoices', data)
      .then((response) => {
        getMyInvoices();
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
        reset();
        setValue(null);
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />);
      });
  }

  async function getMyInvoices(): Promise<void> {
    await api
      .get(`/invoices/${user_id}`)
      .then((response) => {
        setMyInvoices(response.data);
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />);
      });
  }

  function handleToast(toast) {
    setToast(toast);

    setTimeout(() => {
      setToast(null);
    }, 5000);
  }

  return (
    <div className={styles.content}>
      {toast}

      <div className={styles.header__inv}>
        <img className={styles.image} src={ArccoRox} alt="" />
        <img
          className={styles.icon}
          alt="icon-logout"
          onClick={() => logout()}
          src={iPower}
        />
      </div>

      {preLoading ? (
        <img src={Loading} width="321" className={styles.loading} />
      ) : (
        <div
          style={{ paddingBottom: '3rem' }}
          className={styles.invoices__request}
        >
          <h4>
            <img src={ContractIcon} alt="contract" />
            CADASTRAR NOTA FISCAL
          </h4>
          <form onSubmit={handleSubmit(newRequirement)}>
            <TextField label="Nº da nota" {...register('number')} required />
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Data da nota"
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
            <TextField label="Valor" {...register('value')} required />
            <Button variant="contained" component="label">
              ANEXAR NOTA FISCAL
              <input
                onChange={(e) => setInvoice(e.target.files)}
                type="file"
                name="file"
                hidden
              />
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              size="large"
              className={styles.btn}
            >
              SOLICITAR
            </Button>
          </form>

          {myInvoices.length ? (
            myInvoices.map((invoice) => (
              <ClientInvoiceRequest
                invoice={invoice}
                handleToast={handleToast}
                getMyInvoices={getMyInvoices}
              />
            ))
          ) : (
            <div className={styles.emptyDataBox}>
              <h3>Não encontramos informações para listar.</h3>
              <img src={empty} alt="empty" />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
