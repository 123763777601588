import { SetStateAction, Dispatch } from 'react'
import { api } from '../../../../../../../../services/api'
import { mdiCloseBox, mdiPlaySpeed } from '@mdi/js'
import { ScheduleType } from '../../..'

import Icon from '@mdi/react'
import styles from './style.module.css'
import Toast from '../../../../../../../Toast'

interface ScheduleBoxProps {
  schedule: ScheduleType
  downgradeSchedule: () => void
  setScheduleBoxDowngradeStateOpen: Dispatch<SetStateAction<boolean>>
  handleToast: (toast: JSX.Element) => void
}

export default function ScheduleBoxDowngradeState({ schedule, setScheduleBoxDowngradeStateOpen, downgradeSchedule, handleToast }: ScheduleBoxProps) {
  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiPlaySpeed} size={1.5} />
            <h4 className={styles.title}>voltar status da etapa</h4>

            <div className={styles.close__button} onClick={() => setScheduleBoxDowngradeStateOpen(false)}>
              <Icon path={mdiCloseBox} size={1.5} />
            </div>
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm} type="button" onClick={() => downgradeSchedule()}>
              Confirmar
            </button>
            <button className={styles.cancel} type="button" onClick={() => setScheduleBoxDowngradeStateOpen(false)}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
