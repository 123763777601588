import styles from './style.module.css'

export default function Parcel({ parcel }) {
  return (
    <tr className={`${styles.parcel__box} ${parcel.status === 2 && styles.paid}`}>
      <td>{parcel.date}</td>
      <td>
        {Number(parcel.value).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL'
        })}
      </td>
      <td align="right">{parcel.payday ? <b>PAGO</b> : 'A PAGAR'}</td>
    </tr>
  )
}
