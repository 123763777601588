import { BaseSyntheticEvent, useContext, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { StagesContext } from '../../../../../../contexts/StageContext'
import { MeasureContext } from '../../../../../../contexts/MeasureContext'
import { mdiHome } from '@mdi/js'
import { api } from '../../../../../../services/api'

import Icon from '@mdi/react'
import Toast from '../../../../../Toast'
import styles from './style.module.css'

type SubmitType = {
  stage_id: number
  measure_id: number
  quantity: number
  unitary_price: number
}

type InputType = {
  value: string
  name: string
}

type ClientType = {
  id: number
  description: string
}

export default function ModalCreateConstructionStageBudget({
  budgetId,
  constructionId,
  setModalCreateConstructionStageBudgetOpen,
  getConstructionBudgetStages,
  handleToast
}) {
  const [formValues, setFormValues] = useState({} as SubmitType)

  const { stages } = useContext(StagesContext)
  const { measures } = useContext(MeasureContext)

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function handleCreate(e: BaseSyntheticEvent) {
    e.preventDefault()

    api
      .post(`/constructions_schedules/${constructionId}`, {
        stage_id: formValues.stage_id,
        measure_id: formValues.measure_id,
        proposed_quantity: String(formValues.quantity).replace(',', '.'),
        proposed_cost_price: String(formValues.unitary_price).replace(',', '.')
      })
      .then((response) => {
        handleToast(<Toast category="success" message={response.data.message} />)
        getConstructionBudgetStages()
        setModalCreateConstructionStageBudgetOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiHome} size={1.5} />
            <h4 className={styles.title}>CADASTRAR ETAPA DE OBRA</h4>
          </div>

          <form className={styles.form__create} onSubmit={(e) => handleCreate(e)}>
            <Autocomplete
              value={undefined}
              onChange={(event, newValue: ClientType) => {
                handleInputChange({
                  name: 'stage_id',
                  value: String(newValue.id)
                })
              }}
              options={stages}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Etapa" required />}
            />

            <Autocomplete
              value={undefined}
              onChange={(event, newValue: ClientType) => {
                handleInputChange({
                  name: 'measure_id',
                  value: String(newValue.id)
                })
              }}
              options={measures}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Un. de Medida" required />}
            />

            <TextField
              label="Quantidade"
              type="text"
              required
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'quantity',
                  value: e.target.value
                })
              }
            />

            <TextField
              label="Valor Unitário"
              type="text"
              required
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'unitary_price',
                  value: e.target.value
                })
              }
            />

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalCreateConstructionStageBudgetOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
