import { createContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { api } from '../services/api';

type AuthContextType = {
  isAuthenticated: boolean;
  role: number | string;
  signIn: (data: SignInData) => Promise<string>;
  logout: () => Promise<void>;
};

type SignInData = {
  email: string;
  password: string;
};

type AuthPayloadType = {
  role: number | string;
  user_id: number;
  token: string;
};

export const AuthContext = createContext({} as AuthContextType);

export function AuthProvider({ children }) {
  const [token, setToken] = useState('');
  const [role, setRole] = useState(0 || '');

  const isAuthenticated = !!token;

  useEffect(() => {
    const tokenAlreadyExists = Cookies.get('token');

    if (tokenAlreadyExists) {
      verifyToken(tokenAlreadyExists);
    } else {
      if (window.location.pathname !== '/' && !isAuthenticated) {
        window.location.href = '/';
      }
    }
  }, [isAuthenticated]);

  async function verifyToken(token: string) {
    const tokenIsValid = await api
      .get(`/users/verify-token/${token}`)
      .then((response) => {
        return response.data.isValid;
      })
      .catch((err) => {
        console.log(err);
      });

    if (!tokenIsValid) {
      Cookies.remove('token');

      window.location.href = '/';
    } else {
      if (window.location.pathname === '/') {
        if (Cookies.get('role') === 'client') {
          window.location.href = '/client';
        } else {
          window.location.href = '/dashboard';
        }
      }
    }
  }

  async function signIn({ email, password }: SignInData) {
    const returnSignIn = await api
      .post('/users/login', {
        email,
        password,
      })
      .then((response) => {
        if (response.status === 401) return false;

        const { role, token, user_id }: AuthPayloadType = response.data;

        return {
          role,
          token,
          user_id,
        };
      })
      .catch((err) => {
        return null;
      });

    if (!returnSignIn) {
      return 'Falha na autenticação, e-mail ou senha inválida.';
    }

    Cookies.set('token', returnSignIn.token, { expires: 1 });
    Cookies.set('role', returnSignIn.role);
    Cookies.set('user_id', returnSignIn.user_id);

    api.defaults.headers['Authorization'] = `Bearer ${returnSignIn.token}`;

    setToken(returnSignIn.token);
    setRole(returnSignIn.role);

    if (returnSignIn.role === 'client') {
      window.location.href = '/client';
    } else if (returnSignIn.role === 7) {
      window.location.href = '/invoices/request';
    } else {
      window.location.href = '/dashboard';
    }
  }

  async function logout() {
    Cookies.remove('token');

    setToken('');

    window.location.href = '/';
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, signIn, logout, role }}>
      {children}
    </AuthContext.Provider>
  );
}
