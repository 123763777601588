import { BaseSyntheticEvent, useContext, useState } from 'react';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { ProjectsContext } from '../../../../contexts/ProjectsContext';
import { api } from '../../../../services/api';
import { mdiPackageVariantClosed } from '@mdi/js';

import Icon from '@mdi/react';
import Toast from '../../../Toast';
import styles from './style.module.css';
import { MeasureContext } from '../../../../contexts/MeasureContext';

type SubmitType = {
  id: number;
  description: string;
  unit_value: string;
  category: string;
  project_type_category: number;
  measure: string;
  measure_id: number;
};

type InputType = {
  value: string;
  name: string;
};

type AutocompleteValueType = {
  id: number;
  description: string;
};

export default function ModalEditProjectType({
  type,
  setModalEditProjectTypeOpen,
  handleToast,
}) {
  const [formValues, setFormValues] = useState({} as SubmitType);

  const { getProjectsTypes } = useContext(ProjectsContext);
  const { measures } = useContext(MeasureContext);

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value,
    }));
  }

  async function handleEdit(e: BaseSyntheticEvent) {
    e.preventDefault();

    api
      .put(`/types/projects/${type.id}`, formValues)
      .then((response) => {
        getProjectsTypes();
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
        setModalEditProjectTypeOpen(false);
      })
      .catch((err) => {
        handleToast(
          <Toast category="error" message={err.response.data.message} />
        );
      });
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiPackageVariantClosed} size={1.5} />
            <h4 className={styles.title}>EDITAR TIPO DE PROJETO/ATIVIDADE</h4>
          </div>

          <form className={styles.form__edit} onSubmit={(e) => handleEdit(e)}>
            <TextField
              defaultValue={type.description}
              label="Descrição"
              type="text"
              required
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'description',
                  value: e.target.value,
                })
              }
            />

            <FormControl>
              <InputLabel required>Categoria</InputLabel>
              <Select
                defaultValue={type.project_type_category}
                value={formValues.project_type_category}
                label="Categoria"
                onChange={(e) =>
                  handleInputChange({
                    name: 'project_type_category',
                    value: String(e.target.value),
                  })
                }
              >
                <MenuItem value="">
                  <em>Nenhum</em>
                </MenuItem>
                <MenuItem value={0}>Projeto</MenuItem>
                <MenuItem value={1}>Atividade</MenuItem>
              </Select>
            </FormControl>

            <Autocomplete
              freeSolo
              defaultValue={
                measures.filter((measure) => measure.id === type.measure_id)[0]
              }
              onChange={(event, newValue: AutocompleteValueType) => {
                handleInputChange({
                  name: 'measure_id',
                  value: newValue ? String(newValue.id) : undefined,
                });
              }}
              options={measures}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => (
                <TextField {...params} label="Unidade de cobrança" required />
              )}
            />

            <TextField
              defaultValue={type.unit_value}
              label="Preço por unidade"
              type="text"
              required
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'unit_value',
                  value: e.target.value,
                })
              }
            />

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button
                className={styles.cancel}
                type="button"
                onClick={() => setModalEditProjectTypeOpen(false)}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
