import { BaseSyntheticEvent, useContext, useState } from 'react'
import { TextField } from '@mui/material'
import { ClientsContext } from '../../../../contexts/ClientsContext'
import { api } from '../../../../services/api'
import { mdiAccountTie } from '@mdi/js'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import styles from './style.module.css'

type SubmitType = {
  id: string
  name: string
  city: string
  state: string
  cpf: string
  cnpj: string
  phone: string
  address: string
  email: string
  observation: string
}

type InputType = {
  value: string
  name: string
}

export default function ModalCreateClient({ setModalCreateClientOpen, handleToast }) {
  const [formValues, setFormValues] = useState({} as SubmitType)

  const { getClients } = useContext(ClientsContext)

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function handleCreate(e: BaseSyntheticEvent) {
    e.preventDefault()

    api
      .post(`/clients`, formValues)
      .then((response) => {
        getClients()
        handleToast(<Toast category="success" message={response.data.message} />)
        setModalCreateClientOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiAccountTie} size={1.5} />
            <h4 className={styles.title}>CADASTRAR CLIENTE</h4>
          </div>

          <form className={styles.form__create} onSubmit={(e) => handleCreate(e)}>
            <TextField
              label="Nome"
              type="text"
              required
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'name',
                  value: e.target.value
                })
              }
            />
            <TextField
              label="E-mail"
              type="text"
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'email',
                  value: e.target.value
                })
              }
            />
            <TextField
              label="Telefone"
              type="text"
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'phone',
                  value: e.target.value
                })
              }
            />
            <div className={styles.input__block}>
              <TextField
                label="CPF"
                type="text"
                autoComplete="off"
                onChange={(e) =>
                  handleInputChange({
                    name: 'cpf',
                    value: e.target.value
                  })
                }
              />
              <TextField
                label="CNPJ"
                type="text"
                autoComplete="off"
                onChange={(e) =>
                  handleInputChange({
                    name: 'cnpj',
                    value: e.target.value
                  })
                }
              />
            </div>

            <div className={styles.input__block}>
              <TextField
                label="Cidade"
                type="text"
                autoComplete="off"
                onChange={(e) =>
                  handleInputChange({
                    name: 'city',
                    value: e.target.value
                  })
                }
              />
              <TextField
                label="Estado"
                type="text"
                autoComplete="off"
                onChange={(e) =>
                  handleInputChange({
                    name: 'state',
                    value: e.target.value
                  })
                }
              />
            </div>

            <TextField
              label="Endereço"
              type="text"
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'address',
                  value: e.target.value
                })
              }
            />
            <TextField
              label="Observação"
              type="text"
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'observation',
                  value: e.target.value
                })
              }
            />
            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalCreateClientOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
