import { createContext, useEffect } from 'react';
import { useState } from 'react';
import { api } from '../services/api';

type ToolsContextType = {
  getTools: () => Promise<void>;
  tools: ToolType[];
};

type ToolType = {
  id: number;
  description: string;
};

export const ToolsContext = createContext({} as ToolsContextType);

export function ToolProvider({ children }) {
  const [tools, setTools] = useState<ToolType[]>([]);

  useEffect(() => {
    if (tools.length === 0) getTools();
  }, []);

  async function getTools() {
    await api
      .get('/tools')
      .then((response) => {
        setTools(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <ToolsContext.Provider value={{ tools, getTools }}>
      {children}
    </ToolsContext.Provider>
  );
}
