import { useEffect, useState } from 'react'
import { api } from '../../../../../services/api'
import { mdiChevronDown, mdiClockFast, mdiPackageVariant } from '@mdi/js'

import Icon from '@mdi/react'
import ScheduleBox from './ScheduleBox'

import Toast from '../../../../Toast'
import iPlusWhite from '../../../../../assets/icons/plus-white.svg'
import iPrint from '../../../../../assets/icons/print.svg'
import iExcel from '../../../../../assets/icons/excel.svg'
import empty from '../../../../../assets/empty.svg'

import styles from './style.module.css'
import DefaultModal from '../../../DefaultModal'
import ModalCreateConstructionSchedule from './Create'
import PDFConstructionSchedulesTable from '../../../../PDFConstructionSchedulesTable'

import * as XLSX from 'xlsx'

export interface ScheduleType {
  id: number
  start_date_prediction: string
  start_date_prediction_unformated: string
  finish_date_prediction: string
  finish_date_prediction_unformated: string
  stage: string
  start_date: string
  start_date_unformated: string
  finish_date: string
  finish_date_unformated: string
  days_left_to_start?: number
  days_left_to_finish?: number
  days_passed_or_lefted_of_start?: number
  days_passed_or_lefted_of_finish?: number
  quantity: string
  total_worked_area: number
  cost_price: string
  proposed_quantity: string
  proposed_cost_price: string
  measure: string
  measure_id: number
  observation: string
  status: number
}

interface SchedulesParams {
  active: ScheduleType[]
  finished: ScheduleType[]
  waiting: ScheduleType[]
  deconfigured: ScheduleType[]
}

export default function ModalConstructionSchedule({ construction, setModalConstructionScheduleOpen, handleToast }) {
  let defaultSchedulesFormat = {
    active: [] as ScheduleType[],
    finished: [] as ScheduleType[],
    waiting: [] as ScheduleType[],
    deconfigured: [] as ScheduleType[]
  }

  const [schedules, setSchedules] = useState<SchedulesParams>(defaultSchedulesFormat)
  const [totalSchedules, setTotalSchedules] = useState<number>(0)

  const [modalCreateConstructionScheduleOpen, setModalCreateConstructionScheduleOpen] = useState(false)

  useEffect(() => {
    getConstructionSchedules()
  }, [])

  async function getConstructionSchedules() {
    try {
      const { data, status } = await api.get(`/constructions_schedules/${construction.id}`)

      if (status === 200) {
        setSchedules(data)

        let total: number = 0

        const arr = data.length ? [...data.active, ...data.deconfigured, ...data.waiting, ...data.finished] : []

        arr.map((item, index) => {
          total += Number(item.quantity) * Number(item.cost_price)
        })

        setTotalSchedules(total)
      }
    } catch (err) {
      handleToast(<Toast category="error" message={err.message} />)
    }
  }

  function generateXLSX() {
    var data = document.getElementById('table')

    var file = XLSX.utils.table_to_book(data, { sheet: 'sheet1' })

    XLSX.write(file, { bookType: 'xlsx', bookSST: true, type: 'base64' })

    XLSX.writeFile(file, 'planejamento.' + 'xlsx')
  }

  async function generatePDFRel() {
    await PDFConstructionSchedulesTable([...schedules.waiting, ...schedules.deconfigured, ...schedules.active, ...schedules.finished])
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <div className={styles.box__title}>
              <Icon path={mdiClockFast} size={1.5} />
              <h4 className={styles.title}>CRONOGRAMA DA OBRA</h4>
            </div>

            <div className={styles.buttons}>
              <button className={styles.excel} title="Exportar em Excel" onClick={() => generateXLSX()}>
                <img src={iExcel} alt="" />
              </button>
              <button className={styles.print} title="Exportar em PDF" onClick={() => generatePDFRel()}>
                <img src={iPrint} alt="" />
              </button>
              {/* <button title="Cadastrar Etapa" onClick={() => setModalCreateConstructionScheduleOpen(true)}>
                <img src={iPlusWhite} alt="" />
              </button> */}
            </div>
          </div>

          <div className={styles.sheet}>
            <div id="waiting" className={styles.section}>
              <h1>aguardando</h1>
              {schedules.deconfigured?.length
                ? schedules.deconfigured.map((schedule) => (
                    <ScheduleBox getConstructionSchedules={getConstructionSchedules} schedule={schedule} handleToast={handleToast} key={schedule.id} />
                  ))
                : null}
              {schedules.waiting?.length
                ? schedules.waiting?.map((schedule) => (
                    <ScheduleBox getConstructionSchedules={getConstructionSchedules} schedule={schedule} handleToast={handleToast} key={schedule.id} />
                  ))
                : null}
              {!schedules.waiting?.length && !schedules.deconfigured?.length ? (
                <div className={styles.empty__section}>
                  <Icon path={mdiPackageVariant} size={1.5} />
                  <h3>não encontramos nada para listar</h3>
                </div>
              ) : null}
            </div>
            <div id="active" className={styles.section}>
              <h1>em andamento</h1>
              {schedules.active?.length ? (
                schedules.active?.map((schedule) => (
                  <ScheduleBox getConstructionSchedules={getConstructionSchedules} schedule={schedule} handleToast={handleToast} key={schedule.id} />
                ))
              ) : (
                <div className={styles.empty__section}>
                  <Icon path={mdiPackageVariant} size={1.5} />
                  <h3>não encontramos nada para listar</h3>
                </div>
              )}
            </div>
            <div id="finished" className={styles.section}>
              <h1>finalizado</h1>
              {schedules.finished?.length ? (
                schedules.finished?.map((schedule) => (
                  <ScheduleBox getConstructionSchedules={getConstructionSchedules} schedule={schedule} handleToast={handleToast} key={schedule.id} />
                ))
              ) : (
                <div className={styles.empty__section}>
                  <Icon path={mdiPackageVariant} size={1.5} />
                  <h3>não encontramos nada para listar</h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <table id="table" style={{ display: 'none' }}>
        <thead>
          <tr>
            <th>Status</th>
            <th>Serviços</th>
            <th>Quantidade</th>
            <th>UND.</th>
            <th>Valor UN.</th>
            <th>Preço Total</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(schedules).length &&
            [...schedules.active, ...schedules.deconfigured, ...schedules.waiting, ...schedules.finished].map((schedule) => (
              <tr key={schedule.id}>
                <td>{schedule.status === 1 ? 'Aguardando' : schedule.status === 2 ? 'Em andamento' : schedule.status === 3 ? 'Finalizado' : ''}</td>
                <td>{schedule.stage}</td>
                <td>{schedule.quantity}</td>
                <td>{schedule.measure}</td>
                <td>
                  {Number(schedule.cost_price).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </td>
                <td>
                  {(Number(schedule.quantity) * Number(schedule.cost_price)).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </td>
              </tr>
            ))}
          <tr>
            <td colSpan={4}>VALOR DE MÃO DE OBRA TOTAL</td>
            <td>
              <b>
                {totalSchedules.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL'
                })}
              </b>
            </td>
          </tr>
        </tbody>
      </table>

      <DefaultModal handleOpen={setModalCreateConstructionScheduleOpen} isOpen={modalCreateConstructionScheduleOpen}>
        <ModalCreateConstructionSchedule
          constructionId={construction.id}
          getConstructionSchedules={getConstructionSchedules}
          setModalCreateConstructionScheduleOpen={setModalCreateConstructionScheduleOpen}
          handleToast={handleToast}
        />
      </DefaultModal>
    </div>
  )
}
