import { BaseSyntheticEvent, useContext, useEffect, useState } from 'react'
import { Autocomplete, TextField, Tooltip } from '@mui/material'
import { api } from '../../../../services/api'
import { mdiImagePlus, mdiPackageVariantClosed } from '@mdi/js'
import { ConstructionsContext } from '../../../../contexts/ConstructionsContext'
import { ClientsContext } from '../../../../contexts/ClientsContext'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { ptBR } from 'date-fns/locale'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

import styles from './style.module.css'
import DefaultModal from '../../DefaultModal'
import ModalAddFile from '../AddFile'
import { ClientType } from '../../../../contexts/ClientsContext'
import { ProjectsContext, TypesType } from '../../../../contexts/ProjectsContext'
import ModalConfirmActionFile from './ConfirmAction'

type SubmitType = {
  client_id: number
  project_type_id: number
  area: string
  start_date: string
}

type InputType = {
  value: string | File
  name: string
}

type AddedFile = {
  description: string
  file: File
}

export interface ProjectFile {
  id: number
  description: string
  filename: string
}

export default function ModalEditProject({ project, refresh, setModalEditProjectOpen, handleToast }) {
  const [modalAddFileOpen, setModalAddFileOpen] = useState(false)
  const [modalConfirmActionOpen, setModalConfirmActionOpen] = useState(false)

  const [formValues, setFormValues] = useState({} as SubmitType)

  const [fileIdToRemove, setFileIdToRemove] = useState<number>(null)

  const { clients } = useContext(ClientsContext)
  const { projectsTypes } = useContext(ProjectsContext)

  const [projectFiles, setProjectFiles] = useState<ProjectFile[]>([])
  const [newProjectFiles, setNewProjectFiles] = useState<AddedFile[]>([])

  useEffect(() => {
    loadProjectFiles()
  }, [])

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function edit(e: BaseSyntheticEvent) {
    e.preventDefault()

    api
      .put(`/projects/${project.id}`, formValues)
      .then((response) => {
        handleToast(<Toast category="success" message={response.data.message} />)
        saveFiles(project.id)
        refresh()
        setModalEditProjectOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  async function saveFiles(projectId: number) {
    for (const file in newProjectFiles) {
      const formData = new FormData()
      formData.append('file', newProjectFiles[file].file)
      formData.append('description', newProjectFiles[file].description)
      formData.append('project_id', projectId.toString())

      try {
        await api.post(`/projects/files`, formData)
      } catch (err) {
        console.error('fail on save project image', { err })
      }
    }
  }

  async function loadProjectFiles() {
    try {
      const { data } = await api.get(`/projects/files/${project.id}`)
      setProjectFiles(data)
    } catch (err) {
      console.error('fail on load project files', { err })
    }
  }

  function addFile(description: string, file: File) {
    setNewProjectFiles((prev) => [...prev, { description, file }])
    setModalAddFileOpen(false)
  }

  function spliceFile(description: string) {
    setNewProjectFiles((prev) => [...prev.filter((f) => f.description !== description)])
  }

  async function deleteFile() {
    try {
      await api.delete(`/projects/files/${fileIdToRemove}`)
      setProjectFiles((prev) => [...prev.filter((f) => f.id !== fileIdToRemove)])
      setModalConfirmActionOpen(false)
    } catch (err) {
      console.error({ message: 'fail on delete file', err })
    }
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiPackageVariantClosed} size={1.5} />
            <h4 className={styles.title}>EDITAR PROJETO</h4>
          </div>

          <form className={styles.form__create} onSubmit={(e) => edit(e)}>
            <Autocomplete
              value={undefined}
              defaultValue={clients.find((c) => c.id === project.client_id)}
              onChange={(event, newValue: ClientType) => {
                handleInputChange({
                  name: 'client_id',
                  value: newValue && newValue.id.toString()
                })
              }}
              options={clients}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Cliente" required />}
            />

            <Autocomplete
              value={undefined}
              defaultValue={projectsTypes.find((pt) => pt.id === project.project_type_id)}
              onChange={(event, newValue: TypesType) => {
                handleInputChange({
                  name: 'project_type_id',
                  value: newValue && newValue.id.toString()
                })
              }}
              options={projectsTypes}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Tipo do Projeto" required />}
            />

            <TextField
              label="Área/Tempo"
              type="text"
              autoComplete="off"
              defaultValue={project.area}
              onChange={(e) =>
                handleInputChange({
                  name: 'area',
                  value: e.target.value
                })
              }
            />

            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Data de Ínicio"
                mask="__/__/____"
                value={
                  formValues.start_date ? formValues.start_date : project.start_date ? `${project.start_date.split('/').reverse().join('-')}, 00:00:00` : null
                }
                onChange={(newValue) => {
                  handleInputChange({
                    name: 'start_date',
                    value: `${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`
                  })
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            <TextField
              label="Observação"
              type="text"
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'observation',
                  value: e.target.value
                })
              }
            />

            {projectFiles.length || newProjectFiles.length ? (
              <div className={styles.files}>
                {projectFiles.length
                  ? projectFiles.map((file) => (
                      <Tooltip title="Clique para remover" placement="top">
                        <div
                          className={styles.new__file}
                          onClick={() => {
                            setFileIdToRemove(file.id)
                            setModalConfirmActionOpen(true)
                          }}
                        >
                          <Icon path={mdiImagePlus} size={1.2} color="#000" /> <h3>{file.description}</h3>
                        </div>
                      </Tooltip>
                    ))
                  : null}

                {newProjectFiles.length
                  ? newProjectFiles.map((file) => (
                      <Tooltip title="Clique para remover" placement="top">
                        <div className={styles.new__file} onClick={() => spliceFile(file.description)}>
                          <Icon path={mdiImagePlus} size={1.2} color="#000" /> <h3>{file.description}</h3>
                        </div>
                      </Tooltip>
                    ))
                  : null}
              </div>
            ) : null}

            <div className={styles.add__file__button} onClick={() => setModalAddFileOpen(true)}>
              <Icon path={mdiImagePlus} size={1.2} color="#FFF" />
            </div>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalEditProjectOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>

      <DefaultModal handleOpen={setModalAddFileOpen} isOpen={modalAddFileOpen}>
        <ModalAddFile setModalAddFileOpen={setModalAddFileOpen} addFile={addFile} />
      </DefaultModal>

      <DefaultModal handleOpen={setModalConfirmActionOpen} isOpen={modalConfirmActionOpen}>
        <ModalConfirmActionFile setModalConfirmActionOpen={setModalConfirmActionOpen} removeFile={deleteFile} />
      </DefaultModal>
    </div>
  )
}
