import { Autocomplete, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import DiaryIcon from '../../../../../assets/icons/diary_brown.svg'
import { api } from '../../../../../services/api'
import ClientViewDiary from './ClientViewDiary'

import EmptySVG from '../../../../../assets/empty.svg'

import styles from './styles.module.css'

interface ConstructionScheduleType {
  id: number
  stage: string
  total_worked_area: number
  quantity: number
  measure: string
}

export default function ModalClientDiaries({ setModalClientDiariesOpen, constructionId }) {
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [scheduleId, setScheduleId] = useState(null)
  const [availableStages, setAvailableStages] = useState([])

  const [diaries, setDiaries] = useState(null)

  useEffect(() => {
    loadConstructionStages()
    handlerDates()
  }, [])

  function handlerDates() {
    var date = new Date()

    var day: any = date.getDate()
    var month: any = date.getMonth() + 1
    var year: any = date.getFullYear()

    var fifty: any = 0

    if (day <= 15) {
      fifty = 1
    } else {
      fifty = day - 15
    }

    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }
    if (fifty < 10) {
      fifty = '0' + fifty
    }

    const today = `${year}-${month}-${day}`
    const fiftyDays = `${year}-${month}-${fifty}`

    setInitialDate(`${fiftyDays}, 00:00:00`)
    setFinalDate(`${today}, 00:00:00`)
  }

  function filterDiaries() {
    api
      .get(`/constructions_diaries/${constructionId}/released/schedule/${scheduleId}`)
      .then((response) => {
        setDiaries(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function loadConstructionStages() {
    api
      .get(`/constructions_schedules/${constructionId}`)
      .then((response) => {
        setAvailableStages([...response.data.active, ...response.data.finished])
      })
      .catch((err) => console.log(err))
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <img src={DiaryIcon} alt="icon" />
            <h4 className={styles.title}>DIÁRIAS DE OBRA</h4>
          </div>

          <div className={styles.filter__box}>
            <Autocomplete
              value={undefined}
              id="construction__schedules"
              onChange={(event, newValue: ConstructionScheduleType) => {
                newValue && setScheduleId(newValue.id)
              }}
              options={availableStages}
              getOptionLabel={(option) => `${option.stage}`}
              renderInput={(params) => <TextField {...params} label="Etapa" required />}
              style={{ width: '100%' }}
            />

            {/* <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Data Inicial"
                mask="__/__/____"
                value={initialDate}
                renderInput={(params) => <TextField {...params} style={{ minWidth: 150 }} />}
                onChange={(newValue) => {
                  setInitialDate(`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`)
                }}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Data Final"
                mask="__/__/____"
                value={finalDate}
                renderInput={(params) => <TextField {...params} style={{ minWidth: 150 }} />}
                onChange={(newValue) => {
                  setFinalDate(`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`)
                }}
              />
            </LocalizationProvider> */}

            <button onClick={() => filterDiaries()} className={styles.confirm}>
              <span>FILTRAR</span>
            </button>
          </div>

          <div className={styles.diary}>
            {scheduleId && diaries ? (
              diaries.length ? (
                diaries.map((diary) => <ClientViewDiary diary={diary} constructionScheduleId={scheduleId} />)
              ) : (
                <h3>não encontramos nada para listar</h3>
              )
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
