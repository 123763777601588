import React, { useState } from 'react'

import Icon from '@mdi/react'

import DefaultModal from '../Modals/DefaultModal'
import ModalIncomingsParcels from '../Modals/Incoming/Parcels'
import ModalDeleteIncoming from '../Modals/Incoming/Delete'
import ModalEditIncoming from '../Modals/Incoming/Edit'

import { mdiTrashCan, mdiListStatus, mdiPencil, mdiFileDocument } from '@mdi/js'
import { IIncoming } from '../../interfaces/Incoming'
import { variables } from '../../variables'

import styles from './style.module.css'

type IncomingParam = {
  incoming: IIncoming
  refs: Header[]
  handleToast: any
}

type Header = {
  label: string
  ref: string
}

function Incoming({ incoming, refs, handleToast }: IncomingParam) {
  const [modalEditIncomingOpen, setModalEditIncomingOpen] = useState(false)
  const [modalDeleteIncomingOpen, setModalDeleteIncomingOpen] = useState(false)
  const [modalIncomingParcelsOpen, setModalIncomingParcelsOpen] = useState(false)

  return (
    <tr className={styles.box}>
      {refs.map((ref) => (
        <td key={ref.ref} title={incoming[ref.ref]}>
          {incoming[ref.ref] ? (
            ref.ref === 'value' ? (
              Number(incoming[ref.ref]).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              })
            ) : (
              incoming[ref.ref]
            )
          ) : (
            <span>------</span>
          )}
          {}
        </td>
      ))}

      <td className={styles.buttons}>
        {incoming.invoice ? (
          <span onClick={() => window.open(`${variables.api}/public/${incoming.invoice}`)} title="Nota Fiscal/Comprovante">
            <Icon path={mdiFileDocument} size={1.2} className={styles.icon__invoice} />
          </span>
        ) : null}

        {(incoming.parcels > 1 || incoming.parcel) && (
          <span onClick={() => setModalIncomingParcelsOpen(true)} title="Parcelas">
            <Icon path={mdiListStatus} size={1.2} className={styles.icon__key} />
          </span>
        )}

        {!incoming.parcel && (
          <>
            <span onClick={() => setModalEditIncomingOpen(true)} title="Editar">
              <Icon path={mdiPencil} size={1.2} className={styles.icon__pencil} />
            </span>

            <span onClick={() => setModalDeleteIncomingOpen(true)} title="Excluir">
              <Icon path={mdiTrashCan} size={1.2} className={styles.icon__trash} />
            </span>
          </>
        )}
      </td>

      <DefaultModal isOpen={modalEditIncomingOpen} handleOpen={setModalEditIncomingOpen}>
        <ModalEditIncoming incoming={incoming} handleToast={handleToast} setModalEditIncomingOpen={setModalEditIncomingOpen} />
      </DefaultModal>

      <DefaultModal isOpen={modalIncomingParcelsOpen} handleOpen={setModalIncomingParcelsOpen}>
        <ModalIncomingsParcels incoming={incoming} handleToast={handleToast} setModalIncomingParcelsOpen={setModalIncomingParcelsOpen} />
      </DefaultModal>

      <DefaultModal isOpen={modalDeleteIncomingOpen} handleOpen={setModalDeleteIncomingOpen}>
        <ModalDeleteIncoming incoming={incoming} handleToast={handleToast} setModalDeleteIncomingOpen={setModalDeleteIncomingOpen} />
      </DefaultModal>
    </tr>
  )
}

export default React.memo(Incoming)
