import { BaseSyntheticEvent, useContext, useEffect, useState } from 'react'
import { ClientsContext, ClientType } from '../../../../contexts/ClientsContext'
import { Autocomplete, FormGroup, InputLabel, TextField } from '@mui/material'
import { api } from '../../../../services/api'
import { mdiCheck, mdiFileDocument, mdiImagePlus, mdiOfficeBuilding } from '@mdi/js'
import { ConstructionsContext } from '../../../../contexts/ConstructionsContext'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { ptBR } from 'date-fns/locale'

import Icon from '@mdi/react'
import loadingGIF from '../../../../assets/loading.gif'
import Toast from '../../../Toast'
import styles from './style.module.css'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

type SubmitType = {
  address: string
  name: string
  address_number: string
  neighborhood: string
  client_id: number
  start_date: string
  delivery_forecast: string
  value?: string
  image?: string
}

type InputType = {
  value: string | File
  name: string
}

export default function ModalEditConstruction({ construction, setModalEditConstructionOpen, handleToast }) {
  const [loadingConstructionInformation, setLoadingConstructionInformation] = useState(true)
  const [formValues, setFormValues] = useState({} as SubmitType)
  const [contract, setContract] = useState<File>(null)

  const { getConstructions } = useContext(ConstructionsContext)
  const { clients } = useContext(ClientsContext)

  useEffect(() => {
    setLoadingConstructionInformation(false)
  }, [])

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function handleEdit(e: BaseSyntheticEvent) {
    e.preventDefault()

    // if (!formValues.image) {
    //   formValues.image = null
    // }

    const formData = new FormData()
    Object.keys(formValues).map((key) => formData.append(key, formValues[key]))

    api
      .put(`/constructions/${construction.id}`, formData)
      .then((response) => {
        if (contract) addContract(Number(construction.id), contract)
        getConstructions()
        handleToast(<Toast category="success" message={response.data.message} />)
        setModalEditConstructionOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  async function addContract(constructionId: number, file: File) {
    const formData = new FormData()
    formData.append('contract', file)

    api
      .put(`/constructions/${constructionId}/contract`, formData)
      .then((_) => {})
      .catch((err) => {
        console.log(err)
        handleToast(<Toast category="error" message="Erro ao salvar o contrato" />)
      })
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiOfficeBuilding} size={1.5} />
            <h4 className={styles.title}>EDITAR OBRA</h4>
          </div>

          {loadingConstructionInformation ? (
            <div className={styles.box__loading}>
              <img src={loadingGIF} alt="loading" />
            </div>
          ) : (
            <form className={styles.form__edit} onSubmit={(e) => handleEdit(e)}>
              <Autocomplete
                value={
                  formValues.client_id
                    ? clients.find((client) => client.id == formValues.client_id)
                    : clients.find((client) => client.id === construction.client_id)
                }
                onChange={(event, newValue: ClientType) => {
                  handleInputChange({
                    name: 'client_id',
                    value: newValue && String(newValue.id)
                  })
                }}
                options={clients}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Cliente" required />}
              />

              <div className={styles.input__block}>
                <TextField
                  label="Nome da Obra"
                  type="text"
                  required
                  autoComplete="off"
                  value={formValues.name ? formValues.name : construction.name}
                  onChange={(e) =>
                    handleInputChange({
                      name: 'name',
                      value: e.target.value
                    })
                  }
                />

                <TextField
                  label="Valor"
                  type="text"
                  autoComplete="off"
                  value={formValues.value ? formValues.value : construction.value}
                  onChange={(e) =>
                    handleInputChange({
                      name: 'value',
                      value: e.target.value
                    })
                  }
                />
              </div>

              <TextField
                label="Endereço"
                type="text"
                required
                autoComplete="off"
                value={formValues.address ? formValues.address : construction.address}
                onChange={(e) =>
                  handleInputChange({
                    name: 'address',
                    value: e.target.value
                  })
                }
              />

              <div className={styles.input__block}>
                <TextField
                  label="Bairro"
                  type="text"
                  required
                  autoComplete="off"
                  value={formValues.neighborhood ? formValues.neighborhood : construction.neighborhood}
                  onChange={(e) =>
                    handleInputChange({
                      name: 'neighborhood',
                      value: e.target.value
                    })
                  }
                />
                <TextField
                  label="Número"
                  type="text"
                  required
                  autoComplete="off"
                  value={formValues.address_number ? formValues.address_number : construction.address_number}
                  onChange={(e) =>
                    handleInputChange({
                      name: 'address_number',
                      value: e.target.value
                    })
                  }
                />
              </div>

              <div className={styles.input__block}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                  <DatePicker
                    label="Data de Início"
                    mask="__/__/____"
                    value={
                      formValues.start_date
                        ? formValues.start_date
                        : construction.start_date
                        ? `${construction.start_date.split('/').reverse().join('-')}, 00:00:00`
                        : null
                    }
                    onChange={(newValue) => {
                      handleInputChange({
                        name: 'start_date',
                        value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                      })
                    }}
                    renderInput={(params) => <TextField {...params} required />}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                  <DatePicker
                    label="Previsão de Finalização"
                    mask="__/__/____"
                    value={
                      formValues.delivery_forecast
                        ? formValues.delivery_forecast
                        : construction.delivery_forecast
                        ? `${construction.delivery_forecast.split('/').reverse().join('-')}, 00:00:00`
                        : null
                    }
                    onChange={(newValue) => {
                      handleInputChange({
                        name: 'delivery_forecast',
                        value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                      })
                    }}
                    renderInput={(params) => <TextField {...params} required />}
                  />
                </LocalizationProvider>
              </div>

              <div className={styles.add__image}>
                <div className={styles.field__box} title="Adicionar Imagem">
                  <input
                    accept="image/png,image/jpg,image/jpeg"
                    style={{ display: 'none' }}
                    onChange={(e) =>
                      handleInputChange({
                        name: 'image',
                        value: e.target.files[0]
                      })
                    }
                    id="construction-image"
                    type="file"
                  />
                  <label htmlFor="construction-image">
                    <Icon path={mdiImagePlus} size={1.2} color="#FFF" />
                  </label>
                  {formValues.image ? (
                    <div className={styles.field__filled}>
                      <Icon path={mdiCheck} size={1.2} color="#FFF" />
                    </div>
                  ) : null}
                </div>

                <div className={styles.field__box} title="Adicionar Contrato">
                  <input
                    accept="application/pdf"
                    style={{ display: 'none' }}
                    onChange={(e) => setContract(e.target.files[0])}
                    id="construction-contract"
                    type="file"
                  />
                  <label htmlFor="construction-contract">
                    <Icon path={mdiFileDocument} size={1.2} color="#FFF" />
                  </label>
                  {contract ? (
                    <div className={styles.field__filled}>
                      <Icon path={mdiCheck} size={1.2} color="#FFF" />
                    </div>
                  ) : null}
                </div>
              </div>

              <div className={styles.buttons}>
                <button className={styles.confirm} type="submit">
                  Confirmar
                </button>
                <button className={styles.cancel} type="button" onClick={() => setModalEditConstructionOpen(false)}>
                  Cancelar
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}
