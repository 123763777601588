import axios from 'axios'
import Cookies from 'js-cookie'
import { variables } from '../variables'

const token = Cookies.get('token')

export const api = axios.create({
  baseURL: variables.api
})

api.interceptors.response.use((response) => {
  if (response.status === 401) {
    Cookies.remove('token')

    window.location.href = '/'
    return
  }

  return response
})

if (token) {
  api.defaults.headers['Authorization'] = `Bearer ${token}`
}
