import { useContext, useState } from 'react'
import { ClientsContext } from '../../../contexts/ClientsContext'

import styles from './style.module.css'
import Sidebar from '../../../components/Sidebar'
import links from '../../../components/configs/links-records'
import iPlus from '../../../assets/icons/plus.svg'
import TableGeneric from '../../../components/TableGeneric'
import ModalCreateClient from '../../../components/Modals/Client/Create'
import DefaultModal from '../../../components/Modals/DefaultModal'

type Header = {
  label: string
  ref: string
}

export default function Clients() {
  const [modalCreateClientOpen, setModalCreateClientOpen] = useState(false)
  const [toast, setToast] = useState<any>()

  const { clients } = useContext(ClientsContext)

  function handleToast(toast) {
    setToast(toast)

    setTimeout(() => {
      setToast(null)
    }, 5000)
  }

  const tableHeaders: Header[] = [
    {
      label: 'Nome',
      ref: 'name'
    }
  ]

  return (
    <div className={styles.main}>
      <Sidebar config={links} />

      {toast}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>CLIENTES</h1>

          <button className={styles.button} onClick={() => setModalCreateClientOpen(true)}>
            <span>NOVO CLIENTE</span>
            <img src={iPlus} alt="" />
          </button>
        </div>

        <div className={styles.table}>
          <TableGeneric headers={tableHeaders} data={clients} handleToast={handleToast} componentToRender="client" fieldToSearch="name" title="" />
        </div>

        <DefaultModal isOpen={modalCreateClientOpen} handleOpen={setModalCreateClientOpen}>
          <ModalCreateClient handleToast={handleToast} setModalCreateClientOpen={setModalCreateClientOpen} />
        </DefaultModal>
      </div>
    </div>
  )
}
