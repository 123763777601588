import { useEffect, useState } from 'react';
import { api } from '../../../../services/api';
import { mdiAccountTie } from '@mdi/js';
import { mdiPlus } from '@mdi/js';
import { mdiMinus } from '@mdi/js';

import Icon from '@mdi/react';
import loadingGIF from '../../../../assets/loading.gif';
import Toast from '../../../Toast';
import styles from './style.module.css';

type ExpensesAndIncomingsType = {
  value: string;
  payday: string;
  type: string;
};

type ClientDetailsType = {
  client: ClientType;
  constructions: ConstructionsType[];
  expenses: ExpensesAndIncomingsType[];
  incomings: ExpensesAndIncomingsType[];
};

type ConstructionsType = {
  address: string;
  id: number;
};

type ClientType = {
  id: number;
  name: string;
};

export default function ModalClientDetails({
  client,
  setModalClientDetailsOpen,
  handleToast,
}) {
  const [loadingClientDetails, setLoadingClientDetails] = useState(true);
  const [clientDetails, setClientDetails] = useState({} as ClientDetailsType);

  useEffect(() => {
    getClientDetails();
  }, []);

  function getClientDetails() {
    setLoadingClientDetails(true);

    api
      .get(`/clients/details/${client.id}`)
      .then((response) => {
        setClientDetails(response.data);
        setLoadingClientDetails(false);
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />);
      });
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiAccountTie} size={1.5} />
            <h4 className={styles.title}>DETALHES DO CLIENTE</h4>
          </div>

          {loadingClientDetails ? (
            <div className={styles.box__loading}>
              <img src={loadingGIF} alt="loading" />
            </div>
          ) : (
            <div className={styles.box__details}>
              <div className={styles.header}>
                <h4 className={styles.client__name}>{client.name}</h4>
              </div>

              <div className={styles.detail__session}>
                <span className={styles.detail__session__title}>Obras</span>

                {clientDetails.constructions.length ? (
                  clientDetails.constructions.map((building) => (
                    <div
                      key={building.id}
                      className={styles.client__building__box}
                    >
                      <h5 className={styles.client__building__address}>
                        - {building.address}
                      </h5>
                    </div>
                  ))
                ) : (
                  <span>Não possui obras cadastradas.</span>
                )}
              </div>

              <div className={styles.detail__session}>
                <span className={styles.detail__session__title}>
                  Últimas receitas
                </span>

                {clientDetails.incomings.length ? (
                  <>
                    {clientDetails.incomings.map((incoming) => (
                      <div className={styles.client__incoming__box}>
                        <div className={styles.client__incoming__value}>
                          <Icon path={mdiPlus} size={1.2} />

                          <span>R$ {incoming.value}</span>
                        </div>

                        <div className={styles.client__incoming__details}>
                          <span>
                            {incoming.payday} - {incoming.type}
                          </span>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <span>Não possui receitas cadastradas.</span>
                )}
              </div>

              <div className={styles.detail__session}>
                <span className={styles.detail__session__title}>
                  Últimas despesas
                </span>

                {clientDetails.expenses.length ? (
                  <>
                    {clientDetails.expenses.map((expense) => (
                      <div className={styles.client__incoming__box}>
                        <div className={styles.client__incoming__value}>
                          <Icon path={mdiMinus} size={1.2} />

                          <span>R$ {expense.value}</span>
                        </div>

                        <div className={styles.client__incoming__details}>
                          <span>
                            {expense.payday} - {expense.type}
                          </span>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <span>Não possui despesas cadastradas.</span>
                )}
              </div>

              <button
                className={styles.buttonModal}
                onClick={() => setModalClientDetailsOpen(false)}
              >
                <span>Fechar</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
