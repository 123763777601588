import { useContext, useEffect, useState } from 'react'

import styles from './style.module.css'

import Sidebar from '../../../components/Sidebar'
import links from '../../../components/configs/links-records'

import { api } from '../../../services/api'

import { ClientsContext } from '../../../contexts/ClientsContext'

import Toast from '../../../components/Toast'
import ProjectClientFolderToColaborator from '../../../components/ProjectClientFolderToColaborator'
import Cookies from 'js-cookie'
import { ProjectsContext } from '../../../contexts/ProjectsContext'

type ProjectsType = {
  id: number
  client: string
  client_id: number
  project_type: string
  project_type_id: number
  value: number
  area: string
  criterion: string
  local: string
  start_date: string
  start_date_unformated: number
  finish_date: string
  finish_date_prediction: string
  finish_date_prediction_unformated: string
  duration: number
  responsable: string
  responsable_id: number
  leader_observation: string
  status: number
}

export default function ProjectsColaborator() {
  const { clients } = useContext(ClientsContext)
  const { handlePageVisible, seeMyProjects } = useContext(ProjectsContext)

  const [projects, setProjects] = useState<ProjectsType[]>([])

  const [toast, setToast] = useState<any>()
  const role = Number(Cookies.get('role'))

  useEffect(() => {
    getColaboratorProjects()
  }, [])

  function getColaboratorProjects() {
    api
      .get(`/projects/colaborator`)
      .then((response) => {
        setProjects(response.data)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.response.data.message} />)
      })
  }

  function handleToast(toast) {
    setToast(toast)

    setTimeout(() => {
      setToast(null)
    }, 5000)
  }

  return (
    <div className={styles.main}>
      <Sidebar config={links} />

      {toast}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>SEUS PROJETOS</h1>

          {role === 1 && (
            <button
              onClick={() => handlePageVisible()}
              style={{
                marginRight: 16
              }}
            >
              <span>ÁREA ADMINSTRATIVA</span>
            </button>
          )}
        </div>

        <div className={styles.table}>
          {clients.length > 0 ? (
            projects.length > 0 ? (
              clients.map((client) =>
                projects.filter((project) => project.client_id === client.id).length > 0 ? (
                  clients.filter(
                    (client) => console.log(client.id),
                    client.id === projects.filter((project) => project.client_id === client.id)[0].client_id
                  ) ? (
                    <ProjectClientFolderToColaborator
                      key={client.id}
                      client={client}
                      projects={projects.filter((project) => project.client_id === client.id)}
                      getColaboratorProjects={getColaboratorProjects}
                    />
                  ) : null
                ) : null
              )
            ) : (
              <span>Não existem projetos cadastrados para você.</span>
            )
          ) : (
            <span>Problemas ao carregar a lista, tente novamente em alguns instantes.</span>
          )}
        </div>
      </div>
    </div>
  )
}
