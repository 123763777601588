import React, { useState } from 'react'
import { mdiKey, mdiEye, mdiTrashCan, mdiPencil, mdiClock, mdiCurrencyUsd } from '@mdi/js'

import Icon from '@mdi/react'
import DefaultModal from '../Modals/DefaultModal'
import ModalEditConstruction from '../Modals/Constructions/Edit'
import ModalDeleteConstruction from '../Modals/Constructions/Delete'
import ModalConstructionDetails from '../Modals/Constructions/Details'
import ModalConstructionBudget from '../Modals/Constructions/Budget/ConstructionStageBudget/Create'

import styles from './style.module.css'
import ConstructionBudget from '../ConstructionBudget'

type ConstructionParam = {
  construction: ConstructionType
  refs: Header[]
  handleToast: any
}

type Header = {
  label: string
  ref: string
}

type ConstructionType = {
  id: number
  name: string
}

function Construction({ construction, refs, handleToast }: ConstructionParam) {
  const [modalEditConstructionOpen, setModalEditConstructionOpen] = useState(false)
  const [modalDeleteConstructionOpen, setModalDeleteConstructionOpen] = useState(false)
  const [modalConstructionDetailsOpen, setModalConstructionDetailsOpen] = useState(false)
  const [modalConstructionBudgetOpen, setModalConstructionBudgetOpen] = useState(false)

  return (
    <tr className={styles.box}>
      {refs.map((ref) => (
        <td key={ref.ref}>
          {ref.ref === 'value' && construction[ref.ref] !== null
            ? Number(construction[ref.ref]).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              })
            : construction[ref.ref]}
        </td>
      ))}

      <td className={styles.buttons}>
        <span onClick={() => setModalConstructionBudgetOpen(true)} title="Orçamento de Execução">
          <Icon path={mdiCurrencyUsd} color="green" size={1.2} />
        </span>
        <span onClick={() => setModalConstructionDetailsOpen(true)} title="Detalhes">
          <Icon path={mdiEye} color="gray" size={1.2} />
        </span>
        <span onClick={() => setModalEditConstructionOpen(true)} title="Editar">
          <Icon path={mdiPencil} color="green" size={1.2} />
        </span>
        <span onClick={() => setModalDeleteConstructionOpen(true)} title="Remover">
          <Icon path={mdiTrashCan} color="red" size={1.2} />
        </span>
      </td>

      <DefaultModal handleOpen={setModalEditConstructionOpen} isOpen={modalEditConstructionOpen}>
        <ModalEditConstruction construction={construction} setModalEditConstructionOpen={setModalEditConstructionOpen} handleToast={handleToast} />
      </DefaultModal>

      <DefaultModal handleOpen={setModalDeleteConstructionOpen} isOpen={modalDeleteConstructionOpen}>
        <ModalDeleteConstruction constructionId={construction.id} setModalDeleteConstructionOpen={setModalDeleteConstructionOpen} handleToast={handleToast} />
      </DefaultModal>

      <DefaultModal handleOpen={setModalConstructionDetailsOpen} isOpen={modalConstructionDetailsOpen}>
        <ModalConstructionDetails construction={construction} setModalConstructionDetailsOpen={setModalConstructionDetailsOpen} handleToast={handleToast} />
      </DefaultModal>

      {modalConstructionBudgetOpen && (
        <ConstructionBudget construction_id={construction.id} opening={true} setModalBudgetOpen={setModalConstructionBudgetOpen} />
      )}
    </tr>
  )
}

export default React.memo(Construction)
