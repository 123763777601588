import { useContext, useState } from 'react';

import styles from './style.module.css';

import Sidebar from '../../components/Sidebar';

import iPlus from '../../assets/icons/plus.svg';
import empty from '../../assets/empty.svg';

import { useForm } from 'react-hook-form';
import { Modal } from '@material-ui/core';
import { api } from '../../services/api';

import Toast from '../../components/Toast';

import { UsersContext } from '../../contexts/UsersContext';
import User from '../../components/User';

type SubmitType = {
  name: string;
  email: string;
  password: string;
  role: number;
};

export default function Users() {
  const { users, getUsers, roles } = useContext(UsersContext);

  const { register, handleSubmit, reset } = useForm();

  const [open, setOpen] = useState(false);

  const [toast, setToast] = useState<any>();

  function newUsers(data: SubmitType) {
    api
      .post('/users', data)
      .then((response) => {
        getUsers();
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
        setOpen(false);
        reset();
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />);
      });
  }

  function handleToast(toast) {
    setToast(toast);

    setTimeout(() => {
      setToast(null);
    }, 5000);
  }

  const bodyNewUser = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Cadastrar Usuário</h4>
          </div>

          <form className={styles.formEdit} onSubmit={handleSubmit(newUsers)}>
            <label htmlFor="name">Nome Completo: *</label>
            <input name="name" required type="text" {...register('name')} />

            <label htmlFor="email">E-mail: *</label>
            <input name="email" required type="email" {...register('email')} />

            <label htmlFor="password">Senha: *</label>
            <input
              name="password"
              required
              type="password"
              {...register('password')}
            />

            <label htmlFor="role">Tipo de Usuário: *</label>
            <select id="role" required name="role" {...register('role')}>
              <option value="0">Nenhum</option>
              {roles.map((role) => (
                <option key={role.id} value={role.id} title={role.title}>
                  {role.description}
                </option>
              ))}
            </select>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button
                className={styles.cancel}
                type="button"
                onClick={() => setOpen(false)}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.main}>
      <Sidebar />

      {toast}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>Usuários</h1>

          <button onClick={() => setOpen(true)}>
            <span>Novo Usuário</span>
            <img src={iPlus} alt="" />
          </button>
        </div>

        <div className={styles.table}>
          {users.length ? (
            users.map((user) => (
              <User key={user.id} user={user} handleToast={handleToast} />
            ))
          ) : (
            <div className={styles.emptyDataBox}>
              <h4>Não encontramos informações para listar.</h4>
              <img src={empty} alt="empty" />
            </div>
          )}
        </div>

        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyNewUser}
        </Modal>
      </div>
    </div>
  );
}
