import { getRoles } from '@testing-library/dom';
import { createContext, useEffect } from 'react';
import { useState } from 'react';
import { api } from '../services/api';

type UsersContextType = {
  getUsers: () => Promise<void>;

  users: UserType[];
  professionals: ProfessionalType[];
  roles: RolesType[];
};

type UserType = {
  id: number;
  name: string;
  email: string;
  password: string;
  role: number;
};

type ProfessionalType = {
  id: number;
  name: string;
};

type RolesType = {
  id: number;
  description: string;
  title: string;
};

export const UsersContext = createContext({} as UsersContextType);

export function UserProvider({ children }) {
  const [users, setUsers] = useState<UserType[]>([]);
  const [roles, setRoles] = useState<RolesType[]>([]);
  const [professionals, setProfessionals] = useState<ProfessionalType[]>([]);

  useEffect(() => {
    if (users.length === 0) getUsers();
    if (professionals.length === 0) getProfessionals();
    if (roles.length === 0) getRoles();
  }, []);

  async function getUsers() {
    await api
      .get('/users')
      .then((response) => {
        setUsers(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getProfessionals() {
    await api
      .get('/users/professionals')
      .then((response) => {
        setProfessionals(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getRoles() {
    await api
      .get('/users/roles')
      .then((response) => {
        setRoles(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <UsersContext.Provider
      value={{
        getUsers,
        users,
        professionals,
        roles,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}
