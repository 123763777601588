import React from 'react';
import styles from './style.module.css';

import iPencil from '../../assets/icons/pencil.svg';
import iTrash from '../../assets/icons/trash.svg';

import { Modal } from '@material-ui/core';
import { useState } from 'react';

import { useForm } from 'react-hook-form';
import { api } from '../../services/api';

import Toast from '../Toast';
import { useContext } from 'react';

import { useEffect } from 'react';
import { UsersContext } from '../../contexts/UsersContext';

type MessageParam = {
  message: MessageType;
  handleToast: any;
};

type MessageType = {
  id: number;
  name: string;
  email: string;
  message: string;
  created_at: string;
};

type SubmitType = {
  name: string;
  email: string;
  message: string;
};

function Message({ message, handleToast }: MessageParam) {
  const { register, handleSubmit } = useForm();

  const [messages, setMessages] = useState<MessageType[]>([]);

  const [open, setOpen] = useState(false);
  const [modalBody, setModalBody] = useState(<div></div>);

  function getMessages() {
    api
      .get('/contacts')
      .then((response) => {
        setMessages(response.data);
      })
      .then((err) => {
        console.log(err);
      });
  }

  function deleteMessage() {
    api
      .delete(`/contacts/${message.id}`)
      .then((response) => {
        getMessages();
        setOpen(false);
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function handleEdit(data: SubmitType) {
    api
      .put(`/contacts/${message.id}`, data)
      .then((response) => {
        getMessages();
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleModalOpen(body: string) {
    if (body === 'edit') {
      setModalBody(bodyEditUser);
    } else if (body === 'delete') {
      setModalBody(bodyDeleteUser);
    }

    setOpen(true);
  }

  const bodyDeleteUser = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>
              Deseja realmente deletar essa mensagem?
            </h4>
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm} onClick={() => deleteMessage()}>
              Confirmar
            </button>
            <button
              className={styles.cancel}
              type="button"
              onClick={() => setOpen(false)}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const bodyEditUser = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Editar Mensagem</h4>
          </div>

          <form className={styles.formEdit} onSubmit={handleSubmit(handleEdit)}>
            <label htmlFor="name">Nome Completo: *</label>
            <input
              name="name"
              type="text"
              {...register('name')}
              defaultValue={message.name}
            />

            <label htmlFor="email">E-mail: *</label>
            <input
              name="email"
              type="email"
              {...register('email')}
              defaultValue={message.email}
            />

            <label htmlFor="message">Mensagem: *</label>
            <textarea
              name="message"
              {...register('message')}
              defaultValue={message.message}
            />

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button
                className={styles.cancel}
                type="button"
                onClick={() => setOpen(false)}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.box}>
      <div className={styles.boxInfoConstruction}>
        <span>{message.name}</span>
        <span>{message.email}</span>
      </div>

      <div className={styles.boxInfoConstructionRole}>
        <span>{message.message}</span>
      </div>

      <div className={styles.buttons}>
        <img src={iPencil} alt="" onClick={() => handleModalOpen('edit')} />
        <img src={iTrash} alt="" onClick={() => handleModalOpen('delete')} />
      </div>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>
    </div>
  );
}

export default React.memo(Message);
