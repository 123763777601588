import { useContext, useState } from 'react';
import { ProjectsContext } from '../../../contexts/ProjectsContext';

import Sidebar from '../../../components/Sidebar';
import links from '../../../components/configs/links-configs';
import iPlus from '../../../assets/icons/plus.svg';
import TableGeneric from '../../../components/TableGeneric';
import DefaultModal from '../../../components/Modals/DefaultModal';
import ModalCreateProjectType from '../../../components/Modals/ProjectType/Create';

import styles from './style.module.css';

type Header = {
  label: string;
  ref: string;
  type?: string;
};

export default function ProjectTypes() {
  const [modalCreateProjectTypeOpen, setModalCreateProjectTypeOpen] =
    useState(false);
  const [toast, setToast] = useState<any>();

  const { projectsTypes } = useContext(ProjectsContext);

  const tableHeaders: Header[] = [
    {
      label: 'Descrição',
      ref: 'description',
    },
    {
      label: 'Categoria',
      ref: 'category',
    },
    {
      label: 'Un. de Cobrança',
      ref: 'measure',
    },
    {
      label: 'Preço por un.',
      ref: 'unit_value',
      type: 'monetary',
    },
  ];

  function handleToast(toast) {
    setToast(toast);

    setTimeout(() => {
      setToast(null);
    }, 5000);
  }

  return (
    <div className={styles.main}>
      <Sidebar config={links} />

      {toast}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>Tipos de Projeto</h1>

          <button
            className={styles.button}
            onClick={() => setModalCreateProjectTypeOpen(true)}
          >
            <span>Novo tipo de projeto</span>
            <img src={iPlus} alt="" />
          </button>
        </div>

        <div className={styles.table}>
          <TableGeneric
            headers={tableHeaders}
            data={projectsTypes}
            handleToast={handleToast}
            componentToRender="projectType"
            fieldToSearch="description"
            title=""
          />
        </div>

        <DefaultModal
          handleOpen={setModalCreateProjectTypeOpen}
          isOpen={modalCreateProjectTypeOpen}
        >
          <ModalCreateProjectType
            setModalCreateProjectTypeOpen={setModalCreateProjectTypeOpen}
            handleToast={handleToast}
          />
        </DefaultModal>
      </div>
    </div>
  );
}
