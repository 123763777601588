import { useContext, useState, useEffect } from 'react'

import styles from './style.module.css'

import Sidebar from '../../components/Sidebar'
import links from '../../components/configs/links-monetary'
import Toast from '../../components/Toast'
import PDFIncomingsGenerator from '../../components/PDFIncomingsGenerator'

import iPlus from '../../assets/icons/plus.svg'

import { useForm } from 'react-hook-form'
import { Dialog, Fade } from '@material-ui/core'
import { api } from '../../services/api'

import { MonetaryContext } from '../../contexts/MonetaryContext'
import { ClientsContext } from '../../contexts/ClientsContext'
import { apiImages } from '../../services/apiImages'

type SubmitType = {
  start_date: Date
  finish_date: Date
  client_id_filter: number
  construction_id: number
  condition: number
}

export default function Invoices() {
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')

  const { register, handleSubmit } = useForm()

  const [open, setOpen] = useState(false)

  const [toast, setToast] = useState<any>()

  useEffect(() => {
    handlerDates()
  }, [])

  function handlerDates() {
    var date = new Date()

    var day: any = date.getDate()
    var month: any = date.getMonth() + 1
    var year: any = date.getFullYear()

    var fifty: any = 0

    if (day <= 15) {
      fifty = 1
    } else {
      fifty = day - 15
    }

    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }
    if (fifty < 10) {
      fifty = '0' + fifty
    }

    const today = `${year}-${month}-${day}`
    const fiftyDays = `${year}-${month}-${fifty}`

    setInitialDate(fiftyDays)
    setFinalDate(today)
  }

  // function filterIncomings(data: SubmitType) {
  //   if (!data.condition) {
  //     handleToast(
  //       <Toast category="error" message="Selecione o método de busca do filtro. (Recebidas, A Receber ou Data de Criação)" />
  //     );

  //     return
  //   }

  //   api
  //     .get(
  //       `/incomings/filter/${data.start_date || initialDate}/${
  //         data.finish_date || finalDate
  //       }/${data.construction_id}/${data.client_id_filter}/${data.condition}`
  //     )
  //     .then((response) => {
  //       setIncomings(response.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  // function downloadPDF() {
  //   PDFIncomingsGenerator(incomings, initialDate, finalDate);
  // }

  function handleToast(toast) {
    setToast(toast)

    setTimeout(() => {
      setToast(null)
    }, 5000)
  }

  return (
    <div className={styles.main}>
      <Sidebar config={links} />

      {toast}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>Notas Fiscais</h1>
        </div>

        <form
          className={styles.formFilter}
          //onSubmit={handleSubmit(filterIncomings)}
        >
          Filtrar:
          <div className={styles.row}>
            <div className={styles.inputRadios}>
              <input
                type="radio"
                name="condition"
                {...register('condition')}
                value="1"
                //onClick={() => setIsParcel(false)}
              />
              Em aberto
              <input
                type="radio"
                name="condition"
                {...register('condition')}
                value="2"
                //onClick={() => setIsParcel(true)}
              />
              Pagas
              <input
                type="radio"
                name="condition"
                {...register('condition')}
                value="3"
                //onClick={() => setIsParcel(true)}
              />
              Canceladas
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.inputBlock}>
              <label htmlFor="start_date">Data inicial</label>
              <input
                type="date"
                id="start_date"
                name="start_date"
                {...register('start_date')}
                defaultValue={initialDate}
                onChange={(e) => setInitialDate(e.target.value)}
              />
            </div>

            <div className={styles.inputBlock}>
              <label htmlFor="finish_date">Data final</label>
              <input
                type="date"
                id="finish_date"
                name="finish_date"
                {...register('finish_date')}
                defaultValue={finalDate}
                onChange={(e) => setFinalDate(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.inputBlock}>
            <label htmlFor="client_id_filter">Cliente</label>
            <select id="client_id_filter" name="client_id_filter" style={{ width: '50%' }} {...register('client_id_filter')} defaultValue="0">
              <option value="0">Nenhum</option>
              {/* {clients.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))} */}
            </select>
          </div>
          <div className={styles.buttons}>
            <button className={styles.confirm}>Filtrar</button>
            <button
              //disabled={!incomings.length}
              className={styles.info}
              //onClick={() => downloadPDF()}
            >
              Baixar em PDF
            </button>
          </div>
        </form>

        <div className={styles.table}>
          {/* {incomings.length ? (
            incomings.map((incoming) => (
              <Incoming
                key={incoming.id}
                incoming={incoming}
                handleToast={handleToast}
              />
            ))
          ) : (
            <span>Não foram encontradas receitas de acordo com o filtro.</span>
          )} */}
        </div>
      </div>
    </div>
  )
}
