import { BaseSyntheticEvent, ChangeEvent, useContext, useState } from 'react'
import { Autocomplete, FormGroup, InputAdornment, TextField } from '@mui/material'
import { ConstructionsContext } from '../../../../contexts/ConstructionsContext'
import { mdiCheck, mdiHome, mdiImagePlus } from '@mdi/js'
import { api } from '../../../../services/api'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { ptBR } from 'date-fns/locale'
import { ColaboratorsContext } from '../../../../contexts/ColaboratorsContext'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import styles from './style.module.css'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import ModalDiaryStages, { StageToAdd } from './Stages'
import DefaultModal from '../../DefaultModal'

type SubmitType = {
  date: string
  construction_id: number
  construction_schedule_id: number
  worked_area: string
  colaborators: any[]
  summary: string
  status: string
  images: File[]
}

type InputType = {
  value: string | FileList
  name: string
}

type ClientType = {
  id: number
  name: string
}

export default function ModalCreateDiary({ setModalCreateDiaryOpen, handleToast }) {
  const [modalDiaryStagesOpen, setModalDiaryStagesOpen] = useState(false)

  const [formValues, setFormValues] = useState({} as SubmitType)
  const [constructionSchedules, setConstructionSchedules] = useState([])
  const [scheduleWorkedArea, setScheduleWorkedArea] = useState<number>(null)
  const [selectedSchedule, setSelectedSchedule] = useState(null)
  const [savedStages, setSavedStages] = useState<StageToAdd[]>([])
  const { getConstructions, constructions } = useContext(ConstructionsContext)
  const { colaborators } = useContext(ColaboratorsContext)

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function handleCreate(e: BaseSyntheticEvent) {
    e.preventDefault()

    if (!formValues.date || formValues.date === 'Invalid Date') {
      formValues.date = new Date().toString()
    }

    formValues.date = new Date(formValues.date).toLocaleDateString('pt-BR').split('/').reverse().join('-')

    const formData = new FormData()

    if (formValues.images) {
      const images = Array.from(formValues.images)

      for (let i = 0; i < images.length; i++) {
        formData.append('images', images[i])
      }
    }

    formData.append('date', formValues.date)
    formData.append('construction_id', formValues.construction_id.toString())
    formData.append('summary', formValues.summary)
    formData.append('stages', JSON.stringify(savedStages))

    if (!!formValues.colaborators && formValues.colaborators.length) {
      console.log('entrou')
      formData.append('colaborators', JSON.stringify(formValues.colaborators))
    }

    api
      .post(`/constructions_diaries`, formData)
      .then((response) => {
        getConstructions()
        handleToast(<Toast category="success" message={response.data.message} />)
        setModalCreateDiaryOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  async function getConstructionSchedules(construction_id: number) {
    if (!construction_id) return

    api
      .get(`/constructions_schedules/${construction_id}`)
      .then((response) => {
        setConstructionSchedules([...response.data.active, ...response.data.waiting, ...response.data.finished])
      })
      .catch((err) => console.log(err))
  }

  async function getConstructionScheduleWorkedArea(schedule_id: number) {
    setSelectedSchedule(constructionSchedules.find((schedule) => schedule.id === schedule_id))

    api
      .get(`/constructions_schedules/worked_area/${schedule_id}`)
      .then((response) => {
        setScheduleWorkedArea(response.data.worked_area)
      })
      .catch((err) => console.log(err))
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiHome} size={1.5} />
            <h4 className={styles.title}>CADASTRAR DIÁRIO DE OBRA</h4>
          </div>

          <form className={styles.form__create} onSubmit={(e) => handleCreate(e)}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Data"
                mask="__/__/____"
                value={formValues.date ? formValues.date : `${new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}
                onChange={(newValue) => {
                  handleInputChange({
                    name: 'date',
                    value: `${newValue}`
                  })
                }}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>

            <Autocomplete
              value={undefined}
              onChange={(event, newValue: ClientType) => {
                getConstructionSchedules(newValue && newValue.id)
                handleInputChange({
                  name: 'construction_id',
                  value: newValue && String(newValue.id)
                })
              }}
              options={constructions}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Obra" required />}
            />

            {formValues.construction_id && constructionSchedules.length > 0 ? (
              <>
                <Autocomplete
                  multiple
                  id="colaborators"
                  options={colaborators}
                  getOptionLabel={(option) => option.name}
                  filterSelectedOptions
                  disableCloseOnSelect
                  onChange={(event, newValue: any) => {
                    handleInputChange({
                      name: 'colaborators',
                      value: newValue
                    })
                  }}
                  renderInput={(params) => <TextField {...params} label="Colaboradores" placeholder="Selecione os colaboradores" />}
                />

                <TextField
                  label="Resumo do dia"
                  type="text"
                  required
                  multiline
                  rows={2}
                  autoComplete="off"
                  onChange={(e) =>
                    handleInputChange({
                      name: 'summary',
                      value: e.target.value
                    })
                  }
                />

                {savedStages.length
                  ? savedStages.map((stage) => (
                      <div className={styles.stage}>
                        <div className={styles.stage__progress}>
                          <h4>
                            {stage.worked_area} {constructionSchedules.find((cs) => cs.id === stage.construction_schedule_id).measure}
                          </h4>

                          <h1>{constructionSchedules.find((cs) => cs.id === stage.construction_schedule_id).stage}</h1>
                        </div>
                      </div>
                    ))
                  : null}

                <button className={styles.handle__stages} type="button" onClick={() => setModalDiaryStagesOpen(true)}>
                  GERENCIAR ETAPAS
                </button>

                <div className={styles.add__image}>
                  <div className={styles.field__box} title="Adicionar Imagens">
                    <input
                      accept="image/png,image/jpg,image/jpeg"
                      style={{ display: 'none' }}
                      onChange={(e) =>
                        handleInputChange({
                          name: 'images',
                          value: e.target.files
                        })
                      }
                      id="construction-image"
                      multiple
                      type="file"
                    />
                    <label htmlFor="construction-image">
                      <Icon path={mdiImagePlus} size={1.2} color="#FFF" />
                    </label>
                    {formValues.images ? (
                      <div className={styles.field__filled}>
                        <Icon path={mdiCheck} size={1.2} color="#FFF" />
                      </div>
                    ) : null}
                  </div>
                </div>
              </>
            ) : formValues.construction_id && constructionSchedules.length < 1 ? (
              <h3 style={{ textAlign: 'center' }}>Não existe cronograma cadastrado nessa obra.</h3>
            ) : null}

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalCreateDiaryOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>

      <DefaultModal handleOpen={setModalDiaryStagesOpen} isOpen={modalDiaryStagesOpen}>
        <ModalDiaryStages
          setModalDiaryStagesOpen={setModalDiaryStagesOpen}
          saveStages={setSavedStages}
          savedStages={savedStages}
          constructionSchedules={constructionSchedules}
        />
      </DefaultModal>
    </div>
  )
}
