import React, { useState } from 'react';
import { Icon } from '@mdi/react';
import { mdiPencil, mdiTrashCan } from '@mdi/js';

import DefaultModal from '../Modals/DefaultModal';
import ModalEditProjectType from '../Modals/ProjectType/Edit';
import ModalDeleteProjectType from '../Modals/ProjectType/Delete';

import styles from './style.module.css';

type ProjectParam = {
  type: TypeType;
  refs: Header[];
  handleToast: any;
};

type Header = {
  label: string;
  ref: string;
  type?: string;
};

type TypeType = {
  id: number;
  description: string;
  unit_value: string;
  category: string;
  project_type_category: number;
  measure: string;
  measure_id: number;
};

function ProjectType({ type, refs, handleToast }: ProjectParam) {
  const [modalEditProjectTypeOpen, setModalEditProjectTypeOpen] =
    useState(false);
  const [modalDeleteProjectTypeOpen, setModalDeleteProjectTypeOpen] =
    useState(false);

  return (
    <tr className={styles.box}>
      {refs.map((column) => (
        <td key={column.ref}>
          {column.type === 'monetary'
            ? Number(type[column.ref]).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })
            : type[column.ref]}
        </td>
      ))}

      <td className={styles.buttons}>
        <span onClick={() => setModalEditProjectTypeOpen(true)}>
          <Icon path={mdiPencil} size={1.2} className={styles.icon__pencil} />
        </span>
        <span onClick={() => setModalDeleteProjectTypeOpen(true)}>
          <Icon path={mdiTrashCan} size={1.2} className={styles.icon__trash} />
        </span>
      </td>

      <DefaultModal
        handleOpen={setModalEditProjectTypeOpen}
        isOpen={modalEditProjectTypeOpen}
      >
        <ModalEditProjectType
          type={type}
          setModalEditProjectTypeOpen={setModalEditProjectTypeOpen}
          handleToast={handleToast}
        />
      </DefaultModal>

      <DefaultModal
        handleOpen={setModalDeleteProjectTypeOpen}
        isOpen={modalDeleteProjectTypeOpen}
      >
        <ModalDeleteProjectType
          type={type}
          setModalDeleteProjectTypeOpen={setModalDeleteProjectTypeOpen}
          handleToast={handleToast}
        />
      </DefaultModal>
    </tr>
  );
}

export default React.memo(ProjectType);
