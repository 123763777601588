import { BaseSyntheticEvent, useContext, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { api } from '../../../../services/api'
import { mdiPackageVariantClosed } from '@mdi/js'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import styles from './style.module.css'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ptBR } from 'date-fns/locale'
import { MonetaryContext } from '../../../../contexts/MonetaryContext'

type MoneyOriginType = {
  id: number
  description: string
}

export default function ModalConfirmActionParcel({ parcel, getParcels, setModalConfirmActionParcelOpen, handleToast, type, action }) {
  console.log({ parcel })
  const [payday, setPayday] = useState(`${parcel.date.split('/').reverse().join('-')}, 00:00:00`)
  const [moneyOriginId, setMoneyOriginId] = useState(parcel.money_origin_id)
  const { moneyOrigins } = useContext(MonetaryContext)

  async function handleEdit(e: BaseSyntheticEvent) {
    e.preventDefault()

    api
      .put(`${type === 'expense' ? '/expenses_parcels' : '/incomings_parcels'}/${action}/${parcel.id}`, { payday, money_origin_id: moneyOriginId })
      .then((response) => {
        getParcels()
        handleToast(<Toast category="success" message={`Parcela ${action === 'pay' ? 'paga' : 'cancelada'} com sucesso.`} />)
        setModalConfirmActionParcelOpen(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiPackageVariantClosed} size={1.5} />
            <h4 className={styles.title}>{action === 'pay' ? 'PAGAR' : 'CANCELAR'} PARCELA</h4>
          </div>

          {action === 'pay' ? (
            <form className={styles.form__edit} onSubmit={(e) => handleEdit(e)}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  label="Data de Pagamento"
                  mask="__/__/____"
                  value={payday}
                  onChange={(newValue) => setPayday(`${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`)}
                  renderInput={(params) => <TextField {...params} required />}
                />
              </LocalizationProvider>

              <Autocomplete
                value={undefined}
                onChange={(event, newValue: MoneyOriginType) => {
                  setMoneyOriginId(newValue?.id)
                }}
                options={moneyOrigins}
                defaultValue={moneyOrigins.find((o) => o.id == parcel.money_origin_id)}
                getOptionLabel={(option) => option.description}
                renderInput={(params) => <TextField {...params} label="Origem Monetária" required />}
              />

              <div className={styles.buttons}>
                <button className={styles.confirm} type="submit">
                  Confirmar
                </button>
                <button className={styles.cancel} type="button" onClick={() => setModalConfirmActionParcelOpen(false)}>
                  Cancelar
                </button>
              </div>
            </form>
          ) : (
            <form className={styles.form__edit}>
              <div className={styles.buttons}>
                <button className={styles.confirm} onClick={(e) => handleEdit(e)}>
                  Confirmar
                </button>
                <button className={styles.cancel} type="button" onClick={() => setModalConfirmActionParcelOpen(false)}>
                  Cancelar
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}
