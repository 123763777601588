import { BaseSyntheticEvent } from 'react'
import { api } from '../../../../services/api'
import { mdiTools } from '@mdi/js'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import styles from './style.module.css'

export default function ModalDeleteProject({ project, setModalDeleteProjectOpen, handleToast, refresh }) {
  async function remove(e: BaseSyntheticEvent) {
    e.preventDefault()

    api
      .delete(`/projects/${project.id}`)
      .then((response) => {
        refresh()
        handleToast(<Toast category="success" message={response.data.message} />)
        setModalDeleteProjectOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message="Erro ao remover a ferramenta." />)
      })
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiTools} size={1.5} />
            <h4 className={styles.title}>DELETAR PROJETO</h4>
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm} onClick={(e) => remove(e)}>
              Confirmar
            </button>
            <button className={styles.cancel} type="button" onClick={() => setModalDeleteProjectOpen(false)}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
