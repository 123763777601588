import { useEffect, useState } from 'react';
import { api } from '../../../../services/api';
import { mdiFileDocumentEdit } from '@mdi/js';

import iPencil from '../../../../assets/icons/pencil.svg';
import iTrash from '../../../../assets/icons/trash.svg';
import iPlusWhite from '../../../../assets/icons/plus-white.svg';

import Icon from '@mdi/react';
import Toast from '../../../Toast';
import PDFColaboratorsAbsentsGenerator from '../../../PDFColaboratorsAbsentsGenerator';
import PDFColaboratorExpensesGenerator from '../../../PDFColaboratorExpensesGenerator';
import DefaultModal from '../../DefaultModal';
import ModalNewColaboratorExpense from './NewExpense';
import ModalEditColaboratorExpense from './EditExpense';
import ModalDeleteColaboratorExpense from './DeleteExpense';

import styles from './style.module.css';

type ColaboratorDetails = {
  id: number;
  name: string;
  role: string;
  cpf: string;
  mensalist: number;
  salary: string;
  nickname: string;
  pix: string;
  agency: string;
  account: string;
  bank: string;
  diaries: number;
  absents: ColaboratorAbsentsType[];
  vouchers: ColaboratorVouchersType[];
  expenses: ColaboratorExpensesType[];
  total_vouchers: string;
  total_expenses: string;
};

type ColaboratorAbsentsType = {
  id: number;
  date: string;
  is_voucher: number;
  construction_id: number;
  integral: number;
};

type ColaboratorVouchersType = {
  id: number;
  value: string;
  is_voucher: number;
  money_origin: string;
  money_origin_id: number;
  payday: string;
  payday_unformated: string;
  description: string;
};

type ColaboratorExpensesType = {
  id: number;
  value: string;
  money_origin: string;
  money_origin_id: number;
  payday: string;
  payday_unformated: string;
  description: string;
};

export default function ModalColaboratorDetails({
  colaborator,
  setModalColaboratorDetailsOpen,
  handleToast,
}) {
  const date = new Date();

  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear());

  const [colaboratorDetails, setColaboratorDetails] = useState(
    {} as ColaboratorDetails
  );

  const [filterMonth, setFilterMonth] = useState(month);
  const [filterYear, setFilterYear] = useState(year);

  const [expenseType, setExpenseType] = useState('');

  const [actualEditingExpense, setActualEditingExpense] = useState(
    {} as ColaboratorExpensesType | ColaboratorVouchersType
  );
  const [actualDeletingExpense, setActualDeletingExpense] = useState(0);

  const [modalNewColaboratorExpenseOpen, setModalNewColaboratorExpenseOpen] =
    useState(false);

  const [modalEditColaboratorExpenseOpen, setModalEditColaboratorExpenseOpen] =
    useState(false);
  const [
    modalDeleteColaboratorExpenseOpen,
    setModalDeleteColaboratorExpenseOpen,
  ] = useState(false);

  useEffect(() => {
    getColaboratorDetails();
  }, []);

  function getColaboratorDetails() {
    api
      .get(
        `/employees/expenses/details/${colaborator.id}/${filterMonth}/${filterYear}`
      )
      .then((response) => {
        setColaboratorDetails(response.data);
      })
      .catch((err) => {
        handleToast(
          <Toast
            category="error"
            message="Erro ao carregar a ficha do cliente."
          />
        );
      });
  }

  function handleFilter(e) {
    e.preventDefault();

    getColaboratorDetails();
  }

  function generateAbsentsPDF(absents: ColaboratorAbsentsType[]) {
    PDFColaboratorsAbsentsGenerator(
      absents,
      undefined,
      undefined,
      colaborator.name
    );
  }

  function handleNewExpenseModalBody(expenseType: string) {
    setExpenseType(expenseType);
    setModalNewColaboratorExpenseOpen(true);
  }

  function generateExpensesPDF(
    vouchers: ColaboratorVouchersType[],
    expenses: ColaboratorExpensesType[]
  ) {
    PDFColaboratorExpensesGenerator(vouchers, expenses, colaborator.name);
  }

  function handleOpenModalEditColaboratorExpense(
    expense: ColaboratorExpensesType | ColaboratorVouchersType
  ) {
    setActualEditingExpense(expense);
    setModalEditColaboratorExpenseOpen(true);
  }

  function handleOpenModalDeleteColaboratorExpense(expenseId: number) {
    setActualDeletingExpense(expenseId);
    setModalDeleteColaboratorExpenseOpen(true);
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiFileDocumentEdit} size={1.5} />
            <h4 className={styles.title}>FICHA DO COLABORADOR</h4>
          </div>

          {colaboratorDetails.name ? (
            <>
              <div>
                <h4 className={styles.colaborator__name}>
                  {colaboratorDetails.name}
                </h4>

                <div className={styles.info}>
                  <span>Profissão: {colaboratorDetails.role}</span>
                  <span>CPF: {colaboratorDetails.cpf}</span>

                  <span>
                    Mensalista: {colaboratorDetails.mensalist ? 'Sim' : 'Não'}
                  </span>

                  {colaboratorDetails.mensalist ? (
                    <span>
                      Salário:{' '}
                      {Number(colaboratorDetails.salary).toLocaleString(
                        'pt-br',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        }
                      )}
                    </span>
                  ) : null}

                  {colaboratorDetails.pix ? (
                    <span>PIX: {colaboratorDetails.pix}</span>
                  ) : null}

                  {colaboratorDetails.agency ? (
                    <span>Agência: {colaboratorDetails.agency}</span>
                  ) : null}

                  {colaboratorDetails.account ? (
                    <span>Conta: {colaboratorDetails.account}</span>
                  ) : null}

                  {colaboratorDetails.bank ? (
                    <span>Banco: {colaboratorDetails.bank}</span>
                  ) : null}
                </div>
              </div>

              <form
                className={styles.form__filter__details}
                onSubmit={(e) => handleFilter(e)}
              >
                <select
                  name="filter_month"
                  onChange={(e) => setFilterMonth(e.target.value)}
                  defaultValue={filterMonth}
                >
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>

                <select
                  name="filter_year"
                  onChange={(e) => setFilterYear(e.target.value)}
                  defaultValue={filterYear}
                >
                  <option value="2019">2019</option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                </select>

                <button
                  className={styles.button__filter__details}
                  type="submit"
                >
                  Filtrar
                </button>
              </form>

              <div className={styles.modal__session}>
                <div className={styles.modal__session__header}>
                  <span className={styles.modal__session__title}>Faltas</span>
                </div>

                <div className={styles.colaborator__details__box}>
                  {colaboratorDetails.absents.length ? (
                    <div>
                      <h5 className={styles.details__box__information}>
                        Existem {colaboratorDetails.absents.length} falta(s)
                        cadastrada(s) para esse colaborador esse mês.
                      </h5>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <button
                          onClick={() =>
                            generateAbsentsPDF(colaboratorDetails.absents)
                          }
                        >
                          baixar em pdf
                        </button>
                      </div>
                    </div>
                  ) : (
                    <h5 className={styles.details__box__information}>
                      Não existem faltas registradas para esse colaborador nesse
                      mês.
                    </h5>
                  )}
                </div>
              </div>

              <div className={styles.modal__session}>
                <div className={styles.modal__session__header}>
                  <span className={styles.modal__session__title}>Vales</span>

                  <button
                    onClick={() => {
                      handleNewExpenseModalBody('voucher');
                    }}
                  >
                    <img src={iPlusWhite} alt="" />
                  </button>
                </div>

                <div className={styles.colaborator__box__session}>
                  {colaboratorDetails.vouchers.length ? (
                    colaboratorDetails.vouchers.map((voucher, index) => (
                      <>
                        {index < 3 ? (
                          <div className={styles.box__item} key={voucher.id}>
                            <div className={styles.item__value}>
                              {Number(voucher.value).toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </div>

                            <div
                              className={styles.colaborator__incoming__detail}
                            >
                              {voucher.payday}
                            </div>

                            <div className={styles.buttons}>
                              <img
                                src={iPencil}
                                alt=""
                                onClick={() =>
                                  handleOpenModalEditColaboratorExpense(voucher)
                                }
                              />
                              <img
                                src={iTrash}
                                alt=""
                                onClick={() =>
                                  handleOpenModalDeleteColaboratorExpense(
                                    voucher.id
                                  )
                                }
                              />
                            </div>
                          </div>
                        ) : null}
                      </>
                    ))
                  ) : (
                    <h5 className={styles.details__box__information}>
                      O colaborador não resgatou vales esse mês.
                    </h5>
                  )}

                  {colaboratorDetails.vouchers.length ? (
                    <button
                      onClick={() =>
                        generateExpensesPDF(
                          colaboratorDetails.vouchers,
                          colaboratorDetails.expenses
                        )
                      }
                    >
                      baixar em pdf
                    </button>
                  ) : null}
                </div>
              </div>

              <div className={styles.modal__session}>
                <div className={styles.modal__session__header}>
                  <span className={styles.modal__session__title}>
                    Despesas Adicionais
                  </span>

                  <button
                    onClick={() => {
                      handleNewExpenseModalBody('expense');
                    }}
                  >
                    <img src={iPlusWhite} alt="" />
                  </button>
                </div>

                <div className={styles.colaborator__box__session}>
                  {colaboratorDetails.expenses.length ? (
                    colaboratorDetails.expenses.map((expense, index) => (
                      <>
                        {index < 3 ? (
                          <div className={styles.box__item} key={expense.id}>
                            <div className={styles.item__value}>
                              {Number(expense.value).toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </div>

                            <div className={styles.colaborator__detail}>
                              {expense.payday}
                            </div>

                            <div className={styles.buttons}>
                              <img
                                src={iPencil}
                                alt=""
                                onClick={() =>
                                  handleOpenModalEditColaboratorExpense(expense)
                                }
                              />
                              <img
                                src={iTrash}
                                alt=""
                                onClick={() =>
                                  handleOpenModalDeleteColaboratorExpense(
                                    expense.id
                                  )
                                }
                              />
                            </div>
                          </div>
                        ) : null}
                      </>
                    ))
                  ) : (
                    <h5 className={styles.details__box__information}>
                      O colaborador não teve despesas adicionais esse mês.
                    </h5>
                  )}

                  {colaboratorDetails.expenses.length ? (
                    <button
                      onClick={() =>
                        generateExpensesPDF(
                          colaboratorDetails.vouchers,
                          colaboratorDetails.expenses
                        )
                      }
                    >
                      baixar em pdf
                    </button>
                  ) : null}
                </div>
              </div>

              {Number(colaboratorDetails.total_vouchers) > 0 ? (
                <h5 className={styles.details__box__information}>
                  Total vales:
                  <b>
                    &nbsp;
                    {Number(colaboratorDetails.total_vouchers).toLocaleString(
                      'pt-br',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      }
                    )}
                  </b>
                </h5>
              ) : null}

              {Number(colaboratorDetails.total_expenses) > 0 ? (
                <h5
                  className={styles.details__box__information}
                  style={{ marginBottom: '1rem' }}
                >
                  Total despesas adicionais:
                  <b>
                    &nbsp;
                    {Number(colaboratorDetails.total_expenses).toLocaleString(
                      'pt-br',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      }
                    )}
                  </b>
                </h5>
              ) : null}

              {Number(colaboratorDetails.total_expenses) > 0 ||
              Number(colaboratorDetails.total_vouchers) > 0 ? (
                <h5
                  className={styles.details__box__information}
                  style={{ marginBottom: '1rem' }}
                >
                  Total de débitos:
                  <b>
                    &nbsp;
                    {(
                      Number(colaboratorDetails.total_expenses) +
                      Number(colaboratorDetails.total_vouchers)
                    ).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </b>
                </h5>
              ) : null}

              {
                <h5
                  className={styles.details__box__information}
                  style={{ marginBottom: '2rem' }}
                >
                  Crédito restante:
                  <b>
                    &nbsp;
                    {colaborator.mensalist
                      ? (
                          Number(colaboratorDetails.salary) -
                          (Number(colaboratorDetails.total_expenses) +
                            Number(colaboratorDetails.total_vouchers))
                        ).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })
                      : (
                          Number(colaboratorDetails.diaries) -
                          (Number(colaboratorDetails.total_expenses) +
                            Number(colaboratorDetails.total_vouchers))
                        ).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                  </b>
                </h5>
              }

              <button
                className={styles.buttonModal}
                onClick={() => setModalColaboratorDetailsOpen(false)}
              >
                <span>Fechar</span>
              </button>
            </>
          ) : (
            <span>Carregando...</span>
          )}
        </div>
      </div>

      <DefaultModal
        handleOpen={setModalNewColaboratorExpenseOpen}
        isOpen={modalNewColaboratorExpenseOpen}
      >
        <ModalNewColaboratorExpense
          expenseType={expenseType}
          colaborator={colaborator}
          setModalNewColaboratorExpenseOpen={setModalNewColaboratorExpenseOpen}
          getColaboratorDetails={getColaboratorDetails}
          handleToast={handleToast}
        />
      </DefaultModal>

      <DefaultModal
        handleOpen={setModalEditColaboratorExpenseOpen}
        isOpen={modalEditColaboratorExpenseOpen}
      >
        <ModalEditColaboratorExpense
          expense={actualEditingExpense}
          setModalEditColaboratorExpenseOpen={
            setModalEditColaboratorExpenseOpen
          }
          getColaboratorDetails={getColaboratorDetails}
          handleToast={handleToast}
        />
      </DefaultModal>

      <DefaultModal
        handleOpen={setModalDeleteColaboratorExpenseOpen}
        isOpen={modalDeleteColaboratorExpenseOpen}
      >
        <ModalDeleteColaboratorExpense
          expenseId={actualDeletingExpense}
          setModalDeleteColaboratorExpenseOpen={
            setModalDeleteColaboratorExpenseOpen
          }
          getColaboratorDetails={getColaboratorDetails}
          handleToast={handleToast}
        />
      </DefaultModal>
    </div>
  );
}
