import { useState } from 'react'

import NoImage from '../../../assets/construction_no_image.png'

import DiaryIcon from '../../../assets/icons/diary_brown.svg'
import ClockIcon from '../../../assets/icons/clock_running.svg'
import ToolIcon from '../../../assets/icons/tool.svg'
import ContractIcon from '../../../assets/icons/contract_client.svg'

import styles from './styles.module.css'
import DefaultModal from '../../Modals/DefaultModal'
import ModalClientDiaries from './Modals/Diaries'
import ModalClientViewConstructionSchedule from './Modals/Schedule'
import ModalClientViewMaterials from './Modals/Materials'
import { variables } from '../../../variables'

interface Construction {
  id: number
  address: string
  address_number: string
  name: string
  image: string
  contract: string
}

interface Props {
  construction: Construction
}

export default function Construction({ construction }: Props) {
  const [modalClientViewDiariesOpen, setModalClientViewDiariesOpen] = useState(false)
  const [modalClientViewScheduleOpen, setModalClientViewScheduleOpen] = useState(false)
  const [modalClientViewMaterialsOpen, setModalClientViewMaterialsOpen] = useState(false)

  return (
    <div className={styles.construction__box}>
      {construction.image ? (
        <img className={styles.construction__image} src={`${variables.api}/public/${construction.image}`} alt="construction-image" />
      ) : (
        <img src={NoImage} alt="construction-image" />
      )}

      <div className={styles.details}>
        <div className={styles.info}>
          <h1>{construction.name}</h1>
          <h5>
            {construction.address}, {construction.address_number}
          </h5>
        </div>

        <div className={styles.actions}>
          <img src={DiaryIcon} alt="diary" onClick={() => setModalClientViewDiariesOpen(true)} />
          <img src={ClockIcon} alt="clock" onClick={() => setModalClientViewScheduleOpen(true)} />
          <img src={ToolIcon} alt="tool" onClick={() => setModalClientViewMaterialsOpen(true)} />
          <img src={ContractIcon} alt="contract" onClick={() => window.open(`${variables.api}/public/${construction.contract}`)} />
        </div>
      </div>

      <DefaultModal isOpen={modalClientViewScheduleOpen} handleOpen={setModalClientViewScheduleOpen}>
        <ModalClientViewConstructionSchedule setModalClientViewConstructionScheduleOpen={setModalClientViewScheduleOpen} construction={construction} />
      </DefaultModal>

      <DefaultModal isOpen={modalClientViewDiariesOpen} handleOpen={setModalClientViewDiariesOpen}>
        <ModalClientDiaries setModalClientDiariesOpen={setModalClientViewDiariesOpen} constructionId={construction.id} />
      </DefaultModal>

      <DefaultModal isOpen={modalClientViewMaterialsOpen} handleOpen={setModalClientViewMaterialsOpen}>
        <ModalClientViewMaterials setModalClientViewMaterialsOpen={setModalClientViewMaterialsOpen} constructionId={construction.id} />
      </DefaultModal>
    </div>
  )
}
