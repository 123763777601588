import { BaseSyntheticEvent, useContext, useEffect, useState } from 'react'
import { ClientsContext } from '../../../../contexts/ClientsContext'
import { TextField } from '@mui/material'
import { api } from '../../../../services/api'
import { mdiAccountTie } from '@mdi/js'

import Icon from '@mdi/react'
import loadingGIF from '../../../../assets/loading.gif'
import Toast from '../../../Toast'
import styles from './style.module.css'

type SubmitType = {
  description: string
}

type InputType = {
  value: string
  name: string
}

type ClientType = {
  id: string
  name: string
  city: string
  state: string
  cpf: string
  cnpj: string
  phone: string
  address: string
  email: string
  observation: string
}

export default function ModalEditClient({ client, setModalEditClientOpen, handleToast }) {
  const [loadingClientInformation, setLoadingClientInformation] = useState(true)
  const [clientDetails, setClientDetails] = useState({} as ClientType)
  const [formValues, setFormValues] = useState({} as SubmitType)

  const { getClients } = useContext(ClientsContext)

  useEffect(() => {
    getClientInformation()
  }, [])

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  function getClientInformation() {
    setLoadingClientInformation(true)

    api
      .get(`/clients/information/${client.id}`)
      .then((response) => {
        setClientDetails(response.data)
        setLoadingClientInformation(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  async function handleEdit(e: BaseSyntheticEvent) {
    e.preventDefault()

    api
      .put(`/clients/${client.id}`, formValues)
      .then((response) => {
        getClients()
        handleToast(<Toast category="success" message={response.data.message} />)
        setModalEditClientOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiAccountTie} size={1.5} />
            <h4 className={styles.title}>EDITAR CLIENTE</h4>
          </div>

          {loadingClientInformation ? (
            <div className={styles.box__loading}>
              <img src={loadingGIF} alt="loading" />
            </div>
          ) : (
            <form className={styles.form__edit} onSubmit={(e) => handleEdit(e)}>
              <TextField
                defaultValue={clientDetails.name}
                label="Nome"
                type="text"
                required
                autoComplete="off"
                onChange={(e) =>
                  handleInputChange({
                    name: 'name',
                    value: e.target.value
                  })
                }
              />
              <TextField
                defaultValue={clientDetails.email}
                label="E-mail"
                type="text"
                autoComplete="off"
                onChange={(e) =>
                  handleInputChange({
                    name: 'email',
                    value: e.target.value
                  })
                }
              />
              <TextField
                defaultValue={clientDetails.phone}
                label="Telefone"
                type="text"
                autoComplete="off"
                onChange={(e) =>
                  handleInputChange({
                    name: 'phone',
                    value: e.target.value
                  })
                }
              />
              <div className={styles.input__block}>
                <TextField
                  defaultValue={clientDetails.cpf}
                  label="CPF"
                  type="text"
                  autoComplete="off"
                  onChange={(e) =>
                    handleInputChange({
                      name: 'cpf',
                      value: e.target.value
                    })
                  }
                />
                <TextField
                  defaultValue={clientDetails.cnpj}
                  label="CNPJ"
                  type="text"
                  autoComplete="off"
                  onChange={(e) =>
                    handleInputChange({
                      name: 'cnpj',
                      value: e.target.value
                    })
                  }
                />
              </div>

              <div className={styles.input__block}>
                <TextField
                  defaultValue={clientDetails.city}
                  label="Cidade"
                  type="text"
                  autoComplete="off"
                  onChange={(e) =>
                    handleInputChange({
                      name: 'city',
                      value: e.target.value
                    })
                  }
                />
                <TextField
                  defaultValue={clientDetails.state}
                  label="Estado"
                  type="text"
                  autoComplete="off"
                  onChange={(e) =>
                    handleInputChange({
                      name: 'state',
                      value: e.target.value
                    })
                  }
                />
              </div>

              <TextField
                defaultValue={clientDetails.address}
                label="Endereço"
                type="text"
                autoComplete="off"
                onChange={(e) =>
                  handleInputChange({
                    name: 'address',
                    value: e.target.value
                  })
                }
              />
              <TextField
                defaultValue={clientDetails.observation}
                label="Observação"
                type="text"
                autoComplete="off"
                onChange={(e) =>
                  handleInputChange({
                    name: 'observation',
                    value: e.target.value
                  })
                }
              />
              <div className={styles.buttons}>
                <button className={styles.confirm} type="submit">
                  Confirmar
                </button>
                <button className={styles.cancel} type="button" onClick={() => setModalEditClientOpen(false)}>
                  Cancelar
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}
