import { BaseSyntheticEvent, useContext, useState } from 'react'
import { Autocomplete, FormGroup, InputLabel, TextField } from '@mui/material'
import { ConstructionsContext } from '../../../../../../contexts/ConstructionsContext'
import { ClientsContext } from '../../../../../../contexts/ClientsContext'
import { mdiClock, mdiClockPlus, mdiHome } from '@mdi/js'
import { api } from '../../../../../../services/api'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { ptBR } from 'date-fns/locale'

import Icon from '@mdi/react'
import Toast from '../../../../../Toast'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

import styles from './style.module.css'
import { MeasureContext } from '../../../../../../contexts/MeasureContext'
import { StagesContext } from '../../../../../../contexts/StageContext'

type SubmitType = {
  client_id: number
  start_date: string
  start_date_formated: string
  delivery_forecast: string
  neighborhood: string
  name: string
  address: string
  address_number: number
  observation: string
  image?: any
}

type InputType = {
  value: string
  name: string
}

type MeasureType = {
  id: number
  description: string
}

type StageType = {
  id: number
  description: string
}

export default function ModalCreateConstructionSchedule({ constructionId, setModalCreateConstructionScheduleOpen, getConstructionSchedules, handleToast }) {
  const [formValues, setFormValues] = useState({} as SubmitType)

  const { measures } = useContext(MeasureContext)
  const { stages } = useContext(StagesContext)

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function handleCreate(e: BaseSyntheticEvent) {
    e.preventDefault()

    if (!formValues.image) {
      formValues.image = null
    }

    console.log(formValues)

    api
      .post(`/constructions_schedules/${constructionId}`, formValues)
      .then((response) => {
        getConstructionSchedules()
        handleToast(<Toast category="success" message={response.data.message} />)
        setModalCreateConstructionScheduleOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiClockPlus} size={1.5} />
            <h4 className={styles.title}>CADASTRAR ETAPA</h4>
          </div>

          <form className={styles.form__create} onSubmit={(e) => handleCreate(e)}>
            <Autocomplete
              value={undefined}
              onChange={(event, newValue: StageType) => {
                handleInputChange({
                  name: 'stage_id',
                  value: newValue && String(newValue.id)
                })
              }}
              options={stages}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Etapa da Obra" required />}
            />

            <TextField
              label="Quantidade"
              type="number"
              required
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'proposed_quantity',
                  value: e.target.value
                })
              }
            />

            <Autocomplete
              value={undefined}
              onChange={(event, newValue: MeasureType) => {
                handleInputChange({
                  name: 'measure_id',
                  value: String(newValue.id)
                })
              }}
              options={measures}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Un. de Medida" required />}
            />

            <TextField
              label="Preço de Venda (UN.)"
              type="text"
              required
              autoComplete="off"
              onChange={(e) =>
                handleInputChange({
                  name: 'proposed_cost_price',
                  value: e.target.value.replace(',', '.')
                })
              }
            />

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalCreateConstructionScheduleOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
