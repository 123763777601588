import { api } from '../../../../../services/api'
import { variables } from '../../../../../variables'

import iTrash from '../../../../../assets/icons/trash.svg'
import iClose from '../../../../../assets/icons/close.svg'
import Toast from '../../../../Toast'

import styles from './style.module.css'

interface ModalFullscreenImageProps {
  diary: any
  image: any
  setModalFullscreenImageOpen: any
  getDiaries?: any
  setImages: any
  handleToast?: any
  clientView?: boolean
}

export default function ModalFullscreenImage({
  diary,
  image,
  setModalFullscreenImageOpen,
  getDiaries,
  setImages,
  handleToast,
  clientView
}: ModalFullscreenImageProps) {
  function deleteImageFromDiary(image_id: number) {
    api
      .delete(`constructions_diaries/images/${image_id}`)
      .then((response) => {
        setModalFullscreenImageOpen(false)
        getDiaryImages()
        getDiaries()
        handleToast(<Toast category="success" message={response.data.message} />)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  function getDiaryImages() {
    api
      .get(`/constructions_diaries/images/${diary.id}`)
      .then((response) => {
        setImages(response.data)
        setModalFullscreenImageOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__fullscreen}>
        <div className={styles.box__content__images}>
          <img src={`${variables.api}/public/${image.src}`} alt="fullscreen" />
          {clientView ? null : (
            <div className={styles.box__options__fullscreen}>
              <button onClick={() => deleteImageFromDiary(image.id)}>
                <img src={iTrash} alt="icon-trash" />
              </button>
              <button onClick={() => setModalFullscreenImageOpen(false)}>
                <img src={iClose} alt="icon-close" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
