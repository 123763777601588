import { BaseSyntheticEvent } from 'react'
import { mdiCloseBox, mdiPlaySpeed, mdiTrashCan } from '@mdi/js'
import { api } from '../../../../../../../../services/api'

import Icon from '@mdi/react'
import styles from './style.module.css'
import Toast from '../../../../../../../Toast'

export default function ModalScheduleBoxDelete({ schedule, setModalScheduleBoxDeleteOpen, handleCloseModalAndRequestSchedules, handleToast }) {
  async function removeSchedule(e: BaseSyntheticEvent) {
    e.preventDefault()

    try {
      const response = await api.delete(`/constructions_schedules/${schedule.id}`)

      if (response.status === 200) {
        handleToast(<Toast category="success" message="Etapa removida com sucesso." />)
        handleCloseModalAndRequestSchedules()
      }
    } catch (err) {
      handleToast(<Toast category="error" message={err.message} />)
    }
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiTrashCan} size={1.5} />
            <h4 className={styles.title}>remover etapa</h4>

            <div className={styles.close__button} onClick={() => setModalScheduleBoxDeleteOpen(false)}>
              <Icon path={mdiCloseBox} size={1.5} />
            </div>
          </div>

          <form onSubmit={(e) => removeSchedule(e)}>
            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalScheduleBoxDeleteOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
