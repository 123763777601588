import { useContext, useState } from 'react'

import Sidebar from '../../../components/Sidebar'
import links from '../../../components/configs/links-records'
import iPlus from '../../../assets/icons/plus.svg'

import { ConstructionsContext } from '../../../contexts/ConstructionsContext'

import DefaultModal from '../../../components/Modals/DefaultModal'
import ModalCreateConstruction from '../../../components/Modals/Constructions/Create'
import TableGeneric from '../../../components/TableGeneric'

import styles from './style.module.css'

type Header = {
  label: string
  ref: string
}

export default function Constructions() {
  const { constructions } = useContext(ConstructionsContext)

  const [toast, setToast] = useState<any>()
  const [modalCreateConstructionOpen, setModalCreateConstructionOpen] = useState(false)

  const tableHeaders: Header[] = [
    {
      label: 'Nome',
      ref: 'name'
    },
    {
      label: 'Endereço',
      ref: 'address'
    },
    {
      label: 'Nº',
      ref: 'address_number'
    },
    {
      label: 'Bairro',
      ref: 'neighborhood'
    },
    {
      label: 'Cliente',
      ref: 'client'
    },
    {
      label: 'Valor',
      ref: 'value'
    }
  ]

  function handleToast(toast) {
    setToast(toast)

    setTimeout(() => {
      setToast(null)
    }, 5000)
  }

  return (
    <div className={styles.main}>
      <Sidebar config={links} />

      {toast}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>OBRAS</h1>

          <button className={styles.button} onClick={() => setModalCreateConstructionOpen(true)}>
            <span>NOVA OBRA</span>
            <img src={iPlus} alt="new-construction" />
          </button>
        </div>

        <div className={styles.table}>
          <TableGeneric headers={tableHeaders} data={constructions} handleToast={handleToast} componentToRender="constructions" fieldToSearch="name" title="" />
        </div>

        <DefaultModal isOpen={modalCreateConstructionOpen} handleOpen={setModalCreateConstructionOpen}>
          <ModalCreateConstruction handleToast={handleToast} setModalCreateConstructionOpen={setModalCreateConstructionOpen} />
        </DefaultModal>
      </div>
    </div>
  )
}
