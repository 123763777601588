import { useContext, useState, useEffect, FormEvent } from 'react'
import { api } from '../../services/api'
import { MonetaryContext, MoneyOriginType, TypesType } from '../../contexts/MonetaryContext'
import { ConstructionsContext, ConstructionType } from '../../contexts/ConstructionsContext'
import { ClientsContext, ClientType } from '../../contexts/ClientsContext'
import { ProvidersContext, ProviderType } from '../../contexts/ProvidersContext'
import { ptBR } from 'date-fns/locale'

import { DatePicker, LocalizationProvider } from '@mui/lab'
import { Autocomplete, TextField } from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

import iPlus from '../../assets/icons/plus.svg'

import Toast from '../../components/Toast'
import PDFExpensesGenerator from '../../components/PDFExpensesGenerator'
import DefaultModal from '../../components/Modals/DefaultModal'
import ModalCreateExpense from '../../components/Modals/Expense/Create'
import Sidebar from '../../components/Sidebar'
import links from '../../components/configs/links-monetary'

import TableGeneric from '../../components/TableGeneric'

import styles from './style.module.css'

type Header = {
  label: string
  ref: string
}

type InputType = {
  value: string
  name: string
}

type FilterType = {
  initialDate: string
  finalDate: string
  provider_id?: number
  client_id?: number
  construction_id?: number
  expense_type_id?: number
  money_origin_id?: number
  condition: string
}

export default function Expenses() {
  const { expenses, expensesTypes, setExpenses, setLastFilter, totalFilteredExpenses, setTotalFilteredExpenses } = useContext(MonetaryContext)
  const [colaboratorsExpensesInPeriod, setColaboratorsExpensesInPeriod] = useState([])
  const { clientConstructions, getConstructionsByClient } = useContext(ConstructionsContext)
  const { clients } = useContext(ClientsContext)
  const { providers } = useContext(ProvidersContext)
  const { moneyOrigins } = useContext(MonetaryContext)

  const [modalCreateExpenseOpen, setModalCreateExpenseOpen] = useState(false)
  const [toast, setToast] = useState<any>()

  const [formValues, setFormValues] = useState({} as FilterType)

  useEffect(() => {
    handlerDates()
  }, [expenses.values])

  function handlerDates() {
    var date = new Date()

    var day: any = date.getDate()
    var month: any = date.getMonth() + 1
    var year: any = date.getFullYear()

    var fifty: any = 0

    if (day <= 15) {
      fifty = 1
    } else {
      fifty = day - 15
    }

    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }
    if (fifty < 10) {
      fifty = '0' + fifty
    }

    const today = `${year}-${month}-${day}`
    const fiftyDays = `${year}-${month}-${fifty}`

    formValues.initialDate = `${fiftyDays}, 00:00:00`
    formValues.finalDate = `${today}, 00:00:00`
  }

  const tableHeaders: Header[] = [
    {
      label: formValues.condition === '1' ? 'Dt. de Pagamento' : formValues.condition === '2' ? 'Dt. de Vencimento' : 'Dt. de Cadastro',
      ref: formValues.condition === '1' ? 'payday' : formValues.condition === '2' ? 'due_date' : 'created_at'
    },
    {
      label: 'Valor',
      ref: 'value'
    },
    {
      label: 'Tipo da Despesa',
      ref: 'expense_type'
    },
    {
      label: 'Origem',
      ref: 'money_origin'
    },
    {
      label: 'Parcela',
      ref: 'parcel'
    },
    {
      label: 'Cliente',
      ref: 'client'
    },
    {
      label: 'Fornecedor',
      ref: 'provider'
    },
    {
      label: 'Colaborador',
      ref: 'employee'
    },
    {
      label: 'Descrição',
      ref: 'description'
    }
  ]

  function downloadPDF() {
    PDFExpensesGenerator([...expenses, ...colaboratorsExpensesInPeriod], formValues.initialDate.split(',')[0], formValues.finalDate.split(',')[0])
  }

  async function getColaboratorsExpensesInPeriod(initalDate: string, finalDate: string, moneyOriginId) {
    await api
      .get(`/employees/expenses/period/filter/${initalDate.split(',')[0]}/${finalDate.split(',')[0]}/${moneyOriginId}`)
      .then((response) => {
        setColaboratorsExpensesInPeriod(response.data)
      })
      .catch((error) => {
        handleToast(<Toast category="error" message="Erro ao encontrar as despesas dos colaboradores." />)
      })
  }

  function filterExpenses(e: FormEvent) {
    e.preventDefault()

    if (!formValues.condition) {
      handleToast(<Toast category="error" message="Selecione o método de busca do filtro. (Pagas, A Pagar ou Data de Criação)" />)

      return
    }

    if (formValues.condition === '1' && !formValues.client_id && !formValues.provider_id && !formValues.expense_type_id) {
      getColaboratorsExpensesInPeriod(formValues.initialDate, formValues.finalDate, formValues.money_origin_id)
    } else {
      setColaboratorsExpensesInPeriod([])
    }

    const filter = {
      ...formValues,
      initialDate: formValues.initialDate.split(',')[0],
      finalDate: formValues.finalDate.split(',')[0]
    }

    let paramQueries: string = `?`

    const fieldsToFilter = Object.keys(filter)
    fieldsToFilter.map((field) => (paramQueries = `${paramQueries}${field}=${filter[field]}&`))

    setLastFilter(`/expenses/filter/custom${paramQueries}`)

    api
      .get(`/expenses/filter/custom${paramQueries}`)
      .then((response) => {
        let total = 0

        for (const expense of response.data) {
          total = Number(total) + Number(expense.value)
        }

        setTotalFilteredExpenses(total)
        setExpenses(response.data)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  function handleToast(toast: JSX.Element) {
    setToast(toast)

    setTimeout(() => {
      setToast(null)
    }, 5000)
  }

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  return (
    <div className={styles.main}>
      <Sidebar config={links} />

      {toast}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>DESPESAS</h1>

          <button className={styles.button} onClick={() => setModalCreateExpenseOpen(true)}>
            <span>NOVA DESPESA</span>
            <img src={iPlus} alt="" />
          </button>
        </div>

        <form className={styles.form__filter} onSubmit={(e) => filterExpenses(e)}>
          <div className={styles.row}>
            <div className={styles.input__radios}>
              <input
                type="radio"
                name="condition"
                value="1"
                onClick={() =>
                  handleInputChange({
                    name: 'condition',
                    value: '1'
                  })
                }
              />
              Pagas
              <input
                type="radio"
                name="condition"
                value="2"
                onClick={() =>
                  handleInputChange({
                    name: 'condition',
                    value: '2'
                  })
                }
              />
              A Pagar
              <input
                type="radio"
                name="condition"
                value="3"
                onClick={() =>
                  handleInputChange({
                    name: 'condition',
                    value: '3'
                  })
                }
              />
              Data de Criação
            </div>

            {totalFilteredExpenses != 0 && (
              <h3 style={{ alignSelf: 'end' }}>
                TOTAL:{' '}
                {totalFilteredExpenses.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL'
                })}
              </h3>
            )}
          </div>
          <div className={styles.row}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Data Inicial"
                mask="__/__/____"
                value={formValues.initialDate}
                renderInput={(params) => <TextField {...params} style={{ minWidth: 150 }} />}
                onChange={(newValue) => {
                  handleInputChange({
                    name: 'initialDate',
                    value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                  })
                }}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Data Final"
                mask="__/__/____"
                value={formValues.finalDate}
                renderInput={(params) => <TextField {...params} style={{ minWidth: 150 }} />}
                onChange={(newValue) => {
                  handleInputChange({
                    name: 'finalDate',
                    value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                  })
                }}
              />
            </LocalizationProvider>

            <Autocomplete
              value={undefined}
              fullWidth
              onChange={(event, newValue: TypesType) => {
                handleInputChange({
                  name: 'expense_type_id',
                  value: newValue ? String(newValue.id) : undefined
                })
              }}
              options={expensesTypes}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Tipo da Despesa" />}
            />

            <Autocomplete
              value={undefined}
              fullWidth
              onChange={(event, newValue: ProviderType) => {
                handleInputChange({
                  name: 'provider_id',
                  value: newValue ? String(newValue.id) : undefined
                })
              }}
              options={providers}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Fornecedor" />}
            />
          </div>

          <div className={styles.row}>
            <Autocomplete
              value={undefined}
              fullWidth
              onChange={(event, newValue: { description: string; value: number }) => {
                console.log(newValue)
                handleInputChange({
                  name: 'subtype',
                  value: newValue && String(newValue.value)
                })
              }}
              options={[
                { description: 'Construtora', value: 1 },
                { description: 'Escritório', value: 2 }
              ]}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Subtipo da Despesa" />}
            />

            <Autocomplete
              value={undefined}
              fullWidth
              onChange={(event, newValue: ClientType) => {
                newValue && getConstructionsByClient(newValue.id)
                handleInputChange({
                  name: 'client_id',
                  value: newValue ? String(newValue.id) : undefined
                })
              }}
              options={clients}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Cliente" />}
            />

            {formValues.client_id && (
              <Autocomplete
                value={undefined}
                fullWidth
                onChange={(event, newValue: ConstructionType) => {
                  handleInputChange({
                    name: 'construction_id',
                    value: newValue ? String(newValue.id) : undefined
                  })
                }}
                options={clientConstructions}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Obra" />}
              />
            )}

            <Autocomplete
              value={undefined}
              fullWidth
              onChange={(event, newValue: MoneyOriginType) => {
                handleInputChange({
                  name: 'money_origin_id',
                  value: newValue ? String(newValue.id) : undefined
                })
              }}
              options={moneyOrigins}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Origem Monetária" />}
            />
          </div>
          <div className={styles.buttons}>
            <button type="submit" className={styles.confirm}>
              FILTRAR
            </button>
            <button disabled={!expenses.length} className={styles.info} onClick={() => downloadPDF()}>
              BAIXAR EM PDF
            </button>
          </div>
        </form>

        <div className={styles.table}>
          <TableGeneric headers={tableHeaders} data={expenses} handleToast={handleToast} componentToRender="expense" fieldToSearch="expense_type" title="" />
        </div>

        <DefaultModal isOpen={modalCreateExpenseOpen} handleOpen={setModalCreateExpenseOpen}>
          <ModalCreateExpense handleToast={handleToast} setModalCreateExpenseOpen={setModalCreateExpenseOpen} />
        </DefaultModal>
      </div>
    </div>
  )
}
