import { mdiCheckBold, mdiCloseBox, mdiPencil, mdiPlayCircle, mdiProgressAlert, mdiTrashCan } from '@mdi/js'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ScheduleType } from '../..'
import { api } from '../../../../../../../services/api'

import Icon from '@mdi/react'
import DefaultModal from '../../../../../../Modals/DefaultModal'

import Toast from '../../../../../../Toast'

import styles from './style.module.css'

interface ScheduleBoxProps {
  scheduleId: number
  setModalScheduleBoxDetailsOpen: Dispatch<SetStateAction<boolean>>
}

export default function ModalScheduleBoxDetails({ scheduleId, setModalScheduleBoxDetailsOpen }: ScheduleBoxProps) {
  const [schedule, setSchedule] = useState({} as ScheduleType)

  const {
    finish_date,
    finish_date_prediction,
    finish_date_prediction_unformated,
    finish_date_unformated,
    stage,
    start_date,
    start_date_prediction,
    start_date_prediction_unformated,
    start_date_unformated,
    status,
    observation
  } = schedule

  const daysToFinish = Math.ceil(Math.abs(new Date(finish_date_prediction_unformated).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))
  const lateFinished = Math.ceil(
    Math.abs(new Date(finish_date_unformated).getTime() - new Date(finish_date_prediction_unformated).getTime()) / (1000 * 60 * 60 * 24)
  )
  const lateStarted = Math.ceil(
    Math.abs(new Date(start_date_unformated).getTime() - new Date(start_date_prediction_unformated).getTime()) / (1000 * 60 * 60 * 24)
  )
  const finishDateIsLate = !!(new Date(finish_date_unformated) > new Date(finish_date_prediction_unformated))
  const startDateIsLate = !!(new Date(start_date_unformated) > new Date(start_date_prediction_unformated))

  useEffect(() => {
    getScheduleDetails()
  }, [])

  async function getScheduleDetails() {
    try {
      const { data } = await api.get(`/constructions_schedules/${scheduleId}/details`)
      setSchedule(data[0])
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiProgressAlert} size={1.5} />
            <h4 className={styles.title}>{stage}</h4>

            <div className={styles.close__button} onClick={() => setModalScheduleBoxDetailsOpen(false)}>
              <Icon path={mdiCloseBox} size={1.5} />
            </div>
          </div>

          {!start_date_prediction && !finish_date_prediction ? (
            <div className={styles.infos}>
              <div className={styles.unconfigured}>
                <h5>datas da etapa não configuradas, aguarde.</h5>
              </div>
            </div>
          ) : status === 2 ? (
            <div>
              <div className={styles.infos}>
                <div className={styles.separated}>
                  <div className={styles.sell__info}>
                    <h5>
                      <b>PREVISÃO DE INÍCIO:</b> {start_date_prediction}
                    </h5>
                    <h5>
                      <b>PREVISÃO DE FIM:</b> {finish_date_prediction}
                    </h5>
                  </div>
                  <div className={styles.production__info}>
                    <h5>
                      <b>INÍCIO:</b> {start_date}
                    </h5>
                    <h5>
                      <b>PREVISÃO DE FIM:</b> {finish_date_prediction}
                    </h5>
                    <h5>Restam {daysToFinish} dias para a finalização planejada.</h5>
                  </div>
                </div>
              </div>
            </div>
          ) : status === 3 ? (
            <div>
              <div className={styles.infos}>
                <div className={styles.separated}>
                  <div className={styles.sell__info}>
                    <h5>
                      <b>PREVISÃO DE INÍCIO:</b> {start_date_prediction}
                    </h5>
                    <h5>
                      <b>PREVISÃO DE FIM:</b> {finish_date_prediction}
                    </h5>
                  </div>
                  <div className={styles.production__info}>
                    <h5>
                      <b>INÍCIO:</b> {start_date} - {startDateIsLate ? `${lateStarted} dia(s) atrasado(s)` : `${lateStarted} dia(s) adiantado(s)`}
                    </h5>
                    <h5>
                      <b>FINALIZAÇÃO:</b> {finish_date} - {finishDateIsLate ? `${lateFinished} dia(s) atrasado(s)` : `${lateFinished} dia(s) adiantado(s)`}
                    </h5>
                    {observation ? (
                      <>
                        <hr />
                        <h5>
                          <b>OBSERVAÇÃO:</b> {observation}
                        </h5>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className={styles.infos}>
                <h5>
                  <b>PREVISÃO DE INÍCIO:</b> {start_date_prediction}
                </h5>
                <h5>
                  <b>PREVISÃO DE FIM:</b> {finish_date_prediction}
                </h5>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
