import { useContext, useEffect, useState } from 'react'
import { ProductsContext } from '../../../contexts/ProductsContext'

import styles from './style.module.css'
import Sidebar from '../../../components/Sidebar'
import links from '../../../components/configs/links-records'
import iPlus from '../../../assets/icons/plus.svg'
import TableGeneric from '../../../components/TableGeneric'
import DefaultModal from '../../../components/Modals/DefaultModal'
import ModalCreateProduct from '../../../components/Modals/Product/Create'

type Header = {
  label: string
  ref: string
}

export default function Products() {
  const { products, getProducts } = useContext(ProductsContext)

  const [modalCreateProductOpen, setModalCreateProductOpen] = useState(false)
  const [toast, setToast] = useState<any>()

  const tableHeaders: Header[] = [
    {
      label: 'Descrição',
      ref: 'description'
    }
  ]

  useEffect(() => {
    getProducts()
  }, [])

  function handleToast(toast) {
    setToast(toast)

    setTimeout(() => {
      setToast(null)
    }, 5000)
  }

  return (
    <div className={styles.main}>
      <Sidebar config={links} />

      {toast}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>PRODUTOS</h1>

          <button className={styles.button} onClick={() => setModalCreateProductOpen(true)}>
            <span>NOVO PRODUTO</span>
            <img src={iPlus} alt="add-product" />
          </button>
        </div>

        <div className={styles.table}>
          <TableGeneric headers={tableHeaders} data={products} handleToast={handleToast} componentToRender="product" fieldToSearch="description" title="" />
        </div>

        <DefaultModal isOpen={modalCreateProductOpen} handleOpen={setModalCreateProductOpen}>
          <ModalCreateProduct handleToast={handleToast} setModalCreateProductOpen={setModalCreateProductOpen} />
        </DefaultModal>
      </div>
    </div>
  )
}
