import { mdiListStatus } from '@mdi/js'
import Icon from '@mdi/react'
import { useState } from 'react'
import { IIncoming } from '../../../interfaces/Incoming'
import DefaultModal from '../../Modals/DefaultModal'
import ModalClientViewIncomingParcels from './Modals/Parcels'

import styles from './styles.module.css'

interface Props {
  incoming: IIncoming
}

export default function Billing({ incoming }: Props) {
  const [modalIncomingParcelsOpen, setModalIncomingParcelsOpen] = useState(false)

  return (
    <tr className={styles.construction__box}>
      <td>{incoming.incoming_type}</td>
      <td>
        {Number(incoming.value).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL'
        })}
      </td>
      <td>{incoming.payday ? incoming.payday : incoming.due_date}</td>
      <td>
        {incoming.parcels > 1 ? (
          <div onClick={() => setModalIncomingParcelsOpen(true)}>
            <Icon path={mdiListStatus} size={1.2} />
          </div>
        ) : (
          '-'
        )}
      </td>

      <DefaultModal isOpen={modalIncomingParcelsOpen} handleOpen={setModalIncomingParcelsOpen}>
        <ModalClientViewIncomingParcels incoming={incoming} setModalIncomingParcelsOpen={setModalIncomingParcelsOpen} />
      </DefaultModal>
    </tr>
  )
}
