import { BaseSyntheticEvent, ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { Autocomplete, InputAdornment, TextField } from '@mui/material'
import { mdiCreditCard } from '@mdi/js'
import { api } from '../../../../services/api'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { ptBR } from 'date-fns/locale'
import { MonetaryContext, MoneyOriginType } from '../../../../contexts/MonetaryContext'
import { ProvidersContext, ProviderType } from '../../../../contexts/ProvidersContext'
import { ClientsContext } from '../../../../contexts/ClientsContext'
import { IExpense } from '../../../../interfaces/Expense'
import { ColaboratorsContext, ColaboratorsType } from '../../../../contexts/ColaboratorsContext'

import Icon from '@mdi/react'
import Toast from '../../../Toast'
import styles from './style.module.css'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

interface SubmitType extends IExpense {
  images: File[]
}

type InputType = {
  value: string
  name: string
}

type ExpenseType = {
  id: number
  description: string
}

type ModalEditExpenseProps = {
  expense: IExpense
  setModalEditExpenseOpen: Dispatch<SetStateAction<boolean>>
  handleToast(toast: JSX.Element): Promise<void>
  getExpense?: () => Promise<void>
}

export default function ModalEditExpense({ expense, setModalEditExpenseOpen, handleToast, getExpense }: ModalEditExpenseProps) {
  const defaultFormValues = expense.parcels ? { parcels: expense.parcels } : { parcels: 1 }

  const [formValues, setFormValues] = useState(defaultFormValues as SubmitType)
  const [expenseInvoice, setExpenseInvoice] = useState<File | null>(null)

  const { expensesTypes, moneyOrigins } = useContext(MonetaryContext)
  const { providers } = useContext(ProvidersContext)
  const { clients } = useContext(ClientsContext)
  const { colaborators } = useContext(ColaboratorsContext)

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value
    }))
  }

  async function handleEdit(e: BaseSyntheticEvent) {
    e.preventDefault()

    if (expense.parcels === null && formValues.parcels > 1) {
      formValues.value = expense.value
    }

    const formData = new FormData()
    Object.keys(formValues).map((key) => formData.append(key, formValues[key]))

    if (expenseInvoice) {
      formData.set('invoice', expenseInvoice)
    }

    api
      .put(`/expenses/${expense.id}`, formData)
      .then((response) => {
        handleToast(<Toast category="success" message={response.data.message} />)
        if (getExpense) getExpense()
        setModalEditExpenseOpen(false)
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />)
      })
  }

  return (
    <div className={styles.box__container}>
      <div className={styles.box__modal}>
        <div className={styles.box__content}>
          <div className={styles.box__header}>
            <Icon path={mdiCreditCard} size={1.5} />
            <h4 className={styles.title}>EDITAR DESPESA</h4>
          </div>

          <form className={styles.form__edit} onSubmit={(e) => handleEdit(e)}>
            <TextField
              label="Valor"
              type="text"
              required
              autoComplete="off"
              value={formValues.value ? formValues.value : expense.value}
              onChange={(e) => {
                handleInputChange({
                  name: 'value',
                  value: e.target.value.replace(',', '.')
                })
              }}
            />

            <Autocomplete
              value={undefined}
              defaultValue={expensesTypes.find((type) => type.id === expense.expense_type_id)}
              onChange={(event, newValue: ExpenseType) => {
                handleInputChange({
                  name: 'expense_type_id',
                  value: newValue && String(newValue.id)
                })
              }}
              options={expensesTypes}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Tipo da despesa" required />}
            />

            <Autocomplete
              value={undefined}
              defaultValue={
                expense.subtype === 0 ? null : expense.subtype === 1 ? { description: 'Construtora', value: 1 } : { description: 'Escritório', value: 2 }
              }
              onChange={(event, newValue: { description: string; value: number }) => {
                handleInputChange({
                  name: 'subtype',
                  value: newValue && String(newValue.value)
                })
              }}
              options={[
                { description: 'Construtora', value: 1 },
                { description: 'Escritório', value: 2 }
              ]}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => <TextField {...params} label="Subtipo da Receita" required />}
            />

            <Autocomplete
              value={undefined}
              defaultValue={providers.find((provider) => provider.id === expense.provider_id)}
              onChange={(event, newValue: ProviderType) => {
                handleInputChange({
                  name: 'provider_id',
                  value: newValue && String(newValue.id)
                })
              }}
              options={providers}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Fornecedor" />}
            />

            <Autocomplete
              value={undefined}
              defaultValue={clients.find((client) => client.id === expense.client_id)}
              onChange={(event, newValue: ProviderType) => {
                handleInputChange({
                  name: 'client_id',
                  value: newValue && String(newValue.id)
                })
              }}
              options={clients}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Cliente" />}
            />

            <Autocomplete
              value={undefined}
              defaultValue={colaborators.find((colaborator) => colaborator.id === expense.employee_id)}
              onChange={(event, newValue: ColaboratorsType) => {
                handleInputChange({
                  name: 'employee_id',
                  value: newValue && String(newValue.id)
                })
              }}
              options={colaborators}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Colaborador" />}
            />

            <TextField
              label="Parcelas"
              type="number"
              required
              helperText={
                expense.parcels > 1 && formValues.parcels < 2
                  ? '* Essa despesa possuí mais de uma parcela cadastrada, se você alterar para uma parcela todas as parcelas cadastradas serão canceladas.'
                  : ''
              }
              value={formValues.parcels ? formValues.parcels : expense.parcels}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {formValues.value
                      ? formValues.value
                      : expense.value &&
                        `${formValues.parcels}x de ${(Number(formValues.value ? formValues.value : expense.value) / Number(formValues.parcels)).toFixed(2)}`}
                  </InputAdornment>
                )
              }}
              onChange={(e) => {
                handleInputChange({
                  name: 'parcels',
                  value: e.target.value
                })
              }}
            />

            {formValues.parcels == 1 ? (
              <>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                  <DatePicker
                    label="Data de Pagamento"
                    mask="__/__/____"
                    value={formValues.payday ? formValues.payday : expense.payday ? `${expense.payday.split('/').reverse().join('-')}, 00:00:00` : null}
                    onChange={(newValue) => {
                      handleInputChange({
                        name: 'payday',
                        value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                      })
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>

                {formValues.payday && (
                  <Autocomplete
                    value={undefined}
                    defaultValue={moneyOrigins.find((origin) => origin.id === expense.money_origin_id)}
                    onChange={(event, newValue: MoneyOriginType) => {
                      handleInputChange({
                        name: 'money_origin_id',
                        value: newValue && String(newValue.id)
                      })
                    }}
                    options={moneyOrigins}
                    getOptionLabel={(option) => option.description}
                    renderInput={(params) => <TextField {...params} label="Origem do Pagamento" />}
                  />
                )}

                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                  <DatePicker
                    label="Data de Vencimento"
                    mask="__/__/____"
                    value={formValues.due_date ? formValues.due_date : expense.due_date ? `${expense.due_date.split('/').reverse().join('-')}, 00:00:00` : null}
                    onChange={(newValue) => {
                      handleInputChange({
                        name: 'due_date',
                        value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                      })
                    }}
                    renderInput={(params) => <TextField {...params} required />}
                  />
                </LocalizationProvider>
              </>
            ) : (
              <>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                  <DatePicker
                    label="Data da Primeira Parcela"
                    mask="__/__/____"
                    value={
                      formValues.payday_first_parcel
                        ? formValues.payday_first_parcel
                        : expense.payday_first_parcel
                        ? `${expense.payday_first_parcel.split('/').reverse().join('-')}, 00:00:00`
                        : null
                    }
                    onChange={(newValue) => {
                      handleInputChange({
                        name: 'payday_first_parcel',
                        value: `${`${new Date(newValue).toLocaleDateString('pt-BR').split('/').reverse().join('-')}, 00:00:00`}`
                      })
                    }}
                    renderInput={(params) => <TextField {...params} required />}
                  />
                </LocalizationProvider>

                <Autocomplete
                  value={undefined}
                  defaultValue={moneyOrigins.find((origin) => origin.id === expense.money_origin_id)}
                  onChange={(event, newValue: MoneyOriginType) => {
                    handleInputChange({
                      name: 'money_origin_id',
                      value: newValue && String(newValue.id)
                    })
                  }}
                  options={moneyOrigins}
                  getOptionLabel={(option) => option.description}
                  renderInput={(params) => <TextField {...params} label="Origem do Pagamento" />}
                />
              </>
            )}

            <TextField
              label="Descrição"
              type="text"
              autoComplete="off"
              defaultValue={expense.description}
              onChange={(e) => {
                handleInputChange({
                  name: 'description',
                  value: e.target.value
                })
              }}
            />

            <label htmlFor="images">Nota Fiscal/Comprovante:</label>
            <input
              name="images"
              className={styles.inputfile}
              type="file"
              onChange={(e) => setExpenseInvoice(e.target.files[0])}
              style={{ padding: '0.9rem 1rem' }}
            />

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button className={styles.cancel} type="button" onClick={() => setModalEditExpenseOpen(false)}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
