import { useContext, useState } from 'react'

import styles from './style.module.css'

import Sidebar from '../../../components/Sidebar'
import links from '../../../components/configs/links-records'

import iPlus from '../../../assets/icons/plus.svg'

import { useForm } from 'react-hook-form'
import { Modal } from '@material-ui/core'
import { api } from '../../../services/api'

import Toast from '../../../components/Toast'
import { ColaboratorsContext } from '../../../contexts/ColaboratorsContext'
import { ConstructionsContext } from '../../../contexts/ConstructionsContext'
import { MonetaryContext } from '../../../contexts/MonetaryContext'
import ColaboratorDiary from '../../../components/ColaboratorDiary'
import { useEffect } from 'react'
import PDFColaboratorsDiariesGenerator from '../../../components/PDFColaboratorsDiariesGenerator'
import TableGeneric from '../../../components/TableGeneric'
import DefaultModal from '../../../components/Modals/DefaultModal'
import ModalCreateDiary from '../../../components/Modals/Diary/Create'
import ModalCreateColaboratorDiary from '../../../components/Modals/ColaboratorDiary/Create'
import ModalEditColaboratorDiary from '../../../components/Modals/ColaboratorDiary/Edit'

type DiarySubmitType = {
  construction_id: number
  employee_id: number
  money_origin_id: number
  value: number
  date: Date
}

type DiariesType = {
  id: number
  employee: string
  employee_id: number
  address: string
  address_name: string
  address_number: number
  construction_id: number
  money_origin_id: number
  value: number
  date: string
  _date: string
}

type DiariesFilterType = {
  start_date: Date
  finish_date: Date
  construction_id_filter: number
  employee_id_filter: number
}

type ColaboratorDebitsType = {
  total_vouchers: string
  total_expenses: string
}

type Header = {
  label: string
  ref: string
}

export default function ColaboratorsDiaries() {
  const { register, handleSubmit, reset } = useForm()

  const [modalCreateColaboratorDiaryOpen, setModalCreateColaboratorDiaryOpen] = useState(false)
  const [colaboratorsDiaries, setColaboratorsDiaries] = useState<DiariesType[]>([])

  const [toast, setToast] = useState<any>()

  const { moneyOrigins } = useContext(MonetaryContext)
  const { constructions } = useContext(ConstructionsContext)
  const { colaborators, diaries, getDiariesWithTheLastFilter, setLastFilter, lastFilter } = useContext(ColaboratorsContext)

  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')

  const [messageDefault, setMessageDefault] = useState('Busque as informações utilizando os filtros acima.')

  const [selectedEmployee, setSelectedEmployee] = useState(0)

  const tableHeaders: Header[] = [
    {
      label: 'Colaborador',
      ref: 'employee'
    },
    {
      label: 'Endereço',
      ref: 'address'
    },
    {
      label: 'Data',
      ref: 'date'
    },
    {
      label: 'Valor',
      ref: 'value'
    }
  ]

  useEffect(() => {
    handlerDates()
  }, [])

  function handlerDates() {
    var date = new Date()

    var day: any = date.getDate()
    var month: any = date.getMonth() + 1
    var year: any = date.getFullYear()

    var fifty: any = 0

    if (day <= 15) {
      fifty = 1
    } else {
      fifty = day - 15
    }

    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }
    if (fifty < 10) {
      fifty = '0' + fifty
    }

    const today = `${year}-${month}-${day}`
    const fiftyDays = `${year}-${month}-${fifty}`

    setInitialDate(fiftyDays)
    setFinalDate(today)
  }

  async function getColaboratorDebits(): Promise<ColaboratorDebitsType> {
    const debits: ColaboratorDebitsType = await api
      .get(`/employees/expenses/debits/${selectedEmployee}/${initialDate}/${finalDate}`)
      .then((response) => {
        return response.data
      })
      .catch((err) => {
        console.log(err)
      })

    return debits
  }

  async function downloadPDF() {
    let employee: string
    let debits: ColaboratorDebitsType

    if (selectedEmployee) {
      employee = colaborators.filter((colaborator) => colaborator.id === selectedEmployee)[0].name

      debits = await getColaboratorDebits()
    }

    PDFColaboratorsDiariesGenerator(colaboratorsDiaries, initialDate, finalDate, employee, debits)
  }

  function newColaboratorDiary(data: DiarySubmitType) {
    api
      .post('/employees/diaries', {
        employee_id: data.employee_id,
        construction_id: data.construction_id,
        value: data.value,
        date: data.date,
        money_origin_id: data.money_origin_id
      })
      .then((response) => {
        handleToast(<Toast category="success" message={response.data.message} />)
        setModalCreateColaboratorDiaryOpen(false)

        if (lastFilter) {
          getDiariesWithTheLastFilter()
        }

        reset()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function filterDiaries(data: DiariesFilterType) {
    let filter = {
      start_date: data.start_date || initialDate,
      finish_date: data.finish_date || finalDate,
      employee_id: data.employee_id_filter,
      construction_id: data.construction_id_filter
    }

    setLastFilter(filter)

    api
      .get(
        `/employees/diaries/filter/${data.start_date || initialDate}/${data.finish_date || finalDate}/${data.employee_id_filter}/${data.construction_id_filter}`
      )
      .then((response) => {
        if (response.data.length === 0) {
          setMessageDefault('Não foram encontradas diárias de acordo com o filtro.')
        }

        setColaboratorsDiaries(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function handleToast(toast) {
    setToast(toast)

    setTimeout(() => {
      setToast(null)
    }, 5000)
  }

  const bodyNewColaboratorDiary = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ maxWidth: '400px' }}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>
            <h4 className={styles.clientName}>Cadastrar diária de colaborador</h4>
          </div>

          <form className={styles.formEdit} onSubmit={handleSubmit(newColaboratorDiary)}>
            <label htmlFor="date">Data: *</label>
            <input name="date" required type="date" {...register('date')} />

            <label htmlFor="value">Valor: *</label>
            <input required name="value" type="text" {...register('value')} />

            <label htmlFor="construction_id">Obra: *</label>
            <select required name="construction_id" {...register('construction_id')} defaultValue="0">
              <option value="0">Selecione uma obra</option>
              {constructions.map((construction) => (
                <option value={construction.id}>
                  {construction.name} ({construction.client})
                </option>
              ))}
            </select>

            <label htmlFor="employee_id">Colaborador: *</label>
            <select name="employee_id" required {...register('employee_id')}>
              <option value="0">Selecione um colaborador</option>
              {colaborators.map((colaborator) => (
                <option value={colaborator.id}>{colaborator.name}</option>
              ))}
            </select>

            <label htmlFor="money_origin_id">Origem do dinheiro: *</label>
            <select name="money_origin_id" required {...register('money_origin_id')}>
              <option value="0">Selecione uma origem</option>
              {moneyOrigins.map((origin) => (
                <option value={origin.id}>{origin.description}</option>
              ))}
            </select>

            <div className={styles.buttons}>
              <button className={styles.confirm} type="submit">
                Confirmar
              </button>
              <button
                className={styles.cancel}
                onClick={() => {
                  setModalCreateColaboratorDiaryOpen(false)
                  reset()
                }}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

  return (
    <div className={styles.main}>
      <Sidebar config={links} />

      {toast}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>DIÁRIAS DE COLABORADORES</h1>

          <button className={styles.button} onClick={() => setModalCreateColaboratorDiaryOpen(true)}>
            <span>NOVA DIÁRIA</span>
            <img src={iPlus} alt="" />
          </button>
        </div>

        <form className={styles.formFilter} onSubmit={handleSubmit(filterDiaries)}>
          <div className={styles.row}>
            <div className={styles.inputBlock}>
              <label htmlFor="start_date">Data inicial</label>
              <input
                required
                type="date"
                id="start_date"
                name="start_date"
                {...register('start_date')}
                defaultValue={initialDate}
                onChange={(e) => setInitialDate(e.target.value)}
              />
            </div>

            <div className={styles.inputBlock}>
              <label htmlFor="finish_date">Data final</label>
              <input
                required
                type="date"
                id="finish_date"
                name="finish_date"
                {...register('finish_date')}
                defaultValue={finalDate}
                onChange={(e) => setFinalDate(e.target.value)}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.inputBlock}>
              <label htmlFor="employee_id_filter">Colaborador</label>
              <select
                required
                id="employee_id_filter"
                name="employee_id_filter"
                {...register('employee_id_filter')}
                onChange={(e) => setSelectedEmployee(Number(e.target.value))}
                defaultValue="0"
              >
                <option value="0">Nenhum</option>
                {colaborators.map((colaborators) => (
                  <option value={colaborators.id}>{colaborators.name}</option>
                ))}
              </select>
            </div>

            <div className={styles.inputBlock}>
              <label htmlFor="construction_id_filter">Obra</label>
              <select required id="construction_id_filter" name="construction_id_filter" {...register('construction_id_filter')} defaultValue="0">
                <option value="0">Nenhum</option>
                {constructions.map((construction) => (
                  <option value={construction.id}>
                    {construction.address}, {construction.address_number}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className={styles.buttons}>
            <button className={styles.confirm}>FILTRAR</button>
            <button disabled={!colaboratorsDiaries.length} onClick={() => downloadPDF()} className={styles.info} type="button">
              BAIXAR EM PDF
            </button>
          </div>
        </form>

        <div className={styles.table}>
          <TableGeneric
            headers={tableHeaders}
            data={colaboratorsDiaries}
            handleToast={handleToast}
            componentToRender="colaboratorsDiaries"
            fieldToSearch="name"
            title=""
          />
        </div>

        {/* <div className={styles.table}>
          {diaries.length || colaboratorsDiaries.length ? (
            diaries.length ? (
              diaries.map((diary) => <ColaboratorDiary key={diary.id} diary={diary} handleToast={handleToast} />)
            ) : (
              colaboratorsDiaries.map((diary) => <ColaboratorDiary key={diary.id} diary={diary} handleToast={handleToast} />)
            )
          ) : (
            <span>{messageDefault}</span>
          )}
        </div> */}

        {/* <Modal
          open={createDiaryOpen}
          onClose={() => setModalCreateColaboratorDiaryOpen(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyNewColaboratorDiary}
        </Modal> */}

        <DefaultModal isOpen={modalCreateColaboratorDiaryOpen} handleOpen={setModalCreateColaboratorDiaryOpen}>
          <ModalCreateColaboratorDiary handleToast={handleToast} setModalCreateColaboratorDiaryOpen={setModalCreateColaboratorDiaryOpen} />
        </DefaultModal>
      </div>
    </div>
  )
}
