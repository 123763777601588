import React from 'react';
import styles from './style.module.css';

import iEye from '../../assets/icons/eye.svg';

import { Dialog, Fade } from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';
import { api } from '../../services/api';
import Toast from '../Toast';

type ProjectParam = {
  project: ProjectType;
  handleToast: any;
  getColaboratorProjects: any;
};

type ProjectType = {
  id: number;
  client: string;
  client_id: number;
  project_type: string;
  project_type_id: number;
  value: number;
  area: string;
  criterion: string;
  local: string;
  start_date: string;
  start_date_unformated: number;
  finish_date: string;
  finish_date_prediction: string;
  finish_date_prediction_unformated: string;
  duration: number;
  responsable: string;
  responsable_id: number;
  leader_observation: string;
  status: number;
};

function ColaboratorProject({
  project,
  handleToast,
  getColaboratorProjects,
}: ProjectParam) {
  const [stage, setStage] = useState('');

  const [open, setOpen] = useState(false);
  const [modalBody, setModalBody] = useState(<div></div>);

  useEffect(() => {
    switch (project.status) {
      case 1:
        setStage('Em aberto');
        break;
      case 2:
        setStage('Em andamento');
        break;
      case 3:
        setStage('Em análise');
        break;
      case 4:
        setStage('Finalizado');
        break;
      default:
        setStage('Indefinido');
    }
  }, [project.status]);

  async function startProject() {
    await api
      .put(`/projects/start/${project.id}`)
      .then((response) => {
        setStage('Em andamento');
        getColaboratorProjects();
        setOpen(false);
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
      })
      .catch((err) => {
        handleToast(
          <Toast category="error" message={err.response.data.message} />
        );
      });
  }

  async function sendProjectToAnalysis() {
    await api
      .put(`/projects/send/${project.id}`)
      .then((response) => {
        setStage('Em análise');
        getColaboratorProjects();
        setOpen(false);
        handleToast(
          <Toast category="success" message={response.data.message} />
        );
      })
      .catch((err) => {
        handleToast(<Toast category="error" message={err.message} />);
      });
  }

  const bodyVisualizeProjectDetails = (
    <div className={styles.boxContainer}>
      <div className={styles.boxModal} style={{ width: '400px' }}>
        <div className={styles.boxContentDetails}>
          <div className={styles.boxHeader}>
            <span className={styles.clientName}>
              Projeto: {project.project_type}
            </span>
          </div>

          <span>
            Cliente: <b>{project.client}</b>
          </span>

          <span>
            Status: <b>{stage}</b>
          </span>

          <span>
            Início do Projeto: <b>{project.start_date}</b>
          </span>

          <span>
            Finalização (previsão): <b>{project.finish_date_prediction}</b>
          </span>

          {project.status === 1 || project.status === 2 ? (
            project.leader_observation ? (
              <span className={styles.observation}>
                <b>Observação da análise:</b> {project.leader_observation}
              </span>
            ) : null
          ) : project.status === 3 ? (
            <span className={styles.observation}>
              <b>Aguardando análise</b>
            </span>
          ) : null}

          {project.status === 1 ? (
            <button className={styles.nextStage} onClick={() => startProject()}>
              Iniciar Projeto
            </button>
          ) : project.status === 2 ? (
            <button
              className={styles.nextStage}
              onClick={() => sendProjectToAnalysis()}
            >
              Enviar Projeta para Revisão
            </button>
          ) : null}

          <button className={styles.buttonModal} onClick={() => setOpen(false)}>
            <span>Fechar</span>
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.box}>
      <div className={styles.boxInfoConstruction}>
        <span>
          {project.project_type} - <b>{stage}</b>
        </span>
      </div>

      <div className={styles.buttons}>
        <img
          src={iEye}
          alt=""
          onClick={() => {
            setModalBody(bodyVisualizeProjectDetails);
            setOpen(true);
          }}
        />
      </div>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        scroll="body"
        TransitionComponent={Fade}
        transitionDuration={200}
      >
        {modalBody}
      </Dialog>
    </div>
  );
}

export default React.memo(ColaboratorProject);
