import { createContext, useEffect } from 'react'
import { useState } from 'react'
import { api } from '../services/api'

type ConstructionsContextType = {
  getConstructions: () => Promise<void>
  getConstructionsByClient: (client_id: number) => Promise<void>
  stages: StageType[]
  constructions: ConstructionType[]
  clientConstructions: ConstructionType[]
}

export type ConstructionType = {
  id: number
  address: string
  name: string
  address_number: number
  client: string
  client_id: number
}

type StageType = {
  id: number
  description: string
}

export const ConstructionsContext = createContext({} as ConstructionsContextType)

export function ConstructionProvider({ children }) {
  const [constructions, setConstructions] = useState<ConstructionType[]>([])
  const [clientConstructions, setClientConstructions] = useState<ConstructionType[]>([])
  const [stages, setStages] = useState<StageType[]>([])

  useEffect(() => {
    if (constructions.length === 0) getConstructions()

    if (stages.length === 0) getStages()
  }, [])

  async function getStages() {
    await api
      .get('/stages')
      .then((response) => {
        setStages(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async function getConstructions() {
    await api
      .get('/constructions')
      .then((response) => {
        setConstructions(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async function getConstructionsByClient(client_id: number) {
    await api
      .get(`/constructions/client/${client_id}`)
      .then((response) => {
        setClientConstructions(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <ConstructionsContext.Provider
      value={{
        constructions,
        stages,
        getConstructions,
        getConstructionsByClient,
        clientConstructions
      }}
    >
      {children}
    </ConstructionsContext.Provider>
  )
}
